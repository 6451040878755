define('uwork-app-v2/mixins/contractor/is-my-order', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Mixin.create({
        session: Ember.inject.service(),
        account: Ember.inject.service('user/session-account'),
        currentUser: Ember.computed.alias('account.currentUser'),

        isMyOrder: Ember.computed('currentUser', 'order', function () {
            var user = Ember.get(this, 'currentUser');

            var contractor = Ember.get(user, 'contractor.id');
            var orderContractor = Ember.get(this, 'order.contractor.id');

            var contractee = Ember.get(user, 'contractee.id');
            var orderContractee = Ember.get(this, 'order.contractee.id');

            if (Ember.get(user, 'isContractor') && contractor === orderContractor) {
                return true;
            } else if (Ember.get(user, 'isContractee') && contractee === orderContractee) {
                return true;
            }
            return false;
        }),
        isNotYourOrder: Ember.computed('currentUser', 'order', function () {
            var user = Ember.get(this, 'currentUser');
            var contractor = Ember.get(user, 'contractor.id');
            var orderContractor = Ember.get(this, 'order.contractor.id');
            //console.log(get(user, 'isContractor') , contractor !== orderContractor , get(this,'order.isAccepted'));
            if (Ember.get(user, 'isContractor') && contractor !== orderContractor && Ember.get(this, 'order.isAccepted')) {
                return true;
            }
        })
    });
});