define('uwork-app-v2/alert/model', ['exports', 'ember-data', 'ember-data/attr', 'ember-data/relationships'], function (exports, _emberData, _attr, _relationships) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });


    var METHOD_EMAIL = 1;
    // const METHOD_SMS = 2;

    exports.default = _emberData.default.Model.extend({
        value: (0, _attr.default)(),
        event: (0, _attr.default)(),
        method: (0, _attr.default)('number'),
        createdAt: (0, _attr.default)('date'),

        user: (0, _relationships.belongsTo)('user'),
        contractor: (0, _relationships.belongsTo)('contractor'),
        contractee: (0, _relationships.belongsTo)('contractee'),

        events: [{ id: "Assignment.Created", value: 'Forespørsel opprettet' }, { id: "JobRequest.Accepted", value: 'Forespørsel akseptert' }],

        getEvent: Ember.computed('event', function () {
            var event = Ember.get(this, 'event');
            var events = Ember.get(this, 'events');

            var t = events.findBy('id', event);

            if (t) {
                return Ember.get(t, 'value');
            }

            return '';
        }),

        getMethod: Ember.computed('method', function () {
            var method = Ember.get(this, 'method');

            return method === METHOD_EMAIL ? 'E-post' : 'SMS';
        })
    });
});