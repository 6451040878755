define('uwork-app-v2/components/contractor/assignment/shift-item/component', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        tagName: '',
        actions: {
            leave: function leave(model) {
                var status = !model.get('leave');
                model.set('leave', status);
            },
            edit: function edit(model) {
                var status = !model.get('edit');

                model.set('edit', status);
            },
            save: function save(model) {
                var _this = this;

                Ember.set(this, 'didValidate', true);
                model.validate({ on: ['restTimeComment'] }).then(function (_ref) {
                    var validations = _ref.validations;


                    if (validations.get('isValid')) {
                        model.save().then(function () {
                            _this.swal({
                                title: "Dine opplysninger er lagret!",
                                timer: 1000,
                                showConfirmButton: false,
                                showCloseButton: false,
                                type: 'success'
                            });
                        }, function () {}).catch(function () {});
                    } else {
                        //console.log('isInvalid');
                    }
                });
            },
            restTime: function restTime(shift, key, time, format) {

                var element = Ember.get(shift, key);
                var value = 0;

                if (element >= 0 && element <= 24 && format === 'plus') {
                    value = element + parseFloat(time / 60);
                    Ember.set(shift, key, value);
                } else if (element > 0 && format === 'minus') {
                    value = element - parseFloat(time / 60);
                    Ember.set(shift, key, value);
                }
            }
        }
    });
});