define('uwork-app-v2/helpers/socials/linkedin-link', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.socialsLinkedinLink = socialsLinkedinLink;
    function socialsLinkedinLink(params /*, hash*/) {
        var model = params[0];

        var content = '<a href="https://www.linkedin.com/shareArticle?mini=true&url=' + model.get('url') + '&summary=' + model.get('title') + '&source=uWOrk.no" data-toggle="tooltip" data-placement="bottom" target="_blank" title="' + model.get('title') + '">\n' + '  <i class="linkedin mdi mdi-linkedin-box"></i>\n' + '</a>';
        content = Ember.String.htmlSafe(content);
        return content;
    }

    exports.default = Ember.Helper.helper(socialsLinkedinLink);
});