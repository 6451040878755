define('uwork-app-v2/components/bs/search-form/component', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        tagName: 'li',
        classNames: ['nav-item', 'align-items-center'],
        open: false,
        actions: {
            showSearchAction: function showSearchAction() {
                this.toggleProperty('open');
            }
        }
    });
});