define('uwork-app-v2/admin/contractor/edit/route', ['exports', 'ember-i18n'], function (exports, _emberI18n) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        title: (0, _emberI18n.translationMacro)('admin.contractor.profile.PageName'),
        breadCrumb: {},
        beforeModel: function beforeModel() {
            this.setLoading(true);
        },
        model: function model(params) {
            return Ember.RSVP.hash({
                contractor: this.store.queryRecord('contractor', { contractor_id: params.contractor_id, view: true }).catch(function () {}),
                skills: this.store.findAll('skill'),
                categories: this.store.findAll('category'),
                roles: this.store.findAll('role'),
                badges: this.store.findAll('badge'),
                approvals: this.store.findAll('approval'),
                courses: this.store.findAll('course'),
                tags: this.store.findAll('tag'),
                shifts: this.store.findAll('shift'),
                titles: this.store.findAll('contractor-title')
            });
        },
        afterModel: function afterModel() {
            var title = this.get('title');

            var bTitle = {
                title: title.string
            };
            this.setLoading(false);
            Ember.set(this, 'breadCrumb', bTitle);
        }
    });
});