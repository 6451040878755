define('uwork-app-v2/helpers/language-level-format', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.languageLevelFormat = languageLevelFormat;
    function languageLevelFormat(params /*, hash*/) {
        var current = parseInt(params[0]);
        var levels = params[1];
        var level = levels.findBy('id', current);
        if (level) {
            return Ember.get(level, 'name');
        }
        return '';
    }
    exports.default = Ember.Helper.helper(languageLevelFormat);
});