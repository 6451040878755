define('uwork-app-v2/admin/dashboard/route', ['exports', 'ember-i18n', 'ember-cli-jsonapi-pagination/mixins/routes/jsonapi-pagination', 'uwork-app-v2/config/environment'], function (exports, _emberI18n, _jsonapiPagination, _environment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend(_jsonapiPagination.default, {
        session: Ember.inject.service(),
        ajax: Ember.inject.service(),
        title: (0, _emberI18n.translationMacro)('admin.dashboard.PageName'),
        breadCrumb: {},
        queryParams: {
            order_status: {
                refreshModel: true,
                replace: true
            },
            week: {
                refreshModel: true,
                replace: true
            },
            year: {
                refreshModel: true,
                replace: true
            }
            // from: {
            //     refreshModel: true
            // },
            // to: {
            //     refreshModel: true
            // }
        },
        beforeModel: function beforeModel() {
            this.setLoading(true);
        },
        afterModel: function afterModel() {
            var title = this.get('title');

            var bTitle = {
                title: title.string
            };
            this.setLoading(false);
            Ember.set(this, 'breadCrumb', bTitle);
        },
        model: function model(params) {
            var store = this.store;

            var url = '' + _environment.default.APP.API_HOST + _environment.default.APP.API_NAMESPACE + '/admin/stats';
            var token = Ember.get(this, 'session.data.authenticated.token');
            var ajax = Ember.get(this, 'ajax');
            return Ember.RSVP.hash({
                stats: ajax.request(url, {
                    beforeSend: function beforeSend(xhr) {
                        xhr.setRequestHeader('Authorization', 'Bearer ' + token);
                    }
                }).then(function (stats) {
                    return stats;
                }),
                contractees: store.query('contractee', { recent: true, limit: 10 }),
                contractors: store.query('contractor', { recent: true, limit: 10 }),
                orderShifts: store.query('order-shift', {
                    week: params.week,
                    year: params.year,
                    calendar: true,
                    limit: 10,
                    includes: 'order,shift'
                })
            });
        }
    });
});