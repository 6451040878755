define('uwork-app-v2/user-notification-setting/model', ['exports', 'ember-data', 'ember-data/attr', 'ember-data/relationships'], function (exports, _emberData, _attr, _relationships) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.schema = undefined;
    var schema = exports.schema = {
        /**
         * Attributes
         */
        notification_type: (0, _attr.default)('string'),
        notification_value: (0, _attr.default)()
    };

    exports.default = _emberData.default.Model.extend(schema, {
        createdAt: (0, _attr.default)('mdate'),
        updatedAt: (0, _attr.default)('mdate'),
        user: (0, _relationships.belongsTo)('user')
    });
});