define('uwork-app-v2/components/admin/assignment/contractor-info/component', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        user: Ember.computed.alias('model.user'),
        rating: Ember.computed.alias('order.rating'),
        stars: Ember.computed('order.rating.stars', function () {
            var stars = Ember.get(this, 'order.rating.stars');
            return stars > 0 ? stars : 0;
        })
    });
});