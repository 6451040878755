define('uwork-app-v2/application/serializer', ['exports', 'ember-data/serializers/json-api'], function (exports, _jsonApi) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _jsonApi.default.extend({
        keyForAttribute: function keyForAttribute(attr) {
            return Ember.String.underscore(attr);
        },

        keyForRelationship: function keyForRelationship(key) {
            return Ember.String.underscore(key);
        }
    });
});