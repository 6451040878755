define('uwork-app-v2/components/contractee/settings/nda/main-display/component', ['exports', 'ember-local-storage'], function (exports, _emberLocalStorage) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        session: Ember.inject.service('session'),
        account: Ember.inject.service('user/session-account'),
        user: Ember.computed.alias('account.currentUser'),

        store: Ember.inject.service(),

        documents: Ember.computed.filterBy('model', 'isNDA'),
        modelIsEmpty: Ember.computed.empty('documents'),
        modelNotEmpty: Ember.computed.notEmpty('documents'),

        showPanel: Ember.computed.empty('model'),

        isWizard: Ember.computed.equal('user.isSignUpCompleted', false),
        wizardService: Ember.inject.service('contractee/wizard'),

        wizardStep: (0, _emberLocalStorage.storageFor)('contractee/wizard'),

        showPanelText: Ember.computed('showPanel', function () {
            return Ember.get(this, 'showPanel') ? 'form.btn.close' : 'form.btn.upload';
        }),
        showPanelIcon: Ember.computed('showPanel', function () {
            return Ember.get(this, 'showPanel') ? 'close-circle' : 'plus-circle';
        }),
        showPanelIconColor: Ember.computed('showPanel', function () {
            return Ember.get(this, 'showPanel') ? 'text-danger' : 'text-primary';
        }),
        actions: {
            insertNew: function insertNew() {
                this.toggleProperty('showPanel');
            },
            saveNext: function saveNext() {
                var next = Ember.get(this, 'wizardService').getNextRoute();
                this.sendAction('toRoute', Ember.get(next, 'route'));
            }
        }
    });
});