define('uwork-app-v2/components/bs/modal-header/component', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        classNames: ['modal-header'],
        actions: {
            closeModal: function closeModal() {
                this.sendAction("closeModal");
            }
        }
    });
});