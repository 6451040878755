define('uwork-app-v2/vacancy/model', ['exports', 'ember-data/model', 'ember-data/attr', 'moment', 'ember-data/relationships', 'uwork-app-v2/mixins/vacancy-mixin', 'uwork-app-v2/config/environment', 'uwork-app-v2/mixins/city-by-zipcode-mixin'], function (exports, _model, _attr, _moment, _relationships, _vacancyMixin, _environment, _cityByZipcodeMixin) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.schema = exports.ROLE_NAME = undefined;


    var TYPE_FULL_TIME = 1;
    var TYPE_PART_TIME = 10;
    var TYPE_PROJECT = 20;
    // const TYPE_SUBSTITUE = 30;
    // const TYPE_ENGAGMENT = 40;
    // const TYPE_TRAINEE = 50;
    // const TYPE_SEASON = 60;
    // const TYPE_APPRENTICE = 70;

    var STATUS_DEFAULT = 1;
    var STATUS_DRAFT = 1;
    var STATUS_UNPUBLISHED = 10;
    var STATUS_PUBLISHED = 20;
    var STATUS_SELECTED = 30;
    var STATUS_HIRED = 40;
    var STATUS_ARCHIVED = 50;

    var ROLE_NAME = exports.ROLE_NAME = "vacancy";

    var schema = exports.schema = {
        title: (0, _attr.default)('string'),
        description: (0, _attr.default)('string', { defaultValue: '' }),
        place: (0, _attr.default)('string'),
        zipCode: (0, _attr.default)('string'),
        numberOfPositions: (0, _attr.default)('number', { defaultValue: 1 }),
        type: (0, _attr.default)('number', { defaultValue: 1 }),
        status: (0, _attr.default)('number'),
        deadlineAt: (0, _attr.default)('mdate'),
        address: (0, _attr.default)('string'),
        prioritized: (0, _attr.default)('boolean', { defaultValue: false }),
        tags: (0, _attr.default)('array'),
        total: (0, _attr.default)('number'),
        vacancyPlan: (0, _relationships.belongsTo)('vacancy-plan'),
        vacancyPlanFeatures: (0, _relationships.hasMany)('vacancy-plan-feature'),
        vacancyFeaturePlanPivot: (0, _relationships.hasMany)('vacancy-feature-plan-pivot'),
        vacancyOccupations: (0, _relationships.hasMany)('vacancy-occupation')
    };

    exports.default = _model.default.extend(_cityByZipcodeMixin.default, schema, _vacancyMixin.default, {
        message: (0, _attr.default)('string'),
        contractee: (0, _relationships.belongsTo)('contractee'),
        applicants: (0, _relationships.hasMany)('applicant', { async: false }),
        //conversations: hasMany('conversation', {async: false}),
        applied: (0, _relationships.belongsTo)('applicant', { async: false, defaultValue: false }),
        selected: (0, _relationships.belongsTo)('applicant', { async: false, defaultValue: false }),
        hired: (0, _relationships.belongsTo)('applicant', { async: false }),
        selectedApplicants: (0, _relationships.hasMany)('applicant', { async: false }),
        //notSelectedApplicants: hasMany('applicant', {async: false}),
        //visits: hasMany('vacancy-visit', {async: false}),

        viewTags: Ember.computed('tags', function () {
            return Ember.get(this, 'tags').join(',');
        }),

        views: (0, _attr.default)('number'),

        slug: (0, _attr.default)('string'),
        slugID: Ember.computed.or('slug', 'id'),
        share: '' + _environment.default.APP.APP_SHARE,
        url: Ember.computed('slug', function () {
            return Ember.get(this, 'share') + Ember.get(this, 'slugID');
        }),
        createdAt: (0, _attr.default)(),
        updatedAt: (0, _attr.default)(),

        fullAddressFormatted: Ember.computed('address', 'zipCode', 'place', function () {
            var output = "";
            var address = Ember.get(this, 'address');
            var zipcode = Ember.get(this, 'zipCode');
            var place = Ember.get(this, 'place');

            if (address && zipcode && place) {
                output = address + ', ' + zipcode + ' ' + place;
            }

            if (!address && zipcode && place) {
                output = zipcode + ' ' + place;
            }

            return output ? Ember.String.htmlSafe(output) : false;
        }),

        hasExpired: Ember.computed('deadlineAt', function () {
            var deadlineAt = Ember.get(this, 'deadlineAt');
            //Add one day just to eliminate today for sure
            deadlineAt = (0, _moment.default)(deadlineAt).add(1, 'day');
            var now = (0, _moment.default)();

            return (0, _moment.default)(deadlineAt).isBefore(now, 'day');
        }),
        notApplied: Ember.computed('applied.length', 'hasExpired', function () {
            return !Ember.get(this, 'applied.length') && Ember.get(this, 'hasExpired');
        }),
        haveApplied: Ember.computed.notEmpty('applied'),
        isSelectedApplicant: Ember.computed.notEmpty('selected'),

        isDefault: Ember.computed.equal('status', STATUS_DEFAULT),
        isDraft: Ember.computed.equal('status', STATUS_DRAFT),
        isUnPublished: Ember.computed.equal('status', STATUS_UNPUBLISHED),
        isPublished: Ember.computed.equal('status', STATUS_PUBLISHED),
        isSelected: Ember.computed.equal('status', STATUS_SELECTED),
        isHired: Ember.computed.equal('status', STATUS_HIRED),
        isArchived: Ember.computed.equal('status', STATUS_ARCHIVED),

        showDescription: Ember.computed('description', function () {
            return Ember.get(this, 'description') ? Ember.String.htmlSafe(Ember.get(this, 'description')) : '';
        }),
        stripDescription: Ember.computed('description', function () {
            return Ember.get(this, 'description') ? Ember.get(this, 'description').replace(/<(?:.|\n)*?>/gm, '') : '';
        }),
        //noVisitor: equal('visits', 0),
        noApplicants: Ember.computed.equal('applicants', 0),

        viewsMsg: Ember.computed('views', function () {
            return Ember.get(this, 'views') ? Ember.get(this, 'views') + " Views" : "No View Yet";
        }),

        // visitorMsg: computed('noVisitor', 'visits', function () {
        //     return get(this, 'visits') ? get(this, 'visits').length + " Views" : "No Visits Yet";
        // }),

        applicantMsg: Ember.computed('noApplicants', 'applicants', function () {
            return Ember.get(this, 'noApplicants') === false ? Ember.get(this, 'applicants.length') + " applicants" : "No applicant Yet";
        }),

        setStatusDefault: function setStatusDefault() {
            Ember.set(this, 'status', STATUS_DEFAULT);
        },
        setStatusDraft: function setStatusDraft() {
            Ember.set(this, 'status', STATUS_DRAFT);
        },
        setStatusUnSelected: function setStatusUnSelected() {
            Ember.set(this, 'status', STATUS_UNPUBLISHED);
        },
        setStatusPublic: function setStatusPublic() {
            Ember.set(this, 'status', STATUS_PUBLISHED);
        },
        setStatusSelected: function setStatusSelected() {
            Ember.set(this, 'status', STATUS_SELECTED);
        },
        setStatusHired: function setStatusHired() {
            Ember.set(this, 'status', STATUS_HIRED);
        },
        setStatusArchived: function setStatusArchived() {
            Ember.set(this, 'status', STATUS_ARCHIVED);
        },
        setTypeFull: function setTypeFull() {
            Ember.set(this, 'type', TYPE_FULL_TIME);
        },
        setTypePart: function setTypePart() {
            Ember.set(this, 'type', TYPE_PART_TIME);
        },
        setTypeProject: function setTypeProject() {
            Ember.set(this, 'type', TYPE_PROJECT);
        },

        getVacancyType: Ember.computed('type', function () {
            var type = Ember.get(this, 'type');
            return this.getType(type);
        }),
        getVacancyStatus: Ember.computed('status', function () {
            var status = Ember.get(this, 'status');
            return this.getStatus(status);
        }),
        filterModel: function filterModel(text) {
            var zipCodes = Ember.get(this, 'zipCodes');
            var selected = zipCodes.findBy('zipCode', text);
            if (selected) {
                return true;
            }
            return false;
        }
    });
});