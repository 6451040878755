define('uwork-app-v2/components/contractee/dashboard/table-vacancy-items/component', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        store: Ember.inject.service(),
        tagName: 'div',
        classNames: ['home-vacancies'],
        didInsertElement: function didInsertElement() {
            this._super.apply(this, arguments);

            var vacancies = Ember.get(this, 'store').query('vacancy', { number: 1, size: 4, status: 20 });
            var hired = Ember.get(this, 'store').query('vacancy', { number: 1, size: 4, status: [1, 10] });

            Ember.set(this, 'vacancies', vacancies);
            Ember.set(this, 'hired', hired);
        }
    });
});