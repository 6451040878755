define('uwork-app-v2/contractee/assignments/index/controller', ['exports', 'ember-cli-jsonapi-pagination/mixins/controllers/jsonapi-pagination'], function (exports, _jsonapiPagination) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend(_jsonapiPagination.default, {
        size: 15,
        number: 1,
        statusOptions: [{ id: 1, name: 'active', 'label': 'Aktiv' }, { id: 2, name: 'completed', 'label': 'Fullført' }, { id: 3, name: 'ongoing', 'label': 'Pågående' }],
        order_status: 'active',
        queryParams: ['order_status'],
        media: Ember.inject.service(),
        totalPages: Ember.computed('size', 'number', 'model', function () {
            return Ember.get(this, 'model.meta.pagination.total_pages');
        }),
        outerWindow: Ember.computed('media.isXs', 'media.isSm', 'media.isMd', 'media.isLg', 'media.isXl', function () {
            return Ember.get(this, 'media.isXs') || Ember.get(this, 'media.isSm') ? 1 : 3;
        })
    });
});