define('uwork-app-v2/socket-guru/service', ['exports', 'ember-socket-guru/services/socket-guru', 'ember-get-config'], function (exports, _socketGuru, _emberGetConfig) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _socketGuru.default.extend({
        socketClient: 'pusher',
        config: {
            pusherKey: _emberGetConfig.default['socket-guru'].pusher.pusherKey,
            cluster: _emberGetConfig.default['socket-guru'].pusher.cluster
            //authEndpoint: ENV['socket-guru'].pusher.authEndpoint
        },

        observedChannels: {
            "notification": ['OrderAcceptedEvent', 'OrderDefaultRequestEvent', 'OrderJobRequestEvent', 'OrderDefaultRequestPusherEvent', 'OrderRatedEvent', 'OrderFeedbackReplyEvent', 'OrderFeedbackEvent']
            //"private-testing-pusher": ["TestingPusher"]
        }
    });
});