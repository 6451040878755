define('uwork-app-v2/admin/vacancies/view/controller', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        vacancy: Ember.computed.alias('model'),
        conversations: Ember.computed.alias('model.conversations'),
        contractee: Ember.computed.alias('model.contractee'),
        applicants: Ember.computed.alias('model.applicants'),
        applied: Ember.computed.alias('model.applied'),
        selected: Ember.computed.alias('model.selected'),
        hired: Ember.computed.alias('model.hired'),
        selectedApplicants: Ember.computed.alias('model.selectedApplicants'),
        actions: {
            toRoute: function toRoute(path) {
                this.transitionToRoute(path);
            },
            changeNotification: function changeNotification(model) {
                var _this = this;

                this.setLoading(true);

                model.setNotificationAll();
                model.save().then(function () {
                    _this.setLoading(false);

                    _this.swal({
                        title: "Alle vikarer har blitt varslet",
                        text: "Du vil nå kunne motta flere søkere på vakten.",
                        showCancelButton: false,
                        confirmButtonColor: "#14CA9C",
                        confirmButtonText: "OK"
                    }).then(function () {}, function () {}).finally(function () {});
                });
            }
        }
    });
});