define('uwork-app-v2/components/app/order-calendar/component', ['exports', 'moment'], function (exports, _moment2) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        session: Ember.inject.service(),
        account: Ember.inject.service('user/session-account'),
        user: Ember.computed.alias('account.currentUser'),
        events: [],
        header: {
            left: '',
            center: 'title',
            right: '',
            defaultView: ''
        },
        shiftsTimeRange: [{ id: 1, 'bg': 'fc-bg-dark-red', 'text': 'fc-text-red', 'name': 'Døgnvakt' }, { id: 2, 'bg': 'fc-bg-dark-green', 'text': 'fc-text-green', 'name': 'Dagvakt' }, { id: 3, 'bg': 'fc-bg-dark-yellow', 'text': 'fc-text-yellow', 'name': 'Kveldsvakt' }, { id: 4, 'bg': 'fc-bg-dark-gray', 'text': 'fc-text-gray', 'name': 'Nattvakt' }],
        didInsertElement: function didInsertElement() {
            var year = Ember.get(this, 'year');
            var week = Ember.get(this, 'week');
            var _moment = (0, _moment2.default)((0, _moment2.default)().set({ 'year': year, 'week': week }));
            Ember.$('#contractorCalendar').fullCalendar("gotoDate", _moment);
        },
        eventRender: function eventRender(event, element) {
            element.find(".fc-title").remove();
            element.find(".fc-time").remove();

            var content = element.find('.fc-content');

            //console.log(event);

            element.attr("title", event.title);
            element.data("content", event.description);

            var shiftType_id = event.shiftType ? event.shiftType : 1;

            var shiftsTimeRange = [{ id: 1, 'color': 'fc-bg-red', 'name': 'Døgnvakt' }, { id: 2, 'color': 'fc-bg-green', 'name': 'Dagvakt' }, { id: 3, 'color': 'fc-bg-yellow', 'name': 'Kveldsvakt' }, { id: 4, 'color': 'fc-bg-gray', 'name': 'Nattvakt' }];

            var shiftType = shiftsTimeRange.findBy('id', parseInt(shiftType_id));

            content.addClass(shiftType.color);

            var new_description = (0, _moment2.default)(event.start).format("HH:mm") + '-' + (0, _moment2.default)(event.end).format("HH:mm");
            content.append(new_description);

            element.popover({
                html: true,
                container: 'body',
                placement: 'top',
                trigger: 'hover',
                template: '<div class="popover popover-calendar" role="tooltip">' + '<div class="popover-arrow"></div>' + '<img class="popover-img" src="' + event.img + '"/>' + '<div class="popover-content text-gray-dark font-weight-normal"></div>' + '<small class="popover-title font-weight-normal text-gray-lighter"></small>' + '</div>'
            });
        },

        actions: {
            toEvent: function toEvent(event, jsEvent, view) {
                var user = Ember.get(this, 'user');

                if (Ember.get(user, 'isContractor')) {
                    this.sendAction("toRoute", 'contractor.assignments.view', event.routeID);
                } else if (Ember.get(user, 'isContractee')) {
                    this.sendAction("toRoute", 'contractee.assignments.view', event.routeID);
                } else if (Ember.get(user, 'isAdmin')) {
                    this.sendAction("toRoute", 'admin.assignments.view', event.routeID);
                }
            },
            navigateCalendar: function navigateCalendar(direction) {

                Ember.$('#contractorCalendar').fullCalendar(direction);
                var moment = Ember.$('#contractorCalendar').fullCalendar('getDate');

                this.sendAction("setPeriod", moment);
            }
        }
    });
});