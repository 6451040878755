define('uwork-app-v2/mixins/order-create-mixin-turnus', ['exports', 'moment'], function (exports, _moment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var keys = Object.keys;
    exports.default = Ember.Mixin.create({
        period: [{
            id: 10, label: '3-7 4-7'
        }, {
            id: 20, label: '7-14'
        }, {
            id: 30, label: '4-7 3-7'
        }, {
            id: 40, label: 'Hverdager'
        }],

        getPeriodTemplateByID: function getPeriodTemplateByID(id) {
            return Ember.get(this, 'period').findBy('id', id);
        },

        canTurnusSave: Ember.computed.and('changeset.orderShifts.length', 'isTurnus'),
        // endTime - check if is today timeEnd should not kill startTime ( mos e le me hi minus )
        canTurnusShifts: Ember.computed('isTurnus', 'changeset.period', 'changeset.contractee', 'changeset.contractor', 'changeset.startDate', 'changeset.shift', 'changeset.endDate', function () {
            if (Ember.get(this, 'isTurnus') && Ember.get(this, 'changeset.contractee.id') && Ember.get(this, 'changeset.contractor.id') && Ember.get(this, 'changeset.startDate') && Ember.get(this, 'changeset.endDate') && Ember.get(this, 'changeset.period') && Ember.get(this, 'changeset.shift')) {
                return true;
            }

            return false;
        }),

        isWeekDay: function isWeekDay(moment) {
            return moment.isoWeekday() < 6;
        },


        // Generate order shifts
        generateOrderShiftCustom: function generateOrderShiftCustom(changeset, duration) {
            var isWeekend = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;

            var store = Ember.get(this, 'store');
            var item = store.createRecord('order-shift');

            var shift = Ember.get(changeset, 'shift');

            var startDate = (0, _moment.default)(Ember.get(changeset, 'startDate')).format('YYYY-MM-DD');

            var startTime = Ember.get(changeset, 'startTime');
            var endTime = Ember.get(changeset, 'endTime');

            var fromDate = (0, _moment.default)(startDate + ' ' + startTime).add(duration, 'day');

            if (isWeekend) {
                var _item = this.isWeekDay(fromDate);

                if (!_item) {
                    return false;
                }
            }

            var toDate = (0, _moment.default)(startDate + ' ' + endTime).add(duration, 'day');

            Ember.set(item, 'shift', shift);
            Ember.set(item, 'fromDate', fromDate);

            //Check if is Døgnvakt or Nattvakt
            if (item.isNightShift()) {
                toDate = (0, _moment.default)(startDate + ' ' + endTime).add(duration + 1, 'day');
            }

            Ember.set(item, 'toDate', toDate);

            return item;
        },
        period3747: function period3747(duration, shifts, changeset) {
            var k = 0;

            for (var i = 0; i < duration; i++) {

                if (k < 3) {

                    var item = this.generateOrderShiftCustom(changeset, i);
                    shifts.pushObject(item);
                } else if (k >= 10 && k < 14) {

                    var _item2 = this.generateOrderShiftCustom(changeset, i);
                    shifts.pushObject(_item2);
                }

                if (k === 20) {
                    k = 0;
                } else {
                    k++;
                }
            }
        },
        period4737: function period4737(duration, shifts, changeset) {

            var k = 0;
            for (var i = 0; i < duration; i++) {

                if (k < 4) {
                    var item = this.generateOrderShiftCustom(changeset, i);
                    shifts.pushObject(item);
                } else if (k >= 11 && k < 14) {
                    var _item3 = this.generateOrderShiftCustom(changeset, i);
                    shifts.pushObject(_item3);
                }

                if (k === 20) {
                    k = 0;
                } else {
                    k++;
                }
            }
        },
        period714: function period714(duration, shifts, changeset) {
            var k = 0;
            for (var i = 0; i < duration; i++) {

                if (k < 7) {
                    var item = this.generateOrderShiftCustom(changeset, i);
                    shifts.pushObject(item);
                }

                if (k === 20) {
                    k = 0;
                } else {
                    k++;
                }
            }
        },
        periodBusinessDays: function periodBusinessDays(duration, shifts, changeset) {

            for (var i = 0; i < duration; i++) {
                var item = this.generateOrderShiftCustom(changeset, i, true);

                if (item) {
                    shifts.pushObject(item);
                }
            }
        },


        // Actions
        actions: {
            buildOrderShiftsCustom: function buildOrderShiftsCustom(changeset) {
                var _this = this;

                var snapshot = changeset.snapshot();

                Ember.set(this, 'btnText', 'assignment.new.button.updateShifts');

                // Reset errors & success variable
                Ember.set(this, 'errors', null);
                Ember.set(this, 'success', null);

                var modelschema = Ember.get(this, 'modelschema');

                var fromDate = Ember.get(changeset, 'fromDate');
                var toDate = Ember.get(changeset, 'toDate');

                var period = Ember.get(changeset, 'period');

                var shifts = Ember.get(changeset, 'orderShifts');
                // Reset previus shifts
                shifts.setObjects([]);

                var duration = toDate.diff(fromDate, 'days');

                //set duration per validation only usage
                Ember.set(changeset, 'duration', duration);

                return changeset.cast(keys(modelschema)).validate().then(function () {
                    if (Ember.get(changeset, 'isValid')) {

                        if (toDate.isSameOrBefore(fromDate)) {
                            changeset.pushErrors('endTime', 'Kan ikke være før tid fra.');
                        } else {

                            //console.log(period);

                            if (period === 40) {
                                _this.periodBusinessDays(duration, shifts, changeset);
                            } else {

                                // Loop through duration and generate order shifts


                                if (period === 10) {
                                    _this.period3747(duration, shifts, changeset);
                                }

                                if (period === 20) {
                                    _this.period714(duration, shifts, changeset);
                                }

                                if (period === 30) {
                                    _this.period4737(duration, shifts, changeset);
                                }
                            }
                        }
                    } else {
                        //console.log('isNotValid');
                    }
                }).catch(function () {
                    changeset.restore(snapshot);
                });
            },
            buildTimeRangesCustom: function buildTimeRangesCustom(changeset) {
                var shift = Ember.get(changeset, 'shift');
                var period = Ember.get(changeset, 'period');

                // Return if shift is not selected.
                if (!shift || !period) {
                    return;
                }

                var shiftId = parseInt(Ember.get(shift, 'id'));
                var now = (0, _moment.default)(),
                    currentDate = (0, _moment.default)().add(1, 'day');

                // // Set date if not set
                if (!Ember.get(changeset, 'startDate')) {
                    Ember.set(changeset, 'startDate', currentDate);
                } else if (!_moment.default.isMoment(Ember.get(changeset, 'startDate'))) {
                    var m = Ember.get(changeset, 'startDate');
                    Ember.set(changeset, 'startDate', (0, _moment.default)(m));
                }

                var startDate = Ember.get(changeset, 'startDate');

                if (!Ember.get(changeset, 'endDate')) {
                    var new_end = startDate.endOf('day').clone().add(20, 'days');
                    Ember.set(changeset, 'endDate', new_end);
                } else if (!_moment.default.isMoment(Ember.get(changeset, 'endDate'))) {
                    var _m = Ember.get(changeset, 'endDate');
                    Ember.set(changeset, 'endDate', (0, _moment.default)(_m));
                }

                var endDate = Ember.get(changeset, 'endDate');

                Ember.set(changeset, 'fromDate', startDate);
                Ember.set(changeset, 'toDate', endDate);

                var ranges = Ember.get(this, 'timeRanges');
                var range = ranges.findBy('id', shiftId);

                var start = (0, _moment.default)(Ember.get(changeset, 'startDate')).startOf('day').add(_moment.default.duration(range.start));
                if (start.isBefore(now)) {
                    start = (0, _moment.default)().startOf('hour').add(1, 'hour');
                }

                var end = start.clone().add(range.end.replace('+', ''), 'hours');
                if (shiftId === 2 && !end.isSame(start, 'day')) {
                    end = start.clone().endOf('day').startOf('hour').add(30, 'm');
                }
                //
                // //Set predefined range - filter is set at observer up
                Ember.set(changeset, 'startTime', start.format('HH:mm'));
                Ember.set(changeset, 'endTime', end.format('HH:mm'));
            },
            validateDateTimeCustom: function validateDateTimeCustom(changeset) {
                // let startDate = get(changeset, 'startDate');
                // let startTime = get(changeset, 'startTime');
                //
                // let endTime = get(changeset, 'endTime');
                //
                // let duration = get(changeset, 'duration');
                // let shift = parseInt(get(changeset, 'shift.id'));
                //
                // let start = moment(startDate).startOf('day').add(moment.duration(startTime));
                // let end = moment(startDate).startOf('day').add(moment.duration(endTime));
                //
                // if (shift === 2 || shift === 3) {
                //     duration = duration - 1;
                // }
                //
                // end.add(duration, 'd');
                //
                // set(changeset, 'fromDate', start);
                // set(changeset, 'toDate', end);
            }
        }

    });
});