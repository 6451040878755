define('uwork-app-v2/components/contractor/profile/display-skills/component', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        tagName: "div",
        editing: false,
        store: Ember.inject.service(),
        init: function init() {
            this._super.apply(this, arguments);
            var skills = Ember.get(this, 'store').findAll('skill');

            Ember.set(this, '_skills', skills);
        },

        sortByTitle: ['title:asc'],
        sortedCourses: Ember.computed.sort('skills', 'sortByTitle'),

        skills: Ember.computed('_skills.@each', 'user.skills.@each', function () {
            var selected = Ember.get(this, 'user.skills');
            var skills = Ember.get(this, '_skills');

            skills.forEach(function (skill) {
                var id = Ember.get(skill, 'id');
                var _selected = selected.findBy('id', id);
                if (_selected) {
                    Ember.set(skill, 'checked', true);
                }
            });

            return skills;
        }),
        addSkillsToUser: function addSkillsToUser(user) {
            var skills = Ember.get(this, 'skills');

            // model.forEach(function (skill) {
            //     if (skill.get('checked')) {
            //         user.get('skills').pushObject(skill);
            //     } else {
            //         user.get('skills').removeObject(skill);
            //     }
            // });

            var skillsChecked = skills.filterBy('checked', true);
            var skillsUnChecked = skills.filterBy('checked', false);

            user.get('skills').pushObjects(skillsChecked);
            user.get('skills').removeObjects(skillsUnChecked);
        },

        actions: {
            editForm: function editForm() {
                this.toggleProperty('editing');
            },
            save: function save(user) {
                var _this = this;

                user.then(function (data) {
                    _this.addSkillsToUser(data);
                    _this.setLoading(true);
                    data.save().then(function () {
                        _this.setLoading(false);
                        _this.swal("Dine opplysninger har blitt laget!");
                    });
                });
            }
        }
    });
});