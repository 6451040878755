define('uwork-app-v2/components/contractor/profile/content-main-default/component', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        contractor: Ember.computed.alias('model'),
        user: Ember.computed.alias('contractor.user'),
        itsMe: false,
        fileStore: Ember.computed.alias('user.fileStore'),
        attachment: Ember.computed.filterBy('fileStore', 'isAttachment')
    });
});