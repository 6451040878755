define('uwork-app-v2/vacancy-user-pivot/model', ['exports', 'ember-data/model', 'ember-data/attr', 'ember-data/relationships'], function (exports, _model, _attr, _relationships) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _model.default.extend({
        status: (0, _attr.default)('number'),
        vacancy_id: (0, _attr.default)('number'),
        message: (0, _attr.default)('string'),
        user_id: (0, _attr.default)('number'),
        user: (0, _relationships.belongsTo)('user'),
        vacancy: (0, _relationships.belongsTo)('vacancy')
    });
});