define('uwork-app-v2/components/cp/input-text/component', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        disabled: false,
        hasContent: Ember.computed.notEmpty('value').readOnly(),
        hasError: Ember.computed.and('shouldDisplayValidations', 'isInvalid').readOnly(),
        isValid: Ember.computed.alias('validation.isTruelyValid').readOnly(),
        isInvalid: Ember.computed.alias('validation.isInvalid').readOnly(),
        shouldDisplayValidations: Ember.computed.or('showValidations', 'hasContent', 'didValidate').readOnly(),
        didValidate: Ember.computed.readOnly('model.validations.didValidate'),
        showValidations: false,

        init: function init() {
            this._super.apply(this, arguments);
            var name = this.get('name');
            Ember.defineProperty(this, 'validation', Ember.computed.readOnly('model.validations.attrs.' + name));
        },
        focusOut: function focusOut() {
            this._super.apply(this, arguments);
            this.set('showValidations', true);
        }
    });
});