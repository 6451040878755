define('uwork-app-v2/contractee/settings/additional-payments/route', ['exports', 'ember-i18n'], function (exports, _emberI18n) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        title: (0, _emberI18n.translationMacro)('contractee.settings.pageName.additional_payments'),
        // wizardService: service('contractee/wizard'),
        wizardRouteName: 'contractee.settings.additional-payments',
        breadCrumb: {},
        // beforeModel() {
        //     this.setLoading(true);
        //
        //     // Set wizard route name
        //     get(this, 'wizardService').setCurrentRoute(get(this, 'wizardRouteName'));
        // },
        afterModel: function afterModel() {
            var title = this.get('title');

            var bTitle = {
                title: title.string
            };
            this.setLoading(false);
            Ember.set(this, 'breadCrumb', bTitle);
        }
    }
    // actions: {
    //     toRoute(path) {
    //         this.transitionTo(path);
    //     }
    // }
    );
});