define('uwork-app-v2/components/contractor/settings/experience/display-item/component', ['exports', 'moment'], function (exports, _moment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        isEditing: false,
        classNames: ['experience-item'],

        isCurrent: Ember.computed.equal('model.current', true),
        isNotCurrent: Ember.computed.equal('model.current', false),

        minDateFrom: (0, _moment.default)('01.01.1950').format('YYYY-MM-DD'),
        maxDateFrom: (0, _moment.default)().format('YYYY-MM-DD'),

        minDateTo: Ember.computed('model.started', function () {
            var started = Ember.get(this, 'model.started');
            return (0, _moment.default)(started).format('YYYY-MM-DD');
        }),
        maxDateTo: (0, _moment.default)().format('YYYY-MM-DD'),
        actions: {
            edit: function edit() {
                this.toggleProperty('isEditing');
            },
            close: function close(model) {
                model.rollbackAttributes();
                this.toggleProperty('isEditing');
            },
            delete: function _delete(model) {

                this.swal({
                    title: 'Slettet!',
                    type: "question",
                    confirmButtonColor: "#DD6B55",
                    confirmButtonText: 'Slettet',
                    showCloseButton: true
                }).then(function () {
                    model.destroyRecord();
                }, function () {});
            }
        }
    });
});