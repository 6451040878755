define('uwork-app-v2/components/contractor/profile/display-courses/component', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        tagName: "div",
        editing: false,
        store: Ember.inject.service(),
        init: function init() {
            this._super.apply(this, arguments);
            var courses = Ember.get(this, 'store').findAll('course');

            Ember.set(this, '_courses', courses);
        },

        sortByTitle: ['title:asc'],
        sortedCourses: Ember.computed.sort('courses', 'sortByTitle'),

        courses: Ember.computed('_courses.@each', 'user.courses.@each', function () {
            var selected = Ember.get(this, 'user.courses');
            var courses = Ember.get(this, '_courses');

            courses.forEach(function (course) {
                var id = Ember.get(course, 'id');
                var _selected = selected.findBy('id', id);
                if (_selected) {
                    Ember.set(course, 'checked', true);
                }
            });

            return courses;
        }),
        addCoursesToUser: function addCoursesToUser(user) {
            var courses = Ember.get(this, 'courses');

            // model.forEach(function (course) {
            //     if (course.get('checked')) {
            //         user.get('courses').pushObject(course);
            //     } else {
            //         user.get('courses').removeObject(course);
            //     }
            // });

            var coursesChecked = courses.filterBy('checked', true);
            var coursesUnChecked = courses.filterBy('checked', false);

            user.get('courses').pushObjects(coursesChecked);
            user.get('courses').removeObjects(coursesUnChecked);
        },

        actions: {
            editForm: function editForm() {
                this.toggleProperty('editing');
            },
            save: function save(user) {
                var _this = this;

                user.then(function (data) {
                    _this.addCoursesToUser(data);
                    _this.setLoading(true);
                    data.save().then(function () {
                        _this.setLoading(false);
                        _this.swal("Dine opplysninger har blitt laget!");
                    });
                });
            }
        }
    });
});