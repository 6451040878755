define('uwork-app-v2/components/contractor/settings/gcc/display-items/component', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        tagName: 'table',
        classNames: ['table', 'responsive']
    });
});