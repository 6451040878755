define('uwork-app-v2/components/contractee/card/main-vacancy-select/component', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        media: Ember.inject.service(),
        session: Ember.inject.service(),
        account: Ember.inject.service('user/session-account'),
        admin: Ember.computed.alias('account.currentUser'),
        user: Ember.computed.alias('model.user'),
        contractor: Ember.computed.alias('model.contractor'),
        classNames: ['user-card', 'd-flex', 'bg-white', 'align-items-stretch', 'mt-4'],
        actions: {
            cancel: function cancel() {
                var request = Ember.get(this, 'model');
                request.set('status', 3);
                request.save();
                this.sendAction('updated');
            },
            save: function save() {
                var _this = this;

                var admin = Ember.get(this, 'admin');
                var order = Ember.get(this, 'order');

                var request = Ember.get(this, 'model');
                var contractor = Ember.get(this, 'contractor');

                contractor.then(function (item) {
                    _this.setLoading(true);

                    order.set('contractor', item);
                    order.save().then(function () {
                        request.setAccept();
                        request.save();

                        if (Ember.get(admin, 'isAdmin')) {
                            _this.sendAction('toRoute', 'admin.assignments.view', order.get('id'));
                        } else {
                            _this.sendAction('toRoute', 'contractee.assignments.view', order.get('id'));
                        }

                        _this.setLoading(false);
                    }).catch(function () {

                        _this.sendAction('updated');
                        _this.setLoading(false);

                        _this.swal({
                            title: "Vikar er opptatt!",
                            html: "Det ser ut som vikar er opptatt i en annen jobb. Vikaren er nå fjernet fra denne listen.",
                            type: 'error'
                        });
                    });
                });
            }
        }
    });
});