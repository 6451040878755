define('uwork-app-v2/admin/contractor/edit/controller', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        fileStore: Ember.computed.alias('model.contractor.user.fileStore'),
        gcc: Ember.computed.filterBy('fileStore', 'isGCC'),
        attachment: Ember.computed.filterBy('fileStore', 'isAttachment')
    });
});