define('uwork-app-v2/user-data-export/model', ['exports', 'ember-data/model', 'ember-data/attr', 'uwork-app-v2/config/environment', 'ember-data/relationships'], function (exports, _model, _attr, _environment, _relationships) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });


    var STATUS_DATA_PENDING = 10;
    var STATUS_DATA_READY = 20;
    // const DATA_REQUEST_EXPIRES = 30;

    exports.default = _model.default.extend({
        user: (0, _relationships.belongsTo)('user'),

        status: (0, _attr.default)('number'),

        downloaded: (0, _attr.default)('boolean'),
        token: (0, _attr.default)('string'),

        deadline_at: (0, _attr.default)('mdate'),

        path: (0, _attr.default)('string'),

        clicked: (0, _attr.default)('boolean'),

        isDataPending: Ember.computed.equal('status', STATUS_DATA_PENDING),
        isDataReady: Ember.computed.equal('status', STATUS_DATA_READY),
        requestExpiresIn: Ember.computed('status', function () {
            //Tell users when they make new request user moment to calculate
            return null;
        }),

        url: Ember.computed('path', 'getUrlPath', function () {
            return this.getUrlPath() + '/' + Ember.get(this, 'path');
        }),

        getUrlPath: function getUrlPath() {
            return _environment.default.APP.API_USER_DATA_PATH;
        }
    });
});