define('uwork-app-v2/components/bs/text-lenght-counter/component', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        model: [],
        left: false,
        right: false,
        max: 300,
        status: false,
        textLength: Ember.computed('model', function () {
            return Ember.get(this, 'model') ? Ember.get(this, 'model').length : 0;
        }),
        classNameBindings: ['status:text-danger:text-primary', 'right:right'],
        lengthChanged: Ember.observer('model', 'max', function () {
            var model = Ember.get(this, 'model');

            if (model) {
                if (Ember.get(this, 'model').length === Ember.get(this, 'max')) {
                    Ember.set(this, 'status', true);
                } else {
                    Ember.set(this, 'status', false);
                }
            }
        })
    });
});