define('uwork-app-v2/components/contractor/edit/profile/user-profile/validation', ['exports', 'ember-changeset-validations/validators'], function (exports, _validators) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = {
        'user.firstName': (0, _validators.validatePresence)(true),
        'user.lastName': (0, _validators.validatePresence)(true),
        birthday: (0, _validators.validatePresence)(true),
        contractorTitle: (0, _validators.validatePresence)(true)
    };
});