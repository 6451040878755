define('uwork-app-v2/components/bs/date-picker-my/component', ['exports', 'ember-one-way-controls/components/one-way-input', 'uwork-app-v2/components/bs/date-picker-my/template', 'moment'], function (exports, _oneWayInput, _template, _moment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    function _toConsumableArray(arr) {
        if (Array.isArray(arr)) {
            for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
                arr2[i] = arr[i];
            }

            return arr2;
        } else {
            return Array.from(arr);
        }
    }

    exports.default = _oneWayInput.default.extend({
        layout: _template.default,
        tagName: 'div',
        returnAsMoment: false,
        _day: '01',
        init: function init() {
            this._super.apply(this, arguments);

            var _day = Ember.get(this, 'name') === 'start' ? '01' : '02';
            Ember.set(this, '_day', _day);

            if (Ember.get(this, 'id')) {
                Ember.set(this, 'elementId', 'custom-' + Math.random().toString(36).substring(7));
            }
        },
        didInsertElement: function didInsertElement() {
            this._super.apply(this, arguments);
            this._setup();
        },
        _setup: function _setup() {
            var val = Ember.get(this, '_value');
            if (val) {
                var dt = (0, _moment.default)(val);

                Ember.set(this, '_month', parseInt(dt.format('M')));
                Ember.set(this, '_year', dt.format('YYYY'));
            }
        },
        change: function change() {
            return true;
        },
        input: function input() {
            return true;
        },


        years: Ember.computed('yearRange', function () {
            var yearReange = Ember.get(this, 'yearRange');
            var count = 80;
            var start = 1940;

            if (yearReange) {
                var range = yearReange.split('|');
                var s = range[0];
                var e = range[1];

                if (s === 'NOW') {
                    s = (0, _moment.default)().format('YYYY');
                } else {
                    s = parseInt(range[0]);
                }

                if (e === 'NOW') {
                    e = (0, _moment.default)().format('YYYY');
                } else {
                    e = parseInt(range[1]);
                }

                count = e - s + 1;
                start = s;
            }

            return Array.apply(undefined, _toConsumableArray(Array(count))).map(function (_, i) {
                return '' + (i + start);
            }).reverse();
        }),

        months: [{ id: 1, value: 'Januar' }, { id: 2, value: 'Februar' }, { id: 3, value: 'Mars' }, { id: 4, value: 'April' }, { id: 5, value: 'Mai' }, { id: 6, value: 'Juni' }, { id: 7, value: 'Juli' }, { id: 8, value: 'August' }, { id: 9, value: 'September' }, { id: 10, value: 'Oktober' }, { id: 11, value: 'November' }, { id: 12, value: 'Desember' }],

        valueChanged: Ember.observer('_value', function () {
            var _value = Ember.get(this, '_value');

            if (!_value) {
                Ember.set(this, '_month', null);
                Ember.set(this, '_year', null);
            }
        }),

        dateChanged: Ember.observer('_day', '_month', '_year', function () {
            var day = Ember.get(this, '_day');
            var month = Ember.get(this, '_month');
            var year = Ember.get(this, '_year');

            if (day && month && year) {
                var string = year + '-' + month + '-' + day;

                var dt = (0, _moment.default)(string, 'YYYY-MM-DD');
                if (Ember.get(this, 'returnAsMoment') === true) {
                    var value = (0, _moment.default)(Ember.get(this, '_value'));

                    dt.add(value.hours(), 'h');
                    dt.add(value.minutes(), 'm');
                    dt.add(value.seconds(), 's');

                    this._processNewValue(dt);
                } else {
                    this._processNewValue(dt.format('YYYY-MM-DD'));
                }
            }
        })
    });
});