define('uwork-app-v2/helpers/nc-shift-icon', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.ncShiftIcon = ncShiftIcon;
    function ncShiftIcon(params /*, hash*/) {
        var name = params[0];

        var icons = [{ id: 1, name: 'Døgnvakt', icon: 'nc-progress-3' }, { id: 2, name: 'Dagvakt', icon: 'nc-brightness-46-3' }, { id: 3, name: 'Kveldsvakt', icon: 'nc-sun-fog-29-3' }, { id: 4, name: 'Nattvakt', icon: 'nc-night-3' }];
        var shift = icons.findBy('name', name);

        if (shift) {
            return Ember.get(shift, 'icon');
        }

        return 'nc-sun-fog-29-3';
    }

    exports.default = Ember.Helper.helper(ncShiftIcon);
});