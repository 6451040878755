define('uwork-app-v2/admin/assignments/ongoing/route', ['exports', 'ember-i18n', 'ember-cli-jsonapi-pagination/mixins/routes/jsonapi-pagination'], function (exports, _emberI18n, _jsonapiPagination) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend(_jsonapiPagination.default, {
        title: (0, _emberI18n.translationMacro)('admin.assignment.onGoing.PageName'),
        breadCrumb: {},
        beforeModel: function beforeModel() {
            this.setLoading(true);
        },

        queryParams: {
            contractor: { replace: true, refreshModel: true },
            contractee: { replace: true, refreshModel: true }
        },
        afterModel: function afterModel() {
            var title = this.get('title');

            var bTitle = {
                title: title.string
            };
            this.setLoading(false);
            Ember.set(this, 'breadCrumb', bTitle);
        },
        model: function model(params) {

            return Ember.RSVP.hash({
                contractors: this.store.query('contractor', { order: true }),
                contractees: this.store.query('contractee', { order: true }),
                orders: this.store.query('order', params).catch(function () {})
            });
        }
    });
});