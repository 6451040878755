define('uwork-app-v2/components/auth/confirm-form/component', ['exports', 'uwork-app-v2/components/auth/confirm-form/validation', 'uwork-app-v2/components/auth/confirm-form/schema', 'uwork-app-v2/auth/error-object-parser'], function (exports, _validation, _schema, _errorObjectParser) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var keys = Object.keys;
    exports.default = Ember.Component.extend({
        validation: _validation.default,
        session: Ember.inject.service(),
        ajax: Ember.inject.service(),
        errors: null,
        success: null,

        init: function init() {
            this._super.apply(this, arguments);
            Ember.set(this, 'model', _schema.default.create({ token: Ember.get(this, 'token') }));
        },


        actions: {
            confirm: function confirm(changeset) {
                var _this = this;

                var snapshot = changeset.snapshot();

                // Reset errors & success variable
                Ember.set(this, 'errors', null);
                Ember.set(this, 'success', null);

                return changeset.cast(keys(_schema.schema)).validate().then(function () {
                    if (Ember.get(changeset, 'isValid')) {
                        changeset.save().then(function (_ref) {
                            var payload = _ref.payload;

                            if (Ember.isPresent(payload.token)) {
                                Ember.set(_this, 'model', _schema.default.create());
                                Ember.set(_this, 'success', 'Konto aktivert. Fortsett til innlogging!');
                            }
                        }).catch(function (_ref2) {
                            var payload = _ref2.payload;

                            var parsedErrors = _errorObjectParser.default.parse(payload, changeset);

                            // Check if this is non attribute error
                            if (parsedErrors && parsedErrors.length > 0) {
                                Ember.set(_this, 'errors', parsedErrors);
                            }
                        });
                    } else {
                        //console.log('isNotValid');
                    }
                }).catch(function () {
                    changeset.restore(snapshot);
                });
            }
        }
    });
});