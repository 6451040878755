define('uwork-app-v2/index/route', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _authenticatedRouteMixin) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, {
        session: Ember.inject.service(),
        title: 'Dashboard',
        account: Ember.inject.service('user/session-account'),
        user: Ember.computed.alias('account.currentUser'),

        beforeModel: function beforeModel() {
            var session = Ember.get(this, 'session');
            if (session.get('isAuthenticated')) {
                this._setUserTransition();
            } else {
                this.transitionTo('auth.login');
            }
        },
        _setUserTransition: function _setUserTransition() {
            var isAuthenticated = this.get('session.isAuthenticated');
            var attemptedTransition = this.get('session.attemptedTransition');

            if (isAuthenticated) {
                if (Ember.get(this, 'user')) {

                    var user = Ember.get(this, 'user');

                    this.setLoading(false);
                    var isSignUpNotCompleted = Ember.get(user, 'isSignUpNotCompleted');

                    if (Ember.get(user, 'isContractor')) {
                        if (isSignUpNotCompleted) {
                            this.showAfterRender(false);
                            this.transitionTo('contractor.settings');
                        } else {
                            this.transitionTo('contractor.dashboard');
                        }
                    } else if (Ember.get(user, 'isContractee')) {
                        if (isSignUpNotCompleted) {
                            this.showAfterRender(false);
                            this.transitionTo('contractee.settings');
                        } else {
                            this.transitionTo('contractee.dashboard');
                        }
                    } else if (Ember.get(user, 'isAdmin')) {
                        this.transitionTo('admin.dashboard');
                    }
                }
            } else {
                Ember.set(this, 'session.attemptedTransition', null);
                attemptedTransition.retry();
            }
        }
    });
});