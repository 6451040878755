define('uwork-app-v2/application/service', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Service.extend({
        loading: true,
        isSigningIn: false,
        reloadNotifications: false,
        newMessage: false,
        toggleMessage: function toggleMessage() {
            this.toggleProperty('newMessage');
        }
    });
});