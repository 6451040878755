define('uwork-app-v2/agreement/model', ['exports', 'ember-data/model', 'ember-data/attr', 'ember-data/relationships'], function (exports, _model, _attr, _relationships) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.schema = undefined;
    var schema = exports.schema = {
        type: (0, _attr.default)(),
        title: (0, _attr.default)(),
        details: (0, _attr.default)(),
        status: (0, _attr.default)(),
        role: (0, _relationships.belongsTo)('role')
    };

    var TYPE_CONTRACTOR = 1;
    var TYPE_CONTRACTEE = 10;

    exports.default = _model.default.extend(schema, {
        showDetails: Ember.computed('details', function () {
            return Ember.String.htmlSafe(Ember.get(this, 'details'));
        }),
        createdAt: (0, _attr.default)(),
        updatedAt: (0, _attr.default)(),
        isContractor: Ember.computed.equal('type', TYPE_CONTRACTOR),
        isContractee: Ember.computed.equal('type', TYPE_CONTRACTEE)
    });
});