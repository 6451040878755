define('uwork-app-v2/timesheet/adapter', ['exports', 'uwork-app-v2/application/adapter'], function (exports, _adapter) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _adapter.default.extend({
        urlForQuery: function urlForQuery(query) {
            if (query.approve_week === true) {
                delete query.approve_week;
                return this.urlPrefix() + '/timesheets/weekly-approve';
            }

            if (query.monthly_approve === true) {
                delete query.monthly_approve;
                return this.urlPrefix() + '/timesheets/monthly-approve';
            }

            if (query.email === true) {
                delete query.email;
                return this.urlPrefix() + '/timesheets/email';
            }
            if (query.download === true) {
                return this.urlPrefix() + '/timesheets/download';
            }

            if (query.approve === true) {
                return this.urlPrefix() + '/timesheets/approve';
            }

            if (query.pay === true) {
                return this.urlPrefix() + '/timesheets/pay';
            }

            return this._super.apply(this, arguments);
        },
        urlForUpdateRecord: function urlForUpdateRecord(id, modelName, snapshot) {
            return this.urlPrefix() + '/timesheets/order-shift-approve';
            // return this._super(...arguments);
        }
    });
});