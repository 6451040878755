define('uwork-app-v2/components/contractee/settings/nda/display-form-new/schema', ['exports', 'uwork-app-v2/auth/default-schema'], function (exports, _defaultSchema) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.schema = undefined;
    var schema = exports.schema = {
        name: null,
        file: null,
        uploader: null,

        save: function save() {
            var file = Ember.get(this, 'file');
            var uploader = Ember.get(this, 'uploader');
            var data = this.getProperties('name');

            if (uploader) {
                return uploader.upload(file, data);
            }
        }
    };
    //import errorParser from 'uwork-app-v2/auth/error-object-parser';
    //ToDo - @flamur - Vec mu siguru qe qikjo DefaultSchema eshte ok qitu.
    exports.default = _defaultSchema.default.extend(schema);
});