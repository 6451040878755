define('uwork-app-v2/helpers/vacancy/vacancy-status', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.vacancyVacancyStatus = vacancyVacancyStatus;
    function vacancyVacancyStatus(params /*, hash*/) {
        var name = params[0];

        var icons = [{ id: 1, name: 'Kladd', value: 1, icon: '' }, { id: 2, name: 'Ikke publisert', value: 10, icon: '' }, { id: 3, name: 'Publisert', value: 20, icon: '' }, { id: 4, name: 'Søkere valgt', value: 30, icon: '' }, { id: 5, name: 'Ferdig', value: 40, icon: '' }, { id: 5, name: 'Arkivert', value: 50, icon: '' }];
        var level = icons.findBy('name', name);

        if (level) {
            return Ember.get(level, 'icon');
        }
        return '';
    }

    exports.default = Ember.Helper.helper(vacancyVacancyStatus);
});