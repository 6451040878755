define('uwork-app-v2/components/admin/management/user-request/edit-form/component', ['exports', 'uwork-app-v2/mixins/default-ds-action-mixin', 'uwork-app-v2/components/admin/management/user-request/edit-form/validation', 'uwork-app-v2/user-request/model'], function (exports, _defaultDsActionMixin, _validation, _model) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_defaultDsActionMixin.default, {
        store: Ember.inject.service(),
        validation: _validation.default,
        schema: _model.schema,
        modelType: "user-request",
        messageSave: "Lagret",

        actions: {
            accept: function accept(changeset) {
                var model = Ember.get(this, 'model');
                model.setStatusAccepted();
                Ember.set(this, 'messageSave', 'Forespørsel akseptert!');
                this.send('save', changeset);
            },
            decline: function decline(changeset) {
                var model = Ember.get(this, 'model');
                model.setStatusDeclined();
                Ember.set(this, 'messageSave', 'Forespørsel avslått!');
                this.send('save', changeset);
            }
        }
    });
});