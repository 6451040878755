define('uwork-app-v2/components/contractor/settings/video/display-main/component', ['exports', 'ember-local-storage'], function (exports, _emberLocalStorage) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        session: Ember.inject.service(),
        store: Ember.inject.service(),
        account: Ember.inject.service('user/session-account'),
        user: Ember.computed.alias('account.currentUser'),
        isWizard: Ember.computed.equal('user.isSignUpCompleted', false),
        wizardStep: (0, _emberLocalStorage.storageFor)('contractor/wizard'),
        videoIsVisible: false,
        isUploading: false,
        uploadProgress: 0,

        actions: {
            onFilesChange: function onFilesChange(files, uploader, component) {
                var _this = this;

                this.setLoading(true);
                var store = Ember.get(this, 'store');

                if (files) {
                    var file = files[0];

                    Ember.set(this, 'isUploading', true);

                    uploader.upload(file).then(function (result) {
                        store.pushPayload(Ember.get(component, 'modelType'), result);
                    });

                    uploader.on('progress', function (e) {
                        Ember.set(_this, 'uploadProgress', e.percent.toFixed(0));
                    });

                    uploader.on('didUpload', function (e) {
                        Ember.set(_this, 'uploadProgress', 0);
                        Ember.set(_this, 'isUploading', false);
                        _this.setLoading(false);
                    });
                }
            },
            toRoute: function toRoute() {
                var step = parseInt(Ember.get(this, 'wizardStep.step'));
                if (step === 4) {
                    this.set('wizardStep.step', 5);
                }
                this.sendAction('toRoute', 'contractor.settings.category');
            },
            back: function back() {
                this.sendAction('toRoute', 'contractor.settings.account');
            },
            selectVideo: function selectVideo() {
                Ember.set(this, 'videoIsVisible', true);
            }
        }
    });
});