define('uwork-app-v2/vacancy-plan/model', ['exports', 'ember-data', 'ember-data/attr', 'ember-data/relationships'], function (exports, _emberData, _attr, _relationships) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.schema = undefined;
    var schema = exports.schema = {
        name: (0, _attr.default)('string'),
        price: (0, _attr.default)('number'),
        status: (0, _attr.default)('boolean', { defaultValue: true })
    };

    exports.default = _emberData.default.Model.extend(schema, {
        plan: (0, _attr.default)('array'),
        features: (0, _attr.default)('array'),
        extraFeatures: (0, _attr.default)('array'),

        vacancy: (0, _relationships.belongsTo)('vacancy'),
        vacancyPlanFeatures: (0, _relationships.hasMany)('vacancy-plan-feature'),
        vacancyFeaturePlanPivot: (0, _relationships.hasMany)('vacancy-feature-plan-pivot'),

        slug: Ember.computed('plan.name', 'name', function () {

            var name = Ember.get(this, 'name') || Ember.get(this, 'plan.name') || false;

            return name ? this.generateSlug(name) : false;
        }),
        isStandard: Ember.computed('name', 'plan.name', function () {
            if (Ember.get(this, 'name')) {
                return Ember.get(this, 'name') === 'Standard';
            }
            return Ember.get(this, 'plan.name') === 'Standard';
        }),
        isPremium: Ember.computed('name', 'plan.name', function () {

            if (Ember.get(this, 'name')) {
                return Ember.get(this, 'name') === 'Premium';
            }
            return Ember.get(this, 'plan.name') === 'Premium';
        }),
        generateSlug: function generateSlug(name) {
            return name.toString().toLowerCase().replace(/\s+/g, '-') // Replace spaces with -
            .replace(/[^\w\-]+/g, '') // Remove all non-word chars
            .replace(/\-\-+/g, '-') // Replace multiple - with single -
            .replace(/^-+/, '') // Trim - from start of text
            .replace(/-+$/, '') || ''; // Trim - from end of text
        },

        total: Ember.computed('price', 'vacancyPlanFeatures.@each.checked', 'vacancyPlanFeatures.[].checked', function () {

            var checkedFeatures = Ember.get(this, 'vacancyPlanFeatures').filterBy('checked', true);

            var _total = 0;
            var total = Ember.get(this, 'isStandard') ? 0 : parseFloat(Ember.get(this, 'price'));

            checkedFeatures.forEach(function (item) {
                _total = _total + parseFloat(item.get('price'));
            });

            return total + _total;
        })
    });
});