define('uwork-app-v2/order-shift-rule/adapter', ['exports', 'uwork-app-v2/application/adapter'], function (exports, _adapter) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _adapter.default.extend({
        urlForQueryRecord: function urlForQueryRecord(query) {
            if (query.custom_request) {

                var contractor_id = query.contractor_id;
                var order_id = query.order_id;

                delete query.custom_request;
                delete query.order_id;
                delete query.contractor_id;

                return this.urlPrefix() + '/order-shift-rules/check-rules/' + order_id + '/' + contractor_id;
            }

            return this._super.apply(this, arguments);
        },
        urlForQuery: function urlForQuery(query) {
            if (query.custom_request) {

                var contractor_id = query.contractor_id;
                var order_id = query.order_id;

                delete query.custom_request;
                delete query.order_id;
                delete query.contractor_id;

                return this.urlPrefix() + '/order-shift-rules/check-rules/' + order_id + '/' + contractor_id;
            }

            return this._super.apply(this, arguments);
        }
    });
});