define('uwork-app-v2/user/session-account/service', ['exports', 'ember-local-storage'], function (exports, _emberLocalStorage) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Service.extend({
        session: Ember.inject.service('session'),
        intercom: Ember.inject.service(),
        store: Ember.inject.service(),
        storage: (0, _emberLocalStorage.storageFor)('user/local-storage'),
        account: null,
        loadCurrentUser: function loadCurrentUser() {
            var _this2 = this;

            var _this = this;
            return new Ember.RSVP.Promise(function (resolve, reject) {
                var token = _this.get('session.data.authenticated.token');
                var isMaster = _this.get('session.data.authenticated.isMaster');

                if (isMaster) {
                    _this.set('storage.isMaster', isMaster);
                } else {
                    _this.set('storage.isMaster', false);
                }

                if (!Ember.isEmpty(token)) {
                    return Ember.get(_this, 'store').queryRecord('user', {}).then(function (user) {
                        Ember.set(_this, 'currentUser', user);
                        //set(_this, 'unratedOrder', get(user, 'unratedOrder'));

                        var intercom = Ember.get(_this2, 'intercom');

                        if (Ember.get(user, 'isContractor')) {
                            Ember.set(intercom, 'user.name', Ember.get(user, 'fullName'));
                        } else if (Ember.get(user, 'isContractee')) {
                            Ember.set(intercom, 'user.name', Ember.get(user, 'contractee.name'));
                        }

                        Ember.set(intercom, 'user.email', Ember.get(user, 'email'));
                        Ember.set(intercom, 'user.createdAt', Ember.get(user, 'createdAt'));

                        resolve();
                    }, function () {
                        Ember.get(_this2, 'session').invalidate();
                    });
                } else {
                    reject();
                }
            });
        }
    });
});