define('uwork-app-v2/components/contractor/settings/biography/display-main/component', ['exports', 'ember-local-storage', 'uwork-app-v2/mixins/simditor-simple-mixin'], function (exports, _emberLocalStorage, _simditorSimpleMixin) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_simditorSimpleMixin.default, {
        wizardStep: (0, _emberLocalStorage.storageFor)('contractor/wizard'),
        session: Ember.inject.service(),
        account: Ember.inject.service('user/session-account'),
        user: Ember.computed.alias('account.currentUser'),
        contractor: Ember.computed.alias('user.contractor'),
        model: Ember.computed.alias('contractor'),
        isWizard: Ember.computed.equal('user.isSignUpCompleted', false),

        actions: {
            save: function save(model) {
                var _this2 = this;

                this.setLoading(true);
                model.then(function (m) {
                    m.save().then(function () {
                        _this2.swal('Lagret!');
                        //new Notification('Lagret!');
                        _this2.setLoading(false);
                    }, function () {
                        //console.log(error);
                        _this2.swal('Error', 'error');
                        // new Notification('Error!', 'error');
                    });
                });
            },
            saveNext: function saveNext(model) {
                var _this3 = this;

                var _this = this;

                _this.swal("Lagret!", 'info').then(function () {
                    //this.setLoading(false);
                    model.then(function (m) {
                        m.save().then(function () {

                            var step = parseInt(Ember.get(_this3, 'wizardStep.step'));
                            if (step === 9) {
                                _this.set('wizardStep.step', 10);
                            }
                            _this.sendAction('toRoute', 'contractor.settings.confirm');
                        });
                    });
                });
            },
            skip: function skip() {
                var step = parseInt(Ember.get(this, 'wizardStep.step'));
                if (step === 9) {
                    this.set('wizardStep.step', 10);
                }
                this.sendAction('toRoute', 'contractor.settings.confirm');
            }
        }
    });
});