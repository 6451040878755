define('uwork-app-v2/components/contractee/assignment/contractor-info/component', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        store: Ember.inject.service(),
        vacancy: Ember.inject.service(),
        menu: Ember.inject.service('extend-menu'),
        session: Ember.inject.service(),
        account: Ember.inject.service('user/session-account'),
        user: Ember.computed.alias('model.user'),
        rating: Ember.computed.alias('order.rating'),
        stars: Ember.computed('order.rating.stars', function () {
            var stars = Ember.get(this, 'order.rating.stars');
            return stars > 0 ? stars : 0;
        }),
        showModal: false,
        _attr: {},
        actions: {
            toggleFeedback: function toggleFeedback() {
                this.toggleProperty('showFeedback');
            },
            newMessage: function newMessage(user, model) {

                var sideNav = Ember.get(this, 'menu.menu');
                var vacancy = Ember.get(this, 'vacancy');

                //New Message
                vacancy.set('new', true);
                vacancy.set('order', model);
                vacancy.set('user', user);
                vacancy.set('type', 'order');
                vacancy.set('conversation', model.get('conversation'));

                sideNav.publicActions.showSubNavigation('messageNew');
            },
            updateRating: function updateRating(params) {
                var _this2 = this;

                var _this = this;
                var rating = params.rating;


                var store = Ember.get(_this, 'store');
                var order = Ember.get(_this, 'order');
                var ratingModel = Ember.get(order, 'rating');

                if (order.get('hasRating')) {

                    Ember.set(ratingModel, 'stars', rating);

                    ratingModel.save().then(function () {
                        _this2.swal({
                            title: "Din rating påh " + rating + " stjerner har blitt registrert! ",
                            timer: 1500,
                            showConfirmButton: false,
                            showCloseButton: false,
                            type: 'success'
                        }).then(function () {}, function () {}).finally(function () {
                            order.reload();
                        });
                    }, function () {
                        _this2.swal({
                            title: "En feil oppstår!",
                            timer: 1500,
                            showConfirmButton: false,
                            showCloseButton: false,
                            type: 'error'
                        });
                    });
                } else {
                    store.createRecord('rating', {
                        stars: rating,
                        order: order
                    }).save().then(function () {
                        _this2.swal({
                            title: "Din rating på " + rating + " stjerner har blitt registrert! ",
                            timer: 1500,
                            showConfirmButton: false,
                            showCloseButton: false,
                            type: 'success'
                        }).then(function () {}, function () {}).finally(function () {
                            order.reload();
                        });
                    }, function () {
                        _this2.swal({
                            title: "En feil oppstår!",
                            timer: 1500,
                            showConfirmButton: false,
                            showCloseButton: false,
                            type: 'error'
                        });
                    });
                }
            },
            addFeedback: function addFeedback() {
                var _this3 = this;

                var _this = this;
                var store = Ember.get(this, 'store');
                var order = Ember.get(this, 'order');
                var ratingModel = Ember.get(this, 'rating');

                if (order.get('hasRating')) {

                    Ember.set(ratingModel, 'comment', Ember.get(_this, '_attr.comment'));

                    ratingModel.save().then(function () {

                        _this3.swal({
                            title: "Tilbakemelding din har blitt registrert!",
                            timer: 1500,
                            showConfirmButton: false,
                            showCloseButton: false,
                            type: 'success'
                        }).then(function () {}, function () {}).finally(function () {
                            Ember.set(_this, '_attr.comment', '');
                            order.reload();
                        });
                    }, function () {
                        _this3.swal({
                            title: "En feil oppstår!",
                            timer: 1500,
                            showConfirmButton: false,
                            showCloseButton: false,
                            type: 'error'
                        });
                    });
                } else {
                    //let comment = get(_this, '_attr.comment');
                    //console.log(comment);

                    store.createRecord('rating', {
                        comment: Ember.get(_this, '_attr.comment'),
                        order: order
                    }).save().then(function () {

                        _this3.swal({
                            title: "Tilbakemelding din har blitt registrert!",
                            timer: 1500,
                            showConfirmButton: false,
                            showCloseButton: false,
                            type: 'success'
                        }).then(function () {}, function () {}).finally(function () {
                            // get(_this, 'account.currentUser').reload();
                            order.reload();
                            Ember.set(_this, '_attr.comment', '');
                        });
                    }, function () {
                        _this3.swal({
                            title: "En feil oppstår!",
                            timer: 1500,
                            showConfirmButton: false,
                            showCloseButton: false,
                            type: 'error'
                        });
                    });
                }

                this.toggleProperty('showFeedback');
            }
        }
    });
});