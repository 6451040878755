define('uwork-app-v2/category-pivot/model', ['exports', 'ember-data/model', 'ember-data/attr', 'ember-data/relationships'], function (exports, _model, _attr, _relationships) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _model.default.extend({
        type: (0, _attr.default)('number'),
        category_id: (0, _attr.default)('number'),
        //user_id: attr('number'),
        user: (0, _relationships.belongsTo)('user'),
        category: (0, _relationships.belongsTo)('category')
    });
});