define('uwork-app-v2/components/bs/modal-container/component', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        classNames: ['modal'],
        attributeBindings: ['tabindex', 'role', 'aria-labelledby', 'aria-hidden', 'data-backdrop', 'data-keyboard'],
        showModal: true,
        size: null,
        showModalRequired: Ember.observer('showModal', function () {
            var _this = this.$();

            if (Ember.get(this, 'showModal') && !_this.hasClass('show')) {
                _this.modal('show');
            } else {
                _this.modal('hide');
            }
        }),
        willDestroyElement: function willDestroyElement() {
            this._super.apply(this, arguments);
            var _this = this.$();

            _this.modal('hide');
        }
    });
});