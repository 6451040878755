define('uwork-app-v2/components/contractor/card/card-header-admin/component', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        statusType: Ember.computed('user.stage', function () {
            var model = Ember.get(this, 'user');

            if (Ember.get(model, 'isProfileCompleted')) {
                return 'info';
            }

            if (Ember.get(model, 'isApplied')) {
                return 'info';
            }

            if (Ember.get(model, 'isApproved')) {
                return 'primary';
            }

            if (Ember.get(model, 'isVerified')) {
                return 'success';
            }

            if (Ember.get(model, 'isDeclined')) {
                return 'danger';
            }

            if (Ember.get(model, 'isDeverified')) {
                return 'warning';
            }

            // Fallback to default
            return 'default';
        }),

        getCurrentStatus: Ember.computed('user.stage', function () {
            var model = Ember.get(this, 'user');

            if (Ember.get(model, 'isProfileCompleted')) {
                return 'form.btn.status.profileCompleted';
            }

            if (Ember.get(model, 'isApplied')) {
                return 'form.btn.status.profileCompleted';
            }

            if (Ember.get(model, 'isApproved')) {
                return 'form.btn.status.approved';
            }

            if (Ember.get(model, 'isDeclined')) {
                return 'form.btn.status.declined';
            }

            if (Ember.get(model, 'isVerified')) {
                return 'form.btn.status.verified';
            }

            if (Ember.get(model, 'isDeverified')) {
                return 'form.btn.status.deverified';
            }

            // Fallback to default
            return 'form.btn.status.default';
        })
    });
});