define('uwork-app-v2/mixins/has-notificaiton-mixin', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Mixin.create({
        session: Ember.inject.service(),
        store: Ember.inject.service(),
        hasNotificationAccountSession: Ember.inject.service('user/session-account'),
        hasNotificationAccountUser: Ember.computed.alias('hasNotificationAccountSession.currentUser'),
        contractorNotifications: [{
            "notification_type": "order_created",
            "notification_value": { 'email': false, 'sms': false }
        }, {
            "notification_type": "order_alerts",
            "notification_value": { 'email': false }
        }, {
            "notification_type": "vacancy_created",
            "notification_value": { 'email': false }
        }, {
            "notification_type": "newsletter",
            "notification_value": { 'email': false }
        }, {
            "notification_type": "new_message",
            "notification_value": { 'email': false }
        }],
        contracteeNotifications: [{
            "notification_type": "order_user_applied",
            "notification_value": { 'email': true },
            "label": "Do you want to be notified by email when someone has applied on a order?"
        }, {
            "notification_type": "vacancy_user_applied",
            "notification_value": { 'email': true },
            "label": "Do you want to be notified by email when someone has applied on a vacancy?"
        }, {
            "notification_type": "newsletter",
            "notification_value": { 'email': true }
        }, {
            "notification_type": "new_message",
            "notification_value": { 'email': true },
            "label": "Do you want to be notified by email when someone sends a message to you?"
        }],
        getModel: function getModel() {
            return Ember.get(this, 'store').findAll('user-notification-setting');
        },

        /**
         * Check if user has a specific notification
         *
         * @param name
         * @param resource
         */
        hasNotification: function hasNotification(name, resource) {
            var user = resource ? resource : Ember.get(this, 'hasNotificationAccountUser');
            return Ember.get(user, 'userNotificationSettings').findBy('notification_type', name);
        }
    });
});