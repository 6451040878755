define('uwork-app-v2/components/contractee/vacancy/conversation-item/component', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        conversation: Ember.inject.service(),
        store: Ember.inject.service(),
        account: Ember.inject.service('user/session-account'),
        user: Ember.computed.alias('account.currentUser'),
        _conversation: Ember.computed.alias('conversation.conversation'),
        messages: Ember.computed.alias('_conversation.messages'),
        appService: Ember.inject.service('application'),
        newMessage: null,
        didRender: function didRender() {
            var _element = this;
            var element = this.$();

            if (element.height() > 1) {

                var height = element.height();
                var parent = Ember.$("#" + _element.elementId).parent();

                Ember.$("#" + parent[0].id).scrollTop(height - 67);
                //jquery("#"+parent[0].id).animate({scrollTop: height - 200}, 700);
            }
        },

        actions: {
            submitNewConversation: function submitNewConversation(model) {
                var _this = this;

                var message = Ember.get(this, 'newMessage');
                var store = Ember.get(this, 'store');
                var user = Ember.get(model, 'interlocutor.user');

                if (message && message.length > 0) {
                    store.createRecord('message', {
                        message: message,
                        conversation: model,
                        user: user
                    }).save().then(function () {
                        model.reload();
                        Ember.set(_this, 'newMessage', '');
                    }, function () {}).finally(function () {
                        Ember.set(_this, 'newMessage', '');
                        Ember.get(_this, 'appService').toggleMessage();
                    }).finally(function () {
                        Ember.set(_this, 'newMessage', '');
                    });
                } else {
                    this.swal({
                        title: "OBS!",
                        html: "Meldingen din inneholder ikke noe tekst. Ønsker du fortsatt å sende meldingen?",
                        type: 'warning'
                    });
                }
            }
        }
    });
});