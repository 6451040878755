define('uwork-app-v2/contractor/settings/controller', ['exports', 'uwork-app-v2/mixins/has-role-mixin', 'uwork-app-v2/substitute/model', 'uwork-app-v2/vacancy/model'], function (exports, _hasRoleMixin, _model, _model2) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend(_hasRoleMixin.default, {
        ROLE_NAME_VACANCY: _model2.ROLE_NAME,
        ROLE_NAME_SUBSTITUTE: _model.ROLE_NAME,
        media: Ember.inject.service(),
        routing: Ember.inject.service('-routing'),
        menu: Ember.inject.service('extend-menu'),
        account: Ember.inject.service('user/session-account'),
        user: Ember.computed.alias('account.currentUser'),
        isWizard: Ember.computed.equal('user.isSignUpCompleted', false),
        wizardService: Ember.inject.service('contractor/wizard'),

        links: Ember.computed('user', function () {

            if (this.hasRole(_model.ROLE_NAME, Ember.get(this, 'user'))) {
                return [{ id: 1, route: 'contractor.settings.general', name: 'contractor.settings.link.general' }, { id: 2, route: 'contractor.settings.account', name: 'contractor.settings.link.account' }, { id: 3, route: 'contractor.settings.avatar', name: 'contractor.settings.link.avatar' }, { id: 4, route: 'contractor.settings.education', name: 'contractor.settings.link.education' }, { id: 5, route: 'contractor.settings.experience', name: 'contractor.settings.link.experience' }, { id: 6, route: 'contractor.settings.gcc', name: 'contractor.settings.link.gcc' }, { id: 7, route: 'contractor.settings.attachment', name: 'contractor.settings.link.attachment' }, { id: 8, route: 'contractor.settings.payment', name: 'contractor.settings.link.payment' }, { id: 9, route: 'contractor.settings.skills', name: 'contractor.settings.link.skills' }, { id: 10, route: 'contractor.settings.others', name: 'contractor.settings.link.others' }, { id: 11, route: 'contractor.settings.notifications', name: 'contractor.settings.link.notifications' }, { id: 12, route: 'contractor.settings.data', name: 'contractor.settings.link.user_data' }, { id: 13, route: 'contractor.settings.agreement', name: 'contractor.settings.link.agreement' }];
            }

            if (this.hasRole(_model2.ROLE_NAME, Ember.get(this, 'user'))) {
                return [{ id: 1, route: 'contractor.settings.general', name: 'contractor.settings.link.general' }, { id: 2, route: 'contractor.settings.account', name: 'contractor.settings.link.account' }, { id: 3, route: 'contractor.settings.education', name: 'contractor.settings.link.education' }, { id: 4, route: 'contractor.settings.experience', name: 'contractor.settings.link.experience' }, { id: 5, route: 'contractor.settings.attachment', name: 'contractor.settings.link.attachment' }, { id: 6, route: 'contractor.settings.skills', name: 'contractor.settings.link.skills' }, { id: 7, route: 'contractor.settings.notifications', name: 'contractor.settings.link.notifications' }, { id: 8, route: 'contractor.settings.data', name: 'contractor.settings.link.user_data' }, { id: 9, route: 'contractor.settings.avatar', name: 'contractor.settings.link.avatar' }];
            }

            return [];
        }),

        wizardNavLinks: Ember.computed('wizardService.visitedRoutes.[]', function () {
            return Ember.get(this, 'wizardService.getNavLinks');
        }),

        closeMenu: function closeMenu() {
            // let menu = get(this, 'menu.menu');
            // menu.publicActions.closeSubNavigation();
            if (Ember.$('#navbarToggleExternalContent').hasClass('show')) {
                Ember.$('#navbarToggleExternalContent').removeClass('show');
            }
        },

        actions: {
            closeMenu: function closeMenu() {
                this.closeMenu();
            }
        }
    });
});