define('uwork-app-v2/components/contractor/assigment/shift-item-leave/component', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });


    var ABSENCE_TYPE_SELF_DECLARATION = 10;
    var ABSENCE_TYPE_SICK_LEAVE = 20;
    var ABSENCE_TYPE_OTHER = 30;

    exports.default = Ember.Component.extend({
        tagName: 'tr',
        classNames: ['bg-yellow'],
        didValidate: false,
        model: false,
        store: Ember.inject.service(),
        init: function init() {
            this._super.apply(this, arguments);
            // let orderShift = get(this, 'orderShift');
            var store = Ember.get(this, 'store');

            var model = store.createRecord('order-shifts/add-absence');

            Ember.set(this, 'model', model);
        },

        actions: {
            save: function save(model) {
                var _this = this;

                var orderShift = Ember.get(this, 'orderShift');

                Ember.set(this, 'didValidate', true);
                model.validate({ on: ['absenceType'] }).then(function (_ref) {
                    var validations = _ref.validations;

                    model.set('orderShift', orderShift);
                    Ember.set(_this, 'didValidate', true);
                    if (validations.get('isValid')) {
                        model.save().then(function () {
                            orderShift.reload();
                            _this.swal({
                                title: "Dine opplysninger er lagret!",
                                timer: 1000,
                                showConfirmButton: false,
                                showCloseButton: false,
                                type: 'success'
                            });
                        });
                    } else {
                        //console.log('isInvalid');
                    }
                });
            },
            leave: function leave(shift) {
                this.sendAction('leave', shift);
            },
            saveLeave: function saveLeave(shift) {
                this.sendAction('saveLeave', shift);
            }
        }
    });
});