define('uwork-app-v2/components/contractor/dasboard/header-content/component', ['exports', 'uwork-app-v2/substitute/model', 'uwork-app-v2/vacancy/model', 'uwork-app-v2/mixins/has-role-mixin'], function (exports, _model, _model2, _hasRoleMixin) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_hasRoleMixin.default, {
        ROLE_NAME_VACANCY: _model2.ROLE_NAME,
        ROLE_NAME_SUBSTITUTE: _model.ROLE_NAME,
        session: Ember.inject.service(),
        viewMode: false,
        notificationStatus: Ember.computed('user.isNotificationEnabled', {
            get: function get(key) {
                return Ember.get(this, 'user.isNotificationEnabled') ? true : false;
            },
            set: function set(key, value) {
                var user = Ember.get(this, 'user');

                if (value) {
                    user.setNotificationEnabled();
                } else {
                    user.setNotificationDisabled();
                }

                user.save();

                return value;
            }
        }),
        classNames: ['contractor-header', 'user-header'],
        actions: {
            toRoute: function toRoute(path) {
                var data = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;

                this.sendAction("toRoute", path, data);
            }
        }
    });
});