define('uwork-app-v2/contractor/settings/index/controller', ['exports', 'uwork-app-v2/substitute/model', 'uwork-app-v2/vacancy/model'], function (exports, _model, _model2) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        ROLE_NAME_SUBSTITUTE: _model.ROLE_NAME,
        ROLE_NAME_VACANCY: _model2.ROLE_NAME,
        media: Ember.inject.service(),
        session: Ember.inject.service(),
        store: Ember.inject.service(),
        intercom: Ember.inject.service()
    });
});