define('uwork-app-v2/components/contractee/dashboard/card-user-feed/component', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        store: Ember.inject.service(),
        tagName: 'testimonials',
        classNames: ['testimonials'],
        didInsertElement: function didInsertElement() {
            this._super.apply(this, arguments);
            var usersShowcase = Ember.get(this, 'store').query('user', { showcase: 'showcase' });

            Ember.set(this, 'usersShowcase', usersShowcase);
        }
    });
});