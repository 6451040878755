define('uwork-app-v2/experience/model', ['exports', 'ember-data/model', 'ember-data/attr'], function (exports, _model, _attr) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.schema = undefined;
    var schema = exports.schema = {
        name: (0, _attr.default)('string'),
        title: (0, _attr.default)('string'),
        description: (0, _attr.default)(),
        started: (0, _attr.default)('mdate'),
        ended: (0, _attr.default)('mdate'),
        current: (0, _attr.default)('boolean', { defaultValue: false })
    };

    exports.default = _model.default.extend(schema, {
        isCurrent: Ember.computed.equal('current', true),
        isNotCurrent: Ember.computed.equal('current', false)
    });
});