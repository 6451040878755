define('uwork-app-v2/components/admin/contractee/edit-form/validation', ['exports', 'ember-changeset-validations/validators'], function (exports, _validators) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = {
        'user.firstName': (0, _validators.validatePresence)(true),
        'user.lastName': (0, _validators.validatePresence)(true),
        'user.email': (0, _validators.validateFormat)({ type: 'email' }),
        'name': (0, _validators.validatePresence)(true),
        'category': (0, _validators.validatePresence)(true)
    };
});