define('uwork-app-v2/components/contractor/card/main-select/component', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        media: Ember.inject.service(),
        user: Ember.computed.alias('model.user'),
        skills: Ember.computed.alias('user.skills'),
        classNameBindings: ['model.checked:checked'],
        classNames: ['user-card', 'd-flex', 'bg-white', 'align-items-stretch', 'mt-4']
    });
});