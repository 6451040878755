define('uwork-app-v2/helpers/return-object-ids', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.returnObjectIds = returnObjectIds;
  function returnObjectIds(params /*, hash*/) {
    //console.log(params);
    return params;
  }

  exports.default = Ember.Helper.helper(returnObjectIds);
});