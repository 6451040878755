define('uwork-app-v2/services/extend-menu', ['exports', 'ornio-nav-app/services/extend-menu'], function (exports, _extendMenu) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _extendMenu.default;
    }
  });
});