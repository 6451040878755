define('uwork-app-v2/components/display-jsonapi-errors/component', ['exports', 'uwork-app-v2/components/display-jsonapi-errors/template'], function (exports, _template) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        layout: _template.default,
        tagName: 'div',
        classNames: ['ornio-auth-alert']
    });
});