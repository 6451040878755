define('uwork-app-v2/components/contractee/settings/order-desc/main-order-desc/component', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        session: Ember.inject.service(),
        account: Ember.inject.service('user/session-account'),
        user: Ember.computed.alias('account.currentUser'),

        isCreating: false,

        panelIcon: Ember.computed('isCreating', function () {
            return Ember.get(this, 'isCreating') ? 'close-circle' : 'plus-circle';
        }),

        panelIconClass: Ember.computed('isCreating', function () {
            return Ember.get(this, 'isCreating') ? 'text-danger' : 'text-primary';
        }),

        panelIconLabel: Ember.computed('isCreating', function () {
            return Ember.get(this, 'isCreating') ? 'form.btn.close' : 'form.btn.add';
        }),

        // Display only saved items
        items: Ember.computed('model.[]', function () {
            //computed.filterBy('model', 'isNew', false),
            return Ember.get(this, 'model').filterBy('userId', parseInt(Ember.get(this, 'user.id'))).filterBy('isNew', false);
        }),

        actions: {
            create: function create() {
                this.toggleProperty('isCreating');
            }
        }
    });
});