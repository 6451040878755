define('uwork-app-v2/components/bs/bs-progress/component', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        tagName: 'div',
        classNames: ['progress'],
        label: false,
        value: 0,
        widthStyle: Ember.computed('value', function () {
            var value = Ember.get(this, 'value');
            return Ember.String.htmlSafe("width: " + value + "%");
        })
    });
});