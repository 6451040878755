define('uwork-app-v2/components/contractor/sidebar/nav-settings/component', ['exports', 'ember-local-storage'], function (exports, _emberLocalStorage) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        routing: Ember.inject.service('-routing'),
        account: Ember.inject.service('user/session-account'),
        wizardStep: (0, _emberLocalStorage.storageFor)('contractor/wizard'),
        wizardCurrentStep: Ember.computed.alias('wizardStep.step'),
        user: Ember.computed.alias('account.currentUser'),
        isWizard: Ember.computed.equal('user.isSignUpCompleted', false),
        tagName: 'ul',
        classNames: ['nav', 'flex-column', 'nav-pills', 'user-setting-nav'],
        links: [{ id: 1, route: 'contractor.settings.general', name: 'contractor.account.edit.personal.general' }, { id: 2, route: 'contractor.settings.account', name: 'contractor.account.edit.personal.who-are-you' }, { id: 3, route: 'contractor.settings.avatar', name: 'contractor.view.avatar' }, { id: 4, route: 'contractor.settings.video', name: 'contractor.view.video' }, { id: 5, route: 'contractor.settings.category', name: 'contractor.view.category-title' }, { id: 6, route: 'contractor.settings.education', name: 'contractor.view.education-title' }, { id: 7, route: 'contractor.settings.experience', name: 'contractor.view.experience-title' }, { id: 8, route: 'contractor.settings.gcc', name: 'contractor.view.gcc' }, { id: 9, route: 'contractor.settings.payment', name: 'contractor.account.edit.business-payment.pageName' }, { id: 10, route: 'contractor.settings.skills', name: 'contractor.view.skills' }, { id: 11, route: 'contractor.settings.languages', name: 'contractor.view.language-title' }, { id: 12, route: 'contractor.settings.biography', name: 'contractor.view.biography' }],
        linksWizard: [{ id: 1, route: 'contractor.settings.index', name: 'contractor.view.index' }, { id: 2, route: 'contractor.settings.account', name: 'contractor.account.edit.personal.who-are-you' }, { id: 3, route: 'contractor.settings.avatar', name: 'contractor.view.avatar' }, { id: 4, route: 'contractor.settings.video', name: 'contractor.view.video' }, { id: 5, route: 'contractor.settings.category', name: 'contractor.view.category-title' }, { id: 6, route: 'contractor.settings.education', name: 'contractor.view.education-title' }, { id: 7, route: 'contractor.settings.experience', name: 'contractor.view.experience-title' }, { id: 8, route: 'contractor.settings.gcc', name: 'contractor.view.gcc' }, { id: 9, route: 'contractor.settings.payment', name: 'contractor.account.edit.business-payment.pageName' }, { id: 10, route: 'contractor.settings.skills', name: 'contractor.view.skills' }, { id: 11, route: 'contractor.settings.languages', name: 'contractor.view.language-title' }, { id: 12, route: 'contractor.settings.biography', name: 'contractor.view.biography' }, { id: 13, route: 'contractor.settings.confirm', name: 'contractor.account.wizard.complete-signup' }],
        // routeChanged: observer('currentRouteName', function () {
        //     let currentRouteName = get(this, 'currentRouteName');
        //     let routeNames = currentRouteName.split('.');
        //     let links = get(this, 'links');
        //
        //     let index = links.indexOf(routeNames[2]);
        //
        //     set(this, 'index', index);
        // }),
        linksWizardChanged: Ember.computed('routing', 'linksWizard', 'currentRouteName', 'wizardCurrentStep', function () {
            var wizardCurrentStep = Ember.get(this, 'wizardCurrentStep');
            return Ember.get(this, 'linksWizard').slice(0, wizardCurrentStep);
        }),
        didRender: function didRender() {
            this._super.apply(this, arguments);

            var routing = Ember.get(this, 'routing');
            Ember.set(this, 'currentRouteName', routing.get('currentRouteName'));
        }
    });
});