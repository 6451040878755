define('uwork-app-v2/components/contractee/settings/contractee-profile/validation', ['exports', 'ember-changeset-validations/validators'], function (exports, _validators) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = {

        firstName: (0, _validators.validatePresence)({ presence: true, description: 'Fornavn' }),
        lastName: (0, _validators.validatePresence)({ presence: true, description: 'Etternavn' }),
        email: (0, _validators.validateFormat)({ type: 'email', description: 'E-postadresse' }),
        password: (0, _validators.validateLength)({ min: 8, allowBlank: true, description: 'Passord' }),
        passwordConfirmation: (0, _validators.validateConfirmation)({ on: 'password', allowBlank: true, description: 'Bekreft passord' })
    };
});