define('uwork-app-v2/mixins/swal-mixin', ['exports', 'ember-sweetalert'], function (exports, _emberSweetalert) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Mixin.create({

        /**
         * Sweet Alert 2 wrapper
         *
         * @param options
         * @param type ['success', 'error', 'warning', 'info', 'question']
         */
        swal: function swal(options) {
            var type = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'success';

            var title = '';

            if (typeof options === 'string' || options instanceof String) {
                title = options;
                options = {};
            }

            var _options = {
                title: title,
                type: type,
                showCancelButton: false,
                confirmButtonColor: "#14CA9C",
                confirmButtonText: "OK",
                showCloseButton: true
            };

            Ember.assign(_options, options);

            return (0, _emberSweetalert.default)(_options);
        },
        swalNotify: function swalNotify(options) {
            var type = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'success';

            var title = '';

            if (typeof options === 'string' || options instanceof String) {
                title = options;
                options = {};
            }

            var _options = {
                title: title,
                type: type,
                timer: 2000,
                showCancelButton: false,
                showConfirmButton: false,
                showCloseButton: false
            };

            Ember.assign(_options, options);

            return (0, _emberSweetalert.default)(_options);
        }
    });
});