define('uwork-app-v2/attachment/adapter', ['exports', 'uwork-app-v2/application/adapter'], function (exports, _adapter) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _adapter.default.extend({
        // urlForFindAll(modelName, snapshot) {
        //     if(snapshot.attr('type') === 60 || snapshot.attr('type') === 70){
        //         return this._buildURL('attachment');
        //     }
        //
        //     // return this._buildURL(modelName);
        //     return this._super(...arguments);
        // },
        // urlForQuery(query) {
        //     if (query.showcase) {
        //         delete query.showcase;
        //         return `${this.urlPrefix()}/users/showcase`;
        //     }
        //     return this._super(...arguments);
        // },
        // urlForUpdateRecord(id, modelName, snapshot){
        //     get(this, 'reload').perform();
        //     return this._buildURL(modelName, id);
        // },
        // urlForQueryRecord(query) {
        //     if (query.email) {
        //         //delete query.email;
        //         return `${this.urlPrefix()}/users/email`;
        //     }
        //
        //     if (query.name) {
        //         //delete query.email;
        //         return `${this.urlPrefix()}/users/company`;
        //     }
        //
        //     return this._super(...arguments);
        // },
    });
});