define('uwork-app-v2/contractee-payment/model', ['exports', 'ember-data/model', 'ember-data/attr', 'ember-data/relationships', 'ember-cp-validations'], function (exports, _model, _attr, _relationships, _emberCpValidations) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.schema = undefined;


    var TYPE_PERCENTAGE = 10;
    var TYPE_RATES = 20;

    var Validations = (0, _emberCpValidations.buildValidations)({
        title: (0, _emberCpValidations.validator)('presence', true),
        value: (0, _emberCpValidations.validator)('presence', true),
        type: (0, _emberCpValidations.validator)('presence', true)
    });

    // Kveldstillegg/"Evening additional pay": [% or NOK]
    // Nattillegg/"Night shift additional pay": [% or NOK]
    // Helgetillegg/ "Weekend shift additional pay": [% or NOK]
    // Helge og høytidstillegg/ "Holiday additional pay": [% or NOK]

    var schema = exports.schema = {
        title: (0, _attr.default)('string'),
        description: (0, _attr.default)('string'),
        value: (0, _attr.default)('number'),
        type: (0, _attr.default)('number'),
        contractee: (0, _relationships.belongsTo)('contractee')
    };

    exports.default = _model.default.extend(Validations, schema, {

        types: [{ id: TYPE_PERCENTAGE, type: '%' }, { id: TYPE_RATES, type: 'NOK' }],
        titles: [{ id: 1, key: 'evening', name: 'Kveldstillegg' }, { id: 2, key: 'night', name: 'Nattillegg' }, { id: 3, key: 'weekend', name: 'Helgetillegg' }, { id: 4, key: 'holiday', name: 'Helge og høytidstillegg' }, { id: 5, key: 'all_day', name: 'Medlever tillegg per døgn' }],
        getTitleByKey: Ember.computed('title', function () {
            var titles = Ember.get(this, 'titles');
            var type = Ember.get(this, 'title') && titles.findBy('key', Ember.get(this, 'title'));

            return type.name;
        }),

        isPercentage: Ember.computed.equal('type', TYPE_PERCENTAGE),
        isRate: Ember.computed.equal('type', TYPE_RATES),
        setType: function setType(type) {
            Ember.set(this, 'type', type);
        },

        getType: Ember.computed('type', function () {
            var types = Ember.get(this, 'types');
            var type = Ember.get(this, 'type') ? types.findBy('id', Ember.get(this, 'type')) : '%';

            return type.type;
        })

    });
});