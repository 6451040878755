define('uwork-app-v2/email/model', ['exports', 'ember-data/model', 'ember-data/attr'], function (exports, _model, _attr) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _model.default.extend({
        date: (0, _attr.default)('mdate'),

        from: (0, _attr.default)('string'),
        to: (0, _attr.default)('string'),

        //bcc: attr('string'),
        headers: (0, _attr.default)('string'),
        subject: (0, _attr.default)('string'),
        body: (0, _attr.default)('string'),
        previewBody: Ember.computed('body', function () {
            return Ember.String.htmlSafe(Ember.get(this, 'body'));
        })
        // headers: attr('string'),
        // attachments: attr('files'),
    });
});