define('uwork-app-v2/components/contractor/settings/contractor-profile/component', ['exports', 'ember-local-storage', 'uwork-app-v2/components/contractor/settings/contractor-profile/validation', 'uwork-app-v2/user/model'], function (exports, _emberLocalStorage, _validation, _model) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var keys = Object.keys;
    exports.default = Ember.Component.extend({
        wizardStep: (0, _emberLocalStorage.storageFor)('contractor/wizard'),
        session: Ember.inject.service(),

        account: Ember.inject.service('user/session-account'),
        user: Ember.computed.alias('account.currentUser'),
        validation: _validation.default,
        schema: _model.schema,
        modelType: "user",
        messageSave: "Profilen oppdatert.",
        messageDelete: "Profilen slettet.",
        isWizard: Ember.computed.equal('user.isSignUpCompleted', false),
        actions: {
            save: function save(changeset) {
                var _this = this;

                var snapshot = changeset.snapshot();
                return changeset.cast(keys(Ember.get(this, 'schema'))).validate().then(function () {
                    if (Ember.get(changeset, 'isValid')) {
                        //this.setLoading(true);
                        changeset.save().then(function () {
                            _this.swal({
                                title: "Dine opplysninger er lagret!",
                                timer: 1000,
                                showConfirmButton: false,
                                showCloseButton: false,
                                type: 'success'
                            });
                        }).catch(function () {
                            Ember.get(_this, 'user.errors').forEach(function (_ref) {
                                var attribute = _ref.attribute,
                                    message = _ref.message;

                                changeset.pushErrors(attribute, message);
                            });
                        });
                    }
                }).catch(function () {
                    changeset.restore(snapshot);
                });
            },
            saveNext: function saveNext(changeset) {
                var _this2 = this;

                var snapshot = changeset.snapshot();

                // Get next route
                var next = Ember.get(this, 'wizardService').getNextRoute();

                return changeset.cast(keys(Ember.get(this, 'schema'))).validate().then(function () {
                    if (Ember.get(changeset, 'isValid')) {

                        changeset.save().then(function () {
                            _this2.swal({
                                title: "Dine opplysninger er lagret!",
                                timer: 1000,
                                showConfirmButton: false,
                                showCloseButton: false,
                                type: 'success'
                            }).then(function () {
                                _this2.sendAction('toRoute', Ember.get(next, 'route'));
                            }, {});
                        }).catch(function () {
                            Ember.get(_this2, 'user.errors').forEach(function (_ref2) {
                                var attribute = _ref2.attribute,
                                    message = _ref2.message;

                                changeset.pushErrors(attribute, message);
                            });
                            _this2.setLoading(false);
                        });
                    }
                }).catch(function () {
                    changeset.restore(snapshot);
                });
            },
            skip: function skip() {
                var step = parseInt(Ember.get(this, 'wizardStep.step'));
                if (step === 2) {
                    this.set('wizardStep.step', 3);
                }
                this.sendAction('toRoute', 'contractor.settings.avatar');
            }
        }
    });
});