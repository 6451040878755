define('uwork-app-v2/contractor/timesheet/controller', ['exports', 'uwork-app-v2/mixins/query-filters-mixin', 'uwork-app-v2/mixins/timesheet-mixin'], function (exports, _queryFiltersMixin, _timesheetMixin) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend(_queryFiltersMixin.default, _timesheetMixin.default, {

        // Sort contractee by name
        sortByName: ['name:asc'],
        mycompanies: Ember.computed.sort('model.mycompanies', 'sortByName')
    });
});