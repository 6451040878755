define('uwork-app-v2/router', ['exports', 'uwork-app-v2/config/environment', 'ember-router-scroll'], function (exports, _environment, _emberRouterScroll) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Router = Ember.Router.extend(_emberRouterScroll.default, {
    location: _environment.default.locationType,
    rootURL: _environment.default.rootURL,
    metrics: Ember.inject.service(),

    didTransition: function didTransition() {
      this._super.apply(this, arguments);
      this._trackPage();
    },
    _trackPage: function _trackPage() {
      var _this = this;

      Ember.run.scheduleOnce('afterRender', this, function () {
        var page = _this.get('url');
        var title = _this.getWithDefault('currentRouteName', 'unknown');

        Ember.get(_this, 'metrics').trackPage({ page: page, title: title });
      });
    }
  });

  Router.map(function () {
    this.route('messages', function () {
      this.route('view', { path: '/view/:conversation_id' });
    });
    this.route('notifications');

    this.route('contractee', function () {
      this.route('dashboard');
      this.route('assignments', function () {
        this.route('new');
        this.route('active-requests');
        this.route('completed');
        this.route('ongoing');
        this.route('view', { path: '/view/:order_id' }, function () {
          this.route('select-worker');
        });
      });

      this.route('timesheet');

      this.route('settings', function () {
        this.route('account');
        this.route('profile');
        this.route('avatar');
        this.route('category');
        this.route('rates');
        this.route('nda');
        this.route('company');
        this.route('confirm');
        this.route('order-desc-templates');
        this.route('agreement');
        this.route('notifications');
        this.route('data');
        this.route('additional-payments');
        this.route('contracts');
      });

      this.route('view', { path: '/view/:contractee_id' });
      this.route('my-workers', function () {
        this.route('order', function () {
          this.route('new');
        });
      });
      this.route('vacancy', function () {
        this.route('add');
        this.route('edit', { path: '/edit/:vacancy_id' });
        this.route('view', { path: '/view/:vacancy_id' });
        this.route('select-applicants', { path: '/select-applicants/:vacancy_id' });
      });

      this.route('agreement', function () {
        this.route('confirmation');
      });
      this.route('user-requests', function () {
        this.route('apply-for-substitute');
        this.route('view');
      });
    });

    this.route('contractor', function () {
      this.route('dashboard');
      this.route('view', { path: '/view/:contractor_id' });

      this.route('orders', function () {
        this.route('ongoing');
        //this.route('pre-order', {path: '/pre-order/:order_id'});
        this.route('active');
        this.route('completed');
        this.route('request', { path: 'applied' });
      });
      this.route('timesheet');
      this.route('settings', function () {
        this.route('avatar');
        this.route('account');
        this.route('category');
        this.route('skills');
        this.route('education');
        this.route('experience');
        this.route('payment');
        this.route('gcc');
        this.route('languages');
        this.route('confirm');
        this.route('video');
        this.route('general');
        this.route('biography');
        this.route('others');
        this.route('agreement');
        this.route('completed');
        this.route('notifications');
        this.route('data');
        this.route('attachment');
      });

      this.route('contractees');
      this.route('assignments', function () {
        this.route('view', { path: '/view/:order_id' });
      });
      this.route('my-companies');
      this.route('vacancy', function () {
        this.route('view', {
          path: '/view/:vacancy_id'
        });
        this.route('applied');
        this.route('hired');
      });
      this.route('agreement', function () {
        this.route('confirmation');
      });
      this.route('user-requests', function () {
        this.route('view', { path: '/view/:request_id' });
        this.route('apply-for-substitute');
      });
    });

    this.route('admin', function () {
      this.route('assignments', function () {
        this.route('view', { path: '/view/:order_id' }, function () {
          this.route('select-worker');
        });
        this.route('new');
        this.route('active');
        this.route('ongoing');
        this.route('completed');
        this.route('lost');
      });

      this.route('dashboard');
      this.route('timesheet');

      this.route('management', function () {
        this.route('faq');
        this.route('titles');
        this.route('skills');
        this.route('category');
        this.route('order-descriptions');
        this.route('order-desc-templates');
        this.route('myworkers');
        this.route('badges');
        this.route('approvals');
        this.route('courses');
        this.route('agreements');
        this.route('tags');
        this.route('role');
        this.route('user-requests', function () {
          this.route('view', { path: '/view/:request_id' });
        });
        //this.route('user-data-export');
        this.route('contractee_contractor');
      });

      this.route('list', function () {
        this.route('contractees');
        this.route('contractors');
        this.route('map');
      });
      this.route('contractor', function () {
        this.route('edit', { path: '/edit/:contractor_id' });
      });
      this.route('metrics');

      this.route('contractee', function () {
        this.route('edit', { path: '/edit/:contractee_id' });
      });

      this.route('vacancies', function () {
        this.route('select-applicants', { path: '/select-applicants/:vacancy_id' });
        this.route('view', { path: '/view/:vacancy_id' });
        this.route('edit', { path: '/edit/:vacancy_id' });
      });
      this.route('horizon');
      this.route('calendar');

      this.route('vacancy', function () {
        this.route('plane');
        this.route('features');
        this.route('occupation');
      });
      this.route('messages', function () {
        this.route('view', { path: '/view/:email_id' });
      });
    });

    this.route('auth', function () {
      this.route('login');
      this.route('reset', { path: 'reset/:token' });
      this.route('forgot');
      this.route('confirm', { path: 'confirm/:token' });
      this.route('confirm-resend');

      this.route('signup', function () {
        this.route('contractor');
        this.route('contractee');
      });
      this.route('redirect', { path: 'redirect/:token' });
    });

    this.route('profile', function () {
      this.route('view', { path: '/view/:contractor_id' });
    });

    // this.route('agreement', function () {
    //     this.route('confirmation');
    // });

    //this.route('404');  // capture route in path

    this.route('404', { path: '/*wildcard' });
  });

  exports.default = Router;
});