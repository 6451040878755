define('uwork-app-v2/components/bs/badge-inline/component', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        tagName: 'span',
        classNames: ['badge-inline', 'badge-inline-pill'],
        colors: ['blue', 'purple', 'orange', 'green'],
        color: function color() {
            var colors = Ember.get(this, 'colors');
            var randomNumber = Math.floor(Math.random() * 4);

            var color = colors[randomNumber];

            this.$().addClass('badge-inline-' + color);
        },
        didInsertElement: function didInsertElement() {
            this._super.apply(this, arguments);

            this.color();
        }
    });
});