define('uwork-app-v2/json/transform', ['exports', 'ember-data/transform'], function (exports, _transform) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _transform.default.extend({
        deserialize: function deserialize(serialized) {
            if (Ember.isEmpty(serialized)) {
                return {};
            } else {
                return serialized;
            }
        },
        serialize: function serialize(deserialized) {
            if (Ember.isEmpty(deserialized)) {
                return {};
            } else {
                return deserialized;
            }
        }
    });
});