define('uwork-app-v2/mixins/mixin-icons', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Mixin.create({
        path: '/assets/images/features/',
        ext: '.svg',
        defaultIcon: 'phone',
        icons: [{ name: 'assign' }, { name: 'doc' }, { name: 'done' }, { name: 'dr' }, { name: 'identity' }, { name: 'money' }, { name: 'nurs' }, { name: 'tel-fb' }],
        findIcon: function findIcon(name) {
            var icon = Ember.get(this, 'icons').findBy('name', name);
            var result = icon ? icon.name : Ember.get(this, 'defaultIcon');
            return result;
        },
        getIcon: function getIcon(name) {
            return Ember.get(this, 'path') + this.findIcon(name) + Ember.get(this, 'ext');
        }
    });
});