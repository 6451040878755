define('uwork-app-v2/messages/index/controller', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        account: Ember.inject.service('user/session-account'),
        media: Ember.inject.service(),
        store: Ember.inject.service(),
        query: '',
        modelSortByDate: ['updatedAt.date:desc'],
        conversations: Ember.computed.sort('model', 'modelSortByDate'),
        user: Ember.computed.alias('account.currentUser'),
        appService: Ember.inject.service('application'),
        selectedConversationMessages: '',
        selectedConversation: '',
        newMessage: '',
        isMobile: Ember.computed('media.isXs', 'media.isSm', 'media.isMd', 'media.isLg', 'media.isXl', function () {
            return Ember.get(this, 'media.isXs') || Ember.get(this, 'media.isSm');
        }),
        actions: {
            back: function back() {
                Ember.set(this, 'selectedConversation', '');
            },
            showMessageItem: function showMessageItem(model) {
                // set(this, 'selectedConversationMessages', model);
                Ember.set(this, 'selectedConversation', model);
                //read msg
                if (model) {
                    var store = Ember.get(this, 'store');

                    var participant = store.peekRecord('participant', model.get('_me').get('id'));
                    participant.save();
                    Ember.get(this, 'account').loadCurrentUser();
                }
                var messages = Ember.get(model, 'messages') ? Ember.get(model, 'messages') : model;
                Ember.set(this, 'selectedConversationMessages', messages);
            },
            submitNewConversation: function submitNewConversation(model) {
                var _this = this;

                var message = Ember.get(this, 'newMessage');
                var user = Ember.get(model, 'interlocutor.user');
                var store = Ember.get(this, 'store');

                if (message && message.length > 0) {
                    store.createRecord('message', {
                        message: message,
                        conversation: model,
                        user: user
                    }).save().then(function () {
                        Ember.set(_this, 'newMessage', '');
                    }).finally(function () {
                        Ember.get(_this, 'appService').toggleMessage();
                    });
                } else {
                    this.swal({
                        title: "OBS!",
                        html: "Meldingen din inneholder ikke noe tekst. Ønsker du fortsatt å sende meldingen?",
                        type: 'warning',
                        confirmButtonText: "Send melding"
                    });
                }
            }
        }
    });
});