define('uwork-app-v2/components/admin/contractor/change-status/component', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        classNames: ['mb-4'],
        actions: {
            updated: function updated() {},
            updateAttr: function updateAttr(item, value) {
                // set(this, 'number', 1);
                // set(this, '_' + item, value);
                // set(this, item, value);
            },
            allowEdit: function allowEdit(model) {
                var _this = this;

                model.then(function (user) {
                    user.allowEdit();
                    user.save().then(function () {
                        _this.swal({
                            title: "Bruker aktivert!",
                            type: 'info'
                        });
                    });
                });
            },
            disableEdit: function disableEdit(model) {
                var _this2 = this;

                model.then(function (user) {
                    user.disableEdit();
                    user.save().then(function () {
                        _this2.swal({
                            title: "Bruker aktivert!",
                            type: 'info'
                        });
                    });
                });
            },
            approve: function approve(model) {
                var _this3 = this;

                model.then(function (m) {
                    m.setApproved();
                    m.save().then(function () {
                        _this3.swal({
                            title: "Bruker status satt til: godkjent.",
                            type: 'info'
                        });
                    });
                });
            },
            default: function _default(model) {
                var _this4 = this;

                model.then(function (m) {
                    m.setDefault();
                    m.save().then(function () {
                        _this4.swal({
                            title: "Bruker status satt til: default.",
                            type: 'info'
                        });
                    });
                });
            },
            verify: function verify(model) {
                var _this5 = this;

                model.then(function (m) {
                    m.setVerified();
                    m.save().then(function () {
                        _this5.swal({
                            title: "Bruker status satt til: verifisert.",
                            type: 'info'
                        });
                    });
                });
            },
            resetAgreement: function resetAgreement(model) {
                var _this6 = this;

                model.then(function (m) {
                    m.setAgreementUpdated();
                    m.save().then(function () {
                        _this6.swalNotify("Avtalen er resatt!");
                    });
                });
            },
            setPublic: function setPublic(model) {
                var _this7 = this;

                model.then(function (m) {
                    m.setPublic();
                    m.save().then(function () {
                        _this7.swal({
                            title: "Brukerprofil publisert tilgjengelig for alle!",
                            type: 'info'
                        });
                    });
                });
            },
            unsetPublic: function unsetPublic(model) {
                var _this8 = this;

                model.then(function (m) {
                    m.unsetPublic();
                    m.save().then(function () {
                        _this8.swal({
                            title: "Brukerprofil publisert tilgjengelig for alle!",
                            type: 'info'
                        });
                    });
                });
            },
            delete: function _delete(model) {
                var _this9 = this;

                this.swal({
                    title: "Slett",
                    html: "Er du sikker du vil slette denne brukeren?",
                    type: "warning",
                    showCancelButton: false,
                    confirmButtonColor: "#14CA9C",
                    confirmButtonText: "Slett!",
                    showCloseButton: true
                }).then(function () {
                    model.set('lock', 0);
                    model.destroyRecord().then(function () {
                        _this9.swal({
                            title: "Info",
                            html: "Bruker slettet!",
                            type: 'info'
                        });
                    }, function () {
                        _this9.swal({
                            title: "Not this time",
                            html: "User cannot be deleted!",
                            type: 'error'
                        });
                    });
                }, function () {});
            }
        }
    });
});