define('uwork-app-v2/vacancy-visit/model', ['exports', 'ember-data', 'ember-data/relationships'], function (exports, _emberData, _relationships) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberData.default.Model.extend({
        vacancy: (0, _relationships.belongsTo)('vacancy'),
        user: (0, _relationships.belongsTo)('user')
    });
});