define('uwork-app-v2/components/bs/tooltip-button/component', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        tagName: 'button',
        attributeBindings: ['data-toggle', 'type', 'data-href', 'data-trigger', 'trigger', 'data-id', 'href', 'data-placement', 'title'],
        classNames: ['btn', 'btn-link'],
        click: function click() {
            var href = Ember.get(this, 'data-href');
            var data = Ember.get(this, 'data-id');
            this.$().tooltip('dispose');
            this.sendAction("toRoute", href, data);
        },
        didInsertElement: function didInsertElement() {
            this._super.apply(this, arguments);
            this.$().tooltip();
        },
        willDestroyElement: function willDestroyElement() {
            this._super.apply(this, arguments);
            // this.$().tooltip('dispose');
        }
    });
});