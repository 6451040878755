define('uwork-app-v2/validators/messages', ['exports', 'ember-cp-validations/validators/messages'], function (exports, _messages) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _messages.default.extend({
        uniqueEmail: 'E-posten finnes allerde',
        present: 'Feltet kan ikke være tomt',
        blank: 'Feltet må fylles ut.',
        notANumber: 'Feltet skal være et tall.',

        accepted: '{description} må aksepteres',
        after: '{description} må være etter {after}',
        before: '{description} må være før {before}',
        collection: '{description} må være en samling',
        confirmation: '{description} er ikke like {on}',
        date: '{description} må være en gyldig dato',
        email: '{description} må være en gyldig e-post adresse',
        empty: 'Feltet kan ikke være tomt',
        equalTo: '{description} må være lik som {is}',
        even: '{description} må være partall',
        exclusion: '{description} er reservert',
        greaterThan: '{description} må være større enn {gt}',
        greaterThanOrEqualTo: '{description} må være større enn eller lik {gte}',
        inclusion: '{description} er ikke inkludet i listen',
        invalid: '{description} er ugyldig',
        lessThan: '{description} må være mindre enn {lt}',
        lessThanOrEqualTo: '{description} må være mindre enn eller lik {lte}',
        notAnInteger: '{description} må være et heltall',
        odd: '{description} må være et oddetall',
        onOrAfter: '{description} må være på eller etter {onOrAfter}',
        onOrBefore: '{description} må være på eller før {onOrBefore}',
        otherThan: '{description} må være annet enn {value}',
        phone: '{description} må være et gyldig telefonnummer',
        positive: '{description} må være positiv',
        multipleOf: '{description} må være et flertall av {multipleOf}',
        singular: '{description} kan ikke være en samling',
        tooLong: '{description} er for lang (maksimum {max} karakterer)',
        tooShort: '{description} er for kort (minimum {min} karakterer)',
        between: '{description} må være mellom {min} og {max} karakterer',
        url: '{description} må være en gyldig url',
        wrongDateFormat: '{description} må være i følgende forma {format}',
        wrongLength: '{description} er feil lengde (må være {is} karakterer)'
    });
});