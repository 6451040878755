define('uwork-app-v2/torii-providers/google', ['exports', 'torii/providers/google-oauth2-bearer'], function (exports, _googleOauth2Bearer) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _googleOauth2Bearer.default.extend({
        fetch: function fetch(data) {
            return data;
        }
    });
});