define('uwork-app-v2/admin/contractee/edit/controller', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        fileStore: Ember.computed.alias('model.contractee.user.fileStore'),
        documents: Ember.computed.filterBy('fileStore', 'isNDA')
    });
});