define('uwork-app-v2/components/contractor/timesheet/shift-item-edit/component', ['exports', 'moment'], function (exports, _moment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        tagName: 'tr',
        classNames: ['bg-yellow'],
        didValidate: false,
        actions: {
            decrement: function decrement(model, key) {
                var oppositeKey = key === 'fromDate' ? 'toDate' : 'fromDate';
                var dt = Ember.get(model, key);
                var oppositeDt = Ember.get(model, oppositeKey);
                var now = (0, _moment.default)();
                var allowPast = Ember.get(this, 'allowPast');

                var sub = dt.clone().subtract(15, 'm');
                var min = model.isNightShift() ? dt.clone().subtract(1, 'd').startOf('day') : dt.clone().startOf('day');

                // Before today not allowed
                if (sub.isBefore(now) && allowPast === false) {
                    return;
                }

                // To date cant be before from date
                if (sub.isSame(oppositeDt)) {
                    return;
                }

                // Validate min date
                if (key === 'fromDate' && sub.isSameOrBefore(min)) {
                    return;
                }

                Ember.set(model, key, sub);
            },
            increment: function increment(model, key) {
                var oppositeKey = key === 'fromDate' ? 'toDate' : 'fromDate';
                var dt = Ember.get(model, key);
                var oppositeDt = Ember.get(model, oppositeKey);

                var add = dt.clone().add(15, 'm');
                var max = model.isNightShift() ? oppositeDt.clone().add(1, 'd').endOf('day') : oppositeDt.clone().endOf('day');

                // To date cant be before from date
                if (add.isSame(oppositeDt)) {
                    return;
                }

                // Validate max date (end of today/add one day on All day shifts and Night Shifts)
                if (key === 'toDate' && add.isSameOrAfter(max)) {
                    return;
                }

                Ember.set(model, key, add);
            },
            save: function save(model) {
                this.sendAction('save', model);
            },
            saveRestTime: function saveRestTime(model) {
                this.sendAction('saveRestTime', model);
            },
            restTime: function restTime(shift, key, time, format) {
                this.sendAction('restTime', shift, key, time, format);
            },
            edit: function edit(shift) {
                this.sendAction('edit', shift);
            }
        }
    });
});