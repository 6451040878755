define('uwork-app-v2/components/contractor/card/main-admin/component', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        media: Ember.inject.service(),
        viewMode: false,
        contractorMode: false,
        checked: false,
        isAdmin: false,
        user: Ember.computed.alias('model.user'),
        skills: Ember.computed.alias('user.skills'),
        classNames: ['user-card', 'd-flex', 'bg-white', 'align-items-stretch', 'mt-4'],

        isVerified: Ember.computed.equal('model.user.isVerified', true),
        isEditable: Ember.computed.equal('model.user.isEditable', true),
        isPublic: Ember.computed.equal('model.user.isPublic', true),

        percentageClass: Ember.computed('model.profileProgress', function () {
            var percentage = Ember.get(this, 'model.profileProgress');
            if (percentage > 50 && percentage < 70) {
                return 'bg-warning';
            } else if (percentage < 50) {
                return 'bg-danger';
            } else {
                return 'bg-success';
            }
        })
    });
});