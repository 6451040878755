define('uwork-app-v2/category/model', ['exports', 'ember-data/model', 'ember-data/attr', 'ember-data/relationships', 'ember-cp-validations'], function (exports, _model, _attr, _relationships, _emberCpValidations) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.schema = undefined;


    var Validations = (0, _emberCpValidations.buildValidations)({ name: (0, _emberCpValidations.validator)('presence', true) }, { debounce: 500 });

    var schema = exports.schema = {
        name: (0, _attr.default)(),
        description: (0, _attr.default)(),
        users: (0, _relationships.hasMany)('user'),
        pivot: (0, _relationships.hasMany)('category-pivot')
    };

    exports.default = _model.default.extend(Validations, schema, {
        checked: (0, _attr.default)('boolean', { defaultValue: false })
    });
});