define('uwork-app-v2/components/custom/user-statistic/component', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        color: 'primary',
        classNames: ['justify-content-center', 'text-center', 'align-items-center', 'flex-column', 'd-flex', 'item'],
        showLabel: true,
        label: 'contractee.dashboard.thisMonth'
    });
});