define('uwork-app-v2/contractee/my-workers/order/new/controller', ['exports', 'uwork-app-v2/mixins/order-create-mixin', 'uwork-app-v2/mixins/simditor-simple-mixin', 'uwork-app-v2/contractee/my-workers/order/new/validation', 'uwork-app-v2/order/model', 'ember-changeset-validations', 'ember-changeset'], function (exports, _orderCreateMixin, _simditorSimpleMixin, _validation, _model, _emberChangesetValidations, _emberChangeset) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend(_orderCreateMixin.default, _simditorSimpleMixin.default, {
        store: Ember.inject.service(),
        templates: Ember.computed.filterBy('model.templates', 'isNew', false),

        modelschema: _model.schema,

        btnText: 'assignment.new.button.createShifts',
        notify: 1,

        // Changeset
        validation: _validation.default,
        _changeset: function _changeset() {
            var order = Ember.get(this, 'store').createRecord('order');

            Ember.set(this, 'btnText', 'assignment.new.button.createShifts');
            Ember.set(this, 'order', order);
            Ember.set(this, 'changeset', new _emberChangeset.default(order, (0, _emberChangesetValidations.default)(_validation.default), _validation.default));
        },


        actions: {
            save: function save(changeset) {
                Ember.set(changeset, 'contractors', Ember.get(this, 'model.workers'));

                Ember.get(this, 'save').perform(changeset);
            }
        }
    });
});