define('uwork-app-v2/admin/management/contractee-contractor/route', ['exports', 'ember-infinity/mixins/route', 'ember-i18n'], function (exports, _route, _emberI18n) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend(_route.default, {
        title: (0, _emberI18n.translationMacro)('admin.salary.pageName'),

        perPageParam: 'size',
        pageParam: 'number',
        totalPagesParam: 'meta.pagination.total_pages',

        queryParams: {
            contractee: { replace: true, refreshModel: true },
            contractor: { replace: true, refreshModel: true },
            page: { replace: true, refreshModel: true }
        },

        beforeModel: function beforeModel() {
            this.setLoading(true);
        },
        afterModel: function afterModel() {
            var title = this.get('title');

            var bTitle = {
                title: title.string
            };

            this.setLoading(false);

            Ember.set(this, 'breadCrumb', bTitle);
        },
        model: function model(params) {

            var cc = this.infinityModel('contractee-contractor', {
                startingPage: 1,
                page: 1,
                perPage: 6,
                modelPath: 'controller.model.cc',
                contractor: params.contractor,
                contractee: params.contractee
            });

            return Ember.RSVP.hash({
                contractors: this.store.query('contractor', { cc: true }),
                contractees: this.store.query('contractee', { cc: true }),
                cc: cc
            });
        }
    });
});