define('uwork-app-v2/validators/found-zipcode', ['exports', 'ember-cp-validations/validators/base', 'uwork-app-v2/mixins/city-by-zipcode-mixin'], function (exports, _base, _cityByZipcodeMixin) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });


    var FoundZipcode = _base.default.extend(_cityByZipcodeMixin.default, {
        validate: function validate(value, options, model, attribute) {

            var zipCodes = Ember.get(this, 'zipCodes');

            if (!value) {
                return true;
            }

            var selected = zipCodes.findBy('zipCode', value.toString());

            if (selected) {
                return true;
            } else {
                return options.get('message');
            }
        }
    });

    FoundZipcode.reopenClass({
        getDependentsFor: function getDependentsFor() /* attribute, options */{
            return [];
        }
    });

    exports.default = FoundZipcode;
});