define('uwork-app-v2/components/contractee/settings/user-data/component', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        store: Ember.inject.service(),
        data: false,
        init: function init() {
            this._super.apply(this, arguments);
            this.loadData();
        },
        loadData: function loadData() {
            var store = Ember.get(this, 'store');
            var _this = this;

            store.findAll('user-data-export').then(function (model) {
                var data = model.get('firstObject');

                Ember.set(_this, 'data', data);
            }).catch(function (error) {
                //console.log(error);
            });
        },

        actions: {
            download: function download() {},
            request: function request() {
                var _this2 = this;

                this.swal({
                    text: "Bekreft at du ønsker å laste ned dine data fra uWork. Det vil ta 2-3 dager før du mottar dataen på din e-post.",
                    type: "warning",
                    showCancelButton: true,
                    cancelButtonText: 'Avbryt',
                    confirmButtonColor: "#14CA9C",
                    confirmButtonText: "Bekreft"
                }).then(function () {
                    //ToDo - Sent Request to server

                    var store = Ember.get(_this2, 'store');
                    store.createRecord('user-data-export').save().then(function () {

                        _this2.swal({
                            title: "Sendt inn!",
                            text: "Husk at det vil ta 2-3 dager før du mottar dine data på e-post."
                        }).then(function () {
                            //ToDo - Sent Request to server
                        }, function () {});

                        _this2.loadData();
                    });
                }, function () {});
            }
        }
    });
});