define('uwork-app-v2/admin/vacancies/select-applicants/route', ['exports', 'ember-i18n'], function (exports, _emberI18n) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        title: (0, _emberI18n.translationMacro)('vacancy.pageName.select'),
        breadCrumb: {},
        queryParams: {
            query: { replace: true },
            status: { replace: true },
            sorting: { replace: true },
            titles: { replace: true },
            skills: { replace: true },
            number: { replace: true },
            driver_license: { replace: true },
            have_car: { replace: true }
        },
        beforeModel: function beforeModel() {
            this.setLoading(true);
        },
        afterModel: function afterModel() {
            var title = this.get('title');

            var bTitle = {
                title: title.string
            };

            this.setLoading(false);

            Ember.set(this, 'breadCrumb', bTitle);
        },
        model: function model(params) {
            var _this = this;

            var vacancy = this.store.findRecord('vacancy', params.vacancy_id).catch(function () {
                _this.transitionTo('contractee.assignments.active-requests');
            });

            return Ember.RSVP.hash({
                vacancy: vacancy,
                categories: this.store.findAll('category'),
                skills: this.store.findAll('skill'),
                titles: this.store.findAll('contractor-title')
            });
        },
        setupController: function setupController(controller, model) {
            // Call _super for default behavior
            this._super(controller, model);
            // Implement your custom setup after

            Ember.get(controller, 'search').perform();
        }
    });
});