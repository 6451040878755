define('uwork-app-v2/contractee/timesheet/controller', ['exports', 'uwork-app-v2/mixins/query-filters-mixin', 'uwork-app-v2/mixins/timesheet-mixin'], function (exports, _queryFiltersMixin, _timesheetMixin) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend(_queryFiltersMixin.default, _timesheetMixin.default, {
        showApprove: Ember.computed('isLastMonth', 'isBeforeFourth', function () {
            return Ember.get(this, 'isLastMonth');
            //return get(this, 'isLastMonth') && get(this, 'isBeforeFourth');
        }),

        // Sort my workers by full name
        sortByFullName: ['user.fullName:asc'],
        myworkers: Ember.computed.sort('model.myworkers', 'sortByFullName'),

        actions: {
            approve: function approve() {
                var _this = this;

                this.swal({
                    title: 'Bekreft godkjenning av timelister',
                    html: 'Er du sikker at du ønsker å godkjenne timelistene for følgende måned?',
                    confirmButtonText: "Bekreft",
                    type: 'question'
                }).then(function () {
                    Ember.get(_this, 'search').perform({ approve: true });
                }, function () {});
            }
        }
    });
});