define('uwork-app-v2/components/contractor/profile/content-main-public/component', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        attachments: Ember.computed.alias('user.attachments'),
        categoryPivot: Ember.computed.alias('user.categoryPivot'),
        educations: Ember.computed.alias('user.educations'),
        experiences: Ember.computed.alias('user.experiences'),
        contractor: Ember.computed.alias('user.contractor'),
        languages: Ember.computed.alias('user.languages'),
        ratings: Ember.computed.alias('user.ratings'),
        shifts: Ember.computed.alias('user.shifts'),
        showRatings: Ember.computed.filterBy('ratings', 'showRating', true),
        categories: Ember.computed('user', 'categoryPivot', function () {
            var store = Ember.get(this, 'store');
            return store.findAll('category');
        }),
        isAdmin: Ember.computed('account', function () {
            var account = Ember.get(this, 'account');
            var user = Ember.get(account, 'currentUser');
            return Ember.get(user, 'isAdmin');
        }),
        userCategories: Ember.computed('categories.[]', 'categoryPivot.[]', function () {
            var pivot = Ember.get(this, 'categoryPivot');
            var model = Ember.get(this, 'categories');

            if (model) {
                model.forEach(function (category) {
                    var id = Ember.get(category, 'id');
                    var _pivot = pivot.findBy('category_id', parseInt(id));

                    if (_pivot) {
                        Ember.set(category, 'checked', true);
                        Ember.set(category, 'type', _pivot.get('type'));
                        Ember.set(category, '_exists', true);
                        Ember.set(category, '_pivot', _pivot);
                    }
                });

                return model;
            }
            return null;
        }),
        unskilledCategories: Ember.computed.filterBy('userCategories', 'type', 1),
        skilledCategories: Ember.computed.filterBy('userCategories', 'type', 2)
    });
});