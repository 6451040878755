define('uwork-app-v2/contractee/dashboard/route', ['exports', 'uwork-app-v2/mixins/has-role-mixin', 'ember-cli-jsonapi-pagination/mixins/routes/jsonapi-pagination', 'ember-i18n'], function (exports, _hasRoleMixin, _jsonapiPagination, _emberI18n) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend(_jsonapiPagination.default, _hasRoleMixin.default, {
        menu: Ember.inject.service('extend-menu'),
        session: Ember.inject.service(),
        ajax: Ember.inject.service(),
        title: (0, _emberI18n.translationMacro)('contractee.pageName.dashboard'),
        breadCrumb: {},
        account: Ember.inject.service('user/session-account'),
        user: Ember.computed.alias('account.currentUser'),
        beforeModel: function beforeModel() {
            this.setLoading(true);
        },
        model: function model() {
            return Ember.RSVP.hash({
                //usersShowcase: this.store.query('user', {showcase: 'showcase'}),
                userNotificationSettings: this.store.findAll('user-notification-setting'),
                vacancies: this.store.query('vacancy', { number: 1, size: 4, status: 20, deadline_in_future: 1 }),
                draft: this.store.query('vacancy', { number: 1, size: 4, status: 10, deadline_in_future: 1 })
            });
        },
        afterModel: function afterModel() {
            var title = this.get('title');

            var bTitle = {
                title: title.string
            };

            this.setLoading(false);
            this.showAfterRender(false);
            Ember.set(this, 'breadCrumb', bTitle);
        }
    });
});