define('uwork-app-v2/notifications/controller', ['exports', 'ember-cli-jsonapi-pagination/mixins/controllers/jsonapi-pagination', 'uwork-app-v2/config/environment', 'uwork-app-v2/mixins/hard-refresh-mixin'], function (exports, _jsonapiPagination, _environment, _hardRefreshMixin) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend(_jsonapiPagination.default, _hardRefreshMixin.default, {
        size: 13,
        number: 1,
        ajax: Ember.inject.service(),
        media: Ember.inject.service(),
        session: Ember.inject.service(),
        account: Ember.inject.service('user/session-account'),
        totalPages: Ember.computed('size', 'number', 'model', function () {
            return Ember.get(this, 'model.meta.pagination.total_pages');
        }),
        actions: {
            readAll: function readAll() {
                var _this = this;
                var ajax = Ember.get(_this, 'ajax');
                var url = '' + _environment.default.APP.API_HOST + _environment.default.APP.API_NAMESPACE + '/notifications/read-all';
                var token = Ember.get(_this, 'session.data.authenticated.token');

                ajax.post(url, {
                    beforeSend: function beforeSend(xhr) {
                        xhr.setRequestHeader('Authorization', 'Bearer ' + token);
                    }
                }).then(function () {
                    _this.hardRefresh();
                });
            },
            notificationUpdate: function notificationUpdate(notificaiton) {
                notificaiton.setRead();
                notificaiton.save().then(function () {
                    //get(this, 'account').loadCurrentUser();
                });
            },
            toRoute: function toRoute(path, data) {
                if (data) {
                    this.transitionToRoute(path, data);
                }

                Ember.get(this, 'account').loadCurrentUser();

                this.transitionToRoute(path);
            }
        },
        outerWindow: Ember.computed('media.isXs', 'media.isSm', 'media.isMd', 'media.isLg', 'media.isXl', function () {
            var media = Ember.get(this, 'media');

            if (media.get('isXs') || media.get('isSm')) {
                return 1;
            } else {
                return 3;
            }
        })
    });
});