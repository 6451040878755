define('uwork-app-v2/components/order-list/contractor-order-request/component', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        classNames: ['row', 'order'],
        rejected: false
    });
});