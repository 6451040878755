define('uwork-app-v2/components/contractor/settings/contractor-intro/validation', ['exports', 'ember-changeset-validations/validators'], function (exports, _validators) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = {
        sex: (0, _validators.validatePresence)(true),
        contractorTitle: (0, _validators.validatePresence)(true),
        birthday: (0, _validators.validatePresence)(true),
        address: (0, _validators.validatePresence)(true),
        zipCode: (0, _validators.validatePresence)(true),
        place: (0, _validators.validatePresence)(true)
        // driverLicense: validatePresence(false),
        // haveCar: validatePresence(false),
        // email: validateFormat({ type: 'email' }),
        // password: validateLength({ min: 8, allowBlank: true }),
        // passwordConfirmation: validateConfirmation({ on: 'password' }),
    };
});