define('uwork-app-v2/application/adapter', ['exports', 'ember-data/adapters/json-api', 'ember-simple-auth/mixins/data-adapter-mixin', 'uwork-app-v2/config/environment'], function (exports, _jsonApi, _dataAdapterMixin, _environment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _jsonApi.default.extend(_dataAdapterMixin.default, {
        host: '' + _environment.default.APP.API_HOST,
        namespace: '' + _environment.default.APP.API_NAMESPACE,
        authorizer: 'authorizer:token'
    });
});