define('uwork-app-v2/components/contractee/agreement/display-main/component', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        classNames: ['bg-white'],
        account: Ember.inject.service('user/session-account'),
        user: Ember.computed.alias('account.currentUser'),
        session: Ember.inject.service(),
        store: Ember.inject.service(),
        menu: Ember.inject.service('extend-menu'),
        didInsertElement: function didInsertElement() {
            var _this = this;

            this._super.apply(this, arguments);

            this.setLoading(true);

            Ember.get(this, 'store').queryRecord('agreement', { latest: true }).then(function (agree) {

                Ember.set(_this, 'agree', agree);

                var sideNav = Ember.get(_this, 'menu.menu');
                sideNav.publicActions.hideNavigation(true);
            }).catch(function () {}).finally(function () {
                _this.setLoading(false);
            });
        },

        actions: {
            singOut: function singOut() {
                Ember.get(this, 'session').invalidate();
            },
            acceptAgreement: function acceptAgreement() {
                var _this2 = this;

                var accepted = Ember.get(this, 'agreementAccepted');

                if (accepted) {
                    var user = Ember.get(this, 'user');
                    var sideNav = Ember.get(this, 'menu.menu');

                    user.setAgreementAccepted();
                    user.save().then(function () {
                        _this2.swal("Du har nå bekreftet avtalen!").then(function () {
                            sideNav.publicActions.hideNavigation(false);
                        }, function () {}).finally(function () {
                            _this2.sendAction('toRoute', 'contractee.dashboard');
                        });
                    });
                }
            }
        }

    });
});