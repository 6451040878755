define('uwork-app-v2/contractee-contractor/adapter', ['exports', 'uwork-app-v2/application/adapter', 'ember-concurrency'], function (exports, _adapter, _emberConcurrency) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _adapter.default.extend({
        urlForQueryRecord: function urlForQueryRecord(query) {
            if (query.verify) {

                var contractee = query.contractee;
                var contractor = query.contractor;
                delete query.verify;
                delete query.contractee;
                delete query.contractor;
                return this.urlPrefix() + '/contractee-contractors/' + contractee + '/' + contractor;
            }

            return this._super.apply(this, arguments);
        }
    });
});