define('uwork-app-v2/components/admin/vacancy/feature/list-item/component', ['exports', 'uwork-app-v2/mixins/default-ds-action-mixin', 'uwork-app-v2/components/admin/vacancy/feature/new-item/validation', 'uwork-app-v2/vacancy-plan-feature/model', 'uwork-app-v2/mixins/mixin-icons'], function (exports, _defaultDsActionMixin, _validation, _model, _mixinIcons) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_defaultDsActionMixin.default, _mixinIcons.default, {
        validation: _validation.default,
        schema: _model.schema,

        modelType: "vacancy-plan-feature",
        messageSave: "Feature oppdatert.",
        messageDelete: "Feature slettet."
    });
});