define('uwork-app-v2/contractee/my-workers/order/new/validation', ['exports', 'ember-changeset-validations/validators'], function (exports, _validators) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = {
        shift: (0, _validators.validatePresence)({ presence: true, description: 'Vakttype' }),
        startDate: (0, _validators.validatePresence)({ presence: true, description: 'Start dato' }),
        startTime: (0, _validators.validatePresence)({ presence: true, description: 'Tid fra' }),
        endTime: (0, _validators.validatePresence)({ presence: true, description: 'Tid til' }),
        duration: (0, _validators.validatePresence)({ presence: true, description: 'Velg antall dager' }),
        notify: (0, _validators.validatePresence)(true),
        type: (0, _validators.validatePresence)(true)
    };
});