define('uwork-app-v2/components/bs/tool-tip/component', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        tagName: 'i',
        classNames: ['mdi', 'mdi-help-circle'],
        attributeBindings: ['data-toggle', 'type', 'data-href', 'data-trigger', 'trigger', 'data-id', 'href', 'data-placement', 'title'],
        didInsertElement: function didInsertElement() {
            this._super.apply(this, arguments);
            this.$().tooltip();
        },
        willDestroyElement: function willDestroyElement() {
            this._super.apply(this, arguments);
            // this.$().tooltip('dispose');
        }
    });
});