define('uwork-app-v2/contractor/assignments/view/controller', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        media: Ember.inject.service(),
        session: Ember.inject.service(),
        store: Ember.inject.service(),
        appService: Ember.inject.service('application'),
        account: Ember.inject.service('user/session-account'),
        user: Ember.computed.alias('account.currentUser'),
        notifications: Ember.computed.alias('user.notifications'),
        //alias
        order: Ember.computed.alias('model'),
        request: Ember.computed.alias('model.orderRequests.firstObject'),
        contractee: Ember.computed.alias('model.contractee'),
        orderShifts: Ember.computed.alias('model.orderShifts'),
        category: Ember.computed.alias('model.category'),
        shift: Ember.computed.alias('model.shift'),
        turnus: Ember.computed.alias('order.turnus'),
        //Error Handler
        notFoundError: Ember.computed.empty('model')

        // actions: {
        //     acceptJob(request){
        //         this.setLoading(true);
        //         let order = get(this,'order');
        //         request.setActive();
        //
        //         request.save().then(() => {
        //
        //             order.reload();
        //             this.swal('Din interesse er nå meldt.').then(()=>{
        //                 // request.reload();
        //
        //             });
        //         }).catch(()=>{
        //
        //         }).finally(()=>{
        //             this.setLoading(false);
        //         });
        //     },
        //     deleteOrder(request){
        //         this.setLoading(true);
        //         request.isCanApply();
        //         let order = get(this,'order');
        //
        //         request.save().then(()=>{
        //             order.reload();
        //             this.swal('Du har blitt fjernet som en søker på vakten.').then(()=>{
        //                 // request.reload();
        //
        //             },()=>{});
        //         }).catch(()=>{
        //
        //         }).finally(()=>{
        //             this.setLoading(false);
        //         });
        //     }
        // }
    });
});