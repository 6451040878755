define('uwork-app-v2/mixins/loading', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Mixin.create({
        status: true,
        appService: Ember.inject.service('application'),
        setLoading: function setLoading(status) {
            var appService = Ember.get(this, 'appService');
            Ember.set(appService, 'loading', status);
        },
        showAfterRender: function showAfterRender(status) {
            var appService = Ember.get(this, 'appService');
            Ember.set(appService, 'isSigningIn', status);
        }
    });
});