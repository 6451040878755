define('uwork-app-v2/components/admin/category-new/component', ['exports', 'uwork-app-v2/components/admin/category-new/validation', 'uwork-app-v2/category/model', 'uwork-app-v2/mixins/default-ds-action-mixin'], function (exports, _validation, _model, _defaultDsActionMixin) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_defaultDsActionMixin.default, {
        store: Ember.inject.service(),
        validation: _validation.default,
        schema: _model.schema,

        modelType: "category",
        messageCreate: "Yrke opprettet.",

        init: function init() {
            this._super.apply(this, arguments);
            var model = Ember.get(this, 'store').createRecord("category");
            Ember.set(this, 'model', model);
        }
    });
});