define('uwork-app-v2/components/bs/badge-outline/component', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        tagName: 'span',
        classNames: ['badge-outline', 'badge-outline-pill'],
        colors: ['blue', 'indigo', 'purple', 'pink', 'red', 'orange', 'yellow', 'green', 'teal', 'cyan'],
        color: function color() {
            var colors = Ember.get(this, 'colors');

            var color = colors[Math.floor(Math.random() * colors.get('length'))];

            this.$().addClass('badge-outline-' + color);
        },
        didInsertElement: function didInsertElement() {
            this._super.apply(this, arguments);

            this.color();
        }
    });
});