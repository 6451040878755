define('uwork-app-v2/components/contractee/settings/additional-payment/component', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        store: Ember.inject.service(),
        session: Ember.inject.service(),
        account: Ember.inject.service('user/session-account'),
        user: Ember.computed.alias('account.currentUser'),
        contractee: Ember.computed.alias('user.contractee'),

        models: null,
        saved: Ember.computed.filterBy('models', 'isNew', false),
        init: function init() {
            this._super.apply(this, arguments);
            var store = Ember.get(this, 'store');

            var models = store.findAll('contractee-payment'); //.filterBy('isNew', true);

            Ember.set(this, 'models', models);
        }
    });
});