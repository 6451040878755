define('uwork-app-v2/participant/model', ['exports', 'ember-data/model', 'ember-data/attr', 'ember-data/relationships'], function (exports, _model, _attr, _relationships) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _model.default.extend({
        lastRead: (0, _attr.default)('mdate'),
        conversation: (0, _relationships.belongsTo)('conversation', { inverse: 'participants' }),
        user: (0, _relationships.belongsTo)('user')
    });
});