define('uwork-app-v2/authenticators/torii', ['exports', 'ember-simple-auth/authenticators/torii', 'uwork-app-v2/mixins/auth/social', 'ember-sweetalert', 'uwork-app-v2/config/environment'], function (exports, _torii, _social, _emberSweetalert, _environment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _torii.default.extend(_social.default, {
        torii: Ember.inject.service(),
        ajax: Ember.inject.service(),
        session: Ember.inject.service(),

        authenticate: function authenticate(provider) {
            var _this = this;

            var redirect_register = '' + _environment.default.APP.APP_REDIRECT_REGISTER;

            return this._super.apply(this, arguments).then(function (data) {

                var payload = {
                    "code": data.authorizationCode || data.authorizationToken.access_token,
                    'provider': provider
                };

                var request = _this.auth(payload);

                return request.then(function (data) {
                    return {
                        token: data.token,
                        provider: provider
                    };
                }, function (error) {
                    (0, _emberSweetalert.default)({
                        title: "Bruker ikke funnet!",
                        html: "Trykk her for å registere deg <br /> <a class='btn btn-primary btn-register m-3' href='" + redirect_register + "'>Register deg</a>",
                        showConfirmButton: false,
                        showCloseButton: true,
                        type: 'error'
                    }).then(function () {}, function () {});
                    return Ember.RSVP.reject(error);
                });
            });
        }
    });
});