define('uwork-app-v2/components/contractee/settings/nda/display-form-new/validation', ['exports', 'ember-changeset-validations/validators'], function (exports, _validators) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = {
        name: (0, _validators.validatePresence)({ presence: true, description: 'Filnavn' }),
        file: (0, _validators.validatePresence)({ presence: true, description: 'Fil' })
    };
});