define('uwork-app-v2/contractee/view/route', ['exports', 'ember-i18n'], function (exports, _emberI18n) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        title: (0, _emberI18n.translationMacro)('contractee.pageName.profile'),
        breadCrumb: {},
        beforeModel: function beforeModel() {
            this.setLoading(true);
        },
        model: function model(params) {
            return this.store.queryRecord('contractee', {
                contractee_id: params.contractee_id,
                view: true
            }).catch(function () {});
        },
        afterModel: function afterModel(model) {
            if (model) {

                var bTitle = {
                    title: model.get('name')
                };

                Ember.set(this, 'title', model.get('name'));
                Ember.set(this, 'breadCrumb', bTitle);
            } else {

                var title = this.get('title');
                var _bTitle = {
                    title: title.string
                };

                this.setLoading(false);
                Ember.set(this, 'breadCrumb', _bTitle);
            }
            this.setLoading(false);
        }
    });
});