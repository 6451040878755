define('uwork-app-v2/components/admin/contractor/edit-form/validation', ['exports', 'ember-changeset-validations/validators', 'uwork-app-v2/validations/found-zipcode'], function (exports, _validators, _foundZipcode) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = {
        'user.firstName': [(0, _validators.validatePresence)(true)],
        'user.lastName': [(0, _validators.validatePresence)(true)],
        'user.email': [(0, _validators.validatePresence)(true)],
        zipCode: [(0, _validators.validatePresence)({ presence: true, description: 'Postnummer' }), (0, _foundZipcode.default)()],
        place: (0, _validators.validateFormat)(true)
    };
});