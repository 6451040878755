define('uwork-app-v2/components/contractor/card/display-order-count/component', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        classNames: ['text-lg-right text-md-left mb-3'],
        counter: Ember.computed('model.orderCount', function () {
            return Ember.get(this, 'model.orderCount') > 0 ? Ember.get(this, 'model.orderCount') : 0;
        })
    });
});