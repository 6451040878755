define('uwork-app-v2/components/admin/agreement-item/component', ['exports', 'uwork-app-v2/mixins/default-ds-action-mixin', 'uwork-app-v2/components/admin/agreement-item/validation', 'uwork-app-v2/agreement/model', 'uwork-app-v2/mixins/simditor-advance-mixin'], function (exports, _defaultDsActionMixin, _validation, _model, _simditorAdvanceMixin) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_defaultDsActionMixin.default, _simditorAdvanceMixin.default, {
        validation: _validation.default,
        schema: _model.schema,

        modelType: "agreement",
        messageSave: "Kontrakt oppdatert.",
        messageDelete: "Kontrakt slettet."
    });
});