define('uwork-app-v2/avatar/model', ['exports', 'uwork-app-v2/file-store/model'], function (exports, _model) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _model.default.extend({
        hasThumb: Ember.computed('filename', function () {
            if (Ember.get(this, 'filename')) {
                return Ember.get(this, 'filename').split('.')[0].length === 40;
            } else {
                return Ember.get(this, 'filename');
            }
        }),

        thumb: Ember.computed('filename', 'getUrlPath', 'type', function () {
            if (Ember.get(this, 'hasThumb')) {
                return Ember.get(this, 'getUrlPath') + '/thumbnails/200x200-' + Ember.get(this, 'filename');
            }

            return Ember.get(this, 'url');
        })
    });
});