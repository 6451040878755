define('uwork-app-v2/components/contractor/settings/gcc/display-main/component', ['exports', 'ember-local-storage'], function (exports, _emberLocalStorage) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        store: Ember.inject.service(),
        session: Ember.inject.service('session'),
        account: Ember.inject.service('user/session-account'),
        user: Ember.computed.alias('account.currentUser'),

        documents: Ember.computed.filterBy('files', 'isGCC'),
        showPanel: Ember.computed.empty('files'),

        wizardStep: (0, _emberLocalStorage.storageFor)('contractor/wizard'),
        isWizard: Ember.computed.equal('user.isSignUpCompleted', false),

        modelIsEmpty: Ember.computed.empty('documents'),
        modelNotEmpty: Ember.computed.notEmpty('documents'),

        showPanelText: Ember.computed('showPanel', function () {
            return Ember.get(this, 'showPanel') ? 'contractor.settings.panel.close' : 'contractor.settings.panel.add';
        }),
        showPanelIcon: Ember.computed('showPanel', function () {
            return Ember.get(this, 'showPanel') ? 'close-circle' : 'plus-circle';
        }),
        showPanelIconColor: Ember.computed('showPanel', function () {
            return Ember.get(this, 'showPanel') ? 'text-danger' : 'text-primary';
        }),

        actions: {
            insertNew: function insertNew() {
                this.toggleProperty('showPanel');
            },
            saveNext: function saveNext() {
                var step = parseInt(Ember.get(this, 'wizardStep.step'));
                if (step === 8) {
                    this.set('wizardStep.step', 9);
                }
                this.sendAction('toRoute', 'contractor.settings.payment');
            },
            back: function back() {
                this.sendAction('toRoute', 'contractor.settings.experience');
            }
        }
    });
});