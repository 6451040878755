define('uwork-app-v2/components/admin/vacancy/display-form-new/component', ['exports', 'uwork-app-v2/vacancy/model', 'uwork-app-v2/validations/vacancy', 'uwork-app-v2/mixins/vacancy-mixin', 'moment'], function (exports, _model, _vacancy, _vacancyMixin, _moment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var keys = Object.keys;
    exports.default = Ember.Component.extend(_vacancyMixin.default, {
        schema: _model.schema,
        VacancyValidations: _vacancy.default,
        store: Ember.inject.service(),
        vacancy: Ember.computed.alias('model.vacancy'),
        occupations: Ember.computed.alias('model.occupations'),
        minDate: (0, _moment.default)().format('YYYY-MM-DD'),
        tags: [],
        selectedTags: Ember.A(),
        classNames: ['post-job-wrapper'],
        toolbar: ['bold', 'italic', 'underline', 'title', 'ol', 'ul', 'hr', 'blockquote'],
        save: function save(changeset) {
            var _this = this;
            var snapshot = changeset.snapshot();
            return changeset.cast(keys(_model.schema)).validate().then(function () {
                if (Ember.get(changeset, 'isValid')) {
                    //this.swal('Oppdatert!');
                    return changeset.save().then(function (vacancy) {
                        _this.sendAction('toRoute', 'admin.vacancies.view', vacancy.get('id'));
                    });
                } else {
                    // console.log('notValid');
                }
            }).catch(function () {
                changeset.restore(snapshot);
            });
        },

        actions: {
            status: function status(changeset, _status) {
                changeset.set('status', _status);
                this.save(changeset);
            },
            handleKeydown: function handleKeydown(changeset, dropdown, e) {

                if (e.keyCode !== 13) {
                    return;
                }
                var text = e.target.value;
                if (text.length > 0 && this.get('tags').indexOf(text) === -1) {
                    var tags = changeset.get('tags');
                    changeset.set('tags', tags.concat([text]));
                }
            },
            publish: function publish(changeset) {
                changeset.set('status', 20);
                this.save(changeset);
            },
            save: function save(changeset) {
                this.save(changeset);
            },
            cancel: function cancel(model) {
                if (model.isNew) {
                    this.sendAction('toRoute', 'admin.vacancies');
                } else {
                    model.rollbackAttributes();
                    this.sendAction('toRoute', 'admin.vacancies.view', model.get('id'));
                }
            }
        }
    });
});