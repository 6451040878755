define('uwork-app-v2/components/app/job-occupations/component', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        classNames: ['badges-container', 'vacancy-occupation-container'],
        showBoxVersion: false,
        occupationsVisible: Ember.computed.filter('occupations', function (user, index) {
            return index < 3;
        }),
        occupationsNotVisible: Ember.computed.filter('occupations', function (user, index) {
            return index >= 3;
        })
    });
});