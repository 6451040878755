define('uwork-app-v2/components/contractee/settings/nda/display-form-new/component', ['exports', 'uwork-app-v2/components/contractee/settings/nda/display-form-new/validation', 'uwork-app-v2/components/contractee/settings/nda/display-form-new/schema', 'uwork-app-v2/auth/error-object-parser', 'ember-changeset-validations', 'ember-changeset'], function (exports, _validation, _schema, _errorObjectParser, _emberChangesetValidations, _emberChangeset) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var keys = Object.keys;
    exports.default = Ember.Component.extend({
        session: Ember.inject.service('session'),
        account: Ember.inject.service('user/session-account'),
        store: Ember.inject.service(),

        errors: null,
        success: null,
        NdaValidation: _validation.default,

        init: function init() {
            this._super.apply(this, arguments);
            this._changeset();
        },
        _changeset: function _changeset() {
            Ember.set(this, 'model', _schema.default.create());
            Ember.set(this, 'changeset', new _emberChangeset.default(Ember.get(this, 'model'), (0, _emberChangesetValidations.default)(_validation.default), _validation.default));
        },


        fileName: Ember.computed('changeset.file', function () {
            var file = Ember.get(this, 'changeset.file');
            if (file) {
                return file.name;
            }

            //translation is coming to late for interpretation
            //return t('contractor.account.edit.politiattest.selectFile');
            return 'Velg fil';
        }),

        actions: {
            clear: function clear(changeset) {
                changeset.rollback();
            },
            upload: function upload(changeset) {
                var _this = this;

                var snapshot = changeset.snapshot();

                // Reset errors & success variable
                Ember.set(this, 'errors', null);
                Ember.set(this, 'success', null);

                return changeset.cast(keys(_schema.schema)).validate().then(function () {
                    if (Ember.get(changeset, 'isValid')) {
                        changeset.save().then(function (payload) {
                            _this._changeset();
                            //ToDo will not work any more?!
                            Ember.get(_this, 'store').pushPayload('file-store', payload);
                            Ember.set(_this, 'success', 'Opplasting var vellykket!');
                        }, function (error) {
                            var parsedErrors = _errorObjectParser.default.parse(error, changeset);

                            // Check if this is non attribute error
                            if (parsedErrors && parsedErrors.length > 0) {
                                Ember.set(_this, 'errors', parsedErrors);
                            }
                        });
                    } else {
                        //console.log('isNotValid');
                    }
                }).catch(function () {
                    changeset.restore(snapshot);
                });
            },
            onFilesChange: function onFilesChange(files, uploader) {
                if (Ember.isPresent(files)) {
                    Ember.set(this, 'changeset.file', files[0]);
                    Ember.set(this, 'changeset.uploader', uploader);
                }
            }
        }
    });
});