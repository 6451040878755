define('uwork-app-v2/controllers/application', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Controller = Ember.Controller,
      computed = Ember.computed;
  exports.default = Controller.extend({
    init: function init() {
      var date = moment().add(1, 'days').toDate();
      this.set('selectedDate', date);
      this.set('selectedDateUtc', new Date(moment.utc().format()));
      this.set('selectedDate2', date);
      this.set('selectedDate3', null);
      this.set('selectedDate4', moment('06/06/2012', 'MM/DD/YYYY').toDate());
      this.set('selectedDate5', date);
      this.set('selectedDate6', null);

      this.set('minDate', moment('06/03/2012', 'MM/DD/YYYY').toDate());
      this.set('maxDate', moment('06/25/2012', 'MM/DD/YYYY').toDate());

      return this._super.apply(this, arguments);
    }
  });
});