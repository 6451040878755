define('uwork-app-v2/order-shift-rule/model', ['exports', 'ember-data/model', 'ember-data/attr', 'ember-data/relationships'], function (exports, _model, _attr, _relationships) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.schema = undefined;


    var STATUS_DEFAULT = 0;
    var STATUS_APPROVED = 10;
    var STATUS_REJECTED = 20;

    var schema = exports.schema = {
        name: (0, _attr.default)('string')
    };

    exports.default = _model.default.extend(schema, {
        orderShift: (0, _relationships.belongsTo)('order-shift'),
        // contractor_id: attr('number'),
        description: (0, _attr.default)('string'),
        user: (0, _relationships.belongsTo)('user'),
        approvedAt: (0, _attr.default)('mdate'),
        confirmed: (0, _attr.default)('number')
    });
});