define('uwork-app-v2/auth/login/controller', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        session: Ember.inject.service(),
        actions: {
            loginWithGithub: function loginWithGithub() {
                this.get('session').authenticate('authenticator:torii', 'github');
            },
            loginWithFacebook: function loginWithFacebook() {
                this.get('session').authenticate('authenticator:torii', 'facebook');
            },
            loginWithGoogle: function loginWithGoogle() {
                this.get('session').authenticate('authenticator:torii', 'google');
            },
            loginWithLinkedIn: function loginWithLinkedIn() {
                this.get('session').authenticate('authenticator:torii', 'linkedin');
            }
        }
    });
});