define('uwork-app-v2/app', ['exports', 'uwork-app-v2/resolver', 'ember-load-initializers', 'uwork-app-v2/config/environment'], function (exports, _resolver, _emberLoadInitializers, _environment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });


    var App = Ember.Application.extend({
        modulePrefix: _environment.default.modulePrefix,
        podModulePrefix: _environment.default.podModulePrefix,
        Resolver: _resolver.default
        // customEvents: {
        //     // prevent listeners for mouseenter/mouseleave events
        //     mouseenter: null,
        //     mouseleave: null
        // }
    });

    (0, _emberLoadInitializers.default)(App, _environment.default.modulePrefix);

    exports.default = App;
});