define('uwork-app-v2/transitions', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    exports.default = function () {
        // this.transition(
        //     this.hasClass('saveJob'),
        //
        //     // this makes our rule apply when the liquid-if transitions to the
        //     // true state.
        //     this.toValue(true),
        //     this.use('crossFade', {duration: 200}),
        //
        //     // which means we can also apply a reverse rule for transitions to
        //     // the false state.
        //     this.reverse('crossFade', {duration: 200})
        // );
        this.transition(this.hasClass('showText'),

        // this makes our rule apply when the liquid-if transitions to the
        // true state.
        this.toValue(true), this.use('toRight', { duration: 400 }),

        // which means we can also apply a reverse rule for transitions to
        // the false state.
        this.reverse('toLeft', { duration: 200 }));
        this.transition(this.hasClass('showMobileNav'),

        // this makes our rule apply when the liquid-if transitions to the
        // true state.
        this.toValue(true), this.use('fade', { duration: 300 }),

        // which means we can also apply a reverse rule for transitions to
        // the false state.
        this.reverse('fade', { duration: 400 }));

        this.transition(this.hasClass('showSearch'),

        // this makes our rule apply when the liquid-if transitions to the
        // true state.
        this.toValue(true), this.use('toLeft', { duration: 200 }),

        // which means we can also apply a reverse rule for transitions to
        // the false state.
        this.reverse('toRight', { duration: 200 }));
    };
});