define('uwork-app-v2/contractee/settings/agreement/route', ['exports', 'ember-i18n'], function (exports, _emberI18n) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        title: (0, _emberI18n.translationMacro)('contractee.settings.pageName.agreement'),
        wizardService: Ember.inject.service('contractee/wizard'),
        wizardRouteName: 'contractee.settings.agreement',
        breadCrumb: {},
        beforeModel: function beforeModel() {
            this.setLoading(true);

            // Set wizard route name
            Ember.get(this, 'wizardService').setCurrentRoute(Ember.get(this, 'wizardRouteName'));
        },
        model: function model() {
            return this.store.queryRecord('agreement-confirmation', { latest: true }).catch(function () {});
        },
        afterModel: function afterModel() {
            var title = this.get('title');

            var bTitle = {
                title: title.string
            };
            this.setLoading(false);
            Ember.set(this, 'breadCrumb', bTitle);
        },

        actions: {
            toRoute: function toRoute(path) {
                this.transitionTo(path);
            }
        }
    });
});