define('uwork-app-v2/contractor/settings/skills/route', ['exports', 'ember-i18n'], function (exports, _emberI18n) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        breadCrumb: {},
        title: (0, _emberI18n.translationMacro)('contractor.settings.pageName.skills'),
        wizardService: Ember.inject.service('contractor/wizard'),
        wizardRouteName: 'contractor.settings.skills',

        beforeModel: function beforeModel() {
            this.setLoading(true);

            // Set wizard route name
            Ember.get(this, 'wizardService').setCurrentRoute(Ember.get(this, 'wizardRouteName'));
        },
        model: function model() {
            return Ember.RSVP.hash({
                skills: this.store.findAll('skill'),
                courses: this.store.findAll('course')
            });
        },
        afterModel: function afterModel() {
            var title = this.get('title');

            var bTitle = {
                title: title.string
            };

            this.setLoading(false);
            Ember.set(this, 'breadCrumb', bTitle);
        },


        actions: {
            toRoute: function toRoute(path) {
                this.transitionTo(path);
            }
        }
    });
});