define('uwork-app-v2/admin/management/contractee-contractor/controller', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        didValidate: false,
        contractor: null,
        contractee: null,
        page: 1,
        queryParams: ['contractee', 'contractor', 'page'],

        // Sort contractors by full name
        sortByFullName: ['user.fullName:asc'],
        contractors: Ember.computed.sort('model.contractors', 'sortByFullName'),

        actions: {
            setCurrentContractor: function setCurrentContractor(contractor) {
                this.set('contractor', contractor);
                this.set('page', 1);
            },
            setCurrentContractee: function setCurrentContractee(contractee) {
                this.set('contractee', contractee);
                this.set('page', 1);
            },
            edit: function edit(model) {
                var edit = !Ember.get(model, 'edit');

                Ember.set(model, 'edit', edit);
            },
            save: function save(model) {
                var _this = this;

                Ember.set(this, 'didValidate', true);
                model.validate().then(function (_ref) {
                    var validations = _ref.validations;


                    if (validations.get('isValid')) {
                        model.save().then(function () {

                            Ember.set(_this, 'didValidate', false);
                            _this.swal({
                                title: "Dine opplysninger er lagret!",
                                timer: 1000,
                                showConfirmButton: false,
                                showCloseButton: false,
                                type: 'success'
                            });

                            Ember.set(model, 'edit', false);

                            // console.log('update order-request close modal');
                            //Close Modal
                            //get(this, 'showAgreement').perform();

                            // Check order shift rules
                            // get(this, 'checkRules').perform();
                        });
                    } else {
                        //console.log('isInvalid');
                    }
                });
            }
        }
    });
});