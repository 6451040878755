define('uwork-app-v2/user-data-export/adapter', ['exports', 'uwork-app-v2/application/adapter'], function (exports, _adapter) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _adapter.default.extend({
        urlForQueryRecord: function urlForQueryRecord(query, modelName) {
            if (query.request) {
                delete query.request;
                return this.urlPrefix() + '/user-data-exports/export';
            }
            return this._super.apply(this, arguments);
        }
    });
});