define('uwork-app-v2/components/bs/accordion-card-panel/component', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        classNames: ['collapse'],
        media: Ember.inject.service(),
        mediaIsMd: Ember.computed.equal('media.isMd', true),
        mediaIsLg: Ember.computed.equal('media.isLg', true),
        mediaIsXl: Ember.computed.equal('media.isXl', true),
        classNameBindings: ['mediaIsMd:show', 'mediaIsLg:show', 'mediaIsXl:show']
    });
});