define('uwork-app-v2/components/auth/login-form-account/component', ['exports', 'uwork-app-v2/components/auth/login-form-account/validation', 'uwork-app-v2/components/auth/login-form-account/schema', 'uwork-app-v2/auth/error-object-parser'], function (exports, _validation, _schema, _errorObjectParser) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var keys = Object.keys;
    exports.default = Ember.Component.extend({
        validation: _validation.default,
        session: Ember.inject.service(),
        errors: null,

        init: function init() {
            this._super.apply(this, arguments);
            Ember.set(this, 'model', _schema.default.create());
        },


        actions: {
            authenticate: function authenticate(changeset) {
                var _this = this;

                var credentials = { identification: Ember.get(changeset, 'account'), password: Ember.get(changeset, 'password') },
                    authenticator = 'authenticator:jwt',
                    snapshot = changeset.snapshot();

                // Reset errors
                Ember.set(this, 'errors', null);

                return changeset.cast(keys(_schema.schema)).validate().then(function () {
                    if (Ember.get(changeset, 'isValid')) {

                        Ember.get(_this, 'session').authenticate(authenticator, credentials).then(function () {}, function (error) {
                            if (error) {
                                var parsedErrors = _errorObjectParser.default.parse(error, changeset);

                                // Check if this is non attribute error
                                if (parsedErrors && parsedErrors.length > 0) {
                                    Ember.set(_this, 'errors', parsedErrors);
                                }
                            }
                        });
                    } else {
                        //console.log('isNotValid');
                    }
                }).catch(function () {
                    changeset.restore(snapshot);
                });
            }
        }
    });
});