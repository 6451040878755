define('uwork-app-v2/components/contractee/vacancy/display-form-new/component', ['exports', 'uwork-app-v2/vacancy/model', 'uwork-app-v2/validations/vacancy', 'uwork-app-v2/mixins/vacancy-mixin', 'moment', 'ember-changeset-validations', 'ember-changeset'], function (exports, _model, _vacancy, _vacancyMixin, _moment, _emberChangesetValidations, _emberChangeset) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var keys = Object.keys;
    exports.default = Ember.Component.extend(_vacancyMixin.default, {
        session: Ember.inject.service(),
        account: Ember.inject.service('user/session-account'),
        user: Ember.computed.alias('account.currentUser'),
        schema: _model.schema,
        VacancyValidations: _vacancy.default,

        vacancy: Ember.computed.alias('model.vacancy'),
        occupations: Ember.computed.alias('model.occupations'),

        plans: Ember.computed.filter('model.plans.@each.name', function (plan, index, array) {
            return plan.get('name') ? plan : null;
        }),

        features: null,
        standardPlan: '',
        tags: [],
        selectedTags: Ember.A(),

        showVacancy: true,
        showPlans: false,
        showStandardFeaturesExtra: false,
        showSuccess: false,

        store: Ember.inject.service(),
        minDate: (0, _moment.default)().format('YYYY-MM-DD'),
        classNames: ['post-job-wrapper'],
        toolbar: ['bold', 'italic', 'underline', 'title', 'ol', 'ul', 'hr', 'blockquote'],
        vacancyModel: '',
        vacancyDirectPublish: Ember.computed.alias('vacancyModel'),
        vacancyDirectPublishPlan: Ember.computed.alias('vacancyDirectPublish.vacancyPlan'),
        vacancyDirectPublishPlanFeatures: Ember.computed.alias('vacancyDirectPublish.extraFeatures'),

        init: function init() {
            this._super.apply(this, arguments);

            var features = Ember.get(this, 'store').query('vacancy_plan_feature', {});
            Ember.set(this, 'features', features);

            Ember.set(this, 'changeset', new _emberChangeset.default(Ember.get(this, 'vacancy'), (0, _emberChangesetValidations.default)(_vacancy.default), _vacancy.default));
        },


        reCalculatePlan: Ember.observer('features.@each.checked', function () {

            var changeset = Ember.get(this, 'changeset');
            var vacancyPlan = Ember.get(changeset, 'vacancyPlan');
            if (vacancyPlan) {

                var existingVacancyPlanFeatures = Ember.get(this, 'features');
                var vacancyPlanFeatures = vacancyPlan.get('vacancyPlanFeatures');
                if (vacancyPlanFeatures) {

                    var checkedFeaturesExtra = existingVacancyPlanFeatures.filterBy('checked', true);
                    var unCheckedFeaturesExtra = existingVacancyPlanFeatures.filterBy('checked', false);

                    if (unCheckedFeaturesExtra) {
                        vacancyPlanFeatures.removeObjects(unCheckedFeaturesExtra);
                    }
                    if (checkedFeaturesExtra) {
                        vacancyPlanFeatures.addObjects(checkedFeaturesExtra);
                    }
                }
            }
        }),

        total: Ember.computed('changeset.vacancyPlan.price', 'features.@each.checked', function () {
            var checkedFeatures = Ember.get(this, 'features').filterBy('checked', true);
            var price = Ember.get(this, 'changeset.vacancyPlan.price');

            var _total = 0;
            var total = !Ember.get(this, 'user.userHasVacancy') && Ember.get(this, 'changeset.vacancyPlan.isStandard') ? 0 : price;

            checkedFeatures.forEach(function (item) {
                _total = _total + parseFloat(item.get('price'));
            });

            return total + _total;
        }),
        checkPrioritized: function checkPrioritized(vacancyPlanFeatures) {
            var names = vacancyPlanFeatures.getEach('name');

            return names.includes('Prioritert synlighet på uWork.no');
        },
        save: function save(changeset) {
            var _this = this;

            var snapshot = changeset.snapshot();

            var total = Ember.get(this, 'total');
            var tags = Ember.get(this, 'model.tags');

            var vacancyFeaturePlanPivot = Ember.get(changeset, 'vacancyPlan.vacancyFeaturePlanPivot');
            var vacancyPlanFeatures = Ember.get(changeset, 'vacancyPlan.vacancyPlanFeatures');
            var features = vacancyFeaturePlanPivot.getEach('vacancyPlanFeature');

            var prioritized = this.checkPrioritized(features);

            Ember.set(changeset, 'prioritized', prioritized);
            Ember.set(changeset, 'total', total);
            Ember.set(changeset, 'tags', tags);

            // changeset.set('vacancyFeaturePlanPivot', vacancyFeaturePlanPivot);
            // set(changeset, 'vacancyPlanFeatures', features);
            changeset.get('vacancyFeaturePlanPivot').pushObjects(vacancyFeaturePlanPivot);
            changeset.get('vacancyPlanFeatures').pushObjects(vacancyPlanFeatures);

            return changeset.cast(keys(_model.schema)).validate().then(function () {
                if (Ember.get(changeset, 'isValid')) {
                    return changeset.save().then(function (vacancy) {
                        Ember.set(_this, 'showSuccess', true);
                        Ember.set(_this, 'vacancyModel', vacancy);

                        Ember.set(_this, 'showVacancy', false);
                        Ember.set(_this, 'showPlans', false);
                        Ember.set(_this, 'showStandardFeaturesExtra', false);
                        //_this.sendAction('toRoute', 'contractee.vacancy.view', vacancy.get('id'));
                    });
                } else {
                    // console.log('notValid');
                }
            }).catch(function () {
                changeset.restore(snapshot);
            });
        },
        validateStepVacancy: function validateStepVacancy() {
            var _this2 = this;

            var changeset = Ember.get(this, 'changeset');
            var snapshot = changeset.snapshot();

            return changeset.cast(keys(_model.schema)).validate().then(function () {
                if (Ember.get(changeset, 'isValid')) {
                    Ember.set(_this2, 'showVacancy', false);
                    Ember.set(_this2, 'showPlans', true);
                    Ember.set(_this2, 'showStandardFeaturesExtra', false);
                } else {
                    //console.log('notValid');
                }
            }).catch(function () {
                changeset.restore(snapshot);
            });
        },

        actions: {
            selectFeature: function selectFeature(feature) {
                if (feature.get('checked')) {
                    feature.set('checked', false);
                } else {
                    feature.set('checked', true);
                }
            },
            handleKeydown: function handleKeydown(dropdown, e) {

                if (e.keyCode !== 13) {
                    return;
                }

                var text = e.target.value;
                var tags = this.get('selectedTags') || '';
                var length = Ember.get(this, 'selectedTags');

                //if input is not null
                if (text.length > 0 && (!tags.includes(text) || //check array
                length === 0 //first element
                && length < 5) //set limit
                ) {
                        Ember.set(this, 'selectedTags', tags.concat([text]));
                        Ember.set(this, 'model.tags', tags.concat([text]));
                    }
            },
            selectPlan: function selectPlan(changeset, plan) {
                changeset.set('vacancyPlan', plan);

                if (plan.get('slug') === 'standard') {
                    Ember.set(this, 'showStandardFeaturesExtra', true);
                    Ember.set(this, 'showVacancy', false);
                    Ember.set(this, 'showPlans', false);
                } else {
                    this.save(changeset);
                }
            },
            showVacancy: function showVacancy() {
                Ember.set(this, 'showVacancy', true);
                Ember.set(this, 'showPlans', false);
                Ember.set(this, 'showStandardFeaturesExtra', false);
            },
            showPlans: function showPlans() {
                this.validateStepVacancy();
            },
            showStandardFeaturesExtra: function showStandardFeaturesExtra() {
                Ember.set(this, 'showVacancy', false);
                Ember.set(this, 'showPlans', false);
                Ember.set(this, 'showStandardFeaturesExtra', true);
            },
            status: function status(changeset, _status) {
                changeset.set('status', _status);
                this.save(changeset);
            },
            publish: function publish(changeset) {
                changeset.set('status', 20);
                this.save(changeset);
            },
            save: function save(changeset) {
                this.save(changeset);
            },
            cancel: function cancel(model) {
                if (model.isNew) {
                    this.sendAction('toRoute', 'contractee.vacancy');
                } else {
                    model.rollbackAttributes();
                    this.sendAction('toRoute', 'contractee.vacancy.view', model.get('id'));
                }
            }
        }
    });
});