define('uwork-app-v2/vacancy/validation', ['exports', 'ember-cp-validations'], function (exports, _emberCpValidations) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = (0, _emberCpValidations.buildValidations)({
        // Vacancy
        title: {
            description: 'Tittel',
            validators: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('ds-error')]
        },

        description: {
            description: 'Beskrivelse',
            validators: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('ds-error')]
        },

        numberOfPositions: {
            description: 'Antall stillinger',
            validators: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('number', { allowString: true }), (0, _emberCpValidations.validator)('ds-error')]
        },

        type: {
            description: 'Stillingstype',
            validators: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('ds-error')]
        },

        deadlineAt: {
            description: 'Søknadsfrist',
            validators: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('ds-error')]
        },

        address: {
            description: 'Adresse',
            validators: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('ds-error')]
        },

        zipCode: {
            description: 'Postnummer',
            validators: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('ds-error'), (0, _emberCpValidations.validator)('found-zipcode', { message: 'Feil postnummer' })]
        },

        place: {
            description: 'Poststed',
            validators: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('ds-error')]
        }
    }, { debounce: 500 });
});