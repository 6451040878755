define('uwork-app-v2/components/contractor/assignment/contractee-info/component', ['exports', 'uwork-app-v2/mixins/contractor/is-my-order'], function (exports, _isMyOrder) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_isMyOrder.default, {
        tagName: '',
        store: Ember.inject.service(),
        model: Ember.computed.alias('vacancy.model'),
        conversation: Ember.computed.alias('model.conversation'),
        userModel: Ember.computed.alias('vacancy.user'),
        vacancy: Ember.inject.service(),
        menu: Ember.inject.service('extend-menu'),
        account: Ember.inject.service('user/session-account'),
        user: Ember.computed.alias('account.currentUser'),
        lat: 59.9139,
        lng: 10.7522,
        customOptions: {
            mapTypeControl: false,
            streetViewControl: false,
            zoomControl: false,
            center: { lat: 59.9139, lng: 10.7522 },
            draggable: false,
            scrollwheel: false,
            styles: [{
                "featureType": "administrative",
                "elementType": "labels",
                "stylers": [{
                    "visibility": "on"
                }]
            }, {
                "featureType": "administrative",
                "elementType": "labels.text.fill",
                "stylers": [{
                    "color": "#ffffff"
                }, {
                    "weight": "0.01"
                }]
            }, {
                "featureType": "administrative",
                "elementType": "labels.text.stroke",
                "stylers": [{
                    "visibility": "off"
                }]
            }, {
                "featureType": "landscape",
                "elementType": "all",
                "stylers": [{
                    "color": "#09b275"
                }]
            }, {
                "featureType": "poi",
                "elementType": "all",
                "stylers": [{
                    "visibility": "off"
                }]
            }, {
                "featureType": "road",
                "elementType": "all",
                "stylers": [{
                    "saturation": -100
                }, {
                    "lightness": "100"
                }, {
                    "color": "#0f8f61"
                }]
            }, {
                "featureType": "road",
                "elementType": "geometry.stroke",
                "stylers": [{
                    "visibility": "off"
                }]
            }, {
                "featureType": "road",
                "elementType": "labels.text.fill",
                "stylers": [{
                    "color": "#ffffff"
                }, {
                    "weight": "1.49"
                }]
            }, {
                "featureType": "road",
                "elementType": "labels.text.stroke",
                "stylers": [{
                    "color": "#ffffff"
                }, {
                    "visibility": "off"
                }]
            }, {
                "featureType": "road.highway",
                "elementType": "geometry.stroke",
                "stylers": [{
                    "gamma": "6.20"
                }, {
                    "saturation": "30"
                }]
            }, {
                "featureType": "road.highway",
                "elementType": "labels",
                "stylers": [{
                    "visibility": "on"
                }]
            }, {
                "featureType": "road.highway",
                "elementType": "labels.text",
                "stylers": [{
                    "visibility": "simplified"
                }, {
                    "invert_lightness": true
                }]
            }, {
                "featureType": "road.highway",
                "elementType": "labels.text.stroke",
                "stylers": [{
                    "visibility": "on"
                }, {
                    "saturation": "52"
                }, {
                    "lightness": "10"
                }, {
                    "weight": "6.63"
                }, {
                    "invert_lightness": true
                }]
            }, {
                "featureType": "road.highway",
                "elementType": "labels.icon",
                "stylers": [{
                    "visibility": "off"
                }]
            }, {
                "featureType": "road.arterial",
                "elementType": "labels.icon",
                "stylers": [{
                    "visibility": "off"
                }]
            }, {
                "featureType": "transit",
                "elementType": "all",
                "stylers": [{
                    "visibility": "off"
                }]
            }, {
                "featureType": "water",
                "elementType": "all",
                "stylers": [{
                    "color": "#09b275"
                }, {
                    "visibility": "on"
                }, {
                    "lightness": "-32"
                }]
            }]
        },
        showRateModal: false,
        showModal: false,
        _attr: {},
        actions: {
            newMessage: function newMessage(contractee, model) {

                var sideNav = Ember.get(this, 'menu.menu');
                var vacancy = Ember.get(this, 'vacancy');

                contractee.get('user').then(function (user) {
                    vacancy.set('user', user);
                });

                vacancy.set('order', model);
                vacancy.set('type', 'order');
                vacancy.set('conversation', model.get('conversation'));

                sideNav.publicActions.showSubNavigation('messageNew');
            },
            changeRateModal: function changeRateModal() {
                this.toggleProperty('showRateModal');
            },
            replyToContractee: function replyToContractee() {
                var ratingModel = Ember.get(this, 'order.rating');
                ratingModel.save();
                this.toggleProperty('showRateModal');
            }
        }
    });
});