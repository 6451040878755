define('uwork-app-v2/shift/model', ['exports', 'ember-data/model', 'ember-data/attr', 'ember-data/relationships', 'ember-cp-validations'], function (exports, _model, _attr, _relationships, _emberCpValidations) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });


    var Validations = (0, _emberCpValidations.buildValidations)({ name: (0, _emberCpValidations.validator)('presence', true) }, { debounce: 500 });

    exports.default = _model.default.extend(Validations, {
        name: (0, _attr.default)(),
        user: (0, _relationships.hasMany)('user'),

        isNightShift: Ember.computed.equal('name', 'Nattvakt'),
        isAllDayShift: Ember.computed.equal('name', 'Døgnvakt')
    });
});