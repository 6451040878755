define('uwork-app-v2/initializers/inject-breadcrumb', ['exports', 'uwork-app-v2/mixins/route-default-mixin'], function (exports, _routeDefaultMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.initialize = initialize;
  function initialize() {
    Ember.Route.reopen(_routeDefaultMixin.default);
  }

  exports.default = {
    name: 'inject-breadcrumb',
    initialize: initialize
  };
});