define('uwork-app-v2/components/contractee/settings/change-notification-settings/component', ['exports', 'uwork-app-v2/mixins/has-notificaiton-mixin'], function (exports, _hasNotificaitonMixin) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_hasNotificaitonMixin.default, {
        store: Ember.inject.service(),
        model: false,
        didInsertElement: function didInsertElement() {
            this._super.apply(this, arguments);

            if (Ember.get(this, 'existing')) {

                var existing = Ember.get(this, 'existing');

                var contractorNotifications = Ember.get(this, 'contracteeNotifications');
                var notifications_settings = new Ember.A();
                var store = Ember.get(this, 'store');

                contractorNotifications.forEach(function (item) {
                    var _item = existing.findBy('notification_type', item.notification_type);
                    if (_item) {
                        notifications_settings.pushObject(_item);
                    } else {
                        var _item2 = store.createRecord('user-notification-setting', item);
                        notifications_settings.pushObject(_item2);
                    }
                });

                Ember.set(this, 'model', notifications_settings);
            }
        },

        actions: {
            saveModel: function saveModel(model) {
                model.forEach(function (item) {
                    item.save();
                });

                this.swal('Dine varslinger har blitt oppdatert!');
            }
        }
    });
});