define('uwork-app-v2/locales/en/translations', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = {
        // Auth
        'auth.signup.link': 'Sign up',

        'auth.login.title': 'Login',
        'auth.login.description': '',
        'auth.login.btn': 'Login',
        'auth.login.link': 'Back to login page.',

        'auth.confirm.title': 'Confirm your account',
        'auth.confirm.description': '',
        'auth.confirm.btn': 'Confirm',
        'auth.confirm.link': 'Confirm account',

        'auth.confirm-resend.title': 'Re-send confirmation link',
        'auth.confirm-resend.description': '',
        'auth.confirm-resend.btn': 'Send',
        'auth.confirm-resend.link': 'Resend confirmation link',

        'auth.forgot.title': 'Forgot password',
        'auth.forgot.description': '',
        'auth.forgot.btn': 'Send password',
        'auth.forgot.link': 'Forgot password',

        'auth.reset.title': 'Reset password',
        'auth.reset.description': '',
        'auth.reset.btn': 'Reset',
        'auth.reset.link': ''
    };
});