define('uwork-app-v2/auth/confirm-resend/controller', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        i18n: Ember.inject.service(),

        hasDescription: Ember.computed(function () {
            var trans = Ember.get(this, 'i18n').t('auth.confirm-resend.description');

            if (Ember.String.isHTMLSafe(trans)) {
                return Ember.get(trans, 'string') === "" ? false : true;
            }

            // Missing translation = Not set
            if (trans.split(' ')[0] === 'Missing') {
                return false;
            }

            return true;
        })
    });
});