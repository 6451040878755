define('uwork-app-v2/components/contractee/settings/payment-item/component', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        classNames: ['payments-item'],
        edit: false,
        i18n: Ember.inject.service(),
        types: [{ id: 10, type: '%' }, { id: 20, type: 'NOK' }],
        actions: {
            edit: function edit() {
                Ember.set(this, 'edit', !Ember.get(this, 'edit'));
            },
            delete: function _delete() {
                var _this = this;

                var _close = Ember.get(this, 'i18n').t('form.btn.close');
                var _delete = Ember.get(this, 'i18n').t('form.btn.delete');

                this.swal({
                    title: "Dine opplysninger er lagret!",
                    showConfirmButton: true,
                    confirmButtonText: _delete,
                    cancelButtonText: _close,
                    showCloseButton: true,
                    showCancelButton: true,
                    type: 'question'
                }).then(function () {
                    Ember.get(_this, 'model').destroyRecord();
                });
            },
            save: function save() {
                var _this2 = this;

                var model = Ember.get(this, 'model');
                Ember.set(model, 'contractee', Ember.get(this, 'contractee'));

                Ember.set(this, 'didValidate', true);

                model.validate().then(function (_ref) {
                    var validations = _ref.validations;


                    if (validations.get('isValid')) {
                        model.save().then(function () {

                            Ember.set(_this2, 'didValidate', false);
                            _this2.swal({
                                title: "Dine opplysninger er lagret!",
                                timer: 1000,
                                showConfirmButton: false,
                                showCloseButton: false,
                                type: 'success'
                            });
                        });
                    } else {
                        //console.log('isInvalid');
                    }
                });
            }
        }
    });
});