define('uwork-app-v2/user/model', ['exports', 'ember-data/model', 'ember-data/attr', 'ember-data/relationships', 'ember-cp-validations', 'uwork-app-v2/config/environment', 'uwork-app-v2/mixins/has-role-mixin', 'uwork-app-v2/substitute/model', 'uwork-app-v2/vacancy/model'], function (exports, _model, _attr, _relationships, _emberCpValidations, _environment, _hasRoleMixin, _model2, _model3) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.schema = undefined;


    var TYPE_CONTRACTOR = 1;
    var TYPE_CONTRACTEE = 2;
    var TYPE_ADMIN = 5;

    // const STATUS_DEFAULT = 1;
    // const STATUS_CONFIRMED = 10;
    // const STATUS_REJECTED = 20;
    // const STATUS_PENDING = 30;
    // const STATUS_APPROVED = 40;
    // const STATUS_VERIFIED = 50;

    var STAGE_DEFAULT = 1;
    var STAGE_PROFILE_COMPLETED = 20;
    var STAGE_APPLIED = 30;
    var STAGE_DECLINED = 35;
    var STAGE_APPROVED = 40;
    var STAGE_VERIFIED = 50;
    var STAGE_DEVERIFIED = 60;

    var SIGNUP_COMPLETED = 1;
    var SIGNUP_DEFAULT = 0;

    // const AGREEMENT_DEFAULT = null;
    var AGREEMENT_UPDATED = 1;
    var AGREEMENT_ACCEPTED = 10;

    var EDIT_DEFAULT = 0;
    var EDIT_ALLOW = 1;

    // const NOTIFICATION_DEFAULT = 1;
    var NOTIFICATION_ENABLED = 1;
    var NOTIFICATION_DISABLED = 10;

    var NOTIFICATION_SETTINGS_ENABLED = 1;
    var NOTIFICATION_SETTINGS_DISABLED = 10;

    var USER_DATA_ENABLED = 1;
    var USER_DATA_PROCCESSING = 10;

    var Validations = (0, _emberCpValidations.buildValidations)({
        firstName: (0, _emberCpValidations.validator)('presence', true),
        lastName: (0, _emberCpValidations.validator)('presence', true),

        email: {
            validators: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('format', { type: 'email' })]
        },
        phone: [(0, _emberCpValidations.validator)('format', {
            allowBlank: true,
            type: 'phone'
        })],
        contractor: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('belongs-to')],

        shifts: [(0, _emberCpValidations.validator)('has-many'), (0, _emberCpValidations.validator)('length', {
            min: 1,
            message: 'Minst en vakttype må være valgt.',
            disabled: Ember.computed.equal('model.hasRoleSubstitute', false)
        })
        // }),
        // validator('presence', {
        //     'presence': true,
        //     disabled: equal('model.isContractee', false),
        // }),
        //validator('has-role', {message: 'Velg minst en vakttyper!'})
        ]
    }, { debounce: 500 });

    var schema = exports.schema = {
        firstName: (0, _attr.default)('string'),
        lastName: (0, _attr.default)('string'),
        email: (0, _attr.default)('string'),
        password: (0, _attr.default)(),
        passwordConfirmation: (0, _attr.default)()
    };

    exports.default = _model.default.extend(Validations, schema, _hasRoleMixin.default, {

        ROLE_NAME_SUBSTITUTE: _model2.ROLE_NAME,
        ROLE_NAME_VACANCY: _model3.ROLE_NAME,
        type: (0, _attr.default)('number'),
        averageStars: (0, _attr.default)('number'),
        totalRatings: (0, _attr.default)('number'),
        signupCompleted: (0, _attr.default)('number'),
        agreementStatus: (0, _attr.default)('number'),
        editable: (0, _attr.default)('number'),
        isPublic: (0, _attr.default)('number'),
        notificationStatus: (0, _attr.default)('number'),
        userDataStatus: (0, _attr.default)('number', { defaultValue: USER_DATA_ENABLED }),
        notificationSettingsModal: (0, _attr.default)('number', { defaultValue: NOTIFICATION_SETTINGS_ENABLED }),
        createdAt: (0, _attr.default)(),
        updatedAt: (0, _attr.default)(),
        status: (0, _attr.default)('number'),
        stage: (0, _attr.default)('number'),
        checked: (0, _attr.default)('boolean', { defaultValue: false }),
        orderCount: (0, _attr.default)('number'),
        unratedOrder: (0, _attr.default)('number'),
        userHasVacancy: (0, _attr.default)('boolean'),
        // Relations
        //bookmarks: hasMany('bookmark', {inverse: 'saver'}),
        //bookmarked: hasMany('bookmark', {inverse: 'user'}),

        // users: hasMany('user', {
        //     inverse: null
        // }),
        //assignments: hasMany('assignment', {inverse: 'assignedUser'}),

        attachments: (0, _relationships.hasMany)('attachment'),
        approvals: (0, _relationships.hasMany)('approval'),
        avatar: (0, _relationships.belongsTo)('avatar'),
        badges: (0, _relationships.hasMany)('badge'),
        //calendar: hasMany('calendar'),
        contractor: (0, _relationships.belongsTo)('contractor'),
        contractee: (0, _relationships.belongsTo)('contractee'),
        courses: (0, _relationships.hasMany)('course'),
        categories: (0, _relationships.hasMany)('category'),
        categoryPivot: (0, _relationships.hasMany)('category-pivot', { async: false }),
        conversations: (0, _relationships.hasMany)('conversation'),
        fileStore: (0, _relationships.hasMany)('file-store'),
        experiences: (0, _relationships.hasMany)('experiences'),
        educations: (0, _relationships.hasMany)('education'),
        notifications: (0, _relationships.hasMany)('notification', { inverse: 'user' }),
        // languages: hasMany('language'),
        skills: (0, _relationships.hasMany)('skill'),
        shifts: (0, _relationships.hasMany)('shift'),
        ratings: (0, _relationships.hasMany)('rating'),
        roles: (0, _relationships.hasMany)('role', { async: false }),
        tags: (0, _relationships.hasMany)('tags'),
        templates: (0, _relationships.hasMany)('order-desc-template'),
        userRequests: (0, _relationships.hasMany)('user-request', { inverse: 'user' }),
        userNotificationSettings: (0, _relationships.hasMany)('user-notification-setting'),
        video: (0, _relationships.belongsTo)('video'),

        // Computed properties/functions
        fullName: Ember.computed('firstName', 'lastName', function () {
            return Ember.get(this, 'firstName') + ' ' + Ember.get(this, 'lastName');
        }),

        isContractor: Ember.computed.equal('type', TYPE_CONTRACTOR),
        isContractee: Ember.computed.equal('type', TYPE_CONTRACTEE),
        isAdmin: Ember.computed.equal('type', TYPE_ADMIN),

        isSignUpNotCompleted: Ember.computed.equal('signupCompleted', 0),
        isSignUpCompleted: Ember.computed.equal('signupCompleted', 1),

        isDefault: Ember.computed.equal('stage', STAGE_DEFAULT),
        isProfileCompleted: Ember.computed.equal('stage', STAGE_PROFILE_COMPLETED),
        //isConfirmed: computed.equal('status', STATUS_CONFIRMED),
        //isModelRejected: computed.equal('status', STATUS_REJECTED),
        //isPendingMode: computed.equal('status', STATUS_PENDING),
        isApplied: Ember.computed.equal('stage', STAGE_APPLIED),
        isApproved: Ember.computed.equal('stage', STAGE_APPROVED),
        isDeclined: Ember.computed.equal('stage', STAGE_DECLINED),
        isVerified: Ember.computed.equal('stage', STAGE_VERIFIED),
        isDeverified: Ember.computed.equal('stage', STAGE_DEVERIFIED),
        isNotVerified: Ember.computed.not('isVerified'),
        isNotificationEnabled: Ember.computed.equal('notificationStatus', NOTIFICATION_ENABLED),
        isNotificationDisabled: Ember.computed.equal('notificationStatus', NOTIFICATION_DISABLED),

        isAgreementUpdated: Ember.computed.equal('agreementStatus', AGREEMENT_UPDATED),

        fixedAverageStars: Ember.computed('averageStars', function () {
            var stars = parseFloat(Ember.get(this, 'averageStars'));
            if (stars % 1 !== 0) {
                return stars.toFixed(2);
            } else {
                return stars;
            }
        }),

        isEditable: Ember.computed.equal('editable', EDIT_ALLOW),

        isNotificationSettingsEnabled: Ember.computed.equal('notificationSettingsModal', NOTIFICATION_SETTINGS_ENABLED),
        isNotificationSettingsDisabled: Ember.computed.equal('notificationSettingsModal', NOTIFICATION_SETTINGS_DISABLED),

        isUserDataEnabled: Ember.computed.equal('userDataStatus', USER_DATA_ENABLED),
        isUserDataDisabled: Ember.computed.equal('userDataStatus', USER_DATA_PROCCESSING),

        isNotEditable: Ember.computed.not('isEditable'),

        canNotEditProfile: Ember.computed('isNotEditable', 'isVerified', function () {
            return Ember.get(this, 'isNotEditable') && Ember.get(this, 'isVerified');
        }),

        unreadMessages: Ember.computed.filterBy('conversations', 'isUnread'),
        unreadNotifications: Ember.computed.filterBy('notifications', 'isUnread'),

        checkAvatar: false,

        avatarUrl: Ember.computed('avatar.url', function () {

            if (Ember.get(this, 'isContractee') && Ember.isEmpty(Ember.get(this, 'avatar.filename'))) {
                return _environment.default.rootURL + 'assets/images/default-avatar.svg';
            }

            return Ember.get(this, 'avatar.url');
        }),

        videoUrl: Ember.computed('video.url', 'url.filename', function () {
            return Ember.get(this, 'video.url');
        }),

        isAvatarEmpty: Ember.computed.empty('avatarUrl'),
        isAvatarNotEmpty: Ember.computed.notEmpty('avatarUrl'),

        hasRoleSubstitute: Ember.computed('roles', function () {
            return this.hasRole(_model2.ROLE_NAME, this);
        }),
        // hasRole(name){
        //     return get(this, 'roles').findBy('name', name) || false;
        // },
        // setActivated(){
        //     set(this, 'status', STATUS_ACTIVATED);
        // },

        setAgreementAccepted: function setAgreementAccepted() {
            Ember.set(this, 'agreementStatus', AGREEMENT_ACCEPTED);
        },
        setAgreementUpdated: function setAgreementUpdated() {
            Ember.set(this, 'agreementStatus', AGREEMENT_UPDATED);
        },
        setMotificationSettingsModalOpen: function setMotificationSettingsModalOpen() {
            Ember.set(this, 'notificationSettingsModal', NOTIFICATION_SETTINGS_ENABLED);
        },
        setMotificationSettingsModalClose: function setMotificationSettingsModalClose() {
            Ember.set(this, 'notificationSettingsModal', NOTIFICATION_SETTINGS_DISABLED);
        },
        setPublic: function setPublic() {
            Ember.set(this, 'isPublic', 1);
        },
        unsetPublic: function unsetPublic() {
            Ember.set(this, 'isPublic', 0);
        },
        setSignupCompleted: function setSignupCompleted() {
            Ember.set(this, 'signupCompleted', SIGNUP_COMPLETED);
        },
        setSignupDefault: function setSignupDefault() {
            Ember.set(this, 'signupCompleted', SIGNUP_DEFAULT);
        },
        setApproved: function setApproved() {
            Ember.set(this, 'stage', STAGE_APPROVED);
        },


        // setConfirmed () {
        //     set(this, 'status', STATUS_CONFIRMED);
        // },

        setDefault: function setDefault() {
            Ember.set(this, 'stage', STAGE_DEFAULT);
        },
        setNotificationEnabled: function setNotificationEnabled() {
            Ember.set(this, 'notificationStatus', NOTIFICATION_ENABLED);
        },
        setNotificationDisabled: function setNotificationDisabled() {
            Ember.set(this, 'notificationStatus', NOTIFICATION_DISABLED);
        },


        // setPending () {
        //     set(this, 'status', STATUS_PENDING);
        // },
        //
        // setRejected () {
        //     set(this, 'status', STATUS_REJECTED);
        // },

        setDeclined: function setDeclined() {
            Ember.set(this, 'stage', STAGE_DECLINED);
        },
        setVerified: function setVerified() {
            Ember.set(this, 'stage', STAGE_VERIFIED);
        },
        setDeverified: function setDeverified() {
            Ember.set(this, 'stage', STAGE_DEVERIFIED);
        },
        allowEdit: function allowEdit() {
            Ember.set(this, 'editable', EDIT_ALLOW);
        },
        disableEdit: function disableEdit() {
            Ember.set(this, 'editable', EDIT_DEFAULT);
        }
    });
});