define('uwork-app-v2/components/conversations/conversations-list/component', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        store: Ember.inject.service(),
        appService: Ember.inject.service('application'),
        isLoading: false,
        init: function init() {
            var _this = this;

            this._super.apply(this, arguments);

            //set(this, 'isLoading', true);
            var application = Ember.get(this, 'appService');

            Ember.get(this, 'store').findAll('conversation').then(function (conversations) {
                Ember.set(_this, 'model', conversations);
                //set(this, 'isLoading', false);

                var showConversation = conversations.filterBy('isUnread');
                if (showConversation.get('length') > 0) {
                    application.set('showConversations', true);
                    application.set('showConversationLength', showConversation.get('length'));
                } else {
                    application.set('showConversations', false);
                    application.set('showConversationLength', 0);
                }
            }).catch(function () {});
        },


        orderBy: ['lastMessage.createdAt:asc'],
        conversationsOrder: Ember.computed.sort('model', 'orderBy'),

        conversationsUnread: Ember.computed.filterBy('conversationsOrder', 'isUnread'),

        showConversations: Ember.computed.gt('conversationsUnread.length', 0)
    });
});