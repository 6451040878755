define('uwork-app-v2/order-shift/adapter', ['exports', 'uwork-app-v2/application/adapter'], function (exports, _adapter) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _adapter.default.extend({
        urlForQuery: function urlForQuery(query) {
            if (query.calendar) {
                return this.urlPrefix() + '/order-shifts/search';
            }
            return this._super.apply(this, arguments);
        }
    });
});