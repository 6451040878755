define("uwork-app-v2/substitute/model", ["exports", "ember-data"], function (exports, _emberData) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.ROLE_NAME = undefined;
  var ROLE_NAME = exports.ROLE_NAME = "substitute";

  exports.default = _emberData.default.Model.extend({});
});