define('uwork-app-v2/contractee-contractor/model', ['exports', 'ember-data/model', 'ember-data/attr', 'ember-data/relationships', 'ember-cp-validations'], function (exports, _model, _attr, _relationships, _emberCpValidations) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.schema = undefined;


    var Validations = (0, _emberCpValidations.buildValidations)({
        seniority: {
            description: 'Tittel',
            validators: [(0, _emberCpValidations.validator)('presence', true)]
        },
        hourlyBasicSalary: {
            description: 'Timeslønn',
            validators: [(0, _emberCpValidations.validator)('presence', true)]
        }
    });

    var schema = exports.schema = {
        seniority: (0, _attr.default)('number'),
        hourlyBasicSalary: (0, _attr.default)('number'),
        dailyBasicSalary: (0, _attr.default)('number')
    };

    exports.default = _model.default.extend(Validations, schema, {
        contractee: (0, _relationships.belongsTo)('contractee'),
        contractor: (0, _relationships.belongsTo)('contractor')
    });
});