define('uwork-app-v2/components/contractee/vacancy/view/display-contractee/component', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        tagsData: Ember.inject.service("tags-data"),
        classNames: ['vacancy-sidebar-wrapper  d-flex flex-column']
    });
});