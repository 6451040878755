define('uwork-app-v2/components/contractor/user-requests/apply-for-subsitute/component', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });


    var USER_REQUEST_TYPE = "verification";
    var USER_REQUEST_STATUS_VERIFIED = 10;
    var USER_REQUEST_STATUS_REJECTED = 20;

    exports.default = Ember.Component.extend({
        didInsertElement: function didInsertElement() {
            this._super.apply(this, arguments);

            var phone = Ember.get(this, 'user.contractor.phone');

            if (phone) {
                Ember.set(this, '_body.phone', phone);
            }
        },

        session: Ember.inject.service(),
        account: Ember.inject.service('user/session-account'),
        user: Ember.computed.alias('account.currentUser'),
        hasUserRequest: Ember.computed.filterBy('user.userRequests', 'type', USER_REQUEST_TYPE),
        hasBeenVerified: Ember.computed.filterBy('hasUserRequest', 'status', USER_REQUEST_STATUS_VERIFIED),
        hasBeenRejected: Ember.computed.filterBy('hasUserRequest', 'status', USER_REQUEST_STATUS_REJECTED),
        store: Ember.inject.service(),
        intercom: Ember.inject.service(),
        _body: {
            travelDistance: 0,
            categories: [],
            shifts: [],
            phone: null
        },
        travelDistanceOptions: [{ label: '15 min', value: 0.25 }, { label: '30 min', value: 0.50 }, { label: '45 min', value: 0.70 }, { label: '1 time', value: 1 }, { label: '2 timer', value: 2 }, { label: '3 timer', value: 3 }, { label: '4 timer', value: 4 }, { label: '5 timer', value: 5 }, { label: '6 timer', value: 6 }, { label: '7 timer', value: 7 }],
        addShiftsToUser: function addShiftsToUser(body) {

            var shifts = Ember.get(this, 'shifts');

            var shiftsChecked = shifts.filterBy('checked', true);
            var shiftsUnChecked = shifts.filterBy('checked', false);

            body.shifts.removeObjects(shiftsUnChecked.getEach('name'));
            body.shifts.addObjects(shiftsChecked.getEach('name'));
        },
        addCategoriesToUser: function addCategoriesToUser(body) {

            var categories = Ember.get(this, 'categories');

            var categoriesChecked = categories.filterBy('checked', true);
            var categoriesUnChecked = categories.filterBy('checked', false);

            body.categories.removeObjects(categoriesUnChecked.getEach('name'));
            body.categories.addObjects(categoriesChecked.getEach('name'));
        },
        save: function save() {
            var _this = this;

            var body = Ember.get(this, '_body');
            this.addShiftsToUser(body);
            this.addCategoriesToUser(body);

            var userRequest = Ember.get(this, 'store').createRecord('user-request');

            userRequest.setVerificationType();
            userRequest.set('body', body);

            userRequest.save().then(function () {
                _this.setLoading(false);
                _this.swal({
                    customClass: 'verify_container',
                    title: "Sendt inn!",
                    html: "<br /><h5 class='verify-title'>Neste steg:</h5>" + "<hr />" + "<div class='verify-list'>" + "<div class='list'><div class='count'>1</div>En uWork representant vil gjøre en vurdering av din profil.</div><br />" + "<div class='list'><div class='count'>2</div>Innen 48 timer får du tilbakemelding på om du kvalifiserer til å gå videre i prosessen.</div><br />" + "<div class='list'><div class='count'>3</div>Om du kvalifiserer vil HR ansvarlig i uWork kontakte deg for å avtale en samtale på våre kontorer eller via Skype/FaceTime</div>" + "</div>" + "<hr />",
                    type: "success",
                    confirmButtonColor: "#B3BBCF",
                    confirmButtonText: "Lukk"
                });
            });
        },

        actions: {
            save: function save() {

                var categories = Ember.get(this, 'categories');
                var shifts = Ember.get(this, 'shifts');
                var travelDistance = Ember.get(this, '_body.travelDistance');
                var shiftsChecked = shifts.filterBy('checked', true);
                var categoriesChecked = categories.filterBy('checked', true);
                var phone = Ember.get(this, '_body.phone');

                if (!!travelDistance && (!Ember.isEmpty(categoriesChecked) || !Ember.isEmpty(shiftsChecked)) && !Ember.isEmpty(phone)) {
                    this.setLoading(true);
                    this.save();
                } else {
                    this.swal({
                        type: "warning",
                        text: "Velg hva du er interessert i å jobbe med, vakttyper, telefon nummer, samt hvor langt du er villig til å reise for en vakt!",
                        confirmButtonColor: "#B3BBCF",
                        confirmButtonText: "Lukk"
                    });
                }
            }
        }
    });
});