define('uwork-app-v2/components/auth/login-form-account/validation', ['exports', 'ember-changeset-validations/validators'], function (exports, _validators) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = {
        account: [(0, _validators.validatePresence)(true)],
        password: [(0, _validators.validatePresence)(true), (0, _validators.validateLength)({ min: 6 })]
    };
});