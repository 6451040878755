define('uwork-app-v2/components/admin/order/edit/order-shift/component', ['exports', 'moment'], function (exports, _moment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        tagName: 'table',
        classNames: ['table', 'table-sm', 'responsive', 'app-order-create'],

        actions: {
            leave: function leave(model) {
                var leave = !model.get('leave');

                model.set('leave', leave);
            },
            rules: function rules(model) {
                var rules = !model.get('rules');

                model.set('rules', rules);
            },
            showRestComment: function showRestComment(model) {
                var hasComment = !model.get('hasComment');

                model.set('hasComment', hasComment);
            },
            restTime: function restTime(shift, key, time, format) {

                var element = Ember.get(shift, key);
                var value = 0;

                if (element >= 0 && element <= 24 && format === 'plus') {
                    value = element + parseFloat(time / 60);
                    Ember.set(shift, key, value);
                } else if (element > 0 && format === 'minus') {
                    value = element - parseFloat(time / 60);
                    Ember.set(shift, key, value);
                }
            },
            edit: function edit(model) {
                var status = !model.get('edit');

                model.set('edit', status);
            },
            decrement: function decrement(model, key) {
                var oppositeKey = key === 'fromDate' ? 'toDate' : 'fromDate';
                var dt = Ember.get(model, key);
                var oppositeDt = Ember.get(model, oppositeKey);
                var now = (0, _moment.default)();
                var allowPast = Ember.get(this, 'allowPast');
                var shifts = Ember.get(this, 'model.orderShifts');

                var sub = dt.clone().subtract(15, 'm');
                var min = model.isNightShift() ? dt.clone().subtract(1, 'd').startOf('day') : dt.clone().startOf('day');

                // Before today not allowed
                if (sub.isBefore(now) && allowPast === false) {
                    return;
                }

                // To date cant be before from date
                if (sub.isSame(oppositeDt)) {
                    return;
                }

                // Validate min date
                if (key === 'fromDate' && sub.isSameOrBefore(min)) {
                    return;
                }

                var index = shifts.indexOf(model);

                var prev = shifts.objectAt(index - 1);

                if (key === 'fromDate' && prev) {
                    var closest = Ember.get(prev, oppositeKey);

                    // To date cant be before from date on prev model.
                    if (closest.isSameOrBefore(Ember.get(prev, key))) {
                        return;
                    }

                    // Change closest date
                    if (closest.isSameOrAfter(sub)) {
                        Ember.set(prev, oppositeKey, sub);
                    }
                }

                Ember.set(model, key, sub);

                //check if is first or last one - 1 (max)

                if (shifts.objectAt(index + 1)) {

                    var next = shifts.objectAt(index + 1);

                    if (next.get('hasDirtyAttributes') && model.isNightShift()) {

                        Ember.set(next, oppositeKey, sub);

                        var hh = next.get(key).format('hh');
                        var _next_from_dt = next.get(oppositeKey);
                        var _next_from_dt_clone = next.get(oppositeKey).clone().startOf('day').hours(hh);

                        // To date cant be before from date on prev model.
                        if (_next_from_dt_clone.isSameOrAfter(_next_from_dt)) {
                            next.rollbackAttributes();
                        }
                    }
                }
            },
            increment: function increment(model, key) {
                var oppositeKey = key === 'fromDate' ? 'toDate' : 'fromDate';
                var dt = Ember.get(model, key);
                var oppositeDt = Ember.get(model, oppositeKey);
                //let allowPast = get(this, 'allowPast');
                var shifts = Ember.get(this, 'model.orderShifts');

                var add = dt.clone().add(15, 'm');
                var max = model.isNightShift() ? oppositeDt.clone().add(1, 'd').endOf('day') : oppositeDt.clone().endOf('day');

                // To date cant be before from date
                if (add.isSame(oppositeDt)) {
                    return;
                }

                // Validate max date (end of today/add one day on All day shifts and Night Shifts)
                if (key === 'toDate' && add.isSameOrAfter(max)) {
                    return;
                }

                var index = shifts.indexOf(model);
                var next = shifts.objectAt(index + 1);

                if (key === 'toDate' && next) {
                    var closest = Ember.get(next, oppositeKey);

                    // Change closest date
                    if (add.isSameOrAfter(closest)) {
                        Ember.set(next, oppositeKey, add);
                    }
                }

                Ember.set(model, key, add);

                //check if isn't first item and is last one - to correct prev one.

                if (shifts.objectAt(index - 1)) {

                    var prev = shifts.objectAt(index - 1);

                    if (prev.get('hasDirtyAttributes') && model.isNightShift()) {

                        Ember.set(prev, oppositeKey, add);

                        // console.log('inc ops', next.get(oppositeKey));
                        var hh = prev.get(key).format('hh');

                        var _next_from_dt = prev.get(oppositeKey);
                        var _next_from_dt_clone = prev.get(oppositeKey).clone().startOf('day').hours(hh);
                        //
                        // // To date cant be before from date on prev model.
                        if (_next_from_dt_clone.isSameOrBefore(_next_from_dt)) {
                            prev.rollbackAttributes();
                        }
                    }
                }
            },
            delete: function _delete(model) {
                model.destroyRecord();
            }
        }
    });
});