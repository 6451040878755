define('uwork-app-v2/components/app/file-upload/component', ['exports', 'ember-uploader', 'uwork-app-v2/config/environment'], function (exports, _emberUploader, _environment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberUploader.default.FileField.extend({
        session: Ember.inject.service('session'),
        url: '',
        filesDidChange: function filesDidChange(files) {
            var token = Ember.get(this, 'session.data.authenticated.token');
            var url = Ember.get(this, 'url');
            var uploader = _emberUploader.default.Uploader.create({
                url: _environment.default.APP.API_UPLOAD_PATH + url,
                ajaxSettings: {
                    headers: {
                        'Authorization': 'Bearer ' + token
                    }
                }
            });
            this.sendAction('onFilesChange', files, uploader);
        }
    });
});