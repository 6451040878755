define('uwork-app-v2/conversation/adapter', ['exports', 'uwork-app-v2/application/adapter'], function (exports, _adapter) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _adapter.default.extend({
        urlForQueryRecord: function urlForQueryRecord(query) {
            if (query.applicant) {
                delete query.applicant;
                //// get-user-conversation?user_id=3410
                return this.urlPrefix() + '/conversations/get-user-vacancy-conversation';
            }

            return this._super.apply(this, arguments);
        }
    });
});