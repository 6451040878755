define('uwork-app-v2/file-store/model', ['exports', 'ember-data/model', 'ember-data/attr', 'uwork-app-v2/config/environment', 'ember-data/relationships'], function (exports, _model, _attr, _environment, _relationships) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });


    var TYPE_DEFAULT = 1;
    var TYPE_AVATAR = 2;
    var TYPE_GCC = 3;
    var TYPE_NDA = 4;
    var TYPE_CV = 5;
    var TYPE_VIDEO = 6;
    var TYPE_ATTACHMENT = 7;

    exports.default = _model.default.extend({
        name: (0, _attr.default)(),
        title: (0, _attr.default)('string'),
        original_name: (0, _attr.default)(),
        filename: (0, _attr.default)(),
        fileSize: (0, _attr.default)(),
        mimeType: (0, _attr.default)(),
        original_ext: (0, _attr.default)(),
        expireAt: (0, _attr.default)(),
        issuedAt: (0, _attr.default)(),
        type: (0, _attr.default)('number'),
        status: (0, _attr.default)('boolean'),
        public: (0, _attr.default)('boolean'),
        createdAt: (0, _attr.default)(),

        category: (0, _relationships.belongsTo)('category'),

        url: Ember.computed('filename', 'getUrlPath', function () {
            var filename = Ember.get(this, 'filename');
            if (filename.includes('https://uwork.ams3.cdn.digitaloceanspaces.com')) {
                return filename.replace('https://uwork.ams3.cdn.digitaloceanspaces.com/dev', _environment.default.APP.API_DO_PATH);
            }

            return Ember.get(this, 'getUrlPath') + '/' + filename;
        }),

        getUrlPath: Ember.computed('filename', 'mimeType', function () {
            var mime = Ember.get(this, 'mimeType');
            if (Ember.get(this, 'filename')) {
                var useMediaPath = Ember.get(this, 'filename').split('.')[0].length === 40;

                if (Ember.get(this, 'isAvatar') && useMediaPath) {
                    return _environment.default.APP.API_MEDIA_PATH + '/avatars';
                }

                if (Ember.get(this, 'isVideo')) {
                    if (useMediaPath) {
                        return _environment.default.APP.API_MEDIA_PATH + '/videos';
                    }
                    return mime === 'video/mp4' || mime === "video/quicktime" ? _environment.default.APP.API_STREAM_FILE : _environment.default.APP.API_DOWNLOAD_FILE;
                }
            }

            return _environment.default.APP.API_DOWNLOAD_FILE;
        }),

        isAvatar: Ember.computed.equal('type', TYPE_AVATAR),
        isCV: Ember.computed.equal('type', TYPE_CV),
        isDefault: Ember.computed.equal('type', TYPE_DEFAULT),
        isGCC: Ember.computed.equal('type', TYPE_GCC),
        isNDA: Ember.computed.equal('type', TYPE_NDA),
        isVideo: Ember.computed.equal('type', TYPE_VIDEO),
        isAttachment: Ember.computed.equal('type', TYPE_ATTACHMENT)

    });
});