define('uwork-app-v2/components/contractor/settings/document/display-item/component', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        didValidate: false,
        tagName: 'tr',
        actions: {
            edit: function edit(model) {
                var edit = Ember.get(model, 'edit');
                model.set('edit', !edit);
            },
            save: function save(model) {
                var _this2 = this;

                model.save().then(function () {
                    model.set('edit', false);
                    _this2.swal({
                        title: "Vedlegg oppdatert",
                        timer: 1000,
                        showConfirmButton: false,
                        showCloseButton: false,
                        type: 'success'
                    });
                }, function () {});
            },
            updated: function updated(model) {
                var _this3 = this;

                model.save().then(function (item) {
                    model.set('edit', false);

                    if (item.get('public')) {

                        _this3.swal({
                            title: "Vedlegg offentlig",
                            text: 'Når du søker på en vakt eller stilling på uWork vil bedrifter kunne se og laste ned dine vedlegg',
                            showConfirmButton: true,
                            showCloseButton: false,
                            type: 'success'
                        });
                    } else {
                        _this3.swal({
                            title: "Vedlegg privat",
                            text: 'Når du søker på en vakt eller en stilling vil bedrifter ikke kunne se eller laste ned dine vedlegg. Kun administrasjonen i uWork vil kunne se og laste ned dine vedlegg.',
                            showConfirmButton: true,
                            showCloseButton: false,
                            type: 'success'
                        });
                    }
                }, function () {});
            },
            download: function download(file) {
                var link = document.createElement('a');
                link.href = file;
                link.id = 'down';
                link.target = '_blank';

                // link.click();
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            },
            delete: function _delete(model) {
                var _this4 = this;

                var _this = this;
                _this.setLoading(true);
                model.destroyRecord().then(function () {
                    _this.setLoading(false);
                    _this4.swal('Attest slettet!');
                    // new Notification('Attest slettet!');
                }, function () {
                    _this.setLoading(false);
                    _this4.swal('Kan ikke slettes!', 'error');
                }).catch(function () {
                    _this.setLoading(false);
                    _this4.swal('Kan ikke slettes!', 'error');
                }).finally(function () {
                    _this.setLoading(false);
                });
            }
        }
    });
});