define('uwork-app-v2/torii-providers/facebook', ['exports', 'torii/providers/facebook-oauth2'], function (exports, _facebookOauth) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _facebookOauth.default.extend({
        fetch: function fetch(data) {
            return data;
        }
    });
});