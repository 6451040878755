define('uwork-app-v2/components/contractee/sidebar/nav-settings/component', ['exports', 'ember-local-storage'], function (exports, _emberLocalStorage) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        routing: Ember.inject.service('-routing'),

        account: Ember.inject.service('user/session-account'),
        wizardStep: (0, _emberLocalStorage.storageFor)('contractee/wizard'),
        wizardCurrentStep: Ember.computed.alias('wizardStep.step'),
        user: Ember.computed.alias('account.currentUser'),
        isWizard: Ember.computed.equal('user.isSignUpCompleted', false),
        tagName: 'ul',
        classNames: ['nav', 'flex-column', 'nav-pills', 'user-setting-nav'],
        links: [{ id: 1, route: 'contractee.settings.account', name: 'contractee.account.nav.user-account' }, { id: 2, route: 'contractee.settings.avatar', name: 'contractee.account.nav.avatar' }, { id: 3, route: 'contractee.settings.category', name: 'contractee.account.nav.category' }, { id: 4, route: 'contractee.settings.company', name: 'contractee.account.nav.general' }, { id: 5, route: 'contractee.settings.nda', name: 'contractee.account.nav.nda' }, { id: 6, route: 'contractee.settings.rates', name: 'contractee.account.nav.rates' }, { id: 7, route: 'contractee.settings.order-desc-templates', name: 'contractee.account.nav.order-desc-templates' }],
        linksWizard: [{ id: 1, route: 'contractee.settings.index', name: 'contractee.nav.wizard.user-account' }, { id: 1, route: 'contractee.settings.account', name: 'contractee.account.nav.user-account' }, { id: 2, route: 'contractee.settings.avatar', name: 'contractee.account.nav.avatar' }, { id: 3, route: 'contractee.settings.category', name: 'contractee.account.nav.category' }, { id: 4, route: 'contractee.settings.company', name: 'contractee.account.nav.general' }, { id: 5, route: 'contractee.settings.nda', name: 'contractee.account.nav.nda' }, { id: 6, route: 'contractee.settings.rates', name: 'contractee.account.nav.rates' }, { id: 8, route: 'contractee.settings.confirm', name: 'contractor.account.wizard.complete-signup' }],
        linksWizardChanged: Ember.computed('routing', 'linksWizard', 'currentRouteName', 'wizardCurrentStep', function () {
            var wizardCurrentStep = Ember.get(this, 'wizardCurrentStep');
            return Ember.get(this, 'linksWizard').slice(0, wizardCurrentStep);
        }),
        didRender: function didRender() {
            this._super.apply(this, arguments);
            var routing = Ember.get(this, 'routing');
            Ember.set(this, 'currentRouteName', routing.get('currentRouteName'));
        }
    });
});