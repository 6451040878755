define('uwork-app-v2/components/contractee/assignment/assignment-details/component', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        classNames: ['card', 'mb-4'],
        checkForFiles: Ember.computed('order.gcc', 'order.nda', function () {
            return Ember.get(this, 'order.gcc') || Ember.get(this, 'order.nda') ? true : false;
        })
    });
});