define('uwork-app-v2/components/cp/select-option/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    hasError: Ember.computed.and('shouldDisplayValidations', 'isInvalid').readOnly(),
    showValidations: false,
    didValidate: false,

    notValidating: Ember.computed.not('validation.isValidating').readOnly(),
    hasContent: Ember.computed.notEmpty('value').readOnly(),
    hasWarnings: Ember.computed.notEmpty('validation.warnings').readOnly(),
    isValid: Ember.computed.and('hasContent', 'validation.isTruelyValid').readOnly(),
    shouldDisplayValidations: Ember.computed.or('showValidations', 'didValidate', 'hasContent').readOnly(),
    showErrorClass: Ember.computed.and('notValidating', 'showErrorMessage', 'hasContent', 'validation').readOnly(),
    showErrorMessage: Ember.computed.and('shouldDisplayValidations', 'validation.isInvalid').readOnly(),
    showWarningMessage: Ember.computed.and('shouldDisplayValidations', 'hasWarnings', 'isValid').readOnly(),

    init: function init() {
      this._super.apply(this, arguments);
      var attr = this.get('attr');
      Ember.defineProperty(this, 'validation', Ember.computed.readOnly('model.validations.attrs.' + attr));
    },
    focusOut: function focusOut() {
      this._super.apply(this, arguments);
      this.set('showValidations', true);
    }
  });
});