define('uwork-app-v2/components/app/card-item/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    // classNames:['bg-white','card-shadow'],
    content: true
  });
});