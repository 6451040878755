define('uwork-app-v2/mixins/route-default-mixin', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    breadCrumb: {},

    /**
     * Initiate loading state
     */
    beforeModel: function beforeModel() {
      this.setLoading(true);
    },


    /**
     * Set title if it exists, remove loading
     */
    afterModel: function afterModel() {
      this.setBredCrump();
    },
    setBredCrump: function setBredCrump() {
      var title = Ember.get(this, 'title');

      if (title) {
        var string = typeof title === 'string' || title instanceof String ? title : title.string;

        var bTitle = {
          title: string,
          label: string
        };

        Ember.set(this, 'breadCrumb', bTitle);
      }

      this.setLoading(false);
    }
  });
});