define('uwork-app-v2/validations/unique-email', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = uniqueEmail;
    function uniqueEmail() {

        return function (key, newValue, oldValue, changes, model) {

            if (!key) {
                return true;
            }

            var store = model.get('store');

            return store.queryRecord('user', { email: newValue }).then(function (user) {

                if (user.get('email') === newValue) {
                    return 'user exists';
                } else {
                    return true;
                }
            }).catch(function () {
                //this.setLoading(false);
                return 'API Issue';
            });
        };
    }
});