define('uwork-app-v2/review/model', ['exports', 'ember-data/model', 'ember-data/attr', 'ember-data/relationships'], function (exports, _model, _attr, _relationships) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _model.default.extend({
        feedback: (0, _attr.default)(),
        reply: (0, _attr.default)(),
        stars: (0, _attr.default)('number'),

        contractor: (0, _relationships.belongsTo)('contractor'),
        contractee: (0, _relationships.belongsTo)('contractee')
        //assignment: belongsTo('assignment')
    });
});