define('uwork-app-v2/helpers/socials/fb-link', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.socialsFbLink = socialsFbLink;
    function socialsFbLink(params /*, hash*/) {
        var model = params[0];

        var content = '<a href="https://www.facebook.com/sharer.php?u=' + model.get('url') + '" data-toggle="tooltip" data-placement="bottom" target="_blank" title="' + model.get('title') + '">\n' + ' <i class="facebook mdi mdi-facebook-box"></i>\n' + '</a>';
        content = Ember.String.htmlSafe(content);
        return content;
    }

    exports.default = Ember.Helper.helper(socialsFbLink);
});