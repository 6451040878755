define('uwork-app-v2/contractee/agreement/confirmation/controller', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        actions: {
            toRoute: function toRoute(path) {
                this.transitionToRoute(path);
            }
        }
    });
});