define('uwork-app-v2/vacancy-occupation/adapter', ['exports', 'uwork-app-v2/application/adapter'], function (exports, _adapter) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _adapter.default.extend({
        urlForQuery: function urlForQuery(query) {
            // console.log(query);
            if (query.recent === true) {
                // delete query.query;
                delete query.recent;
                // delete query.location;
                return this.urlPrefix() + '/vacancies/public/vacancy-occupations';
            }

            return this._super.apply(this, arguments);
        }
    });
});