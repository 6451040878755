define('uwork-app-v2/message/model', ['exports', 'ember-data/model', 'ember-data/attr', 'ember-data/relationships'], function (exports, _model, _attr, _relationships) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _model.default.extend({
        message: (0, _attr.default)('string'),
        createdAt: (0, _attr.default)('mdate'),
        updatedAt: (0, _attr.default)('mdate'),
        recipientId: (0, _attr.default)(), // This felt is used on create new message only
        conversation: (0, _relationships.belongsTo)('conversation'),
        user: (0, _relationships.belongsTo)('user'),
        order: (0, _relationships.belongsTo)('order'),
        vacancy: (0, _relationships.belongsTo)('vacancy'),
        previewMessage: Ember.computed('message', function () {
            if (Ember.get(this, 'message')) {

                var message = Ember.get(this, 'message');

                var regex = /(https?:\/\/([-\w\.]+)+(:\d+)?(\/([\w\/_\.]*(\?\S+)?)?)?)/ig;
                // Replace plain text links by hyperlinks
                var message_hyperlink = message.replace(regex, "<a href='$1' target='_blank'>$1</a>");

                var message_new_line = message_hyperlink.split("\n");
                var messages = '';

                if (message_new_line.get('length') > 0) {
                    message_new_line.forEach(function (item) {
                        messages += '<p class="text-gray-dark">' + item + '</p>';
                    });
                }
                return Ember.String.htmlSafe(messages);
            }
            return '';
        })
    });
});