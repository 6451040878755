define('uwork-app-v2/contractee/my-workers/index/controller', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        store: Ember.inject.service(),
        account: Ember.inject.service('user/session-account'),
        user: Ember.computed.alias('account.currentUser'),
        //contractors: computed.alias('user.users'),
        isDisabled: true,
        isChecked: true,
        btnText: 'Fortsett',

        selected: Ember.computed.filterBy('model', 'checked', true),
        isSelectAll: Ember.computed('selected', 'model.[]', function () {
            return Ember.get(this, 'selected.length') === Ember.get(this, 'model.length');
        }),

        actions: {
            newOrder: function newOrder() {
                var ids = Ember.get(this, 'selected').getEach('id');
                this.transitionToRoute('contractee.my-workers.order.new', { queryParams: { contractors: ids } });
            },
            selectAll: function selectAll() {
                if (Ember.get(this, 'isSelectAll')) {
                    Ember.get(this, 'model').setEach('checked', false);
                } else {
                    Ember.get(this, 'model').setEach('checked', true);
                }
            }
        }
    });
});