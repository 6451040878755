define('uwork-app-v2/validations/experience', ['exports', 'ember-changeset-validations/validators'], function (exports, _validators) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = {
        //ToDo : check for custom validation date end to be null if current is true
        name: (0, _validators.validatePresence)({ presence: true, description: 'Arbeidserfaring' }),
        title: (0, _validators.validatePresence)({ presence: true, description: 'Tittel' }),
        started: (0, _validators.validatePresence)({ presence: true, description: 'Fra dato' }),
        // ended: validatePresence({ presence: false, description: 'Til dato'}),
        // current: validatePresence({ presence: false, description: 'Nåværende' }),
        description: (0, _validators.validatePresence)({ presence: true, description: 'Beskrivelse' })
    };
});