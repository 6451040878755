define('uwork-app-v2/contractee/model', ['exports', 'ember-data/model', 'ember-data/attr', 'ember-data/relationships', 'ember-cp-validations'], function (exports, _model, _attr, _relationships, _emberCpValidations) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.schema = undefined;


    var TYPE_UWORKER = 1;
    var TYPE_CONSULTANT = 10;
    var TYPE_ALL = 20;

    var Validations = (0, _emberCpValidations.buildValidations)({
        name: {
            description: 'Firmanavn',
            validators: [(0, _emberCpValidations.validator)('presence', true)]
        },
        phone: (0, _emberCpValidations.validator)('presence', true),
        city: (0, _emberCpValidations.validator)('presence', true),
        address: (0, _emberCpValidations.validator)('presence', true),
        zipCode: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('number', { allowString: true })],
        place: (0, _emberCpValidations.validator)('presence', true),
        category: [(0, _emberCpValidations.validator)('belongs-to'), (0, _emberCpValidations.validator)('presence', {
            presence: true,
            message: 'Du må velge bransje.'
        })],
        workersType: (0, _emberCpValidations.validator)('presence', true),
        restTimeAllDayShifts: {
            validators: [(0, _emberCpValidations.validator)('number', {
                allowString: true,
                integer: true,
                gt: 0
            })]
        }
    }, { debounce: 500 });

    var schema = exports.schema = {
        name: (0, _attr.default)(),
        intro: (0, _attr.default)(),
        businessNumber: (0, _attr.default)(),
        web: (0, _attr.default)(),
        address: (0, _attr.default)(),
        zipCode: (0, _attr.default)('string'),
        place: (0, _attr.default)(),
        phone: (0, _attr.default)(),
        category: (0, _relationships.belongsTo)('category'),
        workersType: (0, _attr.default)('number', { defaultValue: 1 }),
        restTimeAllDayShifts: (0, _attr.default)('number', { defaultValue: 1 }),
        shiftBrake: (0, _attr.default)('number')
    };

    exports.default = _model.default.extend(Validations, schema, {
        showIntro: Ember.computed('intro', function () {
            return Ember.String.htmlSafe(Ember.get(this, 'intro'));
        }),
        fullAddress: Ember.computed('address', 'zipCode', 'place', function () {
            return Ember.get(this, 'address') + ', ' + Ember.get(this, 'zipCode') + ' ' + Ember.get(this, 'place');
        }),
        hasAddress: Ember.computed('address', 'zipCode', 'place', function () {
            return Ember.get(this, 'address') || Ember.get(this, 'zipCode') || Ember.get(this, 'place');
        }),

        fullAddressFormated: Ember.computed('address', 'zipCode', 'place', function () {
            return Ember.String.htmlSafe(Ember.get(this, 'address') + '<br />' + Ember.get(this, 'zipCode') + ' ' + Ember.get(this, 'place'));
        }),
        country: (0, _attr.default)(),

        hasRequiredRestPeriod: (0, _attr.default)(),
        requiredRestPeriod: (0, _attr.default)(),

        user: (0, _relationships.belongsTo)('user'),
        payments: (0, _relationships.hasMany)('contractee-payment'),
        isVerified: Ember.computed.alias('user.isVerified'),

        tel: Ember.computed('phone', function () {
            return Ember.get(this, 'phone');
        }),
        webUrl: Ember.computed('web', function () {
            var web = Ember.get(this, 'web');
            if (!web) {
                return;
            }
            if (!/^https?:\/\//i.test(web)) {
                web = 'http://' + web;
            }

            return web;
        }),
        profilePercentage: Ember.computed('name', 'title', 'businessNumber', 'address', 'zipCode', 'place', 'country', 'phone', 'hasRequiredRestPeriod', 'requiredRestPeriod', 'user', 'category', 'assignments', 'isVerified', 'profilePercentage', function () {
            var attributes = ['name', 'title', 'businessNumber', 'address', 'zipCode', 'place', 'country', 'phone', 'category'];
            var percentage = 0;
            var _this = this;
            attributes.forEach(function (item) {
                if (Ember.get(_this, item)) {
                    percentage += 1;
                }
            });
            return Math.floor(percentage / 11 * 100).toFixed(0);
        }),
        profileCompleted: Ember.computed.equal('profilePercentage', 100),

        setType: function setType(type) {
            Ember.set(this, 'workersType', type);
        },
        setTypeUworker: function setTypeUworker() {
            Ember.set(this, 'workersType', TYPE_UWORKER);
        },
        setTypeConsultatnt: function setTypeConsultatnt() {
            Ember.set(this, 'workersType', TYPE_CONSULTANT);
        },
        setTypeAll: function setTypeAll() {
            Ember.set(this, 'workersType', TYPE_ALL);
        }
    });
});