define('uwork-app-v2/components/admin/vacancy/feature/new-item/validation', ['exports', 'ember-changeset-validations/validators'], function (exports, _validators) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = {
        name: (0, _validators.validatePresence)(true)
        // description: validatePresence(true),
        // price: validatePresence(true),
        // status: validatePresence(true)
    };
});