define('uwork-app-v2/components/custom/md-datepicker/component', ['exports', 'moment'], function (exports, _moment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        classNames: ['md-datepicker-group'],
        inputID: null,
        init: function init() {
            this._super.apply(this, arguments);

            this.set('_viewingDate', null);
            this.set('isDirty', false);

            return this._super.apply(this, arguments);
        },
        didInsertElement: function didInsertElement() {

            this._super.apply(this, arguments);

            // Currently unable to get right click cut/paste to work, so disable right click for now
            var self = this;

            //let _this = this;
            var elementID = "#date-picker-" + self.elementId;
            Ember.set(self, 'inputID', elementID);

            Ember.$(elementID).on('contextmenu', function () {
                return false;
            });

            Ember.$(elementID).on('invalid', function (e) {
                e.preventDefault();
                self.set('isDirty', true);
            });

            return this._super.apply(this, arguments);
        },
        didReceiveAttrs: function didReceiveAttrs() {
            this._super.apply(this, arguments);

            if (this.get('mode') !== undefined && this.get('mode') !== 'date' && this.get('mode') !== 'datetime') {
                throw new Error('Unknown mode ' + this.get('mode'));
            }
            // this._super(...arguments);
        },


        dateText: Ember.computed('selectedDate', 'format', {
            set: function set(key, val) {
                this.set('_dateText', val);

                return val;
            },
            get: function get() {
                var dateText = null;
                var _dateText = this.get('_dateText');
                var selectedDate = this.get('selectedDate');
                if (selectedDate) {
                    dateText = this.getMoment(selectedDate).format(this.get('format'));
                } else {
                    dateText = _dateText;
                }
                this.set('_dateText', dateText);
                return dateText;
            }
        }),
        isDateTime: Ember.computed('mode', function () {
            if (this.get('mode') === 'datetime') {
                return true;
            }
        }),
        daySpans: Ember.computed('viewingDate', 'selectedDate', 'minDate', 'maxDate', 'hourOffset', function () {
            var viewingDate = this.get('viewingDate');
            var selectedDate = this.get('selectedDate');
            var minDate = this.get('minDate');
            var maxDate = this.get('maxDate');
            var hourOffset = this.get('hourOffset');

            var daySpans = Ember.A([]);

            // Add blank days from Monday to the first day of the month
            var firstDay = Number(this.getMoment(viewingDate).startOf('month').format('E'));
            for (var i = 0; i < firstDay - 1; i++) {
                daySpans.pushObject({ day: '', today: false, isSelectedDate: false, date: null, disabled: false });
            }

            // Populate for each day
            var daysInMonth = this.getMoment(viewingDate).daysInMonth();
            var today = this.getMoment();
            for (var _i = 0; _i < daysInMonth; _i++) {
                var day = _i + 1;
                var momentDate = this.getMoment(this.getMoment(viewingDate).startOf('month')).add(day - 1, 'days');

                if (hourOffset) {
                    momentDate.add(hourOffset, 'hours');
                }
                var date = momentDate.toDate();

                var isSelectedDate = false;
                if (selectedDate) {
                    isSelectedDate = this.getMoment(selectedDate).isSame(date, 'day');
                }

                var disabled = false;
                if (minDate && this.getMoment(minDate).isAfter(date, 'day')) {
                    disabled = true;
                } else if (maxDate && this.getMoment(maxDate).isBefore(date, 'day')) {
                    disabled = true;
                }

                var className = 'btn-date';
                if (isSelectedDate) {
                    className = 'btn-date selected';
                }
                if (disabled) {
                    className += ' disabled';
                }

                daySpans.pushObject({ day: day.toString(), today: today.isSame(date, 'day'), isSelectedDate: isSelectedDate, date: date, className: className, disabled: disabled });
            }

            // Fill out remaining row
            var lastDay = Number(this.getMoment(viewingDate).endOf('month').format('E'));
            for (var _i2 = lastDay; _i2 < 7; _i2++) {
                daySpans.pushObject({ day: '', today: false, isSelectedDate: false, date: null, disabled: false });
            }

            return daySpans;
        }),
        weekSpans: Ember.computed('daySpans.[]', function () {
            var daySpans = this.get('daySpans');
            var weekSpans = Ember.A([]);
            var splitSize = 7;

            for (var i = 0; i < daySpans.get('length'); i += splitSize) {
                weekSpans.addObject({ daySpans: daySpans.slice(i, i + splitSize) });
            }

            return weekSpans;
        }),
        defaultErrorMessage: Ember.computed('format', 'required', 'dateText', 'isEarly', 'isLate', 'minDate', 'maxDate', function () {
            var dateText = this.get('dateText');
            if (this.get('required') && (dateText === undefined || dateText === null || dateText === '')) {
                return 'Date is required';
            }

            var format = this.get('format');

            if (this.get('isEarly')) {
                return 'Date entered must be on or after ' + this.getMoment(this.get('minDate')).format(format);
            }

            if (this.get('isLate')) {
                return 'Date entered must be on or before ' + this.getMoment(this.get('maxDate')).format(format);
            }

            return 'Invalid date, required format is ' + format;
        }),
        errorMessageShown: Ember.computed('errorMessage', 'isInvalidDate', function () {
            return this.get('errorMessage.length') > 0 || this.get('isInvalidDate');
        }),
        format: Ember.computed('dateFormat', 'timeFormat', 'isDateTime', function () {
            var dateFormat = this.get('dateFormat');
            var timeFormat = this.get('timeFormat');

            var result = 'MM/DD/YYYY';
            if (dateFormat) {
                result = dateFormat;
            }
            if (this.get('isDateTime')) {
                if (timeFormat) {
                    result += timeFormat;
                } else {
                    result += ' HH:mm';
                }
            }
            return result;
        }),
        isEarly: Ember.computed('dateText', 'minDate', 'format', 'useStrictMode', function () {
            var dateText = this.get('dateText');
            var minDate = this.get('minDate');

            if (!minDate) {
                return false;
            }

            return this.getMoment(dateText, this.get('format'), this.get('useStrictMode')).isBefore(this.getMoment(minDate), 'day');
        }),
        isLate: Ember.computed('dateText', 'maxDate', 'format', 'useStrictMode', function () {
            var dateText = this.get('dateText');
            var maxDate = this.get('maxDate');

            if (!maxDate) {
                return false;
            }

            return this.getMoment(dateText, this.get('format'), this.get('useStrictMode')).isAfter(this.getMoment(maxDate), 'day');
        }),
        isValidDate: Ember.computed('dateText', 'required', 'isDirty', 'isEarly', 'isLate', function () {
            var dateText = this.get('dateText');

            var notRequiredOrNotDirty = !this.get('required') || !this.get('isDirty');

            if (notRequiredOrNotDirty && (dateText === undefined || dateText === null || dateText === '')) {
                return true;
            }

            return this.getMoment(dateText, this.get('format'), this.get('useStrictMode')).isValid() && !this.get('isEarly') && !this.get('isLate');
        }),
        isInvalidDate: Ember.computed.not('isValidDate'),
        mdClass: Ember.computed('inputClass', 'isValidDate', 'errorMessage', function () {
            var result = '';
            var inputClass = this.get('inputClass');
            if (inputClass) {
                result += inputClass;
            }

            if (this.get('isValidDate') && !this.get('errorMessage.length')) {
                return result;
            }
            return result === '' ? 'invalid' : result + ' ' + 'invalid';
        }),
        placeholderClass: Ember.computed('dateText', function () {
            if (this.get('dateText.length') > 0) {
                return 'text-present';
            }

            return '';
        }),
        selectedDayMonth: Ember.computed('selectedDate', 'isInvalidDate', 'dateText', function () {
            var selectedDate = this.get('selectedDate');
            var dateText = this.get('dateText');

            if (selectedDate) {
                return this.getMoment(selectedDate).format('ddd, MMM D');
            }
            if (this.get('isInvalidDate')) {
                if (this.get('required') && (dateText === undefined || dateText === null || dateText === '')) {
                    return 'Date is required';
                }

                return 'Invalid date';
            }

            return '';
        }),
        viewingLongMonth: Ember.computed('viewingDate', function () {
            var viewingDate = this.get('viewingDate');
            return this.getMoment(viewingDate).format('MMMM');
        }),
        viewingYear: Ember.computed('viewingDate', function () {
            var viewingDate = this.get('viewingDate');
            return this.getMoment(viewingDate).format('YYYY');
        }),
        selectedYear: Ember.computed('selectedDate', function () {
            var selectedDate = this.get('selectedDate');
            if (selectedDate) {
                return this.getMoment(selectedDate).format('YYYY');
            }
            return '';
        }),
        selectedTime: Ember.computed('selectedDate', function () {
            var selectedDate = this.get('selectedDate');
            if (selectedDate) {
                return this.getMoment(selectedDate).format('HH:mm');
            }

            return '';
        }),
        viewingDate: Ember.computed('selectedDate', {
            set: function set(key, val) {
                this.set('_viewingDate', val);
                return val;
            },
            get: function get() {
                var selectedDate = this.get('selectedDate');
                var _viewingDate = this.get('_viewingDate');

                if (selectedDate) {
                    _viewingDate = selectedDate;
                } else if (_viewingDate) {
                    return _viewingDate;
                } else {
                    _viewingDate = this.getMoment().toDate();
                }

                this.set('_viewingDate', _viewingDate);
                return _viewingDate;
            }
        }),
        useStrictMode: Ember.computed('relaxValidation', function () {
            if (this.get('relaxValidation')) {
                return false;
            }

            return true;
        }),
        getMoment: function getMoment(date, format, useStrictMode) {
            if (this.get('utc')) {
                return _moment.default.utc(date, format, useStrictMode);
            }
            return (0, _moment.default)(date, format, useStrictMode);
        },

        actions: {
            absorbMouseDown: function absorbMouseDown() {
                // Used to retain focus in the input by absorbing mouse down 'clicks' on the datepicker that do not have explicit actions
            },
            dateClicked: function dateClicked(dayspan) {
                var date = dayspan.date;
                var isValid = true;
                var _this = this;
                // Preserve selected time
                var selectedDate = this.get('selectedDate');
                if (selectedDate) {
                    date.setHours(this.getMoment(selectedDate).format('HH'));
                    date.setMinutes(this.getMoment(selectedDate).format('mm'));
                    date.setSeconds(this.getMoment(selectedDate).format('ss'));
                }
                if (!dayspan.disabled) {

                    this.sendAction('dateChanged', dayspan.date, isValid);

                    if (this.get('clearErrorMessageOnChange')) {
                        this.set('errorMessage', null);
                    }
                }
                //console.log(get(this,'inputID'))

                Ember.$(Ember.get(_this, 'inputID')).blur();
            },
            downArrowClick: function downArrowClick() {

                this.$(Ember.get(this, 'elementID')).focusin();
            },
            focusOut: function focusOut() {
                this.set('isDirty', true);
            },
            keyUp: function keyUp() {
                this.set('isDirty', true);

                var dateText = this.get('dateText');
                var isValid = this.get('isValidDate');
                if (isValid && dateText !== undefined && dateText !== null && dateText !== '') {
                    var momentDate = this.getMoment(dateText, this.get('format'));
                    var hourOffset = this.get('hourOffset');
                    if (hourOffset) {
                        momentDate.add(hourOffset, 'hours');
                    }

                    this.sendAction('dateChanged', momentDate.toDate(), isValid);
                } else {
                    this.sendAction('dateChanged', null, isValid);
                }

                if (this.get('clearErrorMessageOnChange')) {
                    this.set('errorMessage', null);
                }
            },
            monthToggle: function monthToggle(value) {
                this.set('viewingDate', this.getMoment(this.get('viewingDate')).add(Number(value), 'months').toDate());
            }
        }
    });
});