define('uwork-app-v2/components/contractee/vacancy/new-message/component', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        menu: Ember.inject.service('extend-menu'),

        store: Ember.inject.service(),
        vacancy: Ember.inject.service(),
        account: Ember.inject.service('user/session-account'),
        user: Ember.computed.alias('account.currentUser'),

        vacancyModel: Ember.computed.alias('vacancy.vacancy'),
        orderModel: Ember.computed.alias('vacancy.order'),
        userModel: Ember.computed.alias('vacancy.user'),
        conversation: Ember.computed.alias('vacancy.conversation'),
        type: Ember.computed.alias('vacancy.type'),

        classNames: ['app-sidenav-new-message'],

        appService: Ember.inject.service('application'),

        newMessage: '',
        didInsertElement: function didInsertElement() {
            this._super.apply(this, arguments);
            Ember.set(this, 'newMessage', '');
        },
        closeMenu: function closeMenu() {
            var sideNav = Ember.get(this, 'menu.menu');
            // sideNav.publicActions.closeSubNavigation();
            sideNav.publicActions.showSubNavigation('navigation');
        },

        actions: {
            sentMessage: function sentMessage() {
                var _this = this;

                var menu = Ember.get(this, 'menu.menu');
                var type = Ember.get(this, 'type');
                var user = Ember.get(this, 'userModel');
                var message = Ember.get(this, 'newMessage');

                if (message && message.length > 0) {
                    if (type === 'order') {
                        // Send message directly
                        var order = Ember.get(this, 'orderModel');

                        Ember.get(this, 'store').createRecord('message', {
                            message: message,
                            user: user,
                            order: order
                        }).save().then(function () {
                            Ember.set(_this, 'newMessage', '');
                            Ember.get(_this, 'user').reload();
                            order.reload();
                        }).finally(function () {
                            Ember.set(_this, 'newMessage', '');
                            Ember.get(_this, 'appService').toggleMessage();
                        });
                    } else if (type === 'vacancy') {

                        var vacancy = Ember.get(this, 'vacancyModel');
                        Ember.get(this, 'store').createRecord('message', {
                            message: message,
                            user: user,
                            vacancy: vacancy
                        }).save().then(function () {
                            Ember.set(_this, 'newMessage', '');
                            Ember.get(_this, 'user').reload();
                            vacancy.reload();
                        }).finally(function () {
                            Ember.get(_this, 'appService').toggleMessage();
                        });
                    }
                    Ember.set(this, 'newMessage', '');
                    menu.publicActions.showSubNavigation('messages');
                } else {
                    this.swal({
                        title: "OBS!",
                        html: "Meldingen din inneholder ikke noe tekst. Ønsker du fortsatt å sende meldingen?",
                        type: 'warning',
                        confirmButtonText: "Send melding"
                    });
                }
            },
            cancel: function cancel() {
                this.closeMenu();
                Ember.set(this, 'newMessage', '');
            }
        }
    });
});