define('uwork-app-v2/components/contractor/profile/display-verified-icon/component', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        tagName: 'span',
        classNames: 'verified',
        didInsertElement: function didInsertElement() {
            this._super.apply(this, arguments);
            this.$('[data-toggle="tooltip"]').tooltip();
        }
    });
});