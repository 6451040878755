define('uwork-app-v2/admin/messages/index/controller', ['exports', 'ember-cli-jsonapi-pagination/mixins/controllers/jsonapi-pagination'], function (exports, _jsonapiPagination) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend(_jsonapiPagination.default, {
        media: Ember.inject.service(),
        totalPages: Ember.computed('size', 'number', 'model', function () {
            return this.get('model.meta.pagination.total_pages');
        }),
        outerWindow: Ember.computed('media.isXs', 'media.isSm', 'media.isMd', 'media.isLg', 'media.isXl', function () {
            var media = Ember.get(this, 'media');

            if (media.get('isXs') || media.get('isSm')) {
                return 1;
            } else {
                return 3;
            }
        })
    });
});