define('uwork-app-v2/components/contractee/profile/header-content/component', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        classNames: ['col-12 col-md-4'],
        user: Ember.computed.alias('contractee.user'),
        account: Ember.inject.service('user/session-account'),
        auth: Ember.computed.alias('account.currentUser'),
        isAdmin: Ember.computed.equal('auth.isAdmin', true)
    });
});