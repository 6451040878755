define('uwork-app-v2/components/bs/subnav-header', ['exports', 'ornio-nav-app/components/bs/subnav-header'], function (exports, _subnavHeader) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _subnavHeader.default;
    }
  });
});