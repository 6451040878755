define('uwork-app-v2/components/bs/custom-checkbox/component', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        tagName: 'label',
        classNames: ['custom-control', 'custom-checkbox'],
        actions: {
            setFilterVal: function setFilterVal(value, name) {
                this.sendAction('setFilterVal', value, name);
            }
        }
    });
});