define('uwork-app-v2/admin/calendar/controller', ['exports', 'moment'], function (exports, _moment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        month: null,

        init: function init() {
            this._super.apply(this, arguments);
            if (Ember.get(this, 'month') === null) {
                var current = (0, _moment.default)().day(0).hour(0).minute(0).second(0).millisecond(0);
                Ember.set(this, 'month', current);
            }
        }
    });
});