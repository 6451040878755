define('uwork-app-v2/agreement-confirmation/model', ['exports', 'ember-data/model', 'ember-data/attr', 'ember-data/relationships'], function (exports, _model, _attr, _relationships) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.schema = undefined;
    var schema = exports.schema = {
        ip: (0, _attr.default)()
    };

    exports.default = _model.default.extend(schema, {
        user: (0, _relationships.belongsTo)('user'),
        agreement: (0, _relationships.belongsTo)('agreement'),
        createdAt: (0, _attr.default)(),
        updatedAt: (0, _attr.default)()
    });
});