define('uwork-app-v2/components/admin/order/edit/order-shift-item/component', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        tagName: 'tr',
        classNames: ['bg-yellow'],
        didValidate: false,
        actions: {
            save: function save(model) {
                var _this2 = this;

                var _this = this;

                var on = null;

                Ember.set(this, 'didValidate', true);

                if (model.get('shift.isAllDayShift')) {
                    on = ['restTimeComment'];
                } else {
                    on = ['description'];
                }

                model.validate({ on: on }).then(function (_ref) {
                    var validations = _ref.validations;


                    if (validations.get('isValid')) {
                        model.save().then(function () {
                            _this2.swal({
                                title: "Dine opplysninger er lagret!",
                                timer: 1000,
                                showConfirmButton: false,
                                showCloseButton: false,
                                type: 'success'
                            }).then(function () {
                                _this.sendAction('edit', model);
                            }, function () {
                                _this.sendAction('edit', model);
                            });
                        });
                    } else {
                        //console.log('isInvalid');
                    }
                });
            },
            restTime: function restTime(shift, key, time, format) {
                this.sendAction('restTime', shift, key, time, format);
            },
            decrement: function decrement(model, key) {
                this.sendAction('decrement', model, key);
            },
            increment: function increment(model, key) {
                this.sendAction('increment', model, key);
            },
            edit: function edit(shift) {
                this.sendAction('edit', shift);
            }
        }
    });
});