define('uwork-app-v2/order/adapter', ['exports', 'uwork-app-v2/application/adapter'], function (exports, _adapter) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _adapter.default.extend({
        urlForQueryRecord: function urlForQueryRecord(query) {
            if (query.resend) {
                var order_id = query.order_id;
                delete query.resend;
                delete query.order_id;
                return this.urlPrefix() + '/orders/resend-confirmation-email/' + order_id;
            }
        },
        urlForCreateRecord: function urlForCreateRecord(modelName, snapshot) {

            if (snapshot.attr('type') === 60 || snapshot.attr('type') === 70) {
                return this._buildURL('order-direct-publish');
            }

            if (snapshot.attr('type') === 80) {
                return this._buildURL('order-turnu');
            }

            return this._buildURL(modelName);
        }
    });
});