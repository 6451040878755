define('uwork-app-v2/admin/vacancies/index/controller', ['exports', 'ember-cli-jsonapi-pagination/mixins/controllers/jsonapi-pagination'], function (exports, _jsonapiPagination) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend(_jsonapiPagination.default, {
        media: Ember.inject.service(),
        size: 15,
        number: 1,
        ordersBy: [{ name: 'Publisert', value: 'created_at:desc' }, { name: 'Søknadsfrist', value: 'deadline_at:asc' }],
        queryParams: ['order_by', 'deadline_in_future'],

        deadline_in_future: 1,
        direction: 'desc',
        order_by: 'created_at:desc',

        // directionController: true,
        // directionLabel: computed('directionController', function(){
        //     return get(this, 'directionController') ? 'desc' : 'asc';
        // }),
        // directionIcon: computed('direction', function(){
        //     return get(this, 'directionController') ? 'down' : 'up';
        // }),

        sortProps: Ember.computed('order_by', function () {
            return Ember.get(this, 'order_by') === 'created_at:desc' ? ['createdAt:desc'] : ['deadlineAt:asc'];
        }),
        vacancies: Ember.computed.sort('model', 'sortProps'),

        totalPages: Ember.computed('size', 'number', 'model', function () {
            return Ember.get(this, 'model.meta.pagination.total_pages');
        }),
        outerWindow: Ember.computed('media.isXs', 'media.isSm', 'media.isMd', 'media.isLg', 'media.isXl', function () {
            var media = Ember.get(this, 'media');

            if (media.get('isXs') || media.get('isSm')) {
                return 1;
            } else {
                return 3;
            }
        }),
        actions: {
            updateAttr: function updateAttr(item, value) {
                Ember.set(this, 'number', 1);
                Ember.set(this, item, value);
            }
        }
    });
});