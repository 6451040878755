define('uwork-app-v2/components/contractor/sidebar/nav-items/component', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        classNames: ['d-flex flex-column'],
        menu: Ember.inject.service('extend-menu'),
        media: Ember.inject.service(),
        tagName: 'ul',
        closeMenu: function closeMenu() {
            var media = Ember.get(this, 'media');

            if (media.get('isXs') || media.get('isSm') || media.get('isMd')) {

                var menu = Ember.get(this, 'menu.menu');
                menu.publicActions.closeSubNavigation();

                if (Ember.$('#navbarToggleExternalContent').hasClass('show')) {
                    Ember.$('#navbarToggleExternalContent').removeClass('show');
                }
            }
        },

        actions: {
            closeMenu: function closeMenu() {
                this.closeMenu();
            }
        }
    });
});