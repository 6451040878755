define('uwork-app-v2/validations/education', ['exports', 'ember-changeset-validations/validators', 'uwork-app-v2/validators/date-or-current'], function (exports, _validators, _dateOrCurrent) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = {
        //name: validatePresence({ presence: true, description: 'Filnavn' }),
        //ToDo : check for custom validation date end to be null if current is true
        institution: (0, _validators.validatePresence)({ presence: true, description: 'Skole' }),
        degree: (0, _validators.validatePresence)({ presence: true, description: 'Grad' }),
        started: (0, _validators.validatePresence)({ presence: true, description: 'Fra dato' }),
        // ended: validatePresence({ presence: false, description: 'Til dato'}),
        // current: validatePresence({ presence: false, description: 'Nåværende' }),
        description: (0, _validators.validatePresence)({ presence: true, description: 'Beskrivelse' })
    };
});