define('uwork-app-v2/breakpoints', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = {
        xs: '(min-width: 0px) and (max-width: 767px)',
        sm: '(min-width: 768px) and (max-width: 991px)',
        md: '(min-width: 992px) and (max-width: 1023px)',
        lg: '(min-width: 1024px) and (max-width: 1366px)',
        xl: '(min-width: 1367px)'
    };
});