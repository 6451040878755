define('uwork-app-v2/components/contractor/settings/languages/display-main/validation', ['exports', 'ember-changeset-validations/validators'], function (exports, _validators) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = {
        language: (0, _validators.validatePresence)(true),
        writeLevel: (0, _validators.validatePresence)(true),
        speakLevel: (0, _validators.validatePresence)(true)
    };
});