define('uwork-app-v2/auth/redirect/route', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        session: Ember.inject.service(),
        model: function model(params) {
            var token = params.token;
            var authenticator = 'authenticator:auth-login';

            // console.log(credentials);

            this.get('session').authenticate(authenticator, token);
        }
    });
});