define('uwork-app-v2/components/admin/agreement-new/component', ['exports', 'uwork-app-v2/mixins/default-ds-action-mixin', 'uwork-app-v2/components/admin/agreement-new/validation', 'uwork-app-v2/agreement/model', 'uwork-app-v2/mixins/simditor-advance-mixin'], function (exports, _defaultDsActionMixin, _validation, _model, _simditorAdvanceMixin) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_defaultDsActionMixin.default, _simditorAdvanceMixin.default, {
        store: Ember.inject.service(),
        validation: _validation.default,
        schema: _model.schema,

        modelType: "agreement",
        messageCreate: "Kontrakt opprettet!",

        init: function init() {
            this._super.apply(this, arguments);
            var model = Ember.get(this, 'store').createRecord("agreement");
            Ember.set(model, 'details', '');
            Ember.set(this, 'model', model);
        }
    });
});