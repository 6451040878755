define('uwork-app-v2/components/contractor/settings/languages/display-main/component', ['exports', 'ember-local-storage', 'uwork-app-v2/components/contractor/settings/languages/display-main/validation', 'uwork-app-v2/components/contractor/language/model'], function (exports, _emberLocalStorage, _validation, _model) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var keys = Object.keys;
    exports.default = Ember.Component.extend({
        tagName: 'div',
        classNames: ['language-list', 'm-b-3'],
        session: Ember.inject.service(),
        store: Ember.inject.service(),
        account: Ember.inject.service('user/session-account'),
        user: Ember.computed.alias('account.currentUser'),
        isWizard: Ember.computed.equal('user.isSignUpCompleted', false),
        wizardStep: (0, _emberLocalStorage.storageFor)('contractor/wizard'),
        LanguageValidation: _validation.default,
        errors: null,
        success: null,

        init: function init() {
            this._super.apply(this, arguments);
            var model = Ember.get(this, 'store').createRecord("language");
            Ember.set(this, 'model', model);
        },


        showPanelText: Ember.computed('showPanel', function () {
            return Ember.get(this, 'showPanel') ? 'form.btn.close' : 'contractor.account.edit.education.modal.add';
        }),

        showPanelIcon: Ember.computed('showPanel', function () {
            return Ember.get(this, 'showPanel') ? 'close-circle' : 'plus-circle';
        }),

        showPanelIconColor: Ember.computed('showPanel', function () {
            return Ember.get(this, 'showPanel') ? 'text-danger' : 'text-primary';
        }),

        showPanel: Ember.computed.empty('model'),

        levels: [{ id: 1, name: 'Morsmål' }, { id: 2, name: 'Flytende' }, { id: 3, name: 'Viderekommen' }, { id: 4, name: 'Grunnleggende' }],

        actions: {
            add: function add(changeset) {
                var _this = this;

                var snapshot = changeset.snapshot();

                // Reset errors & success variable
                Ember.set(this, 'errors', null);
                Ember.set(this, 'success', null);

                return changeset.cast(keys(_model.schema)).validate().then(function () {
                    if (Ember.get(changeset, 'isValid')) {
                        changeset.save().then(function () {
                            Ember.set(_this, 'model', Ember.get(_this, 'store').createRecord("language"));
                            _this.swal('Språk er lagt under din profil.');
                            // new Notification('Språk er lagt under din profil.');
                        }).catch(function () {
                            Ember.get(_this, 'model.errors').forEach(function (_ref) {
                                var attribute = _ref.attribute,
                                    message = _ref.message;

                                changeset.pushErrors(attribute, message);
                            });
                        });
                    } else {
                        //console.log('isNotValid');
                    }
                }).catch(function () {
                    changeset.restore(snapshot);
                });
            },
            clear: function clear(changeset) {
                changeset.rollback();
            },
            insertNew: function insertNew() {
                this.toggleProperty('showPanel');
            },
            back: function back() {
                this.sendAction('toRoute', 'contractor.settings.skills');
            },
            toRoute: function toRoute() {
                var step = parseInt(Ember.get(this, 'wizardStep.step'));
                if (step === 11) {
                    this.set('wizardStep.step', 12);
                }
                this.sendAction('toRoute', 'contractor.settings.biography');
            },
            delete: function _delete(model) {
                var _this2 = this;

                this.setLoading(true);
                model.destroyRecord().then(function () {
                    // new Notification('Språk fjernet!');
                    _this2.swal('Språk fjernet!');
                    _this2.setLoading(false);
                });
            }
        }
    });
});