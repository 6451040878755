define('uwork-app-v2/components/admin/contractor/display-map-address-marker/component', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        tagName: '',

        fullAddress: Ember.computed('model', function () {
            var model = Ember.get(this, 'model');

            if (model['address'] === null || model['address'] === "") {
                return "Josefinesgate 41, 0351 Oslo";
            }

            return model['address'] + ', ' + model['zip_code'] + ' ' + model['place'];
        }),

        fullName: Ember.computed('model', function () {
            var model = Ember.get(this, 'model');

            return model['first_name'] + ' ' + model['last_name'];
        })

    });
});