define('uwork-app-v2/contractor/view/controller', ['exports', 'uwork-app-v2/substitute/model', 'uwork-app-v2/vacancy/model', 'uwork-app-v2/mixins/has-role-mixin'], function (exports, _model, _model2, _hasRoleMixin) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend(_hasRoleMixin.default, {
        ROLE_NAME_SUBSTITUTE: _model.ROLE_NAME,
        ROLE_NAME_VACANCY: _model2.ROLE_NAME,
        session: Ember.inject.service(),
        user: Ember.computed.alias('model.user'),
        account: Ember.inject.service('user/session-account'),
        auth: Ember.computed.alias('account.currentUser'),
        isAdmin: Ember.computed.equal('auth.isAdmin', true),
        itsMe: Ember.computed('auth', 'user', function () {
            var user = Ember.get(this, 'user');
            var auth = Ember.get(this, 'auth');

            if (user.get('id') === auth.get('id') && auth.get('isVerified') && this.hasRole(_model.ROLE_NAME)) {
                return true;
            } else {
                return false;
            }
        })
    });
});