define('uwork-app-v2/components/admin/new-tag/component', ['exports', 'uwork-app-v2/mixins/default-ds-action-mixin', 'uwork-app-v2/components/admin/new-tag/validation', 'uwork-app-v2/tag/model'], function (exports, _defaultDsActionMixin, _validation, _model) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_defaultDsActionMixin.default, {
        store: Ember.inject.service(),
        validation: _validation.default,
        schema: _model.schema,

        modelType: "tag",
        messageCreate: "Tag opprettet",

        init: function init() {
            this._super.apply(this, arguments);
            var model = Ember.get(this, 'store').createRecord("tag");
            Ember.set(this, 'model', model);
        }
    });
});