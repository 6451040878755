define('uwork-app-v2/components/contractor/profile/display-attachment/component', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        tagName: "",
        itsMe: false,
        models: Ember.computed('itsMe', 'documents', function () {
            var documents = Ember.get(this, 'documents');
            var itsMe = Ember.get(this, 'itsMe');

            if (itsMe) {
                return documents;
            } else {
                return documents.filterBy('public', true);
            }
        }),
        actions: {
            download: function download(file) {
                var link = document.createElement('a');
                link.href = file;
                link.id = 'down';
                link.target = '_blank';

                // link.click();
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        }
    });
});