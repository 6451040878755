define('uwork-app-v2/contractee/user-requests/apply-for-substitute/route', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        breadCrumb: {},
        title: "Ønsker å bli vikar",

        model: function model() {
            return this.store.findAll('category');
        },


        actions: {
            toRoute: function toRoute(path) {
                this.transitionTo(path);
            }
        }
    });
});