define('uwork-app-v2/contractor/dashboard/route', ['exports', 'ember-i18n', 'uwork-app-v2/config/environment'], function (exports, _emberI18n, _environment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({

        session: Ember.inject.service(),
        account: Ember.inject.service('user/session-account'),
        user: Ember.computed.alias('account.currentUser'),
        menu: Ember.inject.service('extend-menu'),
        ajax: Ember.inject.service(),
        queryParams: {
            week: {
                refreshModel: true,
                replace: true
            },
            year: {
                refreshModel: true,
                replace: true
            }
        },
        title: (0, _emberI18n.translationMacro)('contractor.pageName.dashboard'),
        breadCrumb: {},
        beforeModel: function beforeModel() {
            this.setLoading(true);
        },
        afterModel: function afterModel() {
            var title = this.get('title');

            var bTitle = {
                title: title.string
            };
            this.setLoading(false);
            this.showAfterRender(false);
            Ember.set(this, 'breadCrumb', bTitle);
        },
        model: function model(params) {
            var url = '' + _environment.default.APP.API_HOST + _environment.default.APP.API_NAMESPACE + '/contractors/stats';
            var token = Ember.get(this, 'session.data.authenticated.token');
            var ajax = Ember.get(this, 'ajax');

            return Ember.RSVP.hash({
                stats: ajax.request(url, {
                    //url: url,
                    beforeSend: function beforeSend(xhr) {
                        xhr.setRequestHeader('Authorization', 'Bearer ' + token);
                    }
                }).then(function (stats) {
                    return stats;
                }).catch(function () {}),
                userNotificationSettings: this.store.findAll('user-notification-setting'),
                vacancies: this.store.query('vacancy', { number: 1, size: 5, deadline_in_future: 1, status: 20, without_applied: 1 }),
                vacanciesApplied: this.store.query('vacancy', { number: params.number, size: params.size, applied: 1, status: 20 })
            });
        },
        setupController: function setupController(controller, model) {
            this._super(controller, model);
            controller.get('notification').perform();
        }
    });
});