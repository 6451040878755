define('uwork-app-v2/messages/view/controller', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    Ember.set(undefined, 'newMessage', '');
    Ember.get(undefined, 'appService').toggleMessage();exports.default = Ember.Controller.extend({
        account: Ember.inject.service('user/session-account'),
        store: Ember.inject.service(),
        user: Ember.computed.alias('account.currentUser'),
        messages: Ember.computed.alias('model.messages'),
        appService: Ember.inject.service('application'),
        newMessage: '',
        actions: {
            submitNewConversation: function submitNewConversation(model) {
                var _this = this;

                var message = Ember.get(this, 'newMessage');
                var user = Ember.get(model, 'interlocutor.user');
                var store = Ember.get(this, 'store');

                if (message && message.length > 0) {
                    store.createRecord('message', {
                        message: message,
                        conversation: model,
                        user: user
                    }).save().then(function () {
                        Ember.set(_this, 'newMessage', '');
                    }).finally(function () {
                        Ember.get(_this, 'appService').toggleMessage();
                    });
                } else {
                    this.swal({
                        title: "OBS!",
                        html: "Meldingen din inneholder ikke noe tekst. Ønsker du fortsatt å sende meldingen?",
                        type: 'warning',
                        confirmButtonText: "Send melding"
                    });
                }
            }
        }
    });
});