define('uwork-app-v2/components/contractor/card/display-skills/component', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        tagName: 'div',
        classNames: ['user-skills'],
        afterSecondSkills: Ember.computed.filter('model', function (skill, index) {
            return index >= 1;
        }),
        firstTwoSkills: Ember.computed.filter('model', function (skill, index) {
            return index < 1;
        }),
        noMore: Ember.computed.gt('model.length', 1)
    });
});