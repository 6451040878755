define('uwork-app-v2/contractee/settings/company/route', ['exports', 'ember-i18n'], function (exports, _emberI18n) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        title: (0, _emberI18n.translationMacro)('contractee.settings.pageName.company'),
        account: Ember.inject.service('user/session-account'),
        user: Ember.computed.alias('account.currentUser'),
        wizardService: Ember.inject.service('contractee/wizard'),
        wizardRouteName: 'contractee.settings.company',
        breadCrumb: {},
        beforeModel: function beforeModel() {
            this.setLoading(true);

            // Set wizard route name
            Ember.get(this, 'wizardService').setCurrentRoute(Ember.get(this, 'wizardRouteName'));
        },
        model: function model() {
            var user_id = Ember.get(this, 'user');
            return this.store.findRecord('contractee', user_id.get('id')).catch(function () {});
        },
        afterModel: function afterModel() {
            var title = this.get('title');

            var bTitle = {
                title: title.string
            };

            this.setLoading(false);
            Ember.set(this, 'breadCrumb', bTitle);
        },

        actions: {
            toRoute: function toRoute(path) {
                this.transitionTo(path);
            }
        }
    });
});