define('uwork-app-v2/messages/index/route', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin', 'ember-i18n'], function (exports, _authenticatedRouteMixin, _emberI18n) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, {
        title: (0, _emberI18n.translationMacro)('messages.nav'),
        model: function model() {
            return this.store.findAll('conversation');
        },
        beforeModel: function beforeModel() {
            this.setLoading(true);
        },
        afterModel: function afterModel() {
            var title = this.get('title');

            var bTitle = {
                title: title.string
            };
            this.setLoading(false);
            Ember.set(this, 'breadCrumb', bTitle);
        }
    });
});