define('uwork-app-v2/application/route', ['exports', 'ember-simple-auth/mixins/application-route-mixin', 'ember-socket-guru/mixins/socket-event-handler'], function (exports, _applicationRouteMixin, _socketEventHandler) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend(_applicationRouteMixin.default, _socketEventHandler.default, {
        openMenu: true,
        account: Ember.inject.service('user/session-account'),
        user: Ember.computed.alias('account.currentUser'),
        session: Ember.inject.service(),
        routing: Ember.inject.service('-routing'),

        setupController: function setupController(controller, model) {
            // Call _super for default behavior
            this._super(controller, model);
            var isAuthenticated = Ember.get(this, 'session.isAuthenticated');

            if (isAuthenticated) {
                Ember.get(controller, 'loadConversations').perform();
                Ember.get(controller, 'loadNotifications').perform();
            }
        },
        beforeModel: function beforeModel(transition) {
            var _this = this,
                _arguments = arguments;

            return this._loadCurrentUser().then(function () {
                if (_this._shouldRedirectToAgreement(transition)) {
                    return _this._redirectToAgreement();
                } else {
                    return _this._super.apply(_this, _arguments);
                }
            }).catch(function () {
                return _this._invalidateSession();
            });
        },
        afterModel: function afterModel(model, transition) {
            var isAuthenticated = Ember.get(this, 'session.isAuthenticated');

            var allowed = ['auth.login', 'auth.redirect', 'auth.reset', 'auth.forgot', 'auth.confirm', 'auth.confirm-resend', 'auth.signup', 'auth.signup.contractor', 'auth.signup.contractee', '404', 'profile', 'profile.view'];

            var targetRoute = transition.targetName;
            var isTransitionToAllowedRoute = allowed.indexOf(targetRoute) > -1;

            if (!isAuthenticated && !isTransitionToAllowedRoute) {
                this.transitionTo('auth.login');
            }
        },
        onSocketAction: function onSocketAction(eventName, eventData) {
            var isAuthenticated = Ember.get(this, 'session.isAuthenticated');

            if (isAuthenticated) {
                Ember.get(this, 'account').loadCurrentUser();
            }
        },

        //
        sessionInvalidated: function sessionInvalidated() {
            this.setLoading(false);
            this.showAfterRender(true);

            //console.log('invalidate');
            this.transitionTo('auth.login');
        },
        sessionAuthenticated: function sessionAuthenticated() {
            var _this2 = this;

            this.setLoading(true);
            this.showAfterRender(true);

            this._loadCurrentUser().then(function () {
                _this2._setUserTransition();
            }); //.catch(() => get(this, 'session').invalidate());
        },
        _loadCurrentUser: function _loadCurrentUser() {
            return Ember.get(this, 'account').loadCurrentUser();
        },

        actions: {
            changeContainer: function changeContainer() {
                this.toggleProperty('openMenu');
            },
            error: function error(_error, transition) {
                if (transition.isActive && _error.message) {
                    transition.abort();
                    this.transitionTo('auth.login');
                }
            },
            willTransition: function willTransition(transition) {
                if (this._shouldRedirectToAgreement(transition)) {
                    transition.abort();
                    this._redirectToAgreement();
                }
            }
        },
        _setUserTransition: function _setUserTransition() {
            var isAuthenticated = this.get('session.isAuthenticated');
            var attemptedTransition = this.get('session.attemptedTransition');

            if (isAuthenticated) {

                var user = Ember.get(this, 'user');

                this.setLoading(false);

                if (Ember.get(this, 'user')) {

                    var isSignUpNotCompleted = Ember.get(user, 'isSignUpNotCompleted');

                    if (Ember.get(user, 'isContractor')) {
                        if (isSignUpNotCompleted) {
                            this.showAfterRender(false);
                            this.transitionTo('contractor.settings');
                        } else {
                            this.transitionTo('contractor.dashboard');
                        }
                    } else if (Ember.get(user, 'isContractee')) {
                        if (isSignUpNotCompleted) {
                            this.showAfterRender(false);
                            this.transitionTo('contractee.settings');
                        } else {
                            this.transitionTo('contractee.dashboard');
                        }
                    } else if (Ember.get(user, 'isAdmin')) {
                        this.transitionTo('admin.dashboard');
                    }
                } else {
                    this.transitionTo('index');
                }
            } else if (attemptedTransition) {
                attemptedTransition.retry();
                Ember.set(this, 'session.attemptedTransition', null);
            }
        },
        _invalidateSession: function _invalidateSession() {
            if (Ember.get(this, 'session.isAuthenticated')) {
                Ember.get(this, 'session').invalidate();
            }
        },
        _shouldRedirectToAgreement: function _shouldRedirectToAgreement(transition) {
            var user = Ember.get(this, 'user');

            // Return false if user session does not exists
            if (!user) {
                return false;
            }

            var allowed = ['auth.login', 'contractor.agreement.confirmation', 'contractee.agreement.confirmation'];

            var targetRoute = transition.targetName;
            var isTransitionToAllowedRoute = allowed.indexOf(targetRoute) > -1;

            return Ember.get(user, 'isSignUpCompleted') && Ember.get(user, 'isVerified') && Ember.get(user, 'isAgreementUpdated') && !isTransitionToAllowedRoute;
        },
        _redirectToAgreement: function _redirectToAgreement() {
            var user = Ember.get(this, 'user');

            // Dont do anything
            if (Ember.get(user, 'isAdmin')) {
                return;
            }

            if (Ember.get(user, 'isContractee')) {
                // Transist to contractee agreement confirmation page
                return this.transitionTo('contractee.agreement.confirmation');
            } else {
                // Transist to contractor agreement confirmation page
                return this.transitionTo('contractor.agreement.confirmation');
            }
        }
    });
});