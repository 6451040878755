define('uwork-app-v2/components/contractor/settings/gcc/schema', ['exports', 'uwork-app-v2/auth/default-schema'], function (exports, _defaultSchema) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.schema = undefined;
    var schema = exports.schema = {
        category: null,
        issuedAt: null,
        file: null,
        uploader: null,

        save: function save() {
            var file = Ember.get(this, 'file');
            var uploader = Ember.get(this, 'uploader');
            var data = {
                "category_id": Ember.get(this, 'category'),
                "issued_at": Ember.get(this, 'issuedAt')
            };

            if (uploader) {
                return uploader.upload(file, data);
            }
        }
    };
    //import set from 'ember-metal/set';
    //import errorParser from 'uwork-app-v2/auth/error-object-parser';
    exports.default = _defaultSchema.default.extend(schema);
});