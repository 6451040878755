define('uwork-app-v2/components/contractee/settings/contracts-form/component', ['exports', 'uwork-app-v2/mixins/default-ds-action-mixin', 'uwork-app-v2/contractee/model'], function (exports, _defaultDsActionMixin, _model) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_defaultDsActionMixin.default, {
        session: Ember.inject.service(),
        store: Ember.inject.service(),
        account: Ember.inject.service('user/session-account'),
        user: Ember.computed.alias('account.currentUser'),
        contractee: Ember.computed.alias('user.contractee'),
        wizardService: Ember.inject.service('contractee/wizard'),

        isWizard: Ember.computed.equal('user.isSignUpCompleted', false),
        feeInPercent: 1.30,

        didValidate: Ember.computed.alias('contractee.validations.didValidate'),

        didInsertElement: function didInsertElement() {
            var _this = this;

            this._super.apply(this, arguments);
            //ToDo - check if user is set
            return Ember.get(this, 'user.contractee').then(function (m) {
                Ember.set(_this, 'contracteeModel', m);
            });
        },


        modelType: "contractee",
        messageSave: "Spørsmål oppdatert.",
        messageDelete: "Spørsmål slettet.",

        actions: {
            save: function save(model) {
                var _this2 = this;

                // Validate
                model.validate({ on: ['shiftBrake', 'workersType', 'restTimeAllDayShifts'] }).then(function (_ref) {
                    var validations = _ref.validations;

                    if (validations.get('isValid')) {
                        model.save().then(function () {
                            _this2.swal("Dine opplysninger er lagret!");
                        });
                    } else {
                        //console.log('isInvalid');
                    }
                });
            },
            saveNext: function saveNext(model) {
                var _this3 = this;

                // Get next route
                var next = Ember.get(this, 'wizardService').getNextRoute();

                // Resolve the promise
                model.then(function (m) {
                    Ember.set(_this3, 'didValidate', true);

                    // Validate
                    m.validate().then(function (_ref2) {
                        var validations = _ref2.validations;

                        if (validations.get('isValid')) {
                            m.save().then(function () {
                                _this3.swal("Dine opplysninger er lagret!").then(function () {
                                    _this3.sendAction('toRoute', Ember.get(next, 'route'));
                                }, function () {});
                            });
                        } else {
                            //console.log('isInvalid');
                        }
                    });
                });
            },
            skip: function skip() {
                var next = Ember.get(this, 'wizardService').getNextRoute();
                this.sendAction('toRoute', Ember.get(next, 'route'));
            }
        }
    });
});