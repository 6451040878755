define('uwork-app-v2/helpers/with-service-fee', ['exports', 'uwork-app-v2/helpers/custom-format-currency'], function (exports, _customFormatCurrency) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.withServiceFee = withServiceFee;
    function withServiceFee(params /*, hash*/) {
        var amount = params[0];
        var fee = parseFloat(amount) * 0.10;

        return (0, _customFormatCurrency.concurrencyFormater)(parseFloat(amount) - parseFloat(fee));
    }

    exports.default = Ember.Helper.helper(withServiceFee);
});