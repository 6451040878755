define('uwork-app-v2/mixins/query-filters-mixin', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Mixin.create({
        results: [],
        loading: false,
        page: false,

        categories: null,
        titles: null,
        tags: null,
        query: null,
        skills: null,
        stage: null,
        sorting: null,
        place: null,
        location: null,
        radius: null,
        number: 1,
        gender: null,
        size: 20,
        month: null,
        year: null,
        shift: null,
        contractee: null,
        contractor: null,
        have_car: null,

        queryParams: ['categories', 'contractee', 'contractor', 'page', 'query', 'month', 'shift', 'gender', 'skills', 'sorting', 'stage', 'titles', 'year', 'have_car']
    });
});