define('uwork-app-v2/components/contractor/profile/display-not-completed-notification/component', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        account: Ember.inject.service('user/session-account'),
        user: Ember.computed.alias('account.currentUser')
    });
});