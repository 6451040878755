define('uwork-app-v2/contractee/my-workers/order/new/route', ['exports', 'ember-i18n'], function (exports, _emberI18n) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        title: (0, _emberI18n.translationMacro)('assignment.new.pageName'),
        breadCrumb: {},
        queryParams: {
            contractors: {
                replace: true
            }
        },
        beforeModel: function beforeModel() {
            this.setLoading(true);
        },
        afterModel: function afterModel() {
            var title = this.get('title');

            var bTitle = {
                title: title.string
            };
            this.setLoading(false);
            Ember.set(this, 'breadCrumb', bTitle);
        },
        model: function model(params) {
            var ids = params.contractors.split(',');

            return Ember.RSVP.hash({
                shifts: this.store.findAll('shift'),
                templates: this.store.findAll('order-desc-template'),
                workers: this.store.query('my-worker', { ids: ids })
            });
        },
        setupController: function setupController(controller, model) {
            this._super(controller, model);

            this.controller._changeset();
        }
    });
});