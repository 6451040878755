define('uwork-app-v2/contractor/vacancy/view/controller', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        vacancy: Ember.computed.alias('model'),
        contractee: Ember.computed.alias('vacancy.contractee'),
        selected: Ember.computed.alias('vacancy.selected'),
        applied: Ember.computed.alias('vacancy.applied'),
        visits: Ember.computed.alias('vacancy.visits')
    });
});