define('uwork-app-v2/contractee/vacancy/view/controller', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        vacancy: Ember.computed.alias('model'),
        //conversations: alias('model.conversations'),
        vacancyPlan: Ember.computed.alias('model.vacancyPlan'),
        contractee: Ember.computed.alias('model.contractee'),
        applicants: Ember.computed.alias('model.applicants'),
        applied: Ember.computed.alias('model.applied'),
        selected: Ember.computed.alias('model.selected'),
        hired: Ember.computed.alias('model.hired'),
        selectedApplicants: Ember.computed.alias('model.selectedApplicants'),
        //notSelectedApplicants: alias('model.notSelectedApplicants'),


        actions: {
            toRoute: function toRoute() {
                var vacancy = Ember.get(this, 'vacancy');
                this.transitionToRoute('contractee.vacancy.select-applicants', vacancy.get('id'));
            }
        }
    });
});