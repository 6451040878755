define('uwork-app-v2/components/custom/edit-form/component', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        tagName: 'buttton',
        tr: false,
        classNames: ['btn-edit'],
        classNameBindings: ['edit:red-button', 'tr:btn-edit-top-right'],
        click: function click() {
            this.sendAction('editForm');
        }
    });
});