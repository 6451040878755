define('uwork-app-v2/components/bs/icon-preview/component', ['exports', 'uwork-app-v2/mixins/mixin-icons'], function (exports, _mixinIcons) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_mixinIcons.default, {
        classNames: ['flex-item'],
        click: function click() {
            var icon = Ember.get(this, 'icon.name');
            var model = Ember.get(this, 'model');
            var key = Ember.get(this, 'key');

            Ember.set(model, key, icon);
        }
    });
});