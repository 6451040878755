define('uwork-app-v2/components/admin/sidebar/nav-sidebar/component', ['exports', 'uwork-app-v2/config/environment'], function (exports, _environment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        classNames: ['admin-menu'],
        menu: Ember.inject.service('extend-menu'),
        route: Ember.inject.service('-routing'),
        media: Ember.inject.service(),
        session: Ember.inject.service(),
        tagName: 'ul',
        closeMenu: function closeMenu() {
            var media = Ember.get(this, 'media');
            if (media.get('isXs') || media.get('isSm') || media.get('isMd')) {

                var menu = Ember.get(this, 'menu.menu');
                menu.publicActions.closeSubNavigation();

                if (Ember.$('#navbarToggleExternalContent').hasClass('show')) {
                    Ember.$('#navbarToggleExternalContent').removeClass('show');
                }
            }
        },

        redirect: Ember.computed(function () {
            var url = _environment.default.APP.API_HOST + '/horizon';
            var token = Ember.get(this, 'session.data.authenticated.token');

            return url + '?token=' + token;
        }),
        actions: {
            closeMenu: function closeMenu() {
                this.closeMenu();
            }
        }
    });
});