define('uwork-app-v2/components/contractor/settings/shift-travel/display-main/component', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        session: Ember.inject.service(),
        account: Ember.inject.service('user/session-account'),
        user: Ember.computed.alias('account.currentUser'),
        contractor: Ember.computed.alias('user.contractor'),
        store: Ember.inject.service(),
        intercom: Ember.inject.service(),
        isEditable: Ember.computed.oneWay('user.canNotEditProfile'),
        userCategories: Ember.computed.alias('user.categories'),
        isWizard: Ember.computed.equal('user.isSignUpCompleted', false),
        isNotWizard: Ember.computed.equal('user.isSignUpCompleted', true),
        wizardService: Ember.inject.service('contractor/wizard'),
        didValidate: false,

        classNameBindings: ['isWizard:transition-panel'],

        travelDistanceOptions: [{ label: '15 min', value: 0.25 }, { label: '30 min', value: 0.50 }, { label: '45 min', value: 0.70 }, { label: '1 time', value: 1 }, { label: '2 timer', value: 2 }, { label: '3 timer', value: 3 }, { label: '4 timer', value: 4 }, { label: '5 timer', value: 5 }, { label: '6 timer', value: 6 }, { label: '7 timer', value: 7 }],

        shiftsUser: Ember.computed('shifts.[]', 'user.shifts.[]', function () {
            //let user = get(this, 'user');
            var model = Ember.get(this, 'shifts');
            var _userShifts = Ember.get(this, 'user.shifts');
            if (_userShifts !== undefined) {
                if (model) {
                    model.forEach(function (shift) {
                        var id = Ember.get(shift, 'id');

                        var _shift = _userShifts.findBy('id', id);
                        if (_shift) {
                            Ember.set(shift, 'checked', true);
                        } else {
                            Ember.set(shift, 'checked', false);
                        }
                    });
                }
                return model;
            }
            return null;
        }),

        addShiftsToUser: function addShiftsToUser(user) {
            var shifts = Ember.get(this, 'shifts');

            var shiftsChecked = shifts.filterBy('checked', true);
            var shiftsUnChecked = shifts.filterBy('checked', false);

            user.get('shifts').pushObjects(shiftsChecked);
            user.get('shifts').removeObjects(shiftsUnChecked);
        },


        actions: {
            save: function save(model) {
                var _this = this;

                // Get user from session
                var user = Ember.get(this, 'user');

                // Add shifts to user
                this.addShiftsToUser(user);

                // Set didValidate
                Ember.set(this, 'didValidate', true);

                // Validate user and check shifts
                user.validate().then(function (_ref) {
                    var m = _ref.m,
                        validations = _ref.validations;


                    // Check if its validated
                    if (validations.get('isValid')) {
                        _this.setLoading(true);
                        user.save().then(function () {

                            // Solve contractor promise
                            model.then(function (m) {

                                // Save contractor
                                m.save().then(function () {
                                    _this.setLoading(false);

                                    _this.swal({
                                        title: "Dine opplysninger er lagret!",
                                        timer: 1000,
                                        showConfirmButton: false,
                                        showCloseButton: false,
                                        type: 'success'
                                    });
                                });
                            });
                        });
                    } else {
                        _this.swal({
                            title: "Vakttype",
                            text: "Minst en vakttype må være valgt.",
                            type: "error",
                            timer: 1000,
                            showConfirmButton: false,
                            showCloseButton: false
                        }).then(function () {}, function () {});
                    }
                });
            },
            saveNext: function saveNext(model) {
                var _this2 = this;

                // Get next route
                var next = Ember.get(this, 'wizardService').getNextRoute();

                // Get user from session
                var user = Ember.get(this, 'user');

                // Set didValidate
                Ember.set(this, 'didValidate', true);

                // Add shifts to user
                this.addShiftsToUser(user);

                // Validate user and check shifts
                user.validate({ on: ['shifts'] }).then(function (_ref2) {
                    var m = _ref2.m,
                        validations = _ref2.validations;


                    // Check if its validated
                    if (validations.get('isValid')) {
                        _this2.setLoading(true);
                        user.save().then(function () {

                            // Solve contractor promise
                            model.then(function (m) {

                                // Save contractor
                                m.save().then(function () {
                                    _this2.setLoading(false);
                                    _this2.swal({
                                        title: "Dine opplysninger er lagret!",
                                        timer: 1000,
                                        showConfirmButton: false,
                                        showCloseButton: false,
                                        type: 'success'
                                    }).then(function () {
                                        _this2.sendAction('toRoute', Ember.get(next, 'route'));
                                    }, function () {
                                        _this2.sendAction('toRoute', Ember.get(next, 'route'));
                                    });
                                });
                            });
                        });
                    } else {
                        //console.log('Invalid (component/contractor/settings/shift-travel/display-main:saveNext)');
                    }
                });
            },
            skip: function skip() {
                var next = Ember.get(this, 'wizardService').getNextRoute();

                this.sendAction('toRoute', Ember.get(next, 'route'));
            }
        }
    });
});