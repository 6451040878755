define('uwork-app-v2/components/admin/contractor/edit-avatar/component', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        session: Ember.inject.service(),
        store: Ember.inject.service(),
        avatarIsVisible: false,
        isUploading: false,
        uploadProgress: 0,

        actions: {
            onFilesChange: function onFilesChange(files, uploader, component) {
                var _this = this;

                this.setLoading(true);
                var store = Ember.get(this, 'store');

                if (files) {
                    var file = files[0];

                    Ember.set(this, 'isUploading', true);

                    uploader.upload(file).then(function (result) {
                        store.pushPayload(Ember.get(component, 'modelType'), result);
                    });

                    uploader.on('progress', function (e) {
                        Ember.set(_this, 'uploadProgress', e.percent.toFixed(0));
                    });

                    uploader.on('didUpload', function (e) {
                        Ember.set(_this, 'uploadProgress', 0);
                        Ember.set(_this, 'isUploading', false);
                        _this.setLoading(false);
                    });
                }
            },
            selectAvatar: function selectAvatar() {
                Ember.set(this, 'avatarIsVisible', true);
            }
        }
    });
});