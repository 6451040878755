define('uwork-app-v2/conversation/model', ['exports', 'ember-data/model', 'ember-data/attr', 'moment', 'ember-data/relationships'], function (exports, _model, _attr, _moment, _relationships) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _model.default.extend({
        account: Ember.inject.service('user/session-account'),

        createdAt: (0, _attr.default)('mdate'),
        updatedAt: (0, _attr.default)('mdate'),
        messages: (0, _relationships.hasMany)('message', { async: false }),
        participants: (0, _relationships.hasMany)('participant'),
        //receiver: belongsTo('participant', {inverse: 'user'}),

        order: (0, _relationships.belongsTo)('order'),
        vacancy: (0, _relationships.belongsTo)('vacancy'),

        isVacancy: Ember.computed.notEmpty('vacancy'),
        isOrder: Ember.computed.notEmpty('order'),

        title: Ember.computed('order', function () {
            return '#' + Ember.get(this, 'order.id');
        }),

        currentUserId: Ember.computed.alias('account.currentUser.id'),
        _interlocutor: null,
        _me: null,

        interlocutor: Ember.computed('participants.[]', function () {
            return Ember.get(this, '_interlocutor') ? Ember.get(this, '_interlocutor') : this.getInterlocutors('_interlocutor');
        }),

        me: Ember.computed('participants.[]', function () {
            return Ember.get(this, '_me') ? Ember.get(this, '_me') : this.getInterlocutors('_me');
        }),

        isUnread: Ember.computed('updatedAt', 'participants.[]', 'messages.[]', function () {
            var me = Ember.get(this, 'me');

            if (me === null) {
                return false;
            }

            var lastRead = Ember.get(me, 'lastRead');
            var updatedAt = Ember.get(this, 'updatedAt');
            if (lastRead == null || (0, _moment.default)(lastRead).isBefore(updatedAt)) {
                return true;
            }

            return false;
        }),

        lastMessage: Ember.computed.alias('messages.lastObject'),

        getInterlocutors: function getInterlocutors(whom) {
            var self = this;
            var participants = Ember.get(this, 'participants');
            var currentUserId = Ember.get(this, 'currentUserId');

            participants.forEach(function (participant) {
                if (Ember.get(participant, 'user.id') == currentUserId) {
                    Ember.set(self, '_me', participant);
                } else {
                    Ember.set(self, '_interlocutor', participant);
                }
            });

            return Ember.get(this, whom);
        }
    });
});