define('uwork-app-v2/components/order-list/contractor-order-item/component', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        classNames: ['row', 'order'],
        session: Ember.inject.service(),
        store: Ember.inject.service(),
        appService: Ember.inject.service('application'),
        account: Ember.inject.service('user/session-account'),
        user: Ember.computed.alias('account.currentUser'),
        userRequestStatus: Ember.observer('model.orderRequests', function () {
            var orderRequests = Ember.get(this, 'model.orderRequests');
            var user = Ember.get(this, 'user');

            var request = orderRequests.findBy('user.id', user.get('id'));
            if (request) {
                request.set('order', 'isWaiting', true);
            }
            //return true;
        })
    });
});