define('uwork-app-v2/components/contractee/settings/notifications-form/component', ['exports', 'uwork-app-v2/substitute/model'], function (exports, _model) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        ROLE_NAME_SUBSTITUTE: _model.ROLE_NAME,
        classNames: ['notification-settings-item']
    });
});