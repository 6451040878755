define('uwork-app-v2/components/app/file-placeholder/component', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        tagName: 'span',
        classNames: ['custom-file-control'],
        attributeBindings: ['data-content'],
        dataContent: Ember.observer('data-content', function () {
            var _this = this.$();
            var content = Ember.get(this, 'data-content');

            if (content) {
                _this.attr('data-content', content);
            }
        })
    });
});