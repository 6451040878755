define('uwork-app-v2/validators/date-or-current', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = dateOrCurrent;
    function dateOrCurrent() {
        return function (key, newValue, oldValue, changes, model) {

            if (!key) {
                return true;
            }

            if (key === 'ended') {
                if (newValue) {
                    changes.current = '';
                    //description: 'Til dato'
                    return true;
                }

                return 'Feltet må fylles ut.';
            }

            if (key === 'current') {
                if (newValue) {
                    changes.ended = '';
                    return true;
                }

                return 'Feltet må fylles ut.';
            }
        };
    }
});