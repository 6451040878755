define('uwork-app-v2/components/contractor/settings/categories/display-main/component', ['exports', 'ember-local-storage'], function (exports, _emberLocalStorage) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        session: Ember.inject.service(),
        account: Ember.inject.service('user/session-account'),
        user: Ember.computed.alias('account.currentUser'),
        store: Ember.inject.service(),
        intercom: Ember.inject.service(),
        isEditable: Ember.computed.oneWay('user.canNotEditProfile'),
        userCategories: Ember.computed.alias('user.categories'),
        isWizard: Ember.computed.equal('user.isSignUpCompleted', false),
        wizardStep: (0, _emberLocalStorage.storageFor)('contractor/wizard'),
        categoriesUser: Ember.computed('categories.[]', 'user.categoryPivot.[]', 'user.categories.[]', function () {
            var pivot = Ember.get(this, 'user.categoryPivot');
            var model = Ember.get(this, 'categories');
            if (pivot !== undefined) {
                if (model) {
                    model.forEach(function (category) {
                        var id = Ember.get(category, 'id');

                        var _pivot = pivot.findBy('category_id', parseInt(id));

                        if (_pivot) {
                            Ember.set(category, '_pivot', _pivot);
                            Ember.set(category, 'type', _pivot.get('type'));

                            if (Ember.get(category, 'checked') === undefined) {
                                Ember.set(category, 'checked', true);
                            }
                        }
                    });
                }
                return model;
            }
            return null;
        }),
        shiftsUser: Ember.computed('shifts.[]', 'user.shifts.[]', function () {
            //let user = get(this, 'user');
            var model = Ember.get(this, 'shifts');
            var _userShifts = Ember.get(this, 'user.shifts');
            if (_userShifts !== undefined) {
                if (model) {
                    model.forEach(function (shift) {
                        var id = Ember.get(shift, 'id');

                        var _shift = _userShifts.findBy('id', parseInt(id));
                        if (_shift) {
                            Ember.set(shift, 'checked', true);
                        }
                    });
                }
                return model;
            }
            return null;
        }),
        addShiftsToUser: function addShiftsToUser(user) {

            var shifts = Ember.get(this, 'shifts');

            var shiftsChecked = shifts.filterBy('checked', true);
            var shiftsUnChecked = shifts.filterBy('checked', false);

            user.get('shifts').pushObjects(shiftsChecked);
            user.get('shifts').removeObjects(shiftsUnChecked);
        },
        save: function save() {
            var _this2 = this;

            var user = Ember.get(this, 'user');
            this.addShiftsToUser(user);
            this.setLoading(true);
            return user.save().then(function () {
                _this2.swal('Lagret!');
                // new Notification('Lagret!', 'success');
                _this2.setLoading(false);
            });
        },

        actions: {
            save: function save() {
                var userCategories = Ember.get(this, 'userCategories');
                if (Ember.isEmpty(userCategories)) {
                    this.swal('Please select at least one category', 'error');
                    // new Notification('Please select at least one category', 'error');
                } else {
                    this.save();
                }
            },
            saveNext: function saveNext() {
                var _this3 = this;

                var _this = this;
                _this.swal({
                    title: "Dine opplysninger er lagret!"
                }).then(function () {

                    var user = Ember.get(_this3, 'user');
                    _this3.addShiftsToUser(user);
                    _this3.setLoading(true);

                    user.save().then(function () {
                        // new Notification('Lagret!', 'success');
                        _this3.setLoading(false);
                        var step = parseInt(Ember.get(_this3, 'wizardStep.step'));
                        if (step === 5) {
                            _this3.set('wizardStep.step', 6);
                        }
                        _this3.sendAction('toRoute', 'contractor.settings.education');
                    });

                    // this.save().then( () => {
                    //     let step = parseInt(get(this, 'wizardStep.step'));
                    //     if (step === 5) {
                    //         this.set('wizardStep.step', 6);
                    //     }
                    //     this.sendAction('toRoute', 'contractor.settings.education');
                    //
                    // });
                }, function () {});
            },
            skip: function skip() {
                var step = parseInt(Ember.get(this, 'wizardStep.step'));
                if (step === 5) {
                    this.set('wizardStep.step', 6);
                }

                this.sendAction('toRoute', 'contractor.settings.education');
            },
            updateType: function updateType(category, type) {
                category.set('type', type);
                //let user = get(this,'user');
                var _pivot = category.get('_pivot');
                _pivot.set('type', type);
                //_pivot.set('user', user);

                this.setLoading(true);
                var _this = this;

                // _pivot.save(function () {
                //     new Notification('Yrke Updated!');
                //     this.setLoading(false);
                //     console.log('tets')
                // });

                _pivot.save().then(function () {
                    this.swal('Yrke Updated!');
                    // new Notification('Yrke Updated!');
                    _this.setLoading(false);
                });
            },
            updateCategory: function updateCategory(category) {
                var self = this;
                var store = Ember.get(self, 'store');
                var user = Ember.get(self, 'user');
                var userPivot = Ember.get(self, 'user.categoryPivot');

                if (category.get('checked')) {

                    Ember.set(category, 'checked', false);
                    var pivot = userPivot.findBy('category_id', parseInt(category.id));

                    user.get('categories').removeObject(category);
                    if (pivot) {
                        pivot.destroyRecord().then(function () {
                            this.swal('Yrke fjernet fra din profil.');
                            // new Notification('Yrke fjernet fra din profil.');
                        }, function () {
                            this.swal('Kunne ikke lagre!', 'error');
                            // new Notification('Kunne ikke lagre!', 'error');
                        });

                        category.set('_pivot', null);
                    }
                } else {
                    Ember.set(category, 'type', 1);
                    Ember.set(category, 'checked', true);

                    var _pivot2 = store.createRecord('category-pivot', { category_id: Ember.get(category, 'id'), type: 1, user_id: user });
                    _pivot2.save().then(function () {
                        this.swal('Yrke valgt');
                        // new Notification('Yrke valgt ');
                        Ember.set(category, '_pivot', _pivot2);
                        user.get('categories').addObject(category);
                    }, function () {
                        this.swal('Kunne ikke lagre!', 'error');
                    });
                }
            },
            startIntercom: function startIntercom() {
                this.get('intercom.api')('show');
            }
        }
    });
});