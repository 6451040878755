define('uwork-app-v2/initializers/ember-intercom', ['exports', 'ember-intercom-io/initializers/ember-intercom'], function (exports, _emberIntercom) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _emberIntercom.default;
    }
  });
  Object.defineProperty(exports, 'initialize', {
    enumerable: true,
    get: function () {
      return _emberIntercom.initialize;
    }
  });
});