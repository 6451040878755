define('uwork-app-v2/components/conversations/conversations-item/component', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        tagName: 'div',
        classNames: ['subnav-content-item', 'flex-column', 'align-items-start'],
        classNameBindings: ['isUnread:unread'],
        me: Ember.computed.alias('model.me'),
        interlocutor: Ember.computed.alias('model.interlocutor'),
        isUnread: Ember.computed.alias('model.isUnread'),
        lastMessage: Ember.computed.alias('model.lastMessage'),
        menu: Ember.inject.service('extend-menu'),
        conversation: Ember.inject.service(),
        store: Ember.inject.service(),
        click: function click() {
            var menu = Ember.get(this, 'menu.menu');

            var model = Ember.get(this, 'model');
            var conversation = Ember.get(this, 'conversation');

            //Make fake show
            Ember.get(this, 'store').findRecord('conversation', model.get('id'));

            conversation.set('conversation', model);

            menu.publicActions.showSubNavigation('conversation');
        },

        description: Ember.computed('model.lastMessage.message', function () {
            var limit = 80;
            var text = Ember.get(this, 'model.lastMessage.message');
            if (text) {

                if (text.length > limit) {
                    text = text.substr(0, limit - 3) + "...";
                }
                return text;
            }
        }),
        actions: {
            closeMenu: function closeMenu() {
                this.closeMenu();
            }
        }
    });
});