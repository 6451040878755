define('uwork-app-v2/components/contractor/assignment/assignment-details/component', ['exports', 'uwork-app-v2/mixins/contractor/is-my-order'], function (exports, _isMyOrder) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_isMyOrder.default, {
        classNames: ['card', 'mb-4'],
        isViews: Ember.computed('order.views', function () {
            return Ember.get(this, 'order.views') > 1 && Ember.get(this, 'order.views') < 6;
        }),
        checkForFiles: Ember.computed('order.gcc', 'order.nda', function () {
            return Ember.get(this, 'order.gcc') || Ember.get(this, 'order.nda') ? true : false;
        })
    });
});