define('uwork-app-v2/auth/reset/route', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        model: function model(params) {
            Ember.set(this, '_params', params);
            return true;
        },
        beforeModel: function beforeModel() {
            this.setLoading(true);
        },
        afterModel: function afterModel() {
            this.setLoading(false);
        },
        setupController: function setupController(controller, model) {
            this._super(controller, model);

            Ember.set(controller, '_params', Ember.get(this, '_params'));
        }
    });
});