define('uwork-app-v2/contractee/assignments/completed/controller', ['exports', 'ember-cli-jsonapi-pagination/mixins/controllers/jsonapi-pagination', 'moment'], function (exports, _jsonapiPagination, _moment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend(_jsonapiPagination.default, {
        media: Ember.inject.service(),
        account: Ember.inject.service('user/session-account'),
        user: Ember.computed.alias('account.currentUser'),

        queryParams: ['status', 'to_date'],
        status: '2',
        to_date: Ember.computed('size', 'number', 'model', function () {
            return '<' + (0, _moment.default)().format();
        }),
        totalPages: Ember.computed('size', 'number', 'model', function () {
            return Ember.get(this, 'model.meta.pagination.total_pages');
        }),

        recentOrders: Ember.computed.sort('model', 'sortDesc'),
        filteredOrders: Ember.computed.filter('recentOrders', function (item, index, array) {
            return array.indexOf(item) < 3;
        }),
        outerWindow: Ember.computed('media.isXs', 'media.isSm', 'media.isMd', 'media.isLg', 'media.isXl', function () {
            var media = Ember.get(this, 'media');

            if (media.get('isXl') || media.get('isSm')) {
                return 1;
            } else {
                return 3;
            }
        })
    });
});