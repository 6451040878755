define('uwork-app-v2/components/admin/order-desc-template-item/component', ['exports', 'uwork-app-v2/mixins/default-ds-action-mixin', 'uwork-app-v2/components/admin/order-desc-template-item/validation', 'uwork-app-v2/order-desc-template/model'], function (exports, _defaultDsActionMixin, _validation, _model) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_defaultDsActionMixin.default, {
        validation: _validation.default,
        schema: _model.schema,

        modelType: "order-desc-template",
        messageSave: "Mal oppdatert.",
        messageDelete: "Mal slettet."
    });
});