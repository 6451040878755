define('uwork-app-v2/components/file/avatar-video/component', ['exports', 'ember-uploader', 'uwork-app-v2/config/environment'], function (exports, _emberUploader, _environment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberUploader.default.FileField.extend({
        session: Ember.inject.service('session'),
        modelType: 'video',

        didRender: function didRender() {
            if (Ember.get(this, 'isVisible')) {
                this.$().trigger('click');
            }

            Ember.set(this, 'isVisible', false);
        },


        filesDidChange: function filesDidChange(files) {
            var token = Ember.get(this, 'session.data.authenticated.token');
            var uploader = _emberUploader.default.Uploader.create({
                url: _environment.default.APP.API_UPLOAD_VIDEO,
                ajaxSettings: {
                    headers: {
                        'Authorization': 'Bearer ' + token
                    }
                }
            });

            this.sendAction('onFilesChange', files, uploader, this);
        }
    });
});