define('uwork-app-v2/bookmark/model', ['exports', 'ember-data/model', 'ember-data/attr', 'ember-data/relationships'], function (exports, _model, _attr, _relationships) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _model.default.extend({
        createdAt: (0, _attr.default)(),

        //Relations
        user: (0, _relationships.belongsTo)('user'),
        saver: (0, _relationships.belongsTo)('user')
    });
});