define('uwork-app-v2/helpers/file-type', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.fileType = fileType;
    function fileType(params /*, hash*/) {
        var file = params[0];

        var result = file.split('.').pop();
        if (result == 'jpg' || result == 'png') {
            return 'image';
        }
        return file.split('.').pop();
    }

    exports.default = Ember.Helper.helper(fileType);
});