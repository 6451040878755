define('uwork-app-v2/components/admin/contractee/edit-form/component', ['exports', 'uwork-app-v2/components/admin/contractee/edit-form/validation', 'uwork-app-v2/contractee/model', 'uwork-app-v2/mixins/pivot-mixin'], function (exports, _validation, _model, _pivotMixin) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var keys = Object.keys;
    exports.default = Ember.Component.extend(_pivotMixin.default, {
        classNames: ['bg-white'],
        session: Ember.inject.service(),
        store: Ember.inject.service(),
        validation: _validation.default,
        schema: _model.schema,
        modelType: 'user',
        messageSave: 'Profilen oppdatert.',
        messageDelete: 'Profilen slettet.',
        user: Ember.computed.alias('model.user'),

        actions: {
            updateByKey: function updateByKey(changeset, key, value) {
                if (value) {
                    Ember.set(changeset, key, value);
                }
            },
            save: function save(changeset) {
                var _this = this;

                var snapshot = changeset.snapshot();
                var user = Ember.get(changeset, 'user.content');

                return changeset.cast(keys(Ember.get(this, 'schema'))).validate().then(function () {
                    //console.log('validating ...');
                    if (Ember.get(changeset, 'isValid')) {
                        //this.setLoading(true);
                        changeset.save().then(function () {
                            _this.swal('Dine opplysninger er lagret!');

                            user.save();
                        }).catch(function () {
                            Ember.get(_this, 'user.errors').forEach(function (_ref) {
                                var attribute = _ref.attribute,
                                    message = _ref.message;

                                changeset.pushErrors(attribute, message);
                            });
                        });
                    } else {
                        //console.log('not valid!');
                    }
                }).catch(function () {
                    //console.log('not validated!');
                    changeset.restore(snapshot);
                });
            }
        }
    });
});