define('uwork-app-v2/helpers/order-status-name', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.shiftIcons = shiftIcons;
    function shiftIcons(params) {

        var id = params[0];
        var object = params[1];

        var icons = [{ id: 1, name: 'Døgnvakt', color: 'bg-invers' }, { id: 2, name: 'Dagvakt', color: 'bg-warning' }, { id: 3, name: 'Kveldsvakt', color: 'bg-primary' }, { id: 4, name: 'Nattvakt', color: 'bg-danger' }];
        var level = icons.findBy('id', id);

        if (level) {
            return Ember.get(level, object);
        }
        return '';
    }

    exports.default = Ember.Helper.helper(shiftIcons);
});