define('uwork-app-v2/admin/list/contractees/route', ['exports', 'ember-i18n', 'ember-cli-jsonapi-pagination/mixins/routes/jsonapi-pagination'], function (exports, _emberI18n, _jsonapiPagination) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend(_jsonapiPagination.default, {
        title: (0, _emberI18n.translationMacro)('admin.users.contractee.PageName'),
        breadCrumb: {},

        queryParams: {
            query: { replace: true },
            stage: { replace: true },
            sorting: { replace: true },
            categories: { replace: true },
            number: { replace: true }
        },

        model: function model() {
            return Ember.RSVP.hash({
                categories: this.store.findAll('category')
            });
        },
        setupController: function setupController(controller, model) {
            // Call _super for default behavior
            this._super(controller, model);
            // Implement your custom setup after

            Ember.get(controller, 'search').perform();
        },
        beforeModel: function beforeModel() {
            this.setLoading(true);
        },
        afterModel: function afterModel() {
            var title = this.get('title');

            var bTitle = {
                title: title.string
            };
            this.setLoading(false);
            Ember.set(this, 'breadCrumb', bTitle);
        }
    });
});