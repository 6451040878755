define('uwork-app-v2/mixins/auth/social', ['exports', 'uwork-app-v2/config/environment'], function (exports, _environment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Mixin.create({
        ajax: Ember.inject.service(),
        session: Ember.inject.service(),
        auth: function auth(data) {
            var ajax = Ember.get(this, 'ajax');
            var method = 'GET';
            var url = '' + _environment.default.APP.API_HOST + _environment.default.APP.API_NAMESPACE + '/socials';

            return ajax.request(url, {
                method: method,
                data: data
            });
        }
    });
});