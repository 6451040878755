define('uwork-app-v2/components/cp/city-by-zipcode/component', ['exports', 'uwork-app-v2/mixins/city-by-zipcode-mixin'], function (exports, _cityByZipcodeMixin) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_cityByZipcodeMixin.default, {

        tagName: 'div',
        model: null,
        value: null,
        valuePath: '',
        cityholder: '',
        validation: null,
        showValidations: false,
        promptIsSelectable: false,
        showLabel: true,
        didValidate: false,

        inputClass: 'form-control',
        labelClass: '',
        required: false,

        hasContent: Ember.computed.notEmpty('value').readOnly(),
        hasError: Ember.computed.and('shouldDisplayValidations', 'isInvalid').readOnly(),
        isValid: Ember.computed.alias('validation.isTruelyValid').readOnly(),
        isInvalid: Ember.computed.alias('validation.isInvalid').readOnly(),
        shouldDisplayValidations: Ember.computed.or('hasContent', 'didValidate').readOnly(),

        init: function init() {
            this._super.apply(this, arguments);
            var valuePath = this.get('valuePath');

            Ember.defineProperty(this, 'validation', Ember.computed.readOnly('model.validations.attrs.' + valuePath));
            Ember.defineProperty(this, 'value', Ember.computed.alias('model.' + valuePath));
        },
        filterModel: function filterModel(text) {
            var zipCodes = Ember.get(this, 'zipCodes');
            var selected = zipCodes.findBy('zipCode', text);

            if (selected) {
                this.setModel(selected);
            } else {
                Ember.set(this, 'model.place', '');
                Ember.set(this, 'didValidate', true);
            }
        },
        setModel: function setModel(selected) {

            var model = Ember.get(this, 'model');
            Ember.set(model, 'place', selected.city);
            Ember.set(model, 'zipCode', selected.zipCode);

            model.notifyPropertyChange('zipCode');
        },

        actions: {
            selectCity: function selectCity(text) {
                //basic input
                if (text.length === 4) {
                    Ember.set(this, 'model.place', '');
                    this.filterModel(text);
                } else if (text.length < 4) {
                    Ember.set(this, 'model.zipCode', text);
                    Ember.get(this, 'model').notifyPropertyChange('zipCode');
                }
            }
        }
    });
});