define('uwork-app-v2/components/contractor/settings/payment/display-main/validation', ['exports', 'ember-changeset-validations/validators'], function (exports, _validators) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = {
        //name: validatePresence({ presence: true, description: 'Bedriftsnavn' }),
        // businessNumber: validatePresence({ presence: true, description: 'Foretaksnummer' }),
        bankAccountNumber: (0, _validators.validatePresence)({ presence: true, description: 'Kontonummer' })
    };
});