define('uwork-app-v2/calendar/model', ['exports', 'ember-data/model', 'ember-data/attr', 'ember-data/relationships', 'moment'], function (exports, _model, _attr, _relationships, _moment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    //@ToDo - Delete
    var Calendar = _model.default.extend({
        session: Ember.inject.service(),
        account: Ember.inject.service('user/session-account'),
        user: Ember.computed.alias('account.currentUser'),

        fromDate: (0, _attr.default)(),
        toDate: (0, _attr.default)(),

        order: (0, _relationships.belongsTo)('order'),
        title: Ember.computed.alias('order.id'),
        shift: (0, _relationships.belongsTo)('shift'),

        content: Ember.computed('user', 'order', function () {
            var user = Ember.get(this, 'user');

            if (Ember.get(user, 'isContractor')) {
                return Ember.get(this, 'order.contractee.name');
            } else {
                return Ember.get(this, 'order.contractor.user.fullName');
            }
        }),
        img: Ember.computed('user', 'order', function () {
            var user = Ember.get(this, 'user');

            if (Ember.get(user, 'isContractor')) {
                return Ember.get(this, 'order.contractee.user.avatarUrl');
            } else {
                return Ember.get(this, 'order.contractor.user.avatarUrl');
            }
        }),

        start: Ember.computed('fromDate', function () {
            var fromDate = Ember.get(this, 'fromDate');

            return (0, _moment.default)(fromDate).format('YYYY-MM-DDTHH:mm:ss');
        }),
        end: Ember.computed('toDate', function () {
            var toDate = Ember.get(this, 'toDate');
            return (0, _moment.default)(toDate).format('YYYY-MM-DDTHH:mm:ss');
        })

    });

    exports.default = Calendar;
});