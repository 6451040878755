define('uwork-app-v2/components/admin/contractor/edit-form/component', ['exports', 'uwork-app-v2/components/admin/contractor/edit-form/validation', 'uwork-app-v2/contractor/model', 'uwork-app-v2/mixins/pivot-mixin', 'uwork-app-v2/mixins/cities-mixin', 'ember-changeset-validations', 'ember-changeset'], function (exports, _validation, _model, _pivotMixin, _citiesMixin, _emberChangesetValidations, _emberChangeset) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var keys = Object.keys;

    var TYPE_FULL_TIME = 1;
    var TYPE_PART_TIME = 20;
    var TYPE_CONSULTANT = 30;

    exports.default = Ember.Component.extend(_pivotMixin.default, _citiesMixin.default, {

        classNames: ['bg-white'],
        session: Ember.inject.service(),
        store: Ember.inject.service(),
        validation: _validation.default,
        schema: _model.schema,
        changeset: null,
        modelType: 'user',
        messageSave: 'Profilen oppdatert.',
        messageDelete: 'Profilen slettet.',
        //user: alias('model.user'),

        isTypeFullTime: Ember.computed.equal('changeset.agreementType', TYPE_FULL_TIME),
        isTypePartTime: Ember.computed.equal('changeset.agreementType', TYPE_PART_TIME),
        isTypeConsultant: Ember.computed.equal('changeset.agreementType', TYPE_CONSULTANT),

        init: function init() {
            this._super.apply(this, arguments);
            var model = Ember.get(this, 'model');

            Ember.set(this, 'changeset', new _emberChangeset.default(Ember.get(this, 'model'), (0, _emberChangesetValidations.default)(_validation.default), _validation.default));
        },

        travelDistanceOptions: [{ label: '15 min', value: 0.25 }, { label: '30 min', value: 0.50 }, { label: '45 min', value: 0.70 }, { label: '1 time', value: 1 }, { label: '2 timer', value: 2 }, { label: '3 timer', value: 3 }, { label: '4 timer', value: 4 }, { label: '5 timer', value: 5 }, { label: '6 timer', value: 6 }, { label: '7 timer', value: 7 }],

        shiftsUser: Ember.computed('shifts.[]', 'model.user.shifts.[]', function () {
            //let user = get(this, 'user');
            var model = Ember.get(this, 'shifts');
            var _userShifts = Ember.get(this, 'model.user.shifts');

            if (_userShifts !== undefined) {
                if (model) {
                    model.forEach(function (shift) {
                        var id = Ember.get(shift, 'id');

                        var _shift = _userShifts.findBy('id', id);
                        if (_shift) {
                            Ember.set(shift, 'checked', true);
                        } else {
                            Ember.set(shift, 'checked', false);
                        }
                    });
                }
                return model;
            }
            return false;
        }),

        categoriesUser: Ember.computed('categories.[]', 'model.user.categoryPivot.[]', 'model.user.categories.[]', 'categoryUnSkilled.[]', 'categorySkilled.[]', function () {
            var pivot = Ember.get(this, 'model.user.categoryPivot');
            var model = Ember.get(this, 'categories');

            if (pivot !== undefined) {
                if (model) {
                    model.forEach(function (category) {
                        var id = Ember.get(category, 'id');

                        var _pivot = pivot.findBy('category_id', parseInt(id));

                        if (_pivot) {
                            Ember.set(category, '_pivot', _pivot);
                            Ember.set(category, 'type', _pivot.get('type'));
                            Ember.set(category, 'checked', true);
                        } else {
                            Ember.set(category, 'checked', false);
                        }
                    });
                }
                return model;
            }
            return null;
        }),

        userHasSelectedCat: Ember.computed.filterBy('categoriesUser', 'checked', true),
        userHasNotSelectedCat: Ember.computed.filterBy('categoriesUser', 'checked', false),

        categoryUnSkilled: Ember.computed.filterBy('userHasSelectedCat', 'type', 1),
        categoryUnSkilledOptions: Ember.computed('categoryUnSkilled', function () {
            return Ember.get(this, 'categoriesUser').removeObjects('categoryUnSkilled');
        }),

        categorySkilled: Ember.computed.filterBy('userHasSelectedCat', 'type', 2),
        categorySkilledOptions: Ember.computed('categorySkilled', function () {
            return Ember.get(this, 'categoriesUser').removeObjects('categorySkilled');
        }),

        addShiftsToUser: function addShiftsToUser(user) {
            var shifts = Ember.get(this, 'shifts');

            var shiftsChecked = shifts.filterBy('checked', true);
            var shiftsUnChecked = shifts.filterBy('checked', false);

            user.get('shifts').pushObjects(shiftsChecked);
            user.get('shifts').removeObjects(shiftsUnChecked);
        },


        actions: {
            updateType: function updateType(category, type) {
                var _this = this;

                this.setLoading(true);

                category.set('type', type);
                var _pivot = category.get('_pivot');
                _pivot.set('type', type);

                _pivot.save().then(function () {
                    _this.setLoading(false);
                });
            },
            updateCategory: function updateCategory(type, categories) {
                var _this2 = this;

                var store = Ember.get(this, 'store');
                var user = Ember.get(this, 'model.user');
                var userPivot = Ember.get(user, 'categoryPivot');
                var categoryUnSkilled = Ember.get(this, 'categoryUnSkilled');
                var categorySkilled = Ember.get(this, 'categorySkilled');
                this.setLoading(true);

                if (type === 1) {

                    if (categories.get('length') > Ember.get(this, 'categoryUnSkilled').get('length')) {
                        categories.forEach(function (item) {

                            var pivot = categoryUnSkilled.findBy('id', item.get('id'));
                            var pivotExists = categorySkilled.findBy('id', item.get('id'));

                            if (pivotExists) {
                                item.set('type', type);
                                _this2.updatePivot(item, type);
                            } else if (!pivot) {
                                _this2.addPivot(item, user, type, store);
                            }
                        });
                    } else {

                        this.preResetSkills(categoryUnSkilled);
                        categoryUnSkilled.forEach(function (item) {
                            var pivot = categories.findBy('id', item.get('id'));
                            if (!pivot) {
                                var _pivot2 = userPivot.findBy('category_id', parseInt(item.get('id')));

                                _this2.removePivot(item, user, _pivot2);
                            }
                        });
                    }
                    this.resetSkills(categories, type, categoryUnSkilled);
                } else if (type === 2) {
                    if (categories.get('length') > Ember.get(this, 'categorySkilled').get('length')) {
                        categories.forEach(function (item) {
                            var pivot = categorySkilled.findBy('id', item.get('id'));
                            var pivotExists = categoryUnSkilled.findBy('id', item.get('id'));

                            if (pivotExists) {
                                item.set('type', type);
                                _this2.updatePivot(item, type);
                            } else if (!pivot) {
                                _this2.addPivot(item, user, type, store);
                            }
                        });
                    } else {
                        this.preResetSkills(categorySkilled);

                        categorySkilled.forEach(function (item) {
                            var pivot = categories.findBy('id', item.get('id'));

                            if (!pivot) {
                                var _pivot3 = userPivot.findBy('category_id', parseInt(item.get('id')));
                                _this2.removePivot(item, user, _pivot3);
                            }
                        });
                    }
                    this.resetSkills(categories, type, categorySkilled);
                }
            },
            updateByKey: function updateByKey(changeset, key, value) {

                if (value) {
                    Ember.set(changeset, key, value);
                }
            },
            save: function save(changeset) {
                var _this3 = this;

                var snapshot = changeset.snapshot();

                var user = Ember.get(changeset, 'user.content');

                this.addShiftsToUser(user);

                return changeset.cast(keys(Ember.get(this, 'schema'))).validate().then(function () {
                    //console.log('validating ...');
                    if (Ember.get(changeset, 'isValid')) {
                        _this3.setLoading(true);
                        changeset.save().then(function () {
                            // this.swal({
                            //     title: 'Dine opplysninger er lagret!',
                            //     type: "success",
                            //     timer: 1000,
                            //     showConfirmButton: false,
                            //     showCloseButton: false
                            // }).then(()=>{},()=>{});
                            //
                            // this.setLoading(false);
                            //{ on: ['shifts'] }

                            user.validate().then(function (_ref) {
                                var m = _ref.m,
                                    validations = _ref.validations;


                                // Check if its validated
                                if (validations.get('isValid')) {
                                    _this3.setLoading(true);
                                    user.save().then(function () {

                                        _this3.swal({
                                            title: 'Dine opplysninger er lagret!',
                                            type: "success",
                                            timer: 2000,
                                            showConfirmButton: false,
                                            showCloseButton: false
                                        }).then(function () {}, function () {});

                                        _this3.setLoading(false);
                                    });
                                } else {

                                    _this3.setLoading(false);

                                    _this3.swal({
                                        title: "Feil",
                                        text: "Kontroller feltene med stjerne.",
                                        type: "error",
                                        timer: 3000,
                                        confirmButtonColor: "#DD6B55",
                                        confirmButtonText: "Back to Panel"
                                    }).then(function () {}, function () {});
                                }
                            });
                        }).catch(function () {
                            Ember.get(_this3, 'user.errors').forEach(function (_ref2) {
                                var attribute = _ref2.attribute,
                                    message = _ref2.message;

                                changeset.pushErrors(attribute, message);
                            });

                            _this3.setLoading(false);
                        });
                    } else {

                        _this3.setLoading(false);

                        _this3.swal({
                            title: "Feil",
                            text: "Kontroller feltene med stjerne.",
                            type: "error",
                            timer: 3000,
                            confirmButtonColor: "#DD6B55",
                            confirmButtonText: "Back to Panel"
                        }).then(function () {}, function () {});
                    }
                }).catch(function () {
                    //console.log('not validated!');
                    changeset.restore(snapshot);
                });
            }
        }
    });
});