define('uwork-app-v2/components/contractor/settings/payment/display-main/component', ['exports', 'uwork-app-v2/components/contractor/settings/payment/display-main/validation', 'uwork-app-v2/contractor/model', 'ember-local-storage'], function (exports, _validation, _model2, _emberLocalStorage) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var keys = Object.keys;
    exports.default = Ember.Component.extend({
        validationContractor: _validation.default,
        schema: _model2.schema,
        session: Ember.inject.service(),
        account: Ember.inject.service('user/session-account'),
        user: Ember.computed.alias('account.currentUser'),
        contractor: Ember.computed.alias('user.contractor'),
        store: Ember.inject.service(),
        isWizard: Ember.computed.equal('user.isSignUpCompleted', false),
        wizardStep: (0, _emberLocalStorage.storageFor)('contractor/wizard'),
        model: Ember.computed.alias('contractor'),
        isWizardMode: Ember.computed.equal('mode', 'wizard'),
        didInsertElement: function didInsertElement() {
            var _this2 = this;

            this._super.apply(this, arguments);
            return Ember.get(this, 'user.contractor').then(function (m) {
                Ember.set(_this2, 'contractorModel', m);
            });
        },

        actions: {
            back: function back() {
                this.sendAction('toRoute', 'contractor.settings.gcc');
            },
            save: function save(changeset) {
                var _this3 = this;

                var snapshot = changeset.snapshot();
                return changeset.cast(keys(Ember.get(this, 'schema'))).validate().then(function () {
                    if (Ember.get(changeset, 'isValid')) {
                        //this.setLoading(true);
                        changeset.save().then(function () {
                            _this3.swal({
                                title: 'Dine data er nå lagret!',
                                timer: 1000,
                                showConfirmButton: false,
                                showCloseButton: false,
                                type: 'success'
                            });
                        }).catch(function () {
                            Ember.get(_this3, 'user.errors').forEach(function (_ref) {
                                var attribute = _ref.attribute,
                                    message = _ref.message;

                                changeset.pushErrors(attribute, message);
                            });
                        });
                    }
                }).catch(function () {
                    changeset.restore(snapshot);
                });
            },
            saveNext: function saveNext(model) {
                var _this = this;

                _this.swal("Lagret!", 'info').then(function () {
                    model.then(function (_model) {
                        _model.save().then(function () {

                            var step = parseInt(Ember.get(_this, 'wizardStep.step'));
                            _this.setLoading(false);
                            if (step === 9) {
                                _this.set('wizardStep.step', 10);
                            }
                            _this.sendAction('toRoute', 'contractor.settings.skills');
                        });
                    });
                });
            },
            skip: function skip() {
                var step = parseInt(Ember.get(this, 'wizardStep.step'));
                if (step === 9) {
                    this.set('wizardStep.step', 10);
                }
                this.sendAction('toRoute', 'contractor.settings.skills');
            }
        }
    });
});