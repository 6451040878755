define('uwork-app-v2/components/bs/modal-footer/component', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        classNames: ['modal-footer'],
        cancelText: 'Avbryt',
        acceptText: 'Bekreft vakt',
        actions: {
            save: function save() {
                this.sendAction("saveModal");
            },
            cancel: function cancel() {
                this.sendAction("cancelModel");
            }
        }
    });
});