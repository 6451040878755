define('uwork-app-v2/components/admin/contractee/settings/additional-payment/component', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        store: Ember.inject.service(),
        i18n: Ember.inject.service(),
        saved: Ember.computed.filterBy('models', 'isNew', false),
        types: [{ id: 10, type: '%' }, { id: 20, type: 'NOK' }],
        actions: {
            edit: function edit(payment) {
                var edit = !Ember.get(payment, 'edit');
                Ember.set(payment, 'edit', edit);
            },
            delete: function _delete(payment) {

                var _close = Ember.get(this, 'i18n').t('form.btn.close');
                var _delete = Ember.get(this, 'i18n').t('form.btn.delete');

                this.swal({
                    title: "Dine opplysninger er lagret!",
                    showConfirmButton: true,
                    confirmButtonText: _delete,
                    cancelButtonText: _close,
                    showCloseButton: true,
                    showCancelButton: true,
                    type: 'question'
                }).then(function () {
                    payment.destroyRecord();
                });
            },
            save: function save(payment, contractee) {
                var _this = this;

                var model = payment;
                Ember.set(model, 'contractee', contractee);
                Ember.set(this, 'didValidate', true);

                model.validate().then(function (_ref) {
                    var validations = _ref.validations;


                    if (validations.get('isValid')) {
                        model.save().then(function () {

                            Ember.set(_this, 'didValidate', false);
                            _this.swal({
                                title: "Dine opplysninger er lagret!",
                                timer: 1000,
                                showConfirmButton: false,
                                showCloseButton: false,
                                type: 'success'
                            });

                            // console.log('update order-request close modal');
                            //Close Modal
                            Ember.get(_this, 'showAgreement').perform();

                            // Check order shift rules
                            Ember.get(_this, 'checkRules').perform();
                        });
                    } else {
                        //console.log('isInvalid');
                    }
                });
            }
        }
    });
});