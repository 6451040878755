define('uwork-app-v2/contractee/assignments/view/index/route', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        model: function model() {
            return this.modelFor('contractee/assignments/view');
        }
    });
});