define('uwork-app-v2/timesheet/model', ['exports', 'uwork-app-v2/order-shift/model', 'ember-data/attr'], function (exports, _model, _attr) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _model.default.extend({
        checked: (0, _attr.default)('boolean', { defaultValue: false }),
        orderId: (0, _attr.default)(),
        contracteeId: (0, _attr.default)(),
        contracteeName: (0, _attr.default)(),
        contractorId: (0, _attr.default)(),
        contractorName: (0, _attr.default)(),
        contractorFirstName: (0, _attr.default)(),
        contractorLastName: (0, _attr.default)()
    });
});