define('uwork-app-v2/components/contractor/settings/experience/display-items/component', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        session: Ember.inject.service(),
        store: Ember.inject.service(),

        account: Ember.inject.service('user/session-account'),
        user: Ember.computed.alias('account.currentUser'),
        isWizard: Ember.computed.equal('user.isSignUpCompleted', false),

        modelIsEmpty: Ember.computed.empty('model'),
        modelNotEmpty: Ember.computed.notEmpty('model'),

        showPanel: Ember.computed.empty('model'),
        wizardService: Ember.inject.service('contractor/wizard'),
        showPanelText: Ember.computed('showPanel', function () {
            return Ember.get(this, 'showPanel') ? 'contractor.settings.panel.close' : 'contractor.settings.panel.add';
        }),
        showPanelIcon: Ember.computed('showPanel', function () {
            return Ember.get(this, 'showPanel') ? 'close-circle' : 'plus-circle';
        }),
        showPanelIconColor: Ember.computed('showPanel', function () {
            return Ember.get(this, 'showPanel') ? 'text-danger' : 'text-primary';
        }),
        recentExperience: Ember.computed.filterBy('model', 'isNew', false),
        actions: {
            saveNext: function saveNext() {
                var next = Ember.get(this, 'wizardService').getNextRoute();
                this.sendAction('toRoute', Ember.get(next, 'route'));
            },
            insertNew: function insertNew() {
                this.toggleProperty('showPanel');
            },
            back: function back() {
                this.sendAction('toRoute', 'contractor.settings.education');
            }
        }

    });
});