define('uwork-app-v2/components/contractee/settings/agreement-confirmation/component', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        account: Ember.inject.service('user/session-account'),
        user: Ember.computed.alias('account.currentUser'),
        isWizard: Ember.computed.equal('user.isSignUpCompleted', false),
        wizardService: Ember.inject.service('contractor/wizard'),
        menu: Ember.inject.service('extend-menu'),
        agreement: Ember.computed.alias('model.agreement')
    });
});