define('uwork-app-v2/components/auth/confirm-token/component', ['exports', 'ember-ajax/raw', 'ember-get-config', 'uwork-app-v2/auth/error-object-parser'], function (exports, _raw, _emberGetConfig, _errorObjectParser) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        session: Ember.inject.service(),
        ajax: Ember.inject.service(),
        errors: null,
        success: null,

        init: function init() {
            this._super.apply(this, arguments);
            this.confirm();
        },
        confirm: function confirm() {
            var _this = this;

            // Reset errors & success variable
            Ember.set(this, 'errors', null);
            Ember.set(this, 'success', null);

            var payload = {
                "data": {
                    "attributes": { token: Ember.get(this, 'token') },
                    "type": 'auth-confirm-token'
                }
            };

            var options = {
                method: 'POST',
                contentType: 'application/vnd.api+json',
                data: JSON.stringify(payload)
            };

            return (0, _raw.default)(_emberGetConfig.default.APP.API_AUTH_CONFIRM, options).then(function (result) {
                if (Ember.isPresent(result.payload.message)) {
                    Ember.set(_this, 'success', 'Din konto er nå aktivert. Fortsett til innlogging.');
                }
            }).catch(function (_ref) {
                var payload = _ref.payload;

                var parsedErrors = _errorObjectParser.default.parse(payload);

                // Check if this is non attribute error
                if (parsedErrors && parsedErrors.length > 0) {
                    Ember.set(_this, 'errors', parsedErrors);
                }
            });
        }
    });
});