define('uwork-app-v2/contractee/timesheet/route', ['exports', 'ember-i18n'], function (exports, _emberI18n) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({

        queryParams: {
            // week: {replace: true, refreshModel: true},
            // month: {replace: true, refreshModel: true},
            year: { replace: true, refreshModel: true },
            shift: { replace: true, refreshModel: true },
            contractor: { replace: true, refreshModel: true }
        },

        title: (0, _emberI18n.translationMacro)('contractee.pageName.timesheet'),
        breadCrumb: {},
        beforeModel: function beforeModel() {
            this.setLoading(true);
        },
        afterModel: function afterModel() {
            var title = this.get('title');

            var bTitle = {
                title: title.string
            };
            this.setLoading(false);
            Ember.set(this, 'breadCrumb', bTitle);
        },
        model: function model() {
            return Ember.RSVP.hash({
                myworkers: this.store.findAll('my-worker'),
                shifts: this.store.findAll('shift')
            });
        },
        setupController: function setupController(controller, model) {
            this._super(controller, model);
            controller.set('results', null);
            controller.get('search').perform();
        },


        actions: {
            goTo: function goTo(orderId) {
                this.transitionTo('contractee.assignments.view', orderId);
            },
            willTransition: function willTransition() {
                this.controller.set('_filter', {});
            }
        }
    });
});