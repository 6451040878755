define('uwork-app-v2/contractee/vacancy/add/controller', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        actions: {
            toRoute: function toRoute(path, id) {
                this.transitionToRoute(path, id);
            }
        }
    });
});