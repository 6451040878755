define('uwork-app-v2/components/simditor-editor', ['exports', 'ember-cli-simditor/components/simditor-editor'], function (exports, _simditorEditor) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _simditorEditor.default;
    }
  });
});