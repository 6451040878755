define('uwork-app-v2/components/contractee/profile/content-sidebar-small/component', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        classNames: ['bg-white'],
        user: Ember.computed.alias('model.user')
    });
});