define('uwork-app-v2/contractor/orders/index/route', ['exports', 'ember-i18n', 'ember-cli-jsonapi-pagination/mixins/routes/jsonapi-pagination'], function (exports, _emberI18n, _jsonapiPagination) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend(_jsonapiPagination.default, {
        title: (0, _emberI18n.translationMacro)('contractor.pageName.orders'),
        breadCrumb: {},
        queryParams: {
            status: { refreshModel: true },
            past: { refreshModel: true }
        },
        beforeModel: function beforeModel() {
            this.setLoading(true);
        },
        afterModel: function afterModel() {
            var title = this.get('title');

            var bTitle = {
                title: title.string
            };
            this.setLoading(false);
            Ember.set(this, 'breadCrumb', bTitle);
        },
        model: function model(params) {
            //return this.store.query('order', params);
            return this.store.query('order-request', params).catch(function () {});
        }
    });
});