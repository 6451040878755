define('uwork-app-v2/contractor/user-requests/apply-for-substitute/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    breadCrumb: {},
    title: "Ønsker å bli vikar",

    model: function model() {
      return Ember.RSVP.hash({
        categories: this.store.findAll('category'),
        shifts: this.store.findAll('shift')
      });
    },


    actions: {
      toRoute: function toRoute(path) {
        this.transitionTo(path);
      }
    }
  });
});