define('uwork-app-v2/admin/assignments/new/validation', ['exports', 'ember-changeset-validations/validators'], function (exports, _validators) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = {
        shift: (0, _validators.validatePresence)(true),
        startDate: (0, _validators.validatePresence)(true),
        startTime: (0, _validators.validatePresence)(true),
        endTime: (0, _validators.validatePresence)(true),
        duration: (0, _validators.validatePresence)(true),
        notify: (0, _validators.validatePresence)(true),
        type: (0, _validators.validatePresence)(true)
    };
});