define('uwork-app-v2/mixins/pivot-mixin', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Mixin.create({
        resetSkills: function resetSkills(categories, type, _resetSkills) {
            categories.setEach('checked', true);
            categories.setEach('type', type);
            _resetSkills.setObjects(categories);
        },
        preResetSkills: function preResetSkills(resetSkills) {
            resetSkills.setEach('checked', false);
            resetSkills.setEach('type', null);
        },
        removePivot: function removePivot(item, user, pivot) {
            var _this = this;

            user.get('categories').removeObject(item);
            pivot.destroyRecord().then(function () {
                _this.setLoading(false);
            });
        },
        addPivot: function addPivot(item, user, type, store) {
            var _this2 = this;

            var pivot = store.createRecord('category-pivot', { category_id: Ember.get(item, 'id'), type: type, user_id: user, user: user });
            pivot.save().then(function () {
                Ember.set(item, '_pivot', pivot);
                user.get('categories').addObject(item);
                _this2.setLoading(false);
            });
        },
        updatePivot: function updatePivot(item, type) {
            var _this3 = this;

            var _pivot = item.get('_pivot');
            _pivot.set('type', type);

            _pivot.save().then(function () {
                _this3.setLoading(false);
            });
        }
    });
});