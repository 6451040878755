define('uwork-app-v2/components/contractor/profile/content-sidebar-public/component', ['exports', 'uwork-app-v2/substitute/model', 'uwork-app-v2/vacancy/model', 'uwork-app-v2/mixins/has-role-mixin'], function (exports, _model, _model2, _hasRoleMixin) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_hasRoleMixin.default, {
        ROLE_NAME_VACANCY: _model2.ROLE_NAME,
        ROLE_NAME_SUBSTITUTE: _model.ROLE_NAME,

        classNames: ['bg-white'],
        model: Ember.computed.alias('user')
    });
});