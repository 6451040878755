define('uwork-app-v2/components/auth/reset-form/component', ['exports', 'uwork-app-v2/components/auth/reset-form/validation', 'uwork-app-v2/components/auth/reset-form/schema', 'uwork-app-v2/auth/error-object-parser'], function (exports, _validation, _schema, _errorObjectParser) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var keys = Object.keys;
    exports.default = Ember.Component.extend({
        validation: _validation.default,
        session: Ember.inject.service(),
        ajax: Ember.inject.service(),
        i18n: Ember.inject.service(),

        hasDescription: Ember.computed(function () {
            var trans = Ember.get(this, 'i18n').t('auth.reset.description');

            if (Ember.String.isHTMLSafe(trans)) {
                return Ember.get(trans, 'string') !== "";
            }

            // Missing translation = Not set
            if (trans.split(' ')[0] === 'Missing') {
                return false;
            }

            return true;
        }),
        errors: null,
        success: null,

        init: function init() {
            this._super.apply(this, arguments);
            Ember.set(this, 'model', _schema.default.create({ token: Ember.get(this, 'token') }));
        },


        actions: {
            resetPassword: function resetPassword(changeset) {
                var _this = this;

                var snapshot = changeset.snapshot();

                // Reset errors & success variable
                Ember.set(this, 'errors', null);
                Ember.set(this, 'success', null);

                return changeset.cast(keys(_schema.schema)).validate().then(function () {
                    if (Ember.get(changeset, 'isValid')) {
                        changeset.save().then(function (_ref) {
                            var payload = _ref.payload;

                            Ember.set(_this, 'model', _schema.default.create());
                            Ember.set(_this, 'success', payload);
                        }, function () {
                            _this.swal({
                                title: "En feil oppstår!",
                                timer: 1500,
                                showConfirmButton: false,
                                showCloseButton: false,
                                type: 'error'
                            });
                        }).catch(function (_ref2) {
                            var payload = _ref2.payload;

                            var parsedErrors = _errorObjectParser.default.parse(payload, changeset);

                            // Check if this is non attribute error
                            if (parsedErrors && parsedErrors.length > 0) {
                                Ember.set(_this, 'errors', parsedErrors);
                            }
                        });
                    } else {
                        //console.log('isNotValid');
                    }
                }).catch(function () {
                    changeset.restore(snapshot);
                });
            }
        }
    });
});