define('uwork-app-v2/contractee/vacancy/add/route', ['exports', 'ember-i18n'], function (exports, _emberI18n) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        title: (0, _emberI18n.translationMacro)('vacancy.pageName.create'),
        breadCrumb: {},
        beforeModel: function beforeModel() {
            this.setLoading(true);
        },
        afterModel: function afterModel() {
            var title = this.get('title');

            var bTitle = {
                title: title.string
            };

            this.setLoading(false);

            Ember.set(this, 'breadCrumb', bTitle);
        },
        model: function model() {
            //
            // this.store.unloadAll('vacancy_plan');
            // this.store.unloadAll('vacancy_plan_feature');
            return Ember.RSVP.hash({
                vacancy: this.store.createRecord('vacancy'),
                occupations: this.store.query('vacancy-occupation', { recent: true }),
                plans: this.store.query('vacancy-plan', { public: true }).catch(function () {})
            });
        },

        actions: {
            willTransition: function willTransition() {
                var model = Ember.get(this, 'controller.model.vacancy');

                if (model && model.get('isNew')) {
                    model.destroyRecord();
                }
            }
        }

    });
});