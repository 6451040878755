define('uwork-app-v2/components/contractee/settings/main-company-details/component', ['exports', 'uwork-app-v2/mixins/default-ds-action-mixin', 'uwork-app-v2/contractee/model', 'uwork-app-v2/mixins/simditor-simple-mixin', 'uwork-app-v2/components/contractee/settings/main-company-details/validation'], function (exports, _defaultDsActionMixin, _model, _simditorSimpleMixin, _validation) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var keys = Object.keys;
    exports.default = Ember.Component.extend(_defaultDsActionMixin.default, _simditorSimpleMixin.default, {
        account: Ember.inject.service('user/session-account'),
        user: Ember.computed.alias('account.currentUser'),
        isWizard: Ember.computed.equal('user.isSignUpCompleted', false),
        wizardService: Ember.inject.service('contractee/wizard'),
        store: Ember.inject.service(),
        validation: _validation.default,
        schema: _model.schema,
        modelType: "contractee",
        messageSave: "Informasjon lagret.",
        messageDelete: "Informasjon slettet.",

        actions: {
            saveNext: function saveNext(changeset) {
                var _this = this;

                var next = Ember.get(this, 'wizardService').getNextRoute();
                var snapshot = changeset.snapshot();

                return changeset.cast(keys(Ember.get(this, 'schema'))).validate().then(function () {
                    if (Ember.get(changeset, 'isValid')) {
                        _this.setLoading(true);
                        changeset.save().then(function () {
                            _this.setLoading(false);

                            _this.swal({
                                title: "Informasjon lagret.",
                                type: 'info',
                                timer: 1000,
                                showConfirmButton: false,
                                showCloseButton: false
                            }).then(function () {
                                _this.sendAction('toRoute', Ember.get(next, 'route'));
                            }, function () {
                                _this.sendAction('toRoute', Ember.get(next, 'route'));
                            });
                        }).catch(function () {
                            Ember.get(_this, 'model.errors').forEach(function (_ref) {
                                var attribute = _ref.attribute,
                                    message = _ref.message;

                                changeset.pushErrors(attribute, message);
                            });
                            // _this.setLoading(false);
                        });
                    }
                }).catch(function () {
                    changeset.restore(snapshot);
                });
            },
            skip: function skip() {
                var next = Ember.get(this, 'wizardService').getNextRoute();
                this.sendAction('toRoute', Ember.get(next, 'route'));
            }
        }

    });
});