define('uwork-app-v2/admin/list/map/route', ['exports', 'ember-i18n', 'ember-cli-jsonapi-pagination/mixins/routes/jsonapi-pagination'], function (exports, _emberI18n, _jsonapiPagination) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend(_jsonapiPagination.default, {
        title: (0, _emberI18n.translationMacro)('admin.nav.contractor.map'),
        breadCrumb: {},
        queryParams: {
            query: { replace: true },
            status: { replace: true },
            sorting: { replace: true },
            categories: { replace: true },
            titles: { replace: true },
            skills: { replace: true },
            number: { replace: true },
            driver_license: { replace: true },
            skilled: { replace: true },
            unskilled: { replace: true },
            car: { replace: true },
            turnus: { replace: true }
        },

        model: function model() {
            return Ember.RSVP.hash({
                categories: this.store.findAll('category'),
                skills: this.store.findAll('skill'),
                titles: this.store.findAll('contractor-title')
            });
        },
        setupController: function setupController(controller, model) {
            // Call _super for default behavior
            this._super(controller, model);
            // Implement your custom setup after

            Ember.get(controller, 'search').perform();
        },
        beforeModel: function beforeModel() {
            this.setLoading(true);
        },
        afterModel: function afterModel() {
            var title = this.get('title');

            var bTitle = {
                title: title.string
            };
            this.setLoading(false);
            Ember.set(this, 'breadCrumb', bTitle);
        }
    });
});