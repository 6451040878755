define('uwork-app-v2/components/admin/assignment/shift-item/schema', ['exports', 'uwork-app-v2/auth/default-schema'], function (exports, _defaultSchema) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.schema = undefined;
    var schema = exports.schema = {
        save: function save() {}
    }; //ToDO - Delete
    //import errorParser from 'uwork-app-v2/auth/error-object-parser';
    exports.default = _defaultSchema.default.extend(schema);
});