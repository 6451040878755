define('uwork-app-v2/helpers/socials/twiter-link', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.socialsTwiterLink = socialsTwiterLink;
    function socialsTwiterLink(params /*, hash*/) {
        var model = params[0];

        var content = '<a href="https://twitter.com/intent/tweet?url=' + model.get('url') + '&text=' + model.get('title') + '" data-toggle="tooltip" data-placement="bottom" target="_blank" title="' + model.get('title') + '">\n' + ' <i class="twitter mdi mdi-twitter-box"></i>\n' + '</a>';
        content = Ember.String.htmlSafe(content);
        return content;
    }

    exports.default = Ember.Helper.helper(socialsTwiterLink);
});