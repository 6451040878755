define('uwork-app-v2/components/contractor/upload/user-video/component', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        session: Ember.inject.service(),
        store: Ember.inject.service(),
        account: Ember.inject.service('user/session-account'),
        user: Ember.computed.alias('account.currentUser'),
        videoIsVisible: false,
        isUploading: false,
        uploadProgress: 0,
        showExampleModal: false,
        videoExample: 'https://api.uwork.no/stream/d10643acba6d05a6a0a0e1d032a5ba49.mov',
        setup: {
            play: true,
            playing: true,
            progress: true,
            controls: true,
            preload: "none"
        },
        player: false,
        component: false,
        actions: {
            deleteVideo: function deleteVideo(model, relationship) {
                var video = Ember.get(model, relationship);
                video.then(function (model) {
                    model.destroyRecord().then(function () {}, function () {});
                });
                // model.save().then(()=>{
                //     this.swal('Slettet!');
                // }, ()=>{
                //     this.swal('Slettet!');
                // })
            },
            ready: function ready(player, component) {
                Ember.set(this, 'player', player);
                Ember.set(this, 'component', component);
            },
            showExampleVideo: function showExampleVideo() {
                Ember.set(this, 'showExampleModal', true);
                var player = Ember.get(this, 'player');
                player.play();
            },
            closeExampleVideo: function closeExampleVideo() {
                Ember.set(this, 'showExampleModal', false);
                var player = Ember.get(this, 'player');
                player.pause();
            },
            onFilesChange: function onFilesChange(files, uploader, component) {
                var _this = this;

                this.setLoading(true);
                var store = Ember.get(this, 'store');

                if (files) {
                    var file = files[0];

                    Ember.set(this, 'isUploading', true);

                    uploader.upload(file).then(function (result) {
                        store.pushPayload(Ember.get(component, 'modelType'), result);
                    });

                    uploader.on('progress', function (e) {
                        Ember.set(_this, 'uploadProgress', e.percent.toFixed(0));
                    });

                    uploader.on('didUpload', function (e) {
                        Ember.set(_this, 'uploadProgress', 0);
                        Ember.set(_this, 'isUploading', false);
                        _this.setLoading(false);
                    });
                }
            },
            selectVideo: function selectVideo() {
                Ember.set(this, 'videoIsVisible', true);
            }
        }
    });
});