define('uwork-app-v2/mixins/has-role-mixin', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Mixin.create({
        session: Ember.inject.service(),
        hasRoleAccountSession: Ember.inject.service('user/session-account'),
        hasRoleAccountUser: Ember.computed.alias('hasRoleAccountSession.currentUser'),

        /**
        * Check if user has a specific role
        *
        * @param name
        * @param resource
        */
        hasRole: function hasRole(name, resource) {
            var user = resource ? resource : Ember.get(this, 'hasRoleAccountUser');
            return Ember.get(user, 'roles').findBy('name', name) || false;
        }
    });
});