define('uwork-app-v2/components/admin/vacancy/view/display-vacancy/component', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        session: Ember.inject.service(),
        store: Ember.inject.service(),
        router: Ember.inject.service(),
        routing: Ember.inject.service('-routing'),
        classNames: ['vacancy-content-wrapper'],
        actions: {
            status: function status(model, _status) {
                model.set('status', _status);
                model.save();
            },
            delete: function _delete(model) {
                var _this = this;
                model.destroyRecord().then(function () {
                    _this.sendAction('toRoute', 'admin.vacancies.index');
                });
            }
        }
    });
});