define('uwork-app-v2/components/contractor/settings/contractor-profile/validation', ['exports', 'ember-changeset-validations/validators'], function (exports, _validators) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = {
        firstName: (0, _validators.validatePresence)(true),
        lastName: (0, _validators.validatePresence)(true),
        email: [(0, _validators.validateFormat)({ type: 'email' })],
        password: (0, _validators.validateLength)({ min: 8, allowBlank: true }),
        passwordConfirmation: (0, _validators.validateConfirmation)({ on: 'password' })
    };
});