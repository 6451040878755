define('uwork-app-v2/helpers/collaps-status', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.collapsStatus = collapsStatus;
    function collapsStatus(params /*, hash*/) {
        var current = params[0];
        var child = params[1];

        if (child === undefined || current === undefined) {
            return '';
        }

        var current_array = current.split('.');
        var prev_url_array = child.split('.');

        var temp = prev_url_array.slice(0, -1);

        if (current_array[1] === temp[1]) {
            return 'show';
        } else {
            return '';
        }
    }

    exports.default = Ember.Helper.helper(collapsStatus);
});