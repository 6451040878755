define('uwork-app-v2/validations/unique-companyname', ['exports', 'ember-cp-validations/validators/base'], function (exports, _base) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });


    var UniqueCompanyname = _base.default.extend({
        store: Ember.inject.service(),
        validate: function validate(value, options) {
            if (!value) {
                return true;
            }
            return this.get('store').queryRecord('user', { name: value }).then(function (user) {

                if (user.get('email')) {
                    return options.get('message');
                } else {
                    return true;
                }
            }).catch(function () {
                return 'Validation Field';
            });
        }
    });

    UniqueCompanyname.reopenClass({
        getDependentsFor: function getDependentsFor() /* attribute, options */{
            return [];
        }
    });

    exports.default = UniqueCompanyname;
});