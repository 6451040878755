define('uwork-app-v2/components/bs/btn-group-radio/component', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        attributeBindings: ['data-toggle'],
        classNameBindings: ["btn-group", "aria-pressed"],
        didInsertElement: function didInsertElement() {
            // this._super(...arguments);
            // let _this = this;
            // let _element = this.$();
            // $().button('toggle');
        },

        actions: {}
    });
});