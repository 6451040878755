define('uwork-app-v2/components/admin/cvs/contractor-block/component', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        user: Ember.computed.alias('contractor.user'),
        store: Ember.inject.service(),
        model: false,
        didInsertElement: function didInsertElement() {
            this._super.apply(this, arguments);
            var ude = Ember.get(this, 'store').createRecord('user-data-export');

            //ToDo check if user is set
            var user = Ember.get(this, 'user');
            user.then(function (data) {
                //console.log(data);
                Ember.set(ude, 'user', data);
            });
            Ember.set(this, 'model', ude);
        },

        actions: {
            save: function save() {
                var _this = this;

                var model = Ember.get(this, 'model');
                model.save().then(function () {
                    _this.swal('Vikar har blitt fjernet!').then(function () {
                        Ember.set(_this, 'model', false);
                    }, function () {});
                });
            }
        }
    });
});