define('uwork-app-v2/404/route', ['exports', 'ember-simple-auth/mixins/unauthenticated-route-mixin'], function (exports, _unauthenticatedRouteMixin) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend(_unauthenticatedRouteMixin.default, {
        afterModel: function afterModel() {
            this.setLoading(false);
            this.showAfterRender(false);
        }
    });
});