define('uwork-app-v2/mixins/hard-refresh-mixin', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Mixin.create({
        hardRefresh: function hardRefresh() {
            if (typeof window !== 'undefined' && window.location) {
                window.location.reload();
            }
        }
    });
});