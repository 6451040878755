define('uwork-app-v2/rating/model', ['exports', 'ember-data/model', 'ember-data/attr', 'ember-data/relationships'], function (exports, _model, _attr, _relationships) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _model.default.extend({
        comment: (0, _attr.default)(),
        userReply: (0, _attr.default)(),
        stars: (0, _attr.default)('number', { defaultValue: 0 }),
        createdAt: (0, _attr.default)('mdate'),
        updatedAt: (0, _attr.default)('mdate'),
        replyAt: (0, _attr.default)(),
        contractor: (0, _relationships.belongsTo)('contractor'),
        contractee: (0, _relationships.belongsTo)('contractee'),
        order: (0, _relationships.belongsTo)('order'),

        hasStar: Ember.computed.notEmpty('stars'),
        hasNotStar: Ember.computed.empty('stars'),

        hasComment: Ember.computed.notEmpty('comment'),
        hasNotComment: Ember.computed.empty('comment'),

        hasReplay: Ember.computed.notEmpty('userReply'),
        hasNotReplay: Ember.computed.empty('userReply')
    });
});