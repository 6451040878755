define('uwork-app-v2/components/order-list/order-accept-action/component', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        store: Ember.inject.service(),
        session: Ember.inject.service(),
        account: Ember.inject.service('user/session-account'),
        user: Ember.computed.alias('account.currentUser'),
        actions: {
            acceptJob: function acceptJob(request) {
                var _this = this;

                request.setActive();
                request.save().then(function () {
                    _this.swal('Request Sent');
                    // new Notification('Request Sent');
                });
            }
        }
    });
});