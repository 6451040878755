define('uwork-app-v2/components/cal/month-view/component', ['exports', 'moment', 'ember-concurrency'], function (exports, _moment, _emberConcurrency) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        store: Ember.inject.service(),

        init: function init() {
            this._super.apply(this, arguments);
        },


        daysWithShifts: Ember.computed('days', 'shifts', function () {
            return Ember.get(this, 'days');
        }),

        getOrderShifts: function getOrderShifts() {
            var _this = this;

            Ember.set(this, 'shifts', []);
            var days = Ember.get(this, 'days');

            Ember.get(this, 'store').query('order-shift', {
                month: Ember.get(this, 'month').format('MM'),
                year: Ember.get(this, 'month').format('YYYY'),
                calendar: true,
                limit: 500,
                includes: 'order,shift',
                order_by: 'from'
            }).then(function (shifts) {
                Ember.set(_this, 'shifts', shifts);

                shifts.forEach(function (shift) {

                    var start = Ember.get(shift, 'fromDate');
                    var end = Ember.get(shift, 'toDate');

                    var isSameDay = start.isSame(end, 'day');
                    var day = days.findBy('id', start.format('YYYY-MM-DD'));
                    var tomorrow = days.findBy('id', end.format('YYYY-MM-DD'));

                    Ember.get(day, 'shifts').pushObject({
                        id: Ember.get(shift, 'id'),
                        isSameDay: isSameDay,
                        isFirstDay: true,
                        isSecondDay: isSameDay,
                        data: shift
                    });

                    // Add second day based on isSameDay check
                    if (!isSameDay) {

                        // Set data into next day
                        Ember.get(tomorrow, 'shifts').pushObject({
                            id: Ember.get(shift, 'id'),
                            isSameDay: isSameDay,
                            isFirstDay: false,
                            isSecondDay: true,
                            data: shift
                        });
                    }
                });
            });
        },
        buildCurrentMonth: function buildCurrentMonth() {
            Ember.set(this, 'days', []);

            var month = Ember.get(this, 'month');
            var start = month.clone();
            var end = month.clone();

            // Set start date to first date of the month
            start.date(1);

            // Reset time
            start.hour(0).minute(0).second(0).millisecond(0);

            // Reset to week start
            start.startOf('week');

            // Reset end date to end of month and the end of that week
            end.endOf('month').endOf('week');

            Ember.set(this, 'start', start);
            Ember.set(this, 'end', end);

            var total_days = end.diff(start, 'days');
            var done = false,
                date = start.clone(),
                monthIndex = month.month(),
                count = 0;
            var days = Ember.get(this, 'days');
            var today = (0, _moment.default)();

            while (!done) {
                days.pushObject({
                    id: date.clone().format('YYYY-MM-DD'),
                    date: date.clone(),
                    currentMonth: monthIndex === date.month(),
                    isToday: date.isSame(today, 'day'),
                    shifts: []
                });

                // Continue with one day
                date.add(1, "d");

                done = count++ == total_days && monthIndex !== date.month();
            }
        },
        rerender: function rerender() {
            this.buildCurrentMonth();
        },
        didInsertElement: function didInsertElement() {
            this._super.apply(this, arguments);

            this.buildCurrentMonth();
            this.getOrderShifts();
        },


        actions: {
            nextMonth: function nextMonth() {
                var current = Ember.get(this, 'month');

                Ember.set(this, 'month', current.clone().add(1, 'month'));
                this.rerender();
            },
            prevMonth: function prevMonth() {
                var current = Ember.get(this, 'month');

                Ember.set(this, 'month', current.clone().subtract(1, 'month'));
                this.rerender();
            }
        }

    });
});