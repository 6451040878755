define('uwork-app-v2/components/bs/main-container/component', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        openMenu: true,
        media: Ember.inject.service(),
        classNames: [],
        classNameBindings: ['isWizard:isWizard', 'openMenu:open', 'media.isXl:is-xl', 'media.isXs:is-xs'],
        checkMediaScreen: Ember.observer('media.isXs', 'media.isSm', 'media.isMd', 'media.isLg', 'media.isXl', function () {
            var media = Ember.get(this, 'media');

            if (media.get('isXl')) {
                Ember.set(this, 'openMenu', true);
            } else {
                Ember.set(this, 'openMenu', false);
            }
        })
    });
});