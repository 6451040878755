define('uwork-app-v2/profile/controller', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        appService: Ember.inject.service('application'),
        session: Ember.inject.service(),

        messages: [],

        actions: {
            toRoute: function toRoute(route) {
                this.transitionToRoute(route);
            }
        }
    });
});