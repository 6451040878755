define('uwork-app-v2/components/contractee/settings/contractee-avatar/component', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        session: Ember.inject.service(),
        store: Ember.inject.service(),
        account: Ember.inject.service('user/session-account'),
        user: Ember.computed.alias('account.currentUser'),
        isWizard: Ember.computed.equal('user.isSignUpCompleted', false),
        wizardService: Ember.inject.service('contractee/wizard'),
        classNameBindings: ['isWizard:transition-panel'],
        avatarIsVisible: false,
        videoIsVisible: false,
        isUploading: false,
        uploadProgress: 0,
        actions: {
            onFilesChange: function onFilesChange(files, uploader, component) {
                var _this = this;

                var store = Ember.get(this, 'store');
                this.setLoading(true);
                if (files) {
                    var file = files[0];

                    Ember.set(this, 'isUploading', true);

                    uploader.upload(file).then(function (result) {
                        store.pushPayload(Ember.get(component, 'modelType'), result);
                        _this.setLoading(false);
                    });

                    uploader.on('progress', function (e) {
                        Ember.set(_this, 'uploadProgress', e.percent.toFixed(0));
                    });

                    uploader.on('didUpload', function (e) {
                        Ember.set(_this, 'uploadProgress', 0);
                        Ember.set(_this, 'isUploading', false);
                    });
                }
            },
            selectAvatar: function selectAvatar() {
                Ember.set(this, 'avatarIsVisible', true);
            },
            selectVideo: function selectVideo() {
                Ember.set(this, 'videoIsVisible', true);
            },
            saveNext: function saveNext() {
                var next = Ember.get(this, 'wizardService').getNextRoute();
                this.sendAction('toRoute', Ember.get(next, 'route'));
            },
            toRoute: function toRoute() {
                var next = Ember.get(this, 'wizardService').getNextRoute();
                this.sendAction('toRoute', Ember.get(next, 'route'));
            }
        }
    });
});