define('uwork-app-v2/helpers/custom-format-age', ['exports', 'moment'], function (exports, _moment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.customFormatAge = customFormatAge;
    function customFormatAge(params /*, hash*/) {
        var age = params[0];

        return parseInt(_moment.default.duration((0, _moment.default)().diff(age)).asYears());
    }

    exports.default = Ember.Helper.helper(customFormatAge);
});