define('uwork-app-v2/torii-providers/github', ['exports', 'torii/providers/github-oauth2'], function (exports, _githubOauth) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _githubOauth.default.extend({
        fetch: function fetch(data) {
            return data;
        }
    });
});