define('uwork-app-v2/vacacny-message/model', ['exports', 'ember-data/model', 'ember-data/attr', 'ember-data/relationships'], function (exports, _model, _attr, _relationships) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _model.default.extend({
        contractor: (0, _relationships.belongsTo)('contractor'),
        vacancy: (0, _relationships.belongsTo)('vacancy'),
        message: (0, _attr.default)()
    });
});