define('uwork-app-v2/components/contractor/profile/display-map/component', ['exports', 'uwork-app-v2/mixins/cities-mixin'], function (exports, _citiesMixin) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_citiesMixin.default, {
        tagName: 'div',
        myIcon: '/assets/images/icons/marker.png',
        lat: 65.482004,
        lng: 13.157315,
        editing: false,
        hasMap: Ember.computed('contractor.lat', 'contractor.lng', function () {
            return Ember.get(this, 'contractor.lng') && Ember.get(this, 'contractor.lat');
        }),
        customOptions: {
            mapTypeControl: false,
            streetViewControl: false,
            zoomControl: true,
            center: { lat: 65.482004, lng: 13.157315 },
            draggable: true,
            scrollwheel: false,
            styles: [{
                'elementType': 'geometry',
                'stylers': [{
                    'color': '#f5f5f5'
                }]
            }, {
                'elementType': 'labels.icon',
                'stylers': [{
                    'visibility': 'off'
                }]
            }, {
                'elementType': 'labels.text.fill',
                'stylers': [{
                    'color': '#616161'
                }]
            }, {
                'elementType': 'labels.text.stroke',
                'stylers': [{
                    'color': '#f5f5f5'
                }]
            }, {
                'featureType': 'administrative',
                'elementType': 'geometry',
                'stylers': [{
                    'visibility': 'off'
                }]
            }, {
                'featureType': 'administrative.land_parcel',
                'elementType': 'labels.text.fill',
                'stylers': [{
                    'color': '#bdbdbd'
                }]
            }, {
                'featureType': 'poi',
                'stylers': [{
                    'visibility': 'off'
                }]
            }, {
                'featureType': 'poi',
                'elementType': 'geometry',
                'stylers': [{
                    'color': '#eeeeee'
                }]
            }, {
                'featureType': 'poi',
                'elementType': 'labels.text.fill',
                'stylers': [{
                    'color': '#757575'
                }]
            }, {
                'featureType': 'poi.park',
                'elementType': 'geometry',
                'stylers': [{
                    'color': '#e5e5e5'
                }]
            }, {
                'featureType': 'poi.park',
                'elementType': 'labels.text.fill',
                'stylers': [{
                    'color': '#9e9e9e'
                }]
            }, {
                'featureType': 'road',
                'elementType': 'geometry',
                'stylers': [{
                    'color': '#ffffff'
                }]
            }, {
                'featureType': 'road',
                'elementType': 'labels.icon',
                'stylers': [{
                    'visibility': 'off'
                }]
            }, {
                'featureType': 'road.arterial',
                'elementType': 'labels.text.fill',
                'stylers': [{
                    'color': '#757575'
                }]
            }, {
                'featureType': 'road.highway',
                'elementType': 'geometry',
                'stylers': [{
                    'color': '#dadada'
                }]
            }, {
                'featureType': 'road.highway',
                'elementType': 'labels.text.fill',
                'stylers': [{
                    'color': '#616161'
                }]
            }, {
                'featureType': 'road.local',
                'elementType': 'labels.text.fill',
                'stylers': [{
                    'color': '#9e9e9e'
                }]
            }, {
                'featureType': 'transit',
                'stylers': [{
                    'visibility': 'off'
                }]
            }, {
                'featureType': 'transit.line',
                'elementType': 'geometry',
                'stylers': [{
                    'color': '#e5e5e5'
                }]
            }, {
                'featureType': 'transit.station',
                'elementType': 'geometry',
                'stylers': [{
                    'color': '#eeeeee'
                }]
            }, {
                'featureType': 'water',
                'elementType': 'geometry',
                'stylers': [{
                    'color': '#c9c9c9'
                }]
            }, {
                'featureType': 'water',
                'elementType': 'labels.text.fill',
                'stylers': [{
                    'color': '#9e9e9e'
                }]
            }]
        },
        actions: {
            save: function save() {
                var _this = this;

                this.setLoading(true);
                Ember.get(this, 'contractor').save().then(function () {
                    _this.swal('Dine opplysninger er lagret!');
                    _this.setLoading(false);
                });
            },
            editForm: function editForm() {
                this.toggleProperty('editing');
            },
            onLocationChangeHandler: function onLocationChangeHandler(lat, lng, results) {
                Ember.set(this, 'lng', lng);
                Ember.set(this, 'lat', lat);
            }
        }
    });
});