define('uwork-app-v2/components/contractor/profile/display-car-driving-license-info/component', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        tagName: "div",
        editing: false,
        actions: {
            editForm: function editForm() {
                this.toggleProperty('editing');
            },
            save: function save(model) {
                var _this = this;

                this.setLoading(true);
                model.save().then(function () {
                    _this.setLoading(false);
                    _this.swal('Dine opplysninger er lagret!');
                });
            }
        }
    });
});