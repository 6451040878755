define('uwork-app-v2/admin/assignments/view/index/controller', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        store: Ember.inject.service(),
        notFoundError: Ember.computed.empty('model'),
        FoundError: Ember.computed.notEmpty('model'),
        hasRequests: Ember.computed.oneWay('model.hasRequests'),

        showNotifyAllButton: Ember.computed('model', function () {
            var isAccepted = Ember.get(this, 'model.isAccepted');
            var isNotifyMyWorker = Ember.get(this, 'model.isNotifyMyWorker');

            return !isAccepted && isNotifyMyWorker;
        }),

        actions: {
            resendOrder: function resendOrder(model) {
                var _this2 = this;

                var store = Ember.get(this, 'store');

                store.queryRecord('order', { resend: true, order_id: model.get('id') }).then(function () {
                    _this2.swal({
                        title: "Oppdragsbekreftelse er nå sendt!",
                        timer: 1500,
                        showConfirmButton: false,
                        showCloseButton: false,
                        type: 'success'
                    }).then(function () {}, function () {}).finally(function () {});
                }, function () {
                    _this2.swal({
                        title: "Oppdragsbekreftelse er nå ikke sendt!",
                        timer: 1500,
                        showConfirmButton: false,
                        showCloseButton: false,
                        type: 'error'
                    }).then(function () {}, function () {}).finally(function () {});
                });
            },
            changeNotification: function changeNotification(model) {
                var _this3 = this;

                this.setLoading(true);

                model.setNotificationAll();
                model.save().then(function () {
                    _this3.setLoading(false);

                    _this3.swal({
                        title: "Alle vikarer har blitt varslet",
                        text: "Du vil nå kunne motta flere søkere på vakten.",
                        showCancelButton: false,
                        confirmButtonColor: "#14CA9C",
                        confirmButtonText: "OK"
                    }).then(function () {}, function () {}).finally(function () {});
                });
            },
            delete: function _delete(order) {
                var _this = this;
                //this.setLoading(true);
                _this.swal({
                    title: "Ønsker du å slette denne bestillingen?",
                    text: "Det er ikke mulig å gjenopprette en slettede bestilling i ettertid!",
                    type: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#DD6B55",
                    confirmButtonText: "Ja, slette bestilling!"
                }).then(function () {
                    order.destroyRecord().then().finally(function () {
                        _this.transitionToRoute('admin.assignments.active');
                    });
                }, function () {});
            }
        }
    });
});