define('uwork-app-v2/vacancy/service', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Service.extend({
        new: false,
        model: {},
        user: false,
        type: '',
        contractor: Ember.computed.alias('user.contractor')
    });
});