define('uwork-app-v2/admin/horizon/route', ['exports', 'ember-i18n'], function (exports, _emberI18n) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        title: (0, _emberI18n.translationMacro)('admin.horizon.PageName'),
        breadCrumb: {},
        ajax: Ember.inject.service(),
        session: Ember.inject.service(),
        afterModel: function afterModel() {
            // To Do
            // this method didn't work on replacing the url :(
            // let url = `${config.APP.API_HOST}/horizon`;
            // let token = get(this, 'session.data.authenticated.token');
            //
            // window.location.replace(url + '?token=' + token);
        }
    });
});