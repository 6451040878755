define('uwork-app-v2/contractee/vacancy/edit/controller', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        actions: {
            toRoute: function toRoute(path, id) {
                if (id) {
                    this.transitionToRoute(path, id);
                } else {
                    this.transitionToRoute(path);
                }
            },
            willTransition: function willTransition() {
                var model = this.controller.get('vacancy');
                if (model.get('hasDirtyAttributes')) {
                    model.rollbackAttributes();
                }
            }
        }
    });
});