define('uwork-app-v2/contractee/assignments/view/select-worker/route', ['exports', 'ember-i18n'], function (exports, _emberI18n) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        title: (0, _emberI18n.translationMacro)('contractee.assignment.selectCandidate.pageName'),
        breadCrumb: {},
        beforeModel: function beforeModel() {
            this.setLoading(true);
        },
        afterModel: function afterModel() {
            var title = this.get('title');

            var bTitle = {
                title: title.string
            };

            this.setLoading(false);

            Ember.set(this, 'breadCrumb', bTitle);
        },

        queryParams: {
            query: { replace: true },
            status: { replace: true },
            sorting: { replace: true },
            titles: { replace: true },
            skills: { replace: true },
            number: { replace: true },
            driver_license: { replace: true },
            skilled: { replace: true },
            unskilled: { replace: true },
            car: { replace: true },
            turnus: { replace: true }
        },

        model: function model() {
            return Ember.RSVP.hash({
                order: this.modelFor('contractee/assignments/view'),
                categories: this.store.findAll('category'),
                skills: this.store.findAll('skill'),
                titles: this.store.findAll('contractor-title')
            });
        },
        setupController: function setupController(controller, model) {
            this._super(controller, model);
            controller.set('results', null);
            controller.get('search').perform();
        }
    });
});