define('uwork-app-v2/components/contractee/vacancy/display-item/component', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        classNames: ['job-card card bg-white d-flex mb-4'],
        contractee: Ember.computed.alias('model.contractee')
    });
});