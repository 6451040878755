define('uwork-app-v2/contractee/user-requests/view/route', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        title: "Visning av forespørsel",

        model: function model(params) {
            return this.store.findRecord('user-request', params.request_id).catch(function () {});
        }
    });
});