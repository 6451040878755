define('uwork-app-v2/components/admin/contractee/settings/paymnet-item/component', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        classNames: ['payments-item'],
        tagName: 'tr',
        edit: false,
        i18n: Ember.inject.service(),
        types: [{ id: 10, type: '%' }, { id: 20, type: 'NOK' }],
        actions: {
            edit: function edit() {
                var edit = !Ember.get(this, 'model.edit');
                Ember.set(this, 'model.edit', edit);
            }
        }
    });
});