define('uwork-app-v2/components/contractor/edit/profile/user-profile/component', ['exports', 'uwork-app-v2/components/contractor/edit/profile/user-profile/validation', 'uwork-app-v2/contractor/model'], function (exports, _validation, _model) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var keys = Object.keys;
    exports.default = Ember.Component.extend({
        validation: _validation.default,
        schema: _model.schema,
        actions: {
            save: function save(changeset) {
                var _this = this;

                var snapshot = changeset.snapshot();

                var user = Ember.get(changeset, 'user.content');

                return changeset.cast(keys(Ember.get(this, 'schema'))).validate().then(function () {
                    if (Ember.get(changeset, 'isValid')) {
                        //this.setLoading(true);
                        changeset.save().then(function () {
                            _this.swal('Dine opplysninger er lagret!');

                            user.save();
                        }).catch(function () {
                            Ember.get(_this, 'user.errors').forEach(function (_ref) {
                                var attribute = _ref.attribute,
                                    message = _ref.message;

                                changeset.pushErrors(attribute, message);
                            });
                        });
                    }
                }).catch(function () {
                    changeset.restore(snapshot);
                });
            }
        }
    });
});