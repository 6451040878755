define('uwork-app-v2/tags-data/service', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Service.extend({
        routURL: 'asdf',
        setSeo: function setSeo(model, routURL) {

            var contractee = model.get('contractee');
            var avatarUrl = contractee.get('user.avatarUrl');

            var metaTags = [{
                tagName: "description",
                tagContent: model.get('description')
            }, {
                tagName: "og:locale",
                tagContent: "nb_NO"
            }, {
                tagName: "og:type",
                tagContent: "website"
            }, {
                tagName: "og:title",
                tagContent: model.get('title')
            }, {
                tagName: "og:description",
                tagContent: model.get('description')
            }, {
                tagName: "og:url",
                tagContent: routURL
            }, {
                tagName: "og:site_name",
                tagContent: 'uWork.no'
            }, {
                tagName: "og:image",
                tagContent: avatarUrl
            }, {
                tagName: "twitter:card",
                tagContent: 'summary'
            }, {
                tagName: "twitter:description",
                tagContent: model.get('description')
            }, {
                tagName: "twitter:title",
                tagContent: model.get('title')
            }, {
                tagName: "twitter:image",
                tagContent: avatarUrl
            }];

            Ember.set(this, 'metaTags', metaTags);
        },
        getSeo: function getSeo() {
            return Ember.get(this, 'metaTags');
        }
    });
});