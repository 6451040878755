define('uwork-app-v2/admin/route', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _authenticatedRouteMixin) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, {
        title: "Admin",
        afterModel: function afterModel() {
            this.setBredCrump();
            this.showAfterRender(false);
        }
    });
});