define('uwork-app-v2/vacancy-feature-plan-pivot/model', ['exports', 'ember-data', 'ember-data/attr', 'ember-data/relationships'], function (exports, _emberData, _attr, _relationships) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberData.default.Model.extend({
        order_id: (0, _attr.default)('number'),
        plan_id: (0, _attr.default)('number'),
        feature_id: (0, _attr.default)('number'),
        vacancyPlanFeature: (0, _relationships.belongsTo)('vacancy-plan-feature')
    });
});