define('uwork-app-v2/mixins/default-ds-action-mixin', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var keys = Object.keys;
    exports.default = Ember.Mixin.create({
        store: Ember.inject.service(),
        isEditing: false,
        modelType: null,

        messageCreate: null,
        messageSave: null,
        messageDelete: null,

        // User options
        dsMixin: {},

        dsMixinOptions: Ember.computed('dsMixin', function () {
            var options = {
                type: null,
                messages: {
                    created: 'Opprettet.',
                    saved: 'Lagret.',
                    deleted: 'Slettet.'
                },
                confirm: {
                    delete: {
                        txt: 'Bekreft sletting!',
                        btn: 'Slett'
                    }
                }
            };

            var userOptions = Ember.get(this, 'dsMixin');
            Ember.assign(options, userOptions);
            return options;
        }),

        hardRefresh: function hardRefresh() {
            if (typeof window !== 'undefined' && window.location) {
                window.location.reload();
            }
        },


        actions: {
            add: function add(changeset) {
                var _this2 = this;

                var snapshot = changeset.snapshot();
                var _this = this;
                return changeset.cast(keys(Ember.get(this, 'schema'))).validate().then(function () {
                    if (Ember.get(changeset, 'isValid')) {
                        _this.setLoading(true);
                        changeset.save().then(function () {
                            Ember.set(_this, 'model', Ember.get(_this, 'store').createRecord(Ember.get(_this, 'modelType')));
                            _this2.swal({
                                title: Ember.get(_this, 'messageCreate'),
                                confirmButtonColor: "#14CA9C"
                            });
                            _this2.setLoading(false);
                        }).catch(function () {
                            Ember.get(_this, 'model.errors').forEach(function (_ref) {
                                var attribute = _ref.attribute,
                                    message = _ref.message;

                                changeset.pushErrors(attribute, message);
                            });
                            _this.setLoading(false);
                        });
                    }
                }).catch(function () {
                    changeset.restore(snapshot);
                });
            },
            clear: function clear(changeset) {
                changeset.rollback();
            },
            close: function close(changeset) {
                changeset.rollback();
                this.toggleProperty('isEditing');
            },
            edit: function edit() {
                this.toggleProperty('isEditing');
            },
            delete: function _delete(model) {
                var _this3 = this;

                //let id = get(model, 'id');
                var options = Ember.get(this, 'dsMixinOptions');
                this.swal({
                    title: options.confirm.delete.txt,
                    type: "question",
                    confirmButtonColor: "#DD6B55",
                    confirmButtonText: options.confirm.delete.btn,
                    showCloseButton: true
                }).then(function () {
                    model.destroyRecord().then(function () {
                        _this3.hardRefresh();
                        //ToDo - is used inlarge scale but should be remove instead to use models.reload() the array for findAll()....
                    });
                }, function () {});
            },
            save: function save(changeset) {
                var _this4 = this;

                var snapshot = changeset.snapshot();
                var _this = this;
                return changeset.cast(keys(Ember.get(_this, 'schema'))).validate().then(function () {
                    if (Ember.get(changeset, 'isValid')) {
                        _this.setLoading(true);
                        changeset.save().then(function () {

                            _this4.swal({
                                title: Ember.get(_this, 'messageSave'),
                                confirmButtonColor: "#14CA9C"
                            });

                            Ember.set(_this, 'isEditing', false);
                            _this.setLoading(false);
                        }).catch(function () {
                            Ember.get(_this, 'model.errors').forEach(function (_ref2) {
                                var attribute = _ref2.attribute,
                                    message = _ref2.message;

                                changeset.pushErrors(attribute, message);
                            });
                            _this4.setLoading(false);
                        });
                    }
                }).catch(function () {
                    changeset.restore(snapshot);
                });
            }
        }
    });
});