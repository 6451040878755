define('uwork-app-v2/components/admin/vacancy/plan/list-item/component', ['exports', 'uwork-app-v2/mixins/default-ds-action-mixin', 'uwork-app-v2/components/admin/vacancy/plan/new-item/validation', 'uwork-app-v2/vacancy-plan/model'], function (exports, _defaultDsActionMixin, _validation, _model) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_defaultDsActionMixin.default, {
        validation: _validation.default,
        schema: _model.schema,
        store: Ember.inject.service(),
        vacancyFeaturePlanPivot: Ember.computed.alias('model.vacancyFeaturePlanPivot'),
        addFeaturesModal: false,
        removeFeaturesModal: false,
        modelType: "vacancy-plan",
        messageSave: "Plan oppdatert.",
        messageDelete: "Plan slettet.",
        addVacancyPlanFeatures: Ember.computed('vacancyFeaturePlanPivot.[]', 'features.[]', function () {
            var vacancyPlanFeatures = Ember.get(this, 'vacancyFeaturePlanPivot').getEach('vacancyPlanFeature');
            var features = Ember.get(this, 'features');
            var filtered = [];

            features.forEach(function (item) {
                if (!vacancyPlanFeatures.getEach('name').contains(item.get('name'))) {
                    filtered.pushObject(item);
                }
            });

            return filtered;
        }),
        actions: {
            updateFeature: function updateFeature(feature) {
                var _this = this;

                this.setLoading(true);

                var plan = Ember.get(this, 'model');

                feature.save().then(function () {
                    _this.swal('Plan oppdatert!');
                    plan.reload();
                    _this.setLoading(false);
                }, function () {
                    _this.swal({
                        title: "En feil oppstår!",
                        timer: 1500,
                        showConfirmButton: false,
                        showCloseButton: false,
                        type: 'error'
                    });
                });
            },
            showAddFeature: function showAddFeature() {
                this.toggleProperty('addFeaturesModal');
            },
            showRemoveFeature: function showRemoveFeature() {
                this.toggleProperty('removeFeaturesModal');
            },
            removeFeature: function removeFeature(feature) {
                var _this2 = this;

                var plan = Ember.get(this, 'model');
                this.setLoading(true);
                feature.destroyRecord().then(function () {
                    _this2.setLoading(false);
                    plan.reload();
                });
            },
            addFeature: function addFeature(feature) {

                var plan = Ember.get(this, 'model');

                var data = {
                    plan_id: plan.get('id'),
                    feature_id: feature.get('id'),
                    order_id: feature.get('order_id')
                };

                var pivot = Ember.get(this, 'store').createRecord('vacancy-feature-plan-pivot', data);
                pivot.save().then(function (featurePlan) {
                    plan.get('vacancyFeaturePlanPivot').pushObject(featurePlan);
                    plan.reload();
                });
            }
        }
    });
});