define('uwork-app-v2/vacancy-plan-feature/model', ['exports', 'ember-data/model', 'ember-data/attr'], function (exports, _model, _attr) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.schema = undefined;
    var schema = exports.schema = {
        name: (0, _attr.default)('string'),
        description: (0, _attr.default)('string'),
        price: (0, _attr.default)('number'),
        icon: (0, _attr.default)('string'),
        status: (0, _attr.default)('boolean', { defaultValue: true }),
        extra: (0, _attr.default)('boolean', { defaultValue: false })
    };

    exports.default = _model.default.extend(schema, {
        checked: (0, _attr.default)('boolean', { defaultValue: false })
    });
});