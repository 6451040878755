define('uwork-app-v2/components/messages/conversation-item/component', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        tagName: 'div',
        // classNames: ['message'],
        account: Ember.inject.service('user/session-account'),
        store: Ember.inject.service(),
        me: Ember.computed.alias('model.me'),
        appService: Ember.inject.service('application'),
        interlocutor: Ember.computed.alias('model.interlocutor'),
        lastMessage: Ember.computed.alias('model.lastMessage'),
        isUnread: Ember.computed.alias('model.isUnread'),
        messages: Ember.computed.alias('model.messages'),
        classNameBindings: ['isUnread:unread'],
        description: Ember.computed('model.lastMessage.message', function () {
            var limit = 80;
            var text = Ember.get(this, 'model.lastMessage.message');
            if (text) {

                if (text.length > limit) {
                    text = text.substr(0, limit - 3) + "...";
                }
                return text;
            }
        }),
        isEmpty: false,
        _attr: {},
        click: function click() {
            var _model = Ember.get(this, 'model');
            var _this = this.$();

            Ember.$('.conversations').children().each(function () {
                Ember.$(this).removeClass("aktive");
            });

            _this.addClass("aktive");
            this.sendAction('showMessageItem', _model);
        },

        actions: {
            sendMessage: function sendMessage() {
                var _this2 = this;

                var message = Ember.get(this, '_attr.message');
                var user = Ember.get(this, 'interlocutor.user');

                if (message && message.length > 0) {
                    //jquery('.reply-textarea').addClass('errorMessage');

                    Ember.get(this, 'store').createRecord('message', {
                        message: message,
                        conversation: Ember.get(this, 'model'),
                        user: user
                    }).save().then(function () {
                        Ember.set(_this2, '_attr.message', '');
                        Ember.set(_this2, 'isEmpty', false);
                        //Ember.$('.reply-textarea').removeClass('errorMessage');
                    }).finally(function () {
                        Ember.get(_this2, 'appService').toggleMessage();
                    });
                } else {
                    this.swal({
                        title: "OBS!",
                        html: "Meldingen din inneholder ikke noe tekst. Ønsker du fortsatt å sende meldingen?",
                        type: 'warning',
                        confirmButtonText: "Send melding"
                    });
                }
            },
            readMessage: function readMessage() {
                if (Ember.get(this, 'isUnread')) {
                    var me = Ember.get(this, 'me');
                    Ember.set(this, 'model.isUnread', false);
                    me.save();
                }
            },
            delete: function _delete(model) {
                var conversationId = model.get('id');
                this.sendAction('deleteMsg', conversationId);
            }
        }

    });
});