define('uwork-app-v2/components/contractor/assignment/shift-item-edit/component', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        tagName: 'tr',
        classNames: ['bg-yellow'],
        didValidate: false,
        actions: {
            save: function save(model) {
                this.sendAction('save', model);
            },
            restTime: function restTime(shift, key, time, format) {
                this.sendAction('restTime', shift, key, time, format);
            },
            edit: function edit(shift) {
                this.sendAction('edit', shift);
            }
        }
    });
});