define('uwork-app-v2/components/auth/reset-form/validation', ['exports', 'ember-changeset-validations/validators'], function (exports, _validators) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = {
        token: [(0, _validators.validatePresence)(true)
        //validateLength({ is: 64 })
        ],
        password: [(0, _validators.validatePresence)(true), (0, _validators.validateLength)({ min: 6 })],

        passwordConfirmation: [(0, _validators.validatePresence)(true), (0, _validators.validateLength)({ min: 6 }), (0, _validators.validateConfirmation)({ on: 'password' })]
    };
});