define('uwork-app-v2/components/contractee/dashboard/card-orders/component', ['exports', 'moment'], function (exports, _moment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        store: Ember.inject.service(),
        classNames: ['card', 'mb-4'],
        size: 4,
        number: 1,
        status: '1',

        from_date: Ember.computed(function () {
            return '>' + (0, _moment.default)().format();
        }),
        didInsertElement: function didInsertElement() {
            this._super.apply(this, arguments);

            var size = Ember.get(this, 'size');
            var number = Ember.get(this, 'number');
            var status = Ember.get(this, 'status');
            var from_date = Ember.get(this, 'from_date');

            var orders = Ember.get(this, 'store').query('order', { from_date: from_date, number: number, size: size, status: status });
            Ember.set(this, 'orders', orders);
        }
    });
});