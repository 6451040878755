define('uwork-app-v2/components/bs/bs-badge/component', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        tagName: 'span',
        classNames: ['badge']
    });
});