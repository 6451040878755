define('uwork-app-v2/contractor/model', ['exports', 'ember-data/model', 'ember-data/attr', 'moment', 'ember-data/relationships', 'ember-cp-validations', 'uwork-app-v2/mixins/city-by-zipcode-mixin'], function (exports, _model, _attr, _moment, _relationships, _emberCpValidations, _cityByZipcodeMixin) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.schema = undefined;


    var Validations = (0, _emberCpValidations.buildValidations)({
        sex: [(0, _emberCpValidations.validator)('ds-error'), (0, _emberCpValidations.validator)('presence', true)],
        birthday: [(0, _emberCpValidations.validator)('ds-error'), (0, _emberCpValidations.validator)('presence', true)],
        zipCode: {
            description: 'Postnummer',
            validators: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('ds-error'), (0, _emberCpValidations.validator)('found-zipcode', { message: 'Feil postnummer' })]
        },
        place: [(0, _emberCpValidations.validator)('ds-error'), (0, _emberCpValidations.validator)('presence', true)],
        contractorTitle: [(0, _emberCpValidations.validator)('ds-error'), (0, _emberCpValidations.validator)('presence', true)]
    }, { debounce: 500 });

    var TYPE_FULL_TIME = 1;
    var TYPE_PART_TIME = 20;
    var TYPE_CONSULTANT = 30;

    var schema = exports.schema = {
        name: (0, _attr.default)(),
        title: (0, _attr.default)(),
        businessNumber: (0, _attr.default)(),
        bankAccountNumber: (0, _attr.default)(),
        profileImage: (0, _attr.default)(),
        profileVideo: (0, _attr.default)(),
        birthday: (0, _attr.default)('string'),
        nationality: (0, _attr.default)('string'),
        address: (0, _attr.default)('string'),
        zipCode: (0, _attr.default)('string'),
        place: (0, _attr.default)('string'),
        city: (0, _attr.default)('string'),
        sex: (0, _attr.default)('number'),
        lat: (0, _attr.default)('number'),
        lng: (0, _attr.default)('number'),
        country: (0, _attr.default)(),
        phone: (0, _attr.default)(),
        describeYourSelf: (0, _attr.default)(),
        contractorTitle: (0, _relationships.belongsTo)('contractor-title'),
        haveCar: (0, _attr.default)('boolean'),
        driverLicense: (0, _attr.default)('boolean'),
        wantTurnus: (0, _attr.default)('boolean'),
        travelDistance: (0, _attr.default)('number'),
        notes: (0, _attr.default)(),
        agreementType: (0, _attr.default)('number', { defaultValue: TYPE_FULL_TIME }),
        agreementPercentage: (0, _attr.default)('number')
    };

    exports.default = _model.default.extend(Validations, schema, _cityByZipcodeMixin.default, {
        profileProgress: (0, _attr.default)('number', { defaultValue: 0 }),

        setType: function setType(type) {
            Ember.set(this, 'agreementType', type);
        },
        setTypeFullTime: function setTypeFullTime() {
            Ember.set(this, 'agreementType', TYPE_FULL_TIME);
        },
        setTypePartTime: function setTypePartTime() {
            Ember.set(this, 'agreementType', TYPE_PART_TIME);
        },
        setTypeConsultant: function setTypeConsultant() {
            Ember.set(this, 'agreementType', TYPE_CONSULTANT);
        },


        isTypeFullTime: Ember.computed.equal('agreementType', TYPE_FULL_TIME),
        isTypePartTime: Ember.computed.equal('agreementType', TYPE_PART_TIME),
        isTypeConsultant: Ember.computed.equal('agreementType', TYPE_CONSULTANT),

        profileCompleted: Ember.computed.gte('profileProgress', 70),
        profileNotCompleted: Ember.computed.lt('profileProgress', 70),
        describe: Ember.computed('describeYourSelf', function () {
            var description = Ember.get(this, 'describeYourSelf');
            return description ? Ember.String.htmlSafe(description) : '';
        }),
        user: (0, _relationships.belongsTo)('user'),

        tel: Ember.computed('phone', function () {
            return Ember.get(this, 'phone');
        }),
        age: Ember.computed('birthday', function () {
            var birthday = Ember.get(this, 'birthday');
            return parseInt(_moment.default.duration((0, _moment.default)().diff(birthday)).asYears());
        }),

        hasNoCarOrDriverLicense: Ember.computed('haveCar', 'driverLicense', function () {
            return Ember.get(this, 'haveCar') || Ember.get(this, 'driverLicense');
        }),

        radius: Ember.computed('travelDistance', function () {
            return Ember.get(this, 'travelDistance') ? parseFloat(Ember.get(this, 'travelDistance')) * 40000 : 0;
        }),

        fullAddress: Ember.computed('address', 'zipCode', 'place', function () {
            var _this = this;
            return Ember.get(_this, 'address') + ', ' + Ember.get(_this, 'zipCode') + ' ' + Ember.get(_this, 'place');
        }),

        profession: Ember.computed('title', 'contractorTitle', function () {
            var profession = Ember.get(this, 'contractorTitle.name');

            if (profession) {
                return profession;
            }

            return Ember.get(this, 'title');
        }),
        filterModel: function filterModel(text) {
            var zipCodes = Ember.get(this, 'zipCodes');
            var selected = zipCodes.findBy('zipCode', text);
            return !!selected;
        },


        languages: (0, _relationships.hasMany)('language'),
        isVerified: Ember.computed.alias('user.isVerified')
    });
});