define('uwork-app-v2/contractor/vacancy/view/route', ['exports', 'ember-i18n'], function (exports, _emberI18n) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        title: (0, _emberI18n.translationMacro)('vacancy.contractor.pageName.view'),
        breadCrumb: {},
        beforeModel: function beforeModel() {
            this.setLoading(true);
        },
        model: function model(params) {
            return this.store.findRecord('vacancy', params.vacancy_id).catch(function () {
                // _this.set('notFoundError',true);
            });
        },
        afterModel: function afterModel() {
            var title = this.get('title');

            var bTitle = {
                title: title.string
            };

            this.setLoading(false);
            Ember.set(this, 'breadCrumb', bTitle);
        }
    });
});