define('uwork-app-v2/locales/nb/translations', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {

    /*
     Admin Pages
     */
    'admin.assignment.noResult': 'Fant ikke noen oppdrag som er utført.',

    /*
     Admin Pages - Assignments
     */
    'admin.assignment.new.PageName': 'Ny vakt',
    'admin.assignment.active.index': 'Utlyste vakter',
    'admin.assignment.active.PageName': 'Utlyste vakter',
    'admin.assignment.completed.PageName': 'Avsluttede vakter',
    'admin.assignment.lost.PageName': 'Avvist jobbforespørsler',
    'admin.assignment.onGoing.PageName': 'Pågående jobber',

    /*
     Admin Pages - Contractor/Edit
     */
    'admin.contractor.title': 'Bruker',
    'admin.contractor.view.gcc': 'Politiattester:',
    'admin.contractor.view.attachment': 'Andre vedlegg',
    'admin.contractor.view.firstName': 'Fornavn:',
    'admin.contractor.view.lastName': 'Etternavn:',
    'admin.contractor.view.email': 'E-postadresse:',
    'admin.contractor.view.sex': 'Gender:',
    'admin.contractor.view.notes': 'Notes:',
    'admin.contractor.view.substitute.title': 'Vikarinformasjon',

    'admin.contractor.view.agreement_type.title': 'Kontrakt: ',
    'admin.contractor.view.agreement_type.full_time': 'Fast ansatt',
    'admin.contractor.view.agreement_type.part_time': 'Midlertidig',
    'admin.contractor.view.agreement_type.consultant_time': 'Konsulent',

    'admin.contractor.view.agreement_percentage': 'Stillingsprosent',

    'admin.contractor.view.skilled': 'Faglært',
    'admin.contractor.view.skilled.placeholder': 'Velg yrker',
    'admin.contractor.view.unskilled': 'Ufaglært',
    'admin.contractor.view.unskilled.placeholder': 'Velg yrker',
    'admin.contractor.view.roles.placeholder': 'Velg rolle',
    'admin.contractor.view.roles': 'Roller',

    'admin.contractor.view.skills': 'Faglært:',
    'admin.contractor.view.categories': 'Ferdigheter:',
    'admin.contractor.view.badges': 'Utmerkelser:',
    'admin.contractor.view.badges.placeholder': 'Velg utmerkelser.',
    'admin.contractor.view.approvals': 'Godkjennlser:',
    'admin.contractor.view.approvals.placeholder': 'Velg godkjennlser.',
    'admin.contractor.view.tags': 'Tags:',
    'admin.contractor.view.tags.placeholder': 'Velg tags.',
    'admin.contractor.view.courses': 'Kurser:',
    'admin.contractor.view.courses.placeholder': 'Velg kurs',
    'admin.contractor.view.save': 'Lagre',

    'admin.contractor.requestsPageName': 'Requests',
    'admin.contractor.requestsPageName.list': 'List',
    'admin.contractor.requestsPageName.view': 'View',

    // 'admin.contractor.request.decision': 'View',
    'admin.contractor.request.view': 'View',
    'admin.contractor.request.accept': 'Accept',
    'admin.contractor.request.update': 'Update',
    'admin.contractor.request.decline': 'Decline',
    'admin.contractor.request.label.decision': 'Decision',
    'admin.contractor.request.label.remind': 'Remind Me',
    'admin.contractor.request.label.handler': 'Handler',
    'admin.contractor.request.label.update': 'Update',

    'admin.contractor.request.list.title': 'View',
    'admin.contractor.request.list.label.decision': 'Decision',
    'admin.contractor.request.list.label.type': 'Type',
    'admin.contractor.request.list.label.status': 'Status',
    'admin.contractor.request.list.label.createdAt': 'Create At',

    /* Admin - Vacancies */

    'admin.vacancy.pageName': 'Vacancies',
    'admin.vacancy.pageName.hired': 'Hired',
    'admin.vacancy.notFound': 'Kunne ikke finne ledig stilling',

    /*
     New Assignment
     */

    'admin.assignment.index.PageName': 'Vakter',
    'assignment.new.pageName': 'Ny vakt',
    'assignment.new.title': 'Vi trenger bare litt mer info så er vakten din klar!',
    'assignment.new.template.label': 'Kort beskrivelse av vakten:',
    'assignment.new.template.option': 'Bruk mal',
    'assignment.new.description': 'Kort beskrivelsen av vakten.',
    'assignment.new.type.label': 'Metoder',
    'assignment.new.type.option': 'Velg metode',

    'assignment.new.period.label': 'Velg turnus',
    'assignment.new.period.option': 'Velg turnus',

    //'assignment.new.opitons.label': 'Etter geografisk område',
    'assignment.new.place.label': 'Sted:',
    'assignment.new.radius.label': 'Radius:',
    'assignment.new.selectContractee.label': 'Bedrift',
    'assignment.new.selectContractor.option': 'Velg bedrift',
    'assignment.new.selectContractor.label': 'Vikare',
    'assignment.new.shift.label': 'Vakttype:',
    'assignment.new.shift.option': 'Velg vakttype',

    'assignment.new.fromDate.label': 'Start dato',
    'assignment.new.toDateSelect.label': 'Slutt dato',
    'assignment.new.toDate.label': 'Fra dato',

    'assignment.new.fromTime.label': 'Tid fra',
    'assignment.new.toTime.label': 'Tid til',
    'assignment.new.dayDuration.label': 'Er vakten for flere døgn?',
    'assignment.new.button.createShifts': 'Fortsett',
    'assignment.new.button.updateShifts': 'Oppdater',
    'assignment.select-candidate': 'Velg Vikar',

    /*
     View Assignment
     */
    'admin.assignment.view.PageName': 'Visning av oppdrag',
    'assignment.view.notFound': 'Fant ikke bestilling.',
    'assignment.view.action.selectWorker': 'Velg Vikar',
    'assignment.view.action.delete': 'Slett oppdrag',
    'assignment.view.order': 'GÅ TIL OPPDRAG',

    /*
    *
    *
    * Component: admin/assignment/assignment-details
    *
    *
    * */
    'assignment.view.approved': 'Godkjent av:',

    'assignment.view.title': 'Bestilling',
    'assignment.view.counter': '{{counter}} visninger',
    'assignment.view.description.title': 'Beskrivelse:',
    'assignment.view.address': 'Adresse:',
    'assignment.view.accepted.shift.allDay.label': 'Pris (Time/Døgn):',
    'assignment.view.accepted.shift.other.label': 'Timepris:',

    'assignment.view.shift.nightShift.label': 'Timepris, nattvakt::',
    'assignment.view.shift.allDay.label': 'Pris, faglært (Time/Døgn):',
    'assignment.view.shift.fixedRateUnskilled.label': 'Pris, ufaglært (Time/Døgn):',
    'assignment.view.shift.rateSkilledWorker.label': 'Timepris, faglært:',
    'assignment.view.shift.rateUnskilledWorker.label': 'Timepris, ufaglært:',

    'assignment.view.gcc.label': 'Politiattest:',
    'assignment.view.gcc.recent.label': 'Last ned',
    'assignment.view.gcc.download': 'Nedlasting',
    'assignment.view.gcc.notFound.label': 'Politiattest:',

    'assignment.view.nda.label': ' Taushetseklæring:',
    'assignment.view.nda.recent.label': 'Last ned',
    'assignment.view.nda.download': 'Nedlasting',
    'assignment.view.nda.notFound.label': 'Taushetseklæring mangler!',

    'form.btn.status.default': 'Default',
    'form.btn.status.profileCompleted': 'Over 70%',
    'form.btn.status.applied': 'Søkt verifisering',
    'form.btn.status.approved': 'Godkjent',
    'form.btn.status.declined': 'Avslått',
    'form.btn.status.verified': 'Verifisert',
    'form.btn.status.deverified': 'Avverifisert',

    'form.btn.allowEdit': 'Tillatt redigering',
    'form.btn.disableEdit': 'Deaktiver redigering',
    'form.btn.add': 'Legg til',
    'form.btn.delete': 'Slett',
    'form.btn.save': 'Lagre',
    'form.btn.download': 'Last ned',
    'form.btn.request': 'Send forespørsel', //Its being used for data export request
    'form.btn.edit': 'Rediger',
    'form.btn.feature': 'Features',
    'form.btn.continue': 'Fortsett',
    'form.btn.saveNext': 'Lagre og fortsett',
    'form.btn.complete': 'Fullfør',
    'form.btn.confirm': 'Bekreft',
    'form.btn.skip': 'Hopp over',
    'form.btn.close': 'Lukk',
    'form.btn.saveAnyWay': "Lagre og fortsett",
    'form.btn.closeModal': 'Avbryt',
    'form.btn.reject': 'Avvis vikar ',
    'form.btn.upload': 'Last opp',
    'form.btn.clear': 'Fjern',

    'form.btn.publish': 'Publiser stilling',
    'form.btn.next': 'Fortsett',
    'form.btn.prev': 'Tilbake',

    /*
    *
    *
    * Component: admin/assignment/requests-item
    *
    * ToDo - check the translation
    * */
    //'assignment.view.requests.label': 'Søkere',

    'assignment.view.requests.label': {
      'zero': 'Ingen søkere',
      'one': '1 søker',
      'other': '{{count}} søkere'
    },

    /*
    *
    *
    * Component: admin/assignment/all-requests
    *
    *
    * */
    'assignment.view.requests-all.label': 'Sendt til',

    'assignment.view.verify-request.label': 'Foretrukket vikar/er fra dere for oppdraget.',

    /*
    *
    *
    * Component: admin/assignment/contractor-info
    *
    *
    * */
    'assignment.view.contractor-info.title': ' Valgt vikar',
    'assignment.view.contractor-info.rating.title': 'Rate for utført jobb:',
    'assignment.view.contractor-info.rating.contractee.title': 'Deres tilbakemelding:',
    'assignment.view.contractor-info.rating.contractor.title': 'Vikarens tilbakemelding:',
    /*
    *
    *
    * Component: admin/assignment/display-shifts
    *
    *
    * */
    // 'assignment.view.shifts.fromDate': ' Dato',
    // 'assignment.view.shifts.fromTime': ' Dato',
    // 'assignment.view.shifts.toTime': ' Dato',

    /*
        Assignment - Notification Section
    */

    'assignment.new.notificationTypeFrom.title': 'Hvem ønsker du at skal bli varslet om vakten?',
    'assignment.new.radio.notificationTypeFrom.myWorker.label': 'Mine vikarer',
    'assignment.new.radio.notificationTypeFrom.myWorker.description': 'Bedriftens vikarer',
    'assignment.new.radio.notificationTypeFrom.allWorker.label': 'Alle vikarer',
    'assignment.new.radio.notificationTypeFrom.allWorker.description': 'Vi anbefaler at også at nye vikarer kan melde interesse for vakten. På den måten er du sikker på at du får dekket vakten.',
    'assignment.new.button.submit': 'Legg ut vakt',

    /*
     Assignment - OrderShifts Section
     */

    'assignment.new.orderShift.title': 'Vakter',

    'assignment.orderShift.startDate': 'Start dato',
    'assignment.orderShift.fromTime': 'Tid fra',
    'assignment.orderShift.toTime': 'Tid til',
    'assignment.orderShift.hours': 'Varighet',
    'assignment.orderShift.duration': 'timer',

    'assignment.contractor.orderShift.duration': 'Dato/tid',
    'assignment.contractor.orderShift.workingHours': 'Varighet',
    'timesheet.contractor.table.header.time': 'Arbeidstimer',

    'assignment.orderShift.totalHorus': 'Hours',
    'assignment.orderShift.date': 'Dato',
    'assignment.orderShift.started_at': 'Fra kl',
    'assignment.orderShift.end_at': 'Til kl',
    'assignment.orderShift.vaktype': 'Vaktype',
    'assignment.orderShift.number': 'Antall',
    'assignment.orderShift.totalHours': 'Varighet',

    'assignment.orderShift.restHours': 'Hviletid',
    'assignment.orderShift.leave.type': 'Fraverstype:',
    'assignment.orderShift.leave.description': 'Tilleggsinformasjon:',
    'assignment.orderShift.leave.reported_at': 'Rapportert til:',
    'assignment.orderShift.price': 'Pris',
    'assignment.orderShift.hour': 'Timer/Døgn',
    'assignment.orderShift.additional': 'Tillegg',
    'assignment.orderShift.amount': 'Beløp',

    'assignment.orderShift.edit.fixedAmount': '1 døgn',
    'assignment.orderShift.edit.sumAdditionalHours': '{{sumAdditionalHours}} timer',
    'assignment.orderShift.edit.dateFrom': 'Dato fra',
    'assignment.orderShift.edit.dateTo': 'Dato til',
    'assignment.orderShift.edit.fromTime': 'Klokkeslett fra:',
    'assignment.orderShift.edit.toTime': 'Klokkeslett til:',
    'assignment.orderShift.edit.description': 'Kommentar:',
    'assignment.orderShift.edit.restDescription': 'Kommentar:',
    'assignment.orderShift.edit.workedHours': 'timer',
    'assignment.orderShift.edit.close': 'Lukk',
    'assignment.orderShift.edit.save': 'Lagre',
    'assignment.orderShift.edit.leave.save': 'Meld fravær',

    /*
        Assignment Card
     */

    'assignment.card.viewOrder': 'GÅ TIL OPPDRAG',
    'assignment.card.selectCandidate': 'VELG VIKAR',
    'assignment.card.fromDate': 'Fra dato',
    'assignment.card.more': 'Mer',
    'assignment.card.hourTotal': '{{hours}} timer total',
    'assignment.card.hourlyRate': '{{rate}} Timepris',
    'assignment.card.rateNightShift': '{{rate}} per time',
    'assignment.card.rateSkilledWorker': '{{rate}} per time - faglært',
    'assignment.card.rateUnskilledWorker': '{{rate}} per time - ufaglært',
    'assignment.card.turnus': 'Turnus',
    'assignment.card.createdAt': 'Opprettet for {{time}}',

    /*
    *
    *
    * Component: contractor/assignment/contractee-info
    *
    *
    * */

    'contratee.info.phone': 'Ring',
    'contratee.info.email': 'Send melding',
    'contratee.info.rating': 'Rating',
    'contratee.info.modal.writeEmail': 'Din melding',
    'contratee.info.modal.messagePlaceholder': 'Din melding ...',
    'contratee.info.modal.close': 'Lukk',
    'contratee.info.modal.sent': 'Send melding',

    /*
         Admin - Contractor Edit Mode
     */
    'admin.contractor.PageName': 'Profile',
    'admin.contractor.profile.PageName': 'Edit Vikar',
    'admin.contractee.profile.PageName': 'Edit Bedrift',
    'admin.contractee.view.firstName': 'Fornavn:',
    'admin.contractee.view.lastName': 'Etternavn:',
    'admin.contractee.view.email': 'E-postadresse:',
    'admin.contractee.view.name': 'Bedriftsnavn',
    'admin.contractee.view.category': 'Bransjer',

    'admin.contractee.view.skilled-worker': 'Timepris faglært dag/kveld',
    'admin.contractee.view.unskilled-worker': 'Timepris ufaglært dag/kveld',
    'admin.contractee.view.night-shift': 'Timepris nattvakt',
    'admin.contractee.view.night-skilled': 'Døgnpris faglært',
    'admin.contractee.view.night-unskilled': 'Døgnpris ufaglært',

    'admin.contractee.view.roles.placeholder': 'Velg rolle',
    'admin.contractee.view.roles': 'Roller',

    'admin.contractee.view.businessNumber': 'Organisasjonsnummer',

    /*
       Admin - Dashboard
    */
    'admin.dashboard.PageName': 'Dashboard',

    'admin.dashboard.stats.contracteesAll': 'Alle Bedrifter',
    'admin.dashboard.stats.contracteesVerified': 'Verifiserte Bedrifter',
    'admin.dashboard.stats.contractorsAll': 'Alle Vikarer',
    'admin.dashboard.stats.contractorsVerified': 'Verifiserte Vikarer',
    'admin.dashboard.stats.label': 'FRA START',

    'admin.dashboard.recent.contractee.title': 'Siste registrerte bedrifter',
    'admin.dashboard.recent.contractee.action.item': ' VIS PROFIL',
    'admin.dashboard.recent.contractee.action.items': 'Vis flere',

    'admin.dashboard.recent.contractor.title': 'Siste registrerte vikarer',
    'admin.dashboard.recent.contractor.action.item': 'VIS PROFIL',
    'admin.dashboard.recent.contractor.action.items': 'Vis flere',

    /*
        Admin - Users List
    */

    'admin.users.PageName': 'Vis flere',
    'admin.users.contractee.PageName': 'Bedrifter',
    'admin.users.contractor.PageName': 'Vikarer',

    'admin.users.contractee.searching': 'Søker ...',
    'admin.users.contractee.noResultFound': 'Fant ikke noe ...',
    'admin.users.contractor.searching': 'Søker ...',
    'admin.users.contractor.noResultFound': 'Fant ikke noe ...',

    /*
        Admin - Advanced Filter
    */

    /*
       Admin - Advanced Filter
    */
    'admin.filter.radio.status.all': ' Vis alle',
    'admin.filter.radio.status.default': ' Default',
    'admin.filter.radio.status.confirmed': 'Bekreftet',
    'admin.filter.radio.status.rejected': 'Avslått',
    'admin.filter.radio.status.pending': ' På vent',
    'admin.filter.radio.status.approved': ' Godkjent',
    'admin.filter.radio.status.verified': ' Verifisert',

    'admin.filter.radio.stage.all': ' Vis alle',
    'admin.filter.radio.stage.default': 'Default (Profil under 70%)',
    'admin.filter.radio.stage.profileCompleted': 'Profil over 70%',
    'admin.filter.radio.stage.contractor.applied': 'Søkt om verifisering',
    'admin.filter.radio.stage.contractee.applied': 'Søkt om verifisering',
    'admin.filter.radio.stage.approved': ' Godkjent',
    'admin.filter.radio.stage.declined': ' Avslått',
    'admin.filter.radio.stage.verified': ' Verifisert',
    'admin.filter.radio.stage.deverified': ' Avverifisert',

    'admin.filter.contractee.query.label': 'Søk etter: Bedriftsnavn, e-post, fornavn, etternavn og telefonnummer.',
    'admin.filter.contractee.categories.label': 'Filtrer bransjer.',

    'admin.filter.contractor.query.label': 'Søk etter: E-post, fornavn, etternavn og telefonnummer.',
    'admin.filter.contractor.categories.label': 'Filtrer på yrke/bransje.',
    'admin.filter.contractor.skills.label': 'Filtrer på ferdigheter.',
    'admin.filter.contractor.title.label': 'Filtrer tittel.',
    'admin.filter.contractor.skilled.label': 'Faglært.',
    'admin.filter.contractor.unskilled.label': 'Ufaglært.',
    'admin.filter.contractor.haveCar.label': 'Bil.',
    'admin.filter.contractor.driveLicense.label': 'Førerkort.',
    'admin.filter.contractor.tags.label': 'Filtrer på tags.',
    'admin.filter.contractor.wantTurnus.label': 'Ønsker turnus.',

    /*
        Admin - Emails
    */
    'admin.email.PageName': 'Emails',
    'admin.email.view.PageName': 'Item',
    'admin.email.id': 'ID',
    'admin.email.subject': 'Subject',
    'admin.email.from': 'From',
    'admin.email.to': 'To',
    'admin.email.headers': 'Headers',
    'admin.email.body': 'Body',
    'admin.email.date': 'Date',
    /*
        Admin - Management
    */
    'admin.manage.PageName': 'Administrasjon',

    /**
     *  Admin - Management - Agreements
     */

    'admin.agreement.pageName': 'Kontrakter',
    'admin.agreement.new.title': 'Ny kontrakt',

    /**
     *  Admin - Management - New Agreements Form
     */

    'admin.agreement.form.title': 'Tittel:',
    'admin.agreement.form.contracts.title': 'Kontrakter',
    'admin.agreement.form.details': 'Innhold:',
    'admin.agreement.form.type.contractor.label': 'Til Vikare:',
    'admin.agreement.form.type.contractee.label': 'Til Bedrifter:',

    'admin.agreement.form.status.public': 'Published:',
    'admin.agreement.form.status.draft': 'Draft:',
    'admin.agreement.form.status.archive': 'Archived:',

    'admin.agreement.form.save': 'Lagre',
    'admin.agreement.form.clear': 'Fjern',
    'admin.agreement.form.close': 'Lukk',
    'admin.agreement.form.edit': 'Rediger',
    'admin.agreement.form.delete': 'Slett',

    /**
     *  Admin - Management - Skills
     */
    'admin.manage.skills.pageName': 'Ferdigheter',
    'admin.manage.skills.newItem': 'Ny ferdighet',
    'admin.manage.skills.items': 'Ferdigheter',

    'admin.manage.skills.form.name': 'Navn',
    'admin.manage.skills.form.description': 'Beskrivelse',

    /**
     * Admin - Management - Approvals
     */

    'admin.approvals.pageName': 'Godkjennelser',

    /**
     *  Admin - Management - Salary
     */

    'admin.manage.payment.company': 'Bedrift',
    'admin.manage.payment.uworker': 'Vikar',
    'admin.manage.payment.seniority': 'Ansiennitet',
    'admin.manage.payment.hourlyBasicSalary': 'Timeslønn',
    'admin.manage.payment.dailyBasicSalary': 'Døgnsats',

    /**
     *  Admin - Management - Approvals Form
     */

    'admin.manage.approvals.title': 'Ny godkjennelse',
    'admin.manage.approvals.list.title': 'Godkjennelser',

    'admin.manage.approvals.form.title': 'Tittel',
    'admin.manage.approvals.form.description': 'Beskrivelse',
    'admin.manage.approvals.form.icon': 'Ikon',

    'admin.manage.approvals.form.save': 'Lagre',
    'admin.manage.approvals.form.clear': 'Fjern',
    'admin.manage.approvals.form.close': 'Lukk',
    'admin.manage.approvals.form.edit': 'Rediger',
    'admin.manage.approvals.form.delete': 'Slett',

    /**
     * Admin - Management - Badges
     */

    'admin.badges.pageName': 'Utmerkelser',

    'admin.manage.badges.form.title': 'Tittel',
    'admin.manage.badges.form.description': 'Beskrivelse',
    'admin.manage.badges.form.icon': 'Ikon',
    /**
     *  Admin - Management - Badges Form
     */

    'admin.manage.badges.title': 'Ny utmerkelse',
    'admin.manage.badges.list.title': 'Utmerkelser',

    'admin.manage.badges.form.save': 'Lagre',
    'admin.manage.badges.form.clear': 'Fjern',
    'admin.manage.badges.form.close': 'Lukk',
    'admin.manage.badges.form.edit': 'Rediger',
    'admin.manage.badges.form.delete': 'Slett',

    /**
     * Admin - Management - Category
     */

    'admin.category.pageName': 'Yrker',

    /**
     * Admin - Management - Category
     */

    'admin.salary.pageName': 'Salary',

    /**
     *  Admin - Management - Category Form
     */

    'admin.manage.category.title': 'Ny yrke',
    'admin.manage.category.items': 'Yrker',
    'admin.manage.category.form.name': 'Navn',
    'admin.manage.category.form.description': 'Beskrivelse',

    'admin.manage.category.form.save': 'Lagre',
    'admin.manage.category.form.clear': 'Fjern',
    'admin.manage.category.form.close': 'Lukk',
    'admin.manage.category.form.edit': 'Rediger',
    'admin.manage.category.form.delete': 'Slett',

    /**
     * Admin - Management - Courses
     */

    'admin.courses.pageName': 'Kurser',

    /**
     *  Admin - Management - Courses Form
     */

    'admin.manage.courses.title': 'Ny kurs',
    'admin.manage.courses.items': 'Kursr',
    'admin.manage.courses.form.title': 'Tittel',
    'admin.manage.courses.form.description': 'Beskrivelse',
    'admin.manage.courses.form.icon': 'Ikon',

    'admin.manage.courses.form.save': 'Lagre',
    'admin.manage.courses.form.clear': 'Fjern',
    'admin.manage.courses.form.close': 'Lukk',
    'admin.manage.courses.form.edit': 'Rediger',
    'admin.manage.courses.form.delete': 'Slett',

    /**
     * Admin - Management - Faqs
     */

    'admin.faqs.pageName': 'Ofte stilte spørsmål',

    /**
     *  Admin - Management - Courses Form
     */

    'admin.manage.faqs.pageName': 'Ofte stilte spørsmål',
    'admin.manage.faqs.title': 'Ny spørsmål',
    'admin.manage.faqs.items': "Ofte stilte spørsmål",

    'admin.manage.faqs.form.title': 'Spørsmål',
    'admin.manage.faqs.form.description': 'Svar',

    'admin.manage.faqs.form.save': 'Lagre',
    'admin.manage.faqs.form.clear': 'Fjern',
    'admin.manage.faqs.form.close': 'Lukk',
    'admin.manage.faqs.form.edit': 'Rediger',
    'admin.manage.faqs.form.delete': 'Slett',

    /**
     *  Admin - Management - Role Form
     */

    'admin.manage.roles.pageName': 'Roller',
    'admin.manage.roles.title': 'Ny rolle',
    'admin.manage.roles.items': "Roller",

    'admin.manage.roles.form.name': 'Navn',

    'admin.manage.roles.form.save': 'Lagre',
    'admin.manage.roles.form.clear': 'Fjern',
    'admin.manage.roles.form.close': 'Lukk',
    'admin.manage.roles.form.edit': 'Rediger',
    'admin.manage.roles.form.delete': 'Slett',

    /**
     * Admin - Management - Faqs
     */

    'admin.my-workers.pageName': 'Mine Vikarer',

    /**
     *  Admin - Management - Courses Form
     */

    'admin.manage.my-workers.pageName': 'Ofte stilte spørsmål',
    'admin.manage.my-workers.title': 'Legg til mine vikarer',

    'admin.manage.my-workers.contractor.title': "Vikar",
    'admin.manage.my-workers.contractor.option.title': "Søk etter vikar",
    'admin.manage.my-workers.contractee.title': "Bedrift",
    'admin.manage.my-workers.contractee.option.title': "Søk etter bedrift",
    'admin.manage.my-workers.form.save': 'Legg til',

    /**
     * Admin - Management - User Data Export
     */

    'admin.manage.ude.notFound': 'Ingen brukerdataeksport funnet!',

    /**
     * Admin - Management - Order Description Template
     */

    'admin.order-desc-template.pageName': 'Vakt malerr',

    /**
     *  Admin - Management - Order Description Form
     */

    'admin.manage.order-desc-template.title': 'Opprett ny mal',
    'admin.manage.order-desc-template.items': 'Lagrede maler',

    'admin.manage.order-desc-template.form.name': 'Navn',
    'admin.manage.order-desc-template.form.body': 'Mal',
    'admin.manage.order-desc-template.form.type.label': 'Publiser',
    'admin.manage.order-desc-template.form.type.private': 'For meg',
    'admin.manage.order-desc-template.form.type.public': 'For alle',

    'admin.manage.order-desc-template.form.save': 'Lagre',
    'admin.manage.order-desc-template.form.clear': 'Fjern',
    'admin.manage.order-desc-template.form.close': 'Lukk',
    'admin.manage.order-desc-template.form.edit': 'Rediger',
    'admin.manage.order-desc-template.form.delete': 'Slett',

    /**
     * Admin - Management - Tags
     */

    'admin.tags.pageName': 'Tags',

    /**
     *  Admin - Management - Order Description Form
     */

    'admin.manage.tags.title': 'New Tag',
    'admin.manage.tags.items': 'Tags',

    'admin.manage.tags.form.name': 'Tag Name',

    'admin.manage.tags.form.save': 'Lagre',
    'admin.manage.tags.form.clear': 'Fjern',
    'admin.manage.tags.form.close': 'Lukk',
    'admin.manage.tags.form.edit': 'Rediger',
    'admin.manage.tags.form.delete': 'Slett',

    /**
     * Admin - Management - Tags
     */
    'admin.manage.category.pageName': 'Yrker',

    'admin.manage.titles.pageName': 'Yrkes titler',

    /**
     *  Admin - Management - Order Description Form
     */
    'admin.manage.titles.newItem': 'Ny tittel',
    'admin.manage.titles.form.title': 'Tittel',

    'admin.manage.titles.item': 'Ny tittel',
    'admin.manage.titles.items': "Yrkes titler",

    'admin.manage.titles.form.title.label': 'Tittel',

    'admin.manage.titles.form.save': 'Lagre',
    'admin.manage.titles.form.clear': 'Fjern',
    'admin.manage.titles.form.close': 'Lukk',
    'admin.manage.titles.form.edit': 'Rediger',
    'admin.manage.titles.form.delete': 'Slett',

    /**
     * Admin - Management - User Requests
     */

    /**
     * Admin - Management - User Requests Form
     */
    'admin.manage.user-request.form.accept': 'Aksepter',
    'admin.manage.user-request.form.decline': 'Avslå',
    'admin.manage.user-request.form.save': 'Lagre',
    'admin.manage.user-request.form.clear': 'Fjern',

    /**
     *  Admin - Vacancy - Occupation
     */

    'admin.vacancy.occupation.newItem': 'New Stillingsfunksjon',
    'admin.vacancy.occupation.items': 'Stillingsfunksjon',

    'admin.vacancy.occupation.label.name': 'Name',

    /**
     *  Admin - Vacancy - Features
     */

    'admin.vacancy.feature.newItem': 'New Feature',
    'admin.vacancy.feature.items': 'Features',

    'admin.vacancy.feature.label.name': 'Name',
    'admin.vacancy.feature.label.description': 'Description',
    'admin.vacancy.feature.label.price': 'Price',
    'admin.vacancy.feature.label.icon': 'Icon',
    'admin.vacancy.feature.label.status': 'Status',
    'admin.vacancy.feature.label.status.activate': 'Activate',
    'admin.vacancy.feature.label.status.deactivate': 'Deactivate',

    'admin.vacancy.feature.label.extra': 'Extra Feature',
    'admin.vacancy.feature.label.extra.activate': 'Is Extra Feature',
    'admin.vacancy.feature.label.extra.deactivate': "Isn't Extra Feature",

    'admin.vacancy.plan.newItem': 'New Plan',
    'admin.vacancy.plan.items': 'Plans',

    'admin.vacancy.plan.label.name': 'Name',
    'admin.vacancy.plan.label.description': 'Description',
    'admin.vacancy.plan.label.price': 'Price',
    'admin.vacancy.plan.label.status': 'Status',
    'admin.vacancy.plan.label.status.activate': 'Activate',
    'admin.vacancy.plan.label.status.deactivate': 'Deactivate',

    /**
     *  Admin - Vacancy - Planes
     */

    /**
       * Admin - Metrics
       */
    'admin.metrics.pageName': 'Metrics',
    'admin.metrics.datePeriod': 'Periode',
    'admin.metrics.typePeriod': 'Visning',

    /**
     *
     * Admin Timesheet - Filter
     */

    /**
     *
     * Timesheet - Global Filter
     */
    'timesheet.PageName': 'Timelister',
    'timesheet.fromPeriod': 'Periode:',
    'timesheet.year': 'År',
    'timesheet.month': 'Måned',
    'timesheet.shiftType': 'Vakt type:',
    'timesheet.shiftType.prompt': 'Alle',
    'timesheet.contractee': 'Bedrift:',
    'timesheet.contractee.prompt': 'Alle',
    'timesheet.contractor': 'Vikar:',
    'timesheet.contractor.prompt': 'Alle',

    'timesheet.action.email': 'E-post',
    'timesheet.action.pay': 'Merk som betalt',
    'timesheet.action.download': 'Last ned',
    'timesheet.action.print': 'Skriv ut',

    'timesheet.table.header.user': 'Vikar/Bedrift',
    'timesheet.table.header.check_all': 'Markere alle',
    'timesheet.table.header.user.contractor': 'Vikar',
    'timesheet.table.header.user.contractee': 'Bedrift',
    'timesheet.table.header.time': 'Antall timer',
    'timesheet.table.header.leave': 'Fravær',
    'timesheet.table.header.shift': 'Vakttype',
    'timesheet.table.header.date': 'Dato',
    'timesheet.table.header.number': 'Antall',
    'timesheet.table.header.price': 'Pris',
    'timesheet.table.header.hours': 'Timer/Døgn',
    'timesheet.table.header.additional': 'Tillegg',
    'timesheet.table.header.amount': 'Beløp',
    'timesheet.table.header.edit': 'Edit',

    'timesheet.table.footer.total': 'TOTAL',
    'timesheet.table.footer.fee': 'UWORK SERVICE FEE',
    'timesheet.table.footer.week': 'UTBETALT VIKAR',
    'timesheet.table.footer.paid': 'UTBETALT',

    /**
     * contractee/agreement/display-main
     */

    'contractee.agreemnt.display-main.info': 'For å kunne bruke uWork AS må du godta avtalen.',
    'contractee.agreemnt.display-main.acceptAgreement': 'Jeg godtar avtalen',
    'contractee.agreemnt.display-main.action.confirm': 'Bekreft',
    'contractee.agreemnt.display-main.action.logout': 'Logg ut',

    /**
     * Contractee Active Assignment
     */

    'contractee.assignment.active.PageName': 'Aktive forespørsler',
    'contractee.assignment.active.title.label': 'Aktive forespørsler',
    'contractee.assignment.active.noResult': 'Ingen utlyste vakter.',

    /**
     * Contractee Incex Assignment
     */

    'contractee.assignment.index.PageName': 'Vakter',
    'contractee.assignment.index.title.label': 'Vakter',
    'contractee.assignment.index.noResult': 'Vakt ikke funnet. Ser ut som den er slettet.',

    /**
     * Contractee Completed Assignment
     */

    'contractee.assignment.completed.PageName': 'Avsluttede vakter',
    'contractee.assignment.completed.title.label': 'Avsluttede vakter',
    'contractee.assignment.completed.noResult': 'Ingen bestilte vakter.',

    /**
     * Contractee Ongoing Assignment
     */

    'contractee.assignment.ongoing.PageName': 'Pågående jobber',
    'contractee.assignment.ongoing.title.label': 'Pågående vakter',
    'contractee.assignment.ongoing.noResult': 'Ingen utførte vakter.',

    /**
     * Contractee Assignment NotFound
     */

    'contractee.assignment.NotFound': ' Vakt ikke funnet. Ser ut som den er slettet.',

    /**
     * Contractee Select Candidate - assignment/assignment-details
     */
    'contractee.search-contractors.latest': 'Siste {{count}} søkere',
    'contractee.search-contractors.selected': 'Valgte søkere',
    'contractee.search-contractors.all': 'Søkere',

    'contractee.search-contractors.filter.skilled': 'Faglært',
    'contractee.search-contractors.filter.unskilled': 'Ufaglært',
    'contractee.search-contractors.filter.havCar': 'Bil',
    'contractee.search-contractors.filter.driveLicense': 'Førerkort',
    'contractee.search-contractors.filter.wantTurnus': 'Ønsker turnus',

    'contractee.assignment.selectCandidate.pageName': 'Velg vikar',
    'contractee.assignment.company-details.address': 'Adresse:',
    'contractee.assignment.company-details.shiftType': 'Vakttype:',
    'contractee.assignment.company-details.hourRate': 'Timepris:',
    'contractee.assignment.company-details.rateNightShift': 'Timepris, nattvakt:',
    'contractee.assignment.company-details.rateSkilledWorker': 'Timepris, faglært:',
    'contractee.assignment.company-details.rateUnskilledWorker': 'Timepris, ufaglært:',

    'contractee.assignment.company-details.gcc': 'Politiattest:',
    'contractee.assignment.company-details.noGcc': 'Politiattest mangler!:',

    'contractee.assignment.company-details.nda': 'Taushetseklæring:',
    'contractee.assignment.company-details.noNda': 'Taushetseklæring mangler!',

    'contractee.assignment.company-details.download': 'Nedlasting',
    //'contractee.assignment.company-details.phone': 'Telefon/Mobil',
    'contractee.assignment.company-details.web': 'Nettside',

    'contractee.filter.contractor.query.label': 'Søk etter: E-post, fornavn, etternavn og telefonnummer.',
    'contractee.filter.contractor.categories.label': 'Filtrer på yrke/bransje.',
    'contractee.filter.contractor.skills.label': 'Filtrer på ferdigheter.',
    'contractee.filter.contractor.title.label': 'Filtrer tittel.',
    'contractee.filter.contractor.skilled.label': 'Faglært.',
    'contractee.filter.contractor.unskilled.label': 'Ufaglært.',
    'contractee.filter.contractor.haveCar.label': 'Bil.',
    'contractee.filter.contractor.driveLicense.label': 'Førerkort.',
    'contractee.filter.contractor.tags.label': 'Filtrer på tags.',
    'contractee.filter.contractor.wantTurnus.label': 'Ønsker turnus.',

    /**
     * Contractee View Assignment - contractee/assignment/assignment-details
     */
    'contractee.assignment.view.PageName': 'Visning av oppdrag',
    'contractor.assignment.view.PageName': 'Visning av oppdrag',

    // contractee - Agreements - Settings
    'contractee.agreement.pageName': 'Deres avtale',
    'contractee.agreement.description': 'Du har godtatt avtalen den: {{date}} fra følgende IP-addresse: {{ip}}',
    'contractee.agreement.confirmation': 'Publisert: {{created}}. Sist oppdatert: {{updated}}',

    /**
     * Contractee Assignment Details
     */

    'contractee.assignment.assignment-details.title': 'Bestilling',

    'contractee.assignment.assignment-details.views': {
      'zero': 'Ingen visninger',
      'one': '1 visning',
      'other': '{{count}} visninger'
    },

    'contractee.assignment.assignment-details.description': 'Beskrivelse:',
    'contractee.assignment.assignment-details.address': 'Adresse:',
    'contractee.assignment.assignment-details.shiftType': 'Vakttype:',
    'contractee.assignment.assignment-details.price_time': 'Pris (Time/Døgn):',
    'contractee.assignment.assignment-details.hourly': 'Timepris:',
    'contractee.assignment.assignment-details.night_shift_price': 'Timepris, nattvakt:',
    'contractee.assignment.assignment-details.unskilled_price': 'Pris, ufaglært (Time/Døgn):',
    'contractee.assignment.assignment-details.skilled_price': 'Timepris, faglært:',

    'contractee.assignment.assignment-details.gcc': 'Politiattest:',
    'contractee.assignment.assignment-details.gcc.expire': 'Last ned',
    'contractee.assignment.assignment-details.gcc.notFound': 'Politiattest mangler!',
    'contractee.assignment.assignment-details.download': 'Nedlasting',

    'contractee.assignment.assignment-details.nda': 'Taushetseklæring:',
    'contractee.assignment.assignment-details.nda.expire': 'Last ned',
    'contractee.assignment.assignment-details.nda.notFound': 'Taushetseklæring mangler!',

    /**
     *
     * contractee.assignment.contractor-info
     *
     */
    'contractee.assignment.contractor-info.selected-worker': 'Valgt vikar',
    'contractee.assignment.contractor-info.phone': 'Ring',
    'contractee.assignment.contractor-info.makeRequest': 'Melding',
    'contractee.assignment.contractor-info.feedBack': 'Tilbakemelding',
    'contractee.assignment.contractor-info.rateJob': 'Rate for utført jobb:',
    'contractee.assignment.contractor-info.comment': 'Gi skriftlig tilbakemelding på utført jobb:',
    'contractee.assignment.contractor-info.saveComment': 'Lagre',
    'contractee.assignment.contractor-info.contracteeFeedback': 'Deres tilbakemelding:',
    'contractee.assignment.contractor-info.contractorFeedback': 'Vikarens tilbakemelding:',

    /**
     * Contracatee Card
     */

    'contractee.view.status.title': 'Endre status til:',
    'contractee.view.status.default': 'Default',
    'contractee.view.status.confirmed': 'Bekreftet',
    'contractee.view.status.rejected': 'Avslått',
    'contractee.view.status.pending': 'På vent',
    'contractee.view.status.approved': 'Godkjent',
    'contractee.view.status.verified': 'Verifisert',
    'contractee.view.action.delete': 'Slett',
    'contractee.view.action.editProfile': 'Rediger',

    'contractee.view.createdAt.label': 'Registrert:',
    'contractee.view.since.label': 'Ble del av uWork:',
    'contractee.view.phone.label': ' Telefon/Mobil:',
    'contractee.view.address.label': 'Address:',
    'contractee.view.web.label': 'Hjemmeside:',
    'contractee.view.web.action.label': 'Besøk side',
    'contractee.view.email.label': 'E-postadresse:',
    'contractee.view.city.label': 'Sted:',
    'contractee.view.category.label': 'Bransjer:',
    'contractee.view.percentage.label': '{{percent}}% fullført',
    'contractee.view.skilled.label': 'Timepris faglært:',
    'contractee.view.unskilled.label': 'Timepris ufaglært:',
    'contractee.view.noDescription.label': 'Avdelingen har ikke lagt beskrivelse.',
    'contractee.view.call.label': 'Ring',
    'contractee.view.sent-email.label': 'Send e-post',
    'contractee.view.rate.label': 'Rate',
    'contractee.view.status.label': 'Varsler:',
    'contractee.view.status.notificationDisabled': 'Av',
    'contractee.view.status.notificationEnabled': 'På',

    'contractee.view.phone': 'Ring',
    'contractee.view.email': 'Send e-post',

    'contractee.view.notFound': 'Bedrift ikke funnet',

    /**
     * Contractee - Vacancy
     */
    'contractee.vacancy.pageName': 'Ledige stillinger',

    /**
     * Contractee - Card - Main Vacancy Select
     */
    'contractee.vacancy.select-main.since': 'Siden',
    'contractee.vacancy.select-main.tel': 'Tel',
    'contractee.vacancy.select-main.address': 'Adresse',
    'contractee.vacancy.select-main.web': 'Web',
    'contractee.vacancy.select-main.visit-site': 'Besøk nettstedet',

    /**
     * Contractor Card
     */
    'contractor.view.player.action.close': 'Lukk',
    'contractor.view.player.action.play': 'Play Video',

    'contractor.view.status.state': 'Endre status til:',
    'contractor.view.status.default': 'Default',
    'contractor.view.status.confirmed': 'Bekreftet',
    'contractor.view.status.rejected': 'Avslått',
    'contractor.view.status.pending': 'På vent',
    'contractor.view.status.approved': 'Godkjent',
    'contractor.view.status.declined': 'Avslag',
    'contractor.view.status.verified': 'Verifisert',
    'contractor.view.status.deverified': 'Avverifisert',
    'contractor.view.status.allowWizard': 'Activate Wizard',

    /**
     * Contractee Profile - View
     */
    'contractor.view.status.unsetPublic': 'Fjern publisering',
    'contractor.view.status.setPublic': 'Publiser profilen',
    'contractor.view.status.disable': 'Fjern redigering',
    'contractor.view.status.allow': 'Tiltatt redigering',
    'contractor.view.action.viewPublicProfile': 'Vis offentlig profil',
    'contractor.view.action.editProfile': 'Rediger',

    'contractor.view.action.delete': 'Slett',
    'contractor.view.rating.label': 'Rating:',
    'contractor.view.orderCount.label': 'Gjennomført:',
    'contractor.view.orderCount.counter.label': '{{counter}} vakter',
    'contractor.view.percentage.label': '{{percent}}% fullført',
    'contractor.view.city.label': 'Sted:',
    'contractor.view.action.accept.label': 'Bekreft vikar',
    'contractor.view.action.favorite.label': 'Foretrukket vikar',
    'contractor.view.action.unFavorite.label': 'Un Favorite',
    'contractor.view.action.reject.label': 'Avvise',

    /**
     * Contractee Settings
     */

    'contractee.settings.btn.delete': 'Slett',
    'contractee.settings.btn.save': 'Lagre',
    'contractee.settings.btn.edit': 'Rediger',
    'contractee.settings.btn.continue': 'Fortsett',
    'contractee.settings.btn.saveNext': 'Lagre og fortsett',
    'contractee.settings.btn.complete': 'Fullfør',
    'contractee.settings.btn.confirm': 'Bekreft',
    'contractee.settings.btn.skip': 'Hopp over',
    'contractee.settings.btn.upload': 'Last opp',

    'contractee.settings.index.p1': 'uWork er den første plattformen i Norge hvor din bedrift kan få umiddelbar tilgang til hundrevis omsorgsarbeidere, klare for vikaroppdrag på din avdeling.',
    'contractee.settings.index.p2': 'I løpet av 2017 vil vi også lansere ledige stillinger på uWork som vil gjøre det mulig for din avdeling å lyse ut faste stillinger. Du vil motta søkere med en',
    'contractee.settings.index.p3': 'Vi er i en oppstartsfase og jobber knallhardt hver eneste dag for å samle flere omsorgarbeider på plattformen, slik at vi kan gjøre det enkelt for deg å fylle en ledig vakt eller en fast stilling.',
    'contractee.settings.index.p4': 'Per Oktober 2017 har uWork flest omsorgsarbeider i barnevernssektoren, men vi utvider raskt og ønsker etterhvert å tilby omosrgsarbeidere til sykehus, barnehager, skoler, m.m',
    'contractee.settings.index.p5': 'Hvordan du kommer i gang og hva du kan forvente:',
    'contractee.settings.index.p6': 'Følg stegene i denne veiviseren for å opprette din profil',
    'contractee.settings.index.p7': '- Det er viktig at du er nøye i utfyllingen av profilen din. uWork vurderer deg basert på informasjonen du oppgir, og kun de beste velges ut.',
    'contractee.settings.index.p8': 'Etter at du har fullført din profil vil uWork ta kontakt med deg for å avklare om du kvalifiserer til å være en uWork-vikar eller ikke.',
    'contractee.settings.index.p9': '- Om du kvalifiserer vil du bli innkalt til et intervju. For personer som ikke kan stille fysisk til intervju kan dette gjøres over skype/facetime.',
    'contractee.settings.index.p10': '- Om du får avslag anbefaler vi deg fortsatt å ligge på uWork plattformen da vi i løpet av kort tid vil få inn nye kunder du kan bli aktuell for på et senere tidspunkt. Du vil også kunne søke på ledige stillinger med din uWork profil.',
    'contractee.settings.index.p11': 'I intervjuet vil du få all nødvendig informasjon for å komme i gang som en uWork vikar. Her vil du også få tilsendt et formål for å søke om politiattest.',
    'contractee.settings.index.p12': 'Når du har lastet opp politiattest på profilen din vil uWork verifisere din uWork profil og du vil være tilgjengelig for oppdrag fra våre kunder.',
    'contractee.settings.index.p13': 'Vi ser frem til å se din uWork profil. Om det er noe du lurer på er det bare å trykke på det grønne ikonet nederst i det høyre hjørnet for å chatte med oss🙂',
    'contractee.settings.index.p14': 'Lykke til!',
    'contractee.settings.index.p15': 'Fortsett',

    'contractee.settings.confirm.header': 'Ved å klikke på fullfør godtar du vår arbeidsavtale.',
    'contractee.settings.confirm.accept.label': 'Jeg godtar vilkårene',
    'contractee.settings.confirm.action.label': 'Fortsett til forsiden',

    'contractee.settings.avatar.allowedImages': 'Kun JPG, GIF eller PNG er tillatt. Minimum 200x200 pixels.',
    'contractee.settings.avatar.selectAvatar': 'Last opp nytt bilde',
    'contractee.settings.avatar.guideLines': 'Retningslinjer profilbilde',
    'contractee.settings.avatar.info': 'Profilbildet er det første en avdelingsleder ser når de søker etter en vikar. Det er viktig at du bruker et bilde som gir et godt førsteinntrykk. Et klart bilde hvor du smiler er alltids en vinner.',

    'contractee.settings.user.firstName': 'Fornavn',
    'contractee.settings.user.lastName': 'Etternavn',
    'contractee.settings.user.email': 'E-postadresse',
    'contractee.settings.user.password': 'Passord',
    'contractee.settings.user.passwordConfirmation': 'Bekreft passord',

    'contractee.settings.category.title': 'Bransjer',
    'contractee.settings.category.description': 'Huk av aktuelle bransjer for din bedrift.',
    'contractee.settings.company.name': 'Bedriftsnavn',
    'contractee.settings.company.intro': 'Avdelings beskrivelse',
    'contractee.settings.company.businessNumber': 'Organisasjonsnummer',
    'contractee.settings.company.description': 'Avdelings beskrivelse',
    'contractee.settings.company.address': 'Adresse',
    'contractee.settings.company.addressInfo': 'NB husk å skriv ned avdelingens adresse. Dette er slik at vikar kan se hvor de skal reise.',
    'contractee.settings.company.zipCode': 'Postnummer',
    'contractee.settings.company.place': 'Poststed',
    'contractee.settings.company.phone': 'Telefon/Mobil',
    'contractee.settings.company.web': 'Nettside',

    'contracetee.settings.contract.title': 'Kontrakt',
    'contracetee.settings.contract.title.1': 'Ansatte',
    'contracetee.settings.contract.title.10': 'Konsulent',
    'contracetee.settings.contract.title.20': 'Begge',

    'contracetee.settings.restTimeAllDayShifts.title': 'Hviletid døgnvakter',
    'contracetee.settings.shiftBrake.title': 'Pauser (sett til 0 om det ikke gjelder)',

    'contractee.settings.rates.title': 'Priser og hviletid',
    'contractee.settings.rates.skilled-worker': 'Timepris faglært dag/kveld',
    'contractee.settings.rates.unskilled-worker': 'Timepris ufaglært dag/kveld',
    'contractee.settings.rates.night-shift': 'Timepris nattvakt',
    'contractee.settings.rates.night-skilled': 'Døgnpris faglært',
    'contractee.settings.rates.night-unskilled': 'Døgnpris ufaglært',

    'contractee.settings.rates.nightwatch.info': 'På døgnvakter beregnes et døgn som 18 timer. 6 timer er dermed forbeholdt søvn for vikaren. Hvis det skulle oppstå situasjoner hvor vikar må holde seg våken, betales det således ikke noe ekstra for dette.',
    'contractee.settings.rates.nightwatch.reminder': 'Vi minner om at bedrifter må forholde seg til vikardirektivets regler.',

    'contractee.settings.nda.title': 'Taushetserklæring',
    'contractee.settings.nda.notFound': 'Fant ingen opplastede filer.',

    'contractee.settings.nda.type.label': 'Type',
    'contractee.settings.nda.title.label': 'Tittel',
    'contractee.settings.nda.createdAt.label': 'Lastet opp',
    'contractee.settings.nda.file.label': 'Velg fil',
    'contractee.settings.nda.panel.newNda.title': 'Last opp taushetserklæring',
    'contractee.settings.nda.panel.new.filename.label': 'Filnavn',
    'contractee.settings.nda.panel.new.declaration.label': 'Taushetserklæring',

    'contractee.settings.order-desc.title': 'Vakt maler',
    'contractee.settings.order-desc.newPanel.title': 'Opprett ny mal',
    'contractee.settings.order-desc.new.name': 'Navn',
    'contractee.settings.order-desc.new.body': 'Mal',

    /**
     *
     * Contractor Dashboard
     *
     *
     */

    //Contractee Dashboard

    'contractee.dashboard.pageName': 'Min oversikt',
    'contractee.dashboard.active.title': 'Aktive vaktforespørsler',
    'contractee.dashboard.activeTitle': 'Aktive jobbforespørsler',

    'contractee.dashboard.recent.jobs.panel.title': 'Siste stillinger',
    'contractee.dashboard.published.jobs.title': 'Published Jobs',
    'contractee.dashboard.draft.jobs.title': 'Draft Jobs',

    'contractee.dashboard.recent.jobs.title': 'Stilling',
    'contractee.dashboard.recent.jobs.location': 'Lokasjon',
    'contractee.dashboard.recent.jobs.status': 'Status',
    'contractee.dashboard.recent.jobs.views': 'Visninger',
    'contractee.dashboard.recent.jobs.type': 'Stillingstype',
    'contractee.dashboard.recent.jobs.deadLineAt': 'Søknadsfrist',

    'contractee.dashboard.categoryUsers': 'Vikarer på uWORK',
    'contractee.dashboard.myContractors': 'Mine vikarer',

    'contractee.dashboard.thisMonth': 'Denne måneden',

    'contractee.dashboard.intercomTitle': 'Trenger du hjelp?',
    'contractee.dashboard.intercomDescription': 'Chat med oss her og vi hjelper deg.',

    'contractee.dashboard.newOrderTitle': 'Bestill vikar',
    'contractee.dashboard.newOrderDescription': 'Kvalifiserte vikarer som matcher din avdeling vil søke på vakten.',

    'contractee.dashboard.newVacancyTitle': 'Legg ut fast stilling',
    'contractee.dashboard.newVacancyDescription': 'Alle søkere vil bli presentert med en uWork-profil.',

    'contractee.dashboard.verifyUser': 'Jeg ønsker å bestille vikarer',
    'contractee.dashboard.verifyUser.button': 'Kom i gang',
    'contractee.dashboard.verifyUserDescription': 'Oppgrader din profil så du kan bestille vikarer.',

    'contractor.dashboard.noOrders': 'Ingen ledige vakter for øyeblikket. Kom tilbake snart.',

    //View Vacancy
    'contractor.dashboard.vacancy': 'Se ledige stillinger',
    'contractor.dashboard.vacancyDescription': 'Bla gjennom ledige stillinger og søk på dem med et klikk ',
    'contractor.dashboard.button': 'Stillinger',

    //View Orders
    'contractor.dashboard.visit-orders': 'Se ledige vakter',
    'contractor.dashboard.visit-orders.description': 'Bla gjennom ledige vakter for å melde din interesse.',
    'contractor.dashboard.visit-orders.button': 'Ledige vakter',

    //Apply per Verify User
    'contractor.dashboard.verifyUser': 'Bli en verifisert uWorker',
    'contractor.dashboard.verifyUser.description': 'Få utvidet tilgang til vikaroppdrag som blir lagt ut på uWork.',
    'contractor.dashboard.verifyUser.button': 'Kom i gang',

    /**
     * contractor/profile/header-top
     */

    'contractor.dashboard.profileProgress': '<h6 class="mb-4">Fullfør din profil</h6>\n' + 'Din profil er kun {{profileProgress}}% ferdig, Du profil må være over 70% før du kan melde interesse på ledige stillinger eller søke om å bli en vikar. Slik veier vi profilen din: \n' + '<ul>\n' + '\t<li>Kjønn = 5%</li>\n' + '<li>Tittel = 5%</li>\n' + '<li>Adresse = 5%</li>\n' + '<li>Fødselsdato = 5%</li>\n' + '<li>Profilbilde = 20%</li>\n' + '<li>Video eller Om (beskriv deg selv) = 30%</li>\n' + '<li>Arbeidserfaring = 15%</li>\n' + '<li>Utdannelse = 15%</li>\n' + '</ul>\n',

    'contractor.dashboard.before': 'Trykk',
    'contractor.dashboard.link': 'her',
    'contractor.dashboard.after': 'for å gjøre ferdig din profil.',
    /**
     * contractor/profile/header-content
     */

    'contractor.dashboard.notification.enabled': 'Du vil nå få varsler på ledige vakter',
    'contractor.dashboard.notification.disabled': 'Du vil ikke få varsler på ledige vakter',
    'contractor.dashboard.visit-profile': 'Se profil',
    'contractor.dashboard.settings': 'Rediger profil',
    'contractor.dashboard.rating': 'Rating',
    'contractor.dashboard.respond-time': 'Respons tid',
    'contractor.dashboard.respond-rate': 'Svarprosent',

    'contractor.dashboard.stats.workingHours': 'Arbeidstimer',
    'contractor.dashboard.stats.acceptedOrder': 'Utførte vakter',
    'contractor.dashboard.stats.amount': 'NOK',
    'contractor.dashboard.my-companies': 'Avdelinger du er tilknyttet:',
    'contractor.dashboard.no-companies': 'Ingen tilknyttede avdelinger',

    'contractor.dashboard.requests.title': 'Status - Vakter du har forespurt',
    'contractor.dashboard.requests.description': 'Du har ikke meldt interesser for noen vakter. Gå til «vakter» for å se siste utlyste vakter',

    /**
     *
     *
     * Contractor - Orders
     *
     *
     */

    /**
     *  Contractor - Orders Active
     */
    'contractor.assignment.active.title.label': 'Utlyste vakter',
    'contractor.assignment.active.noResult': 'Ingen ledige vakter for øyeblikket. Kom tilbake snart.',

    /**
     * Contractor - Order Completed
     */

    'contractor.assignment.completed.title.label': 'Fullførte vakter',
    'contractor.assignment.completed.noResult': 'Fant ikke noen oppdrag som er utført.',

    /**
     * Contractor - Order OnGoing
     */

    'contractor.assignment.ongoing.title.label': 'Mine vakter',
    'contractor.assignment.ongoing.noResult': 'Ingen bestilte vakter for øyeblikket.',

    /**
     * Contractor - Order OnGoing
     */

    'contractor.assignment.index.title.label': 'Vakter',
    'contractor.assignment.index.noResult': 'Vakt ikke funnet. Ser ut som den er slettet.',

    /**
     * Contractor - Order Request
     */

    'contractor.assignment.request.title.label': 'Forespurte vakter',
    'contractor.assignment.request.noResult': 'Ingen forespurte vakter for øyeblikket.',
    'contractor.assignment.request.published.action': 'Klikk her for å se ledige vakter.',

    /**
     *
     *
     * Contractor Settings
     *
     *
     */
    'contractor.agreement.confirmation': 'Publisert: {{created}}. Sist oppdatert: {{updated}}',
    'contractor.agreement.description': 'Du har akseptert denne avtalen den: {{date}} fra følgende IP-addresse: {{ip}}',

    /* Contractor Setting - Panels */
    'contractor.settings.panel.add': 'Legg til',
    'contractor.settings.panel.close': 'Lukk',

    'contractor.settings.btn.delete': 'Slett',
    'contractor.settings.btn.save': 'Lagre',
    'contractor.settings.btn.edit': 'Rediger',
    'contractor.settings.btn.continue': 'Fortsett',
    'contractor.settings.btn.saveNext': 'Lagre og fortsett',
    'contractor.settings.btn.complete': 'Fullfør',
    'contractor.settings.btn.confirm': 'Bekreft',
    'contractor.settings.btn.skip': 'Hopp over',
    'contractor.settings.btn.upload': 'Last opp',
    'contractor.settings.btn.add': 'Legg til',
    'contractor.settings.btn.clear': 'Fjern',

    /**
     * Contractor Settings - Intro (Account)
     */
    'contractee.account.nav.user-account': 'Bruker',
    'contractor.settings.account.title': 'Hvem er du?',
    'contractor.settings.account.sex.select': 'Velg kjønn?',
    'contractor.settings.account.sex.man.label': 'Mann',
    'contractor.settings.account.sex.female.label': 'Kvinne',
    'contractor.settings.account.title.label': 'Tittel',
    'contractor.settings.account.birthday.label': 'Fødselsdato',
    'contractor.settings.account.phone.label': 'Telefon/Mobil',
    'contractor.settings.account.city.label': 'By',
    'contractor.settings.account.address.label': 'Adresse',
    'contractor.settings.account.zipCode.label': 'Postnummer',
    'contractor.settings.account.place.label': 'Poststed',
    'contractor.settings.account.driverLicense.label': 'Jeg har førerkort',
    'contractor.settings.account.have-car.label': 'Jeg disponerer egen bil',

    /**
     * Contractor Settings - Avatar
     */

    'contractor.settings.avatar.title': 'Profilbilde',
    'contractor.settings.avatar.allowedImages': 'Kun JPG, GIF eller PNG er tillatt. Minimum 200x200 pixels.',
    'contractor.settings.avatar.selectAvatar': 'Last opp nytt bilde',
    'contractor.settings.avatar.guideLines': 'Retningslinjer profilbilde',
    'contractor.settings.avatar.info': 'Profilbildet er det første en avdelingsleder ser når de søker etter en vikar. Det er viktig at du bruker et bilde som gir et godt førsteinntrykk. Et klart bilde hvor du smiler er alltids en vinner.',

    /**
     * Contractor Settings - Video
     */

    'contractor.settings.video.title': 'Profilvideo',
    'contractor.settings.video.allowedVideos': 'Last opp din video, vi anbefaler mp4 som filformat. Maks størrelse skal ikke overstige 100 MB',
    'contractor.settings.video.selectVideo': 'Last opp ny video',
    'contractor.settings.video.guideLines': 'Retningslinjer video',

    'contractor.settings.video.guideline.description': 'Ved å laste opp en kort video der du presenterer deg selv, øker du sjansen for et intervju eller å bli valgt til et vikaroppdrag betraktelig. En enkel introduksjon av deg selv, din erfaring og dine ferdigheter er det eneste som trengs </br> </br> PS. Kun avdelingsledere og ansatte i uWork vil kunne se videoen din.',

    'contractor.settings.video.p1': 'Alle uWorkere må spille inn en kort video der de presenterer seg selv. Lengden på videoen skal være mellom 20 og 40 sekunder og skal inneholde: Navn, alder, hvor du bor, utdanning, relevant erfaring fra helse og omsorgsektoren, evt. annen informasjon du ønsker å legge til.',
    'contractor.settings.video.p2': 'Prøv å smil og få frem dine beste sider. Husk at videoen er ment som et verktøy for å gi avdelingsledere et intrykk av deg som person og kan være avgjørende for om du blir valgt til ditt neste intervju eller vikaroppdrag.',
    'contractor.settings.video.p3': 'Videoen kan tas med en smartphone. Stå mot en nøytral bakgrunn og sørg for at det er god belysning (naturlig lys er best).',

    'contractor.settings.describe-yourself': 'Beskriv deg selv som person, dine styrker og ferdigheter og hvorfor en arbeidsgiver skal velge nettopp deg.',
    'contractor.settings.edit.describe-you': 'Beskriv deg selv',
    /**
     * Contractor Settings - Category - Not In Use as Component
     */

    'contractor.settings.category.title': 'Generell informasjon',
    'contractor.settings.category.description': 'Velg hvilke bransjer du ønsker å jobbe i og huk av for om du er faglært eller ufaglært. Du kan alltid gå tilbake å endre dine preferanser på et senere tidspunkt',

    'contractor.settings.category.warning.canNotEdit': 'Vi har låst endring av yrker og ferdigheter. For å endre/legge til nye yrker/ferdigheter må du kontakte oss ved å klikke',
    'contractor.settings.category.warning.intercom': 'her',
    'contractor.settings.category.panel.title': 'Yrker',
    'contractor.settings.category.skilled': 'Faglært',
    'contractor.settings.category.unskilled': 'Ufaglært',

    /**
     * Contractor Settings - Education
     */

    'contractor.settings.education.title.label': 'Utdanning',
    'contractor.settings.education.general.title.label': 'Generell informasjon',
    'contractor.settings.education.general.description.label': 'Vennligst fyll ut relevant utdanning. Legg til utdanning ved å trykke på "Legg til" ikonet.',
    'contractor.settings.education.panel.title': 'Registrer relevant utdanning',
    'contractor.settings.education.noEducation': 'Ingen utdaning er registrer hos deg',

    'contractor.settings.education.attr.institution': 'Skole',
    'contractor.settings.education.attr.degree': 'Grad',
    'contractor.settings.education.attr.started': 'Fra dato',
    'contractor.settings.education.attr.ended': 'Til dato',
    'contractor.settings.education.attr.current': 'Nåværende',
    'contractor.settings.education.attr.description': 'Beskrivelse',

    /**
     * Contractor Settings - Experience
     */

    'contractor.settings.experience.title.label': 'Arbeidserfaring',
    'contractor.settings.experience.general.title.label': 'Generell informasjon',
    'contractor.settings.experience.general.description.label': 'Vennligst fyll ut relevant arbeidserfaring. Legg til arbeidserfaring ved å trykke på "Legg til" ikonet.',
    'contractor.settings.experience.panel.title': 'Registrer relevant erfaring',
    'contractor.settings.experience.noExperience': 'Legg til tidligere arbeidserfaring.',

    'contractor.settings.experience.attr.name': 'Bedrift / Selskap',
    'contractor.settings.experience.attr.title': 'Tittel',
    'contractor.settings.experience.attr.started': 'Fra dato',
    'contractor.settings.experience.attr.ended': 'Til dato',
    'contractor.settings.experience.attr.onGoing': 'Pågående',
    'contractor.settings.experience.attr.current': 'Nåværende',
    'contractor.settings.experience.attr.description': 'Beskrivelse',

    /**
     * Contractor Settings - Notifications
     */

    'contractor.settings.notifications.title.label': 'Varslinger',
    'contractor.settings.notifications.description.label': 'Vi ønsker å gi deg en bedre opplevelse av uWork samtidig som vi handler i tråd med de nye retningslinjene til GDPR. Nedenfor kan du oppdatere hvilke varslinger du ønsker å få på uWork.',

    /**
     * Contractor Settings - User Data
     */

    'contractor.settings.user_data.title.label': 'Last ned dine data ',
    'contractor.settings.user_data.description.label': 'Med GDPR-forordningen (General Data Protection Regulation) har du rett til å vite hva selskaper vet om deg. Her kan du laste ned en oversikt over alle dine data uwork.no har lagret.',

    /**
     * Contractee Settings - Notifications
     */

    'contractee.settings.notifications.title.label': 'Varslinger',
    'contractee.settings.notifications.description.label': 'Vi ønsker å gi deg en bedre opplevelse av uWork samtidig som vi handler i tråd med de nye retningslinjene til GDPR. Nedenfor kan du oppdatere hvilke varslinger du ønsker å få på uWork.',

    /**
     * Contractee Settings - User Data
     */

    'contractee.settings.user_data.title.label': 'Last ned dine data ',
    'contractee.settings.user_data.description.label': 'Med GDPR-forordningen (General Data Protection Regulation) har du rett til å vite hva selskaper vet om deg. Her kan du laste ned en oversikt over alle dine data uwork.no har lagret.',
    'contractee.settings.user_data.pending': 'Du har allerede søkt om å laste ned dine data, du vil motta en e-post når dataene er klar til nedlasting.',
    'contractee.settings.user_data.download': 'Dine data er klare, last dem ned',

    /**
     * Contractor Settings - Document
     */

    'contractor.settings.document.title.label': 'Vedlegg',
    'contractor.settings.document.file.label': 'Velg Fil',

    'contractor.settings.document.type': 'Type',
    'contractor.settings.document.title': 'Tittel',
    'contractor.settings.document.status': 'Status',

    'contractor.settings.document.enabled': 'Offentlig',
    'contractor.settings.document.disabled': 'Privat',

    /**
     * Contractor Settings - GCC
     */

    'contractor.settings.gcc.title.label': 'Politiattest',
    'contractor.settings.gcc.info.description': 'Vi anbefaler alle å laste opp politiattesten sin på uWork plattformen. Ved å laste opp politiattesten din vil det være mye enklere for arbeidsgiver få å tilgang til godkjent politiattest. Å laste opp politiattest på uWork er frivillig. Hvis du ikke ønsker å laste opp politiattesten må du ta med fysisk politiattest til alle oppdragene du gjør.',
    'contractor.settings.gcc.info.reminder': 'Politiattest kan ikke være eldre enn 3 måneder. Vårt system vil minne deg på (i god tid) når du må søke om ny politiattest.',

    'contractor.settings.gcc.upload.label': 'Last opp politiattest',
    'contractor.settings.gcc.category.label': 'Yrke:',
    'contractor.settings.gcc.category.prompt.label': 'Velg yrke',
    'contractor.settings.gcc.issuedAt.label': 'Utstedt dato:',
    'contractor.settings.gcc.file.label': 'Politiattest:',

    'contractor.settings.gcc.type': 'Type',
    'contractor.settings.gcc.category': 'Kategori',
    'contractor.settings.gcc.issuedAt': 'Utstedt dato',
    'contractor.settings.gcc.createdAt': 'Lastet opp',
    'contractor.settings.gcc.title': 'Tittel',
    'contractor.settings.gcc.file': 'Velg fil',
    'contractor.settings.gcc.selectFile': 'Select File',
    'contractor.settings.gcc.noFiles': 'Fant ingen opplastede filer.',

    /**
     * Contractor Settings - Profile
     */

    'contractor.settings.user.title': 'Bruker',
    'contractor.settings.user.firstName': 'Fornavn',
    'contractor.settings.user.lastName': 'Etternavn',
    'contractor.settings.user.email': 'E-postadresse',
    'contractor.settings.user.password': 'Passord',
    'contractor.settings.user.passwordConfirmation': 'Bekreft passord',

    /**
     * Contractor Settings - Index - Substitued
     */

    'contractor.settings.index.title': 'Velkommen til uWork!',
    'contractor.settings.index.p1': 'uWork er den første plattformen i Norge hvor du som omsorgsarbeider kan få umiddelbar tilgang til ledige vikar-oppdrag og faste stillinger, alt på et og samme sted!',
    'contractor.settings.index.p2': 'Per Oktober 2017 har uWorkflest kunder i barnevernssektoren, men vi utvider raskt og ønsker etterhvert å tilby oppdrag på sykehus, barnehager, skoler, m.m. En uWork-profil kan gi utallige muligheter for arbeid i helse- og omsorgssektoren.',
    'contractor.settings.index.p3': 'Glem CV og lange søknadsbrev - uWork-profilen vil fungere som din søknad på plattformen!',
    'contractor.settings.index.panel': 'Neste steg er som følger:',
    'contractor.settings.index.li1.title': 'Følg stegene i veiviseren for å opprette din profil.',
    'contractor.settings.index.li1.description': 'Det er viktig at du er nøye i utfyllingen av profilen din. uWork vurderer deg basert på informasjonen du oppgir, og kun de beste velges ut. Profilen din må inneholde profibilde, utdanning og arbeidserfaring for at vi skal kunne vurdere deg.',

    'contractor.settings.index.li2.title': 'uWork tar kontakt med deg når du har fullført din profil',
    'contractor.settings.index.li2.description': 'Om du kvalifiserer vil du bli innkalt til et intervju. For personer som ikke kan stille fysisk til intervju kan dette gjøres over skype/facetime.',
    'contractor.settings.index.li2.description2': 'Om du får avslag anbefaler vi deg fortsatt å ligge på uWork plattformen da vi i løpet av kort tid vil få inn nye kunder du kan bli aktuell for på et senere tidspunkt. Du vil også etterhvert kunne søke på ledige stillinger med din uWork profil.',

    'contractor.settings.index.li3.title': 'Intervju og verifisiering av profil',
    'contractor.settings.index.li3.description': 'I intervjuet vil du få all nødvendig informasjon for å komme i gang som en uWork vikar. Her vil du også få tilsendt et formål for å søke om politiattest.',
    'contractor.settings.index.li3.description2': 'Når du har lastet opp politiattest på profilen din vil uWork verifisere din uWork profil og du vil være tilgjengelig for oppdrag fra våre kunder',
    'contractor.settings.index.info': 'Vi ser frem til å se din uWork profil. Om det er noe du lurer på er det bare å trykke på det grønne ikonet nederst i det høyre hjørnet for å chatte med oss :)',

    /**
     * Contractor Settings - Vacancy Role
     */

    'contractor.settings.index.vacancy.title': 'Velkommen til uWork!',

    'contractor.settings.index.vacancy.p1': 'uWork er Norges første bemannings-plattform som baserer seg på delingsøkonomien, og vi har en ambisjon om å transformere og effektivisere måten vi søker jobb på i helse- og omsorgssektoren. Vi har selv erfart hvor tungvinte de tradisjonelle metodene kan være, men ved bruk av teknologi skal vi sørge for at det å finne ledige stillinger eller vikar-oppdrag blir langt mindre komplisert og tidkrevende.  ',
    'contractor.settings.index.vacancy.p2': 'uWork har som mål å samle alle oppdragsgivere og omsorgsarbeidere på én plattform, slik at de enkelt kan finne fram til hverandre basert på behov og ferdigheter. ',
    'contractor.settings.index.vacancy.p3': 'Med en profil på uWork vil du umiddelbart få tilgang til et hav av karrieremuligheter i helse- og omsorgssektoren, enten du ser etter noe fast eller midlertidig arbeid.',

    'contractor.settings.index.vacancy.how-it-works.title': 'Slik fungerer det:',
    'contractor.settings.index.vacancy.how-it-works.p1': 'På uWork opererer vi med to profiltyper, "uWorker og "Verifisert bedrift". Når du oppretter en profil vil du automatisk bli en "uWorker", som betyr at du kan søke på ledige stillinger helt fritt inne på plattformen. En "Verifisert bedrift" kan både søke på ledige stillinger og vikaroppdrag. For å bli en "verified uWorker" må du gjennomføre en kort screening prosess med HR ansvarlig i uWork, for å sikre at du er passende kandidat. Du kan enkelt søke om å bli "Verified uWorker" så snart du har opprettet en profil.',

    'contractor.settings.index.vacancy.next-step.title': 'Neste steg',
    'contractor.settings.index.vacancy.next-step.p1': 'Følg veiviseren for å opprette en profil på uWork. Vær så utfyllende som mulig. uWork-profilen er din digitale CV. Den er det første avdelingsledere ser når du søker på en jobb eller et vikaroppdrag. Førsteinntrykk er veldig viktig!',
    'contractor.settings.index.vacancy.next-step.p2': 'Når du har opprettet en profil kan du ta en titt på ledige stillinger som ligger ute. Søk med et enkelt klikk!',
    'contractor.settings.index.vacancy.next-step.p3': 'Om du ønsker å bli en "Verifisert bedrift" kan du søke ved å trykke på "Jeg vil bli en vikar" inne på din profil.',

    'contractor.settings.index.vacancy.p4': 'Vi ser frem til å se din uWork profil. Om det er noe du lurer på er det bare å trykke på det grønne ikonet nederst i det høyre hjørnet for å chatte med oss.',
    'contractor.settings.index.vacancy.p5': 'Takk igjen for at du vil være en del av vårt voksende team av uWorkere. Vi ser frem til samarbeidet!',

    /**
     * Contractor Settings - Other
     */

    'contractor.settings.other.title': 'Vakttyper og reisevei',
    'contractor.settings.other.title.label': 'Generell informasjon',
    'contractor.settings.other.description.label': 'Velg hvilke vakttyper du er interessert i og spesifiser hvor langt du er villig til å reise for en vakt.',
    'contractor.settings.other.shiftType.label': 'Godkjente Vakttyper',
    'contractor.settings.other.howTo.label': 'Reisevei',
    'contractor.settings.other.distance.label': 'Maks reisevei (i timer)',
    'contractor.settings.other.distance.description': 'Indiker hvor langt du er villig til å reise for å ta på deg oppdrag.',

    /**
     * Contractor Settings - Payment
     */

    'contractor.settings.payment.title': 'Betaling og lønn',
    'contractor.settings.payment.general.title': 'Generell informasjon',
    'contractor.settings.payment.general.description': 'For å kunne betale ut din lønn trenger vi til enhver tid oppdatert bank kontonummer og evt. foretaksnavn/org. nr om du opererer som konsulent/underleverandør.',
    'contractor.settings.payment.general.description1': 'Hvis du ikke har mottatt organisasjonsnummeret til ditt Enkeltpersonforetak enda kan du fylle ut dette på et senere tidspunkt ved å navigere til "rediger profil.',
    'contractor.settings.payment.general.description2': 'Ved å trykke på linken nedenfor vil du få retningslinjer på hvordan du enkelt kan opprette ditt Enkeltpersonforetak. Det er gjort på 5 minutter og er helt gratis.',
    'contractor.settings.payment.general.action': 'Hvordan oppretter jeg et enkeltmannsforetak?',

    'contractor.settings.payment.describe-you': 'Beskriv deg selv',
    'contractor.settings.payment.business-payment.pageName': 'Betaling og lønn',
    'contractor.settings.payment.business-number': 'Organisasjonsnummer',
    'contractor.settings.payment.bank-account-number': 'Kontonummer',
    'contractor.settings.payment.section-3': 'Hvis du opererer som underleverandør',

    'contractor.settings.payment.contractor.name': 'Navn på bedrift',
    'contractor.settings.payment.minimum-hourly-rate': 'Minstelønn per time',
    'contractor.settings.payment.minimum-hourly-deatilas': 'Hvis du som vikar setter din minstelønn til f.eks 160kr/t, vil du kun være tilgjengelig for bedrifter med lik eller høyere timelønn. Hvis en bedrift oppgir en høyere timelønn (f.eks 200kr/t) vil denne satsen gjelde.',
    'contractor.settings.payment.travel-distance': 'Maks reisevei (i timer)',
    'contractor.settings.payment.night-guard-msg': 'På døgnvakter beregnes et døgn som 18 timer. 6 timer er dermed forbeholdt søvn for vikaren. Hvis det skulle oppstå situasjoner hvor vikar må holde seg våken, betales det således ikke noe ekstra for dette.',

    /**
     * Contractor Settings - Skills
     */

    'contractor.settings.shift.title': 'Vakttyper',

    /**
     * Contractor Settings - Skills
     */
    'contractor.settings.skills.title': 'Kurs og ferdigheter',
    'contractor.settings.skills.general.title': 'Generell informasjon',
    'contractor.settings.skills.general.description': 'Har du tatt et kurs som ikke står oppført? Eller mangler vi noe annet? Fortell oss om det i chatten (ikonet nederst i høyre hjørnet) og vi vil kunne legge det til så du kan velge det.',
    'contractor.settings.courses.list': 'Kurs jeg har tatt:',
    'contractor.settings.skills.list': 'Jeg har tidligere jobbet med:',

    /**
     * Contractor View - Profile
     */

    'contractor.view.notFound': 'Vikar ikke funnet',

    /**
     * contractor/profile/content-sidebar-default
     */

    'contractor.admin.viewMore': 'Edit Other Settings',
    'contractor.edit.viewMore': 'Alle innstillinger',

    /**
     * contractor/profile/content-sidebar-small
     */

    'contractor.admin.viewLess': 'Vis profil',

    /**
     * -> contractor/profile/display-notification-status
     */
    'contractor.view.status': 'Varsler:',
    'contractor.view.status.notificationDisabled': 'Av',
    'contractor.view.status.notificationEnabled': 'På',

    /**
     * -> contractor/profile/display-phone
     */
    'contractor.view.phone': 'Telefon/Mobil:',

    /**
     * -> contractor/profile/display-email
     */

    'contractor.view.email': 'E-postadresse:',

    /**
     * -> contractor/profile/display-email
     */

    'contractor.view.city': 'By:',

    /**
     * -> contractor/profile/display-address
     */

    'contractor.view.address': 'Adresse:',

    'contractor.view.feed-back-title': 'Tilbakemeldinger',

    /**
     * contractor/profile/display-rating-counter
     */
    'contractor.view.rating': 'Vurdering',

    /**
     * contractor/profile/display-order-counter
     */
    'contractor.view.countOrder': 'Gjennonmforte vakter:',

    /**
     * contractor/profile/display-skills
     */
    'contractor.view.skills': 'har tidligere jobbet med:',

    /**
     * contractor/profile/display-course
     */
    'contractor.view.course': 'Kurs:',

    /**
     * contractor/profile/display-approvals
     */
    'contractor.view.approvals': 'Godkjennelser:',

    /**
     * contractor/profile/display-car-license
     */
    'contractor.view.driveLicense': ' har:',

    /**
     * contractor/profile/display-order-counter
     */

    'contractor.view.bill': 'Bil',
    'contractor.view.license': 'Førerkort',

    /**
     * contractor/profile/display-shift
     */
    'contractor.view.shiftType': 'Vakttyper:',

    /**
     * contractor/profile/display-badges
     */
    'contractor.view.badges': 'Andre utmerkelser:',

    /**
     * contractor/profile/display-experience
     */
    'contractor.view.experience': 'Arbeidserfaring',
    'contractor.view.experience.current': 'Pågående',

    /**
     * contractor/profile/display-education
     */
    'contractor.view.education': 'Utdanning',
    'contractor.view.education.current': 'Pågående',

    /**
     *
     *
     * SideNav
     *
     *
     */

    /**
     * Main Menu
     */

    'sidenav.settings': 'Innstillinger',
    'sidenav.user.view': 'Min profil',
    'sidenav.logout': 'Logg ut',

    'sidenav.link.messages': 'Meldinger',
    'sidenav.link.view.message': 'Logg ut',
    'sidenav.link.notifications': 'Varsler',

    'contractee.assignment-item.sendMessageButton': 'Send melding',
    'contractee.assignment-item.writeMessage': 'Din melding',

    /**
     *
     * Notification Page
     */

    'pageName.notifications': 'Varsler',
    'title.notifications': 'Varsler',
    'notifications.readAll': 'Les alle',

    /**
     * Contractee Main Sidenav
     */
    'contractee.link.dashboard': 'Min oversikt',
    'contractee.link.myWorkers': 'Mine vikarer',
    'contractee.link.orders': 'Vakter',
    'contractee.link.activeOrders': 'Utlyste vakter',
    'contractee.link.onGoingOrders': 'Bestilte vakter',
    'contractee.link.completedOrders': 'Avsluttede vakter',
    'contractee.link.timesheet': 'Timelister',
    'contractee.link.vacancy': 'Aktive stillinger',
    'contractee.link.vacancy.all': 'Alle stillinger',
    'contractee.link.vacancy.active': 'Aktive stillinger',
    'contractee.link.vacancy.hired': 'Stillinger i boks',
    'contractee.link.vacancy.archived': 'Arkiverte stillinger',
    'contractee.link.vacancy.draft': 'Ikke publiserte stillinger',

    /**
     * Contractee Main Sidenav
     */
    'contractee.pageName': 'Bedrift',
    'contractee.pageName.agreement': 'Deres avtale',
    'contractee.pageName.agreement.confirmation': 'Bekreft ny avtale',
    'contractee.pageName.dashboard': 'Min oversikt',
    'contractee.pageName.profile': 'Min profil',
    'contractee.pageName.myWorkers': 'Mine vikarer',
    'contractee.pageName.orders': 'Oppdrag',
    'contractee.pageName.activeOrders': 'Utlyste vakter',
    'contractee.pageName.onGoingOrders': 'Bestilte vakter',
    'contractee.pageName.completedOrders': 'Avsluttede vakter',
    'contractee.pageName.timesheet': 'Timelister',
    'contractee.pageName.vacancy': 'Ledige stillinger',

    /**
     * Contractee Settings Sidenav
     */

    'contractee.settings.link': 'Innstillinger',
    'contractee.settings.link.index': 'Velkommen',
    'contractee.settings.link.account': 'Bruker',
    'contractee.settings.link.agreement': 'Avtale',
    'contractee.settings.link.avatar': 'Profilbilde',
    'contractee.settings.link.category': 'Bransjer',
    'contractee.settings.link.company': 'Om bedriften',
    'contractee.settings.link.confirm': 'Fullfør registrering',
    'contractee.settings.link.nda': 'Taushetserklæring',
    'contractee.settings.link.desc-template': 'Vakt maler',
    'contractee.settings.link.rates': 'Hviletid og pauser',
    'contractee.settings.link.contracts': 'Kontrakt',
    'contractee.settings.link.notifications': 'Varslinger',
    'contractee.settings.link.user_data': 'Administrer dine data',
    'contractee.settings.link.additional_payments': 'Lønnsbetingelser',

    /**
     * Contractee Settings PageNames
     */

    'contractee.settings.pageName': 'Innstillinger',
    'contractee.settings.pageName.index': 'Velkommen',
    'contractee.settings.pageName.account': 'Bruker',
    'contractee.settings.pageName.agreement': 'Avtale',
    'contractee.settings.pageName.avatar': 'Profilbilde',
    'contractee.settings.pageName.category': 'Bransjer',
    'contractee.settings.pageName.company': 'Om bedriften',
    'contractee.settings.pageName.confirm': 'Fullfør registrering',
    'contractee.settings.pageName.nda': 'Taushetserklæring',
    'contractee.settings.pageName.notifications': 'Varslinger',
    'contractee.settings.pageName.desc-template': 'Vakt maler',
    'contractee.settings.pageName.contracts': 'Kontrakt',
    'contractee.settings.pageName.rates': 'Selskapsinformasjon',
    'contractee.settings.pageName.user_data': 'Administrer dine data',
    'contractee.settings.pageName.additional_payments': 'Lønnsbetingelser',

    'contractee.settings.additional-payments.title': 'Tillegg',
    'contractee.settings.additional-payments.existing': 'Deres registrerte tillegg',
    'contractee.settings.additional-payment.title': 'Tittel på tillegg - eksempelvis: "Kveldstillegg"',
    'contractee.settings.additional-payment.description': 'Beskrivelse - eksempelvis: "For arbeid mellom kl. 17:00 og kl. 21:00, mandag til fredag"',
    'contractee.settings.additional-payment.value': 'Verdi - eksempelvis: "100"',
    'contractee.settings.additional-payment.type': 'Type - NOK eller %',

    'contractee.settings.additional-payment.table.title': 'Tittel',
    'contractee.settings.additional-payment.table.description': 'Beskrivelse - eksempelvis',
    'contractee.settings.additional-payment.table.value': 'Verdi',
    'contractee.settings.additional-payment.table.type': 'Type',

    /**
     * Contractor PageNames
     */
    'contractor.pageName': 'Vikar',
    'contractor.pageName.dashboard': 'Min oversikt',
    'contractor.pageName.myCompanies': 'Mine avdelinger',
    'contractor.pageName.orders': 'Vakter',
    'contractor.pageName.orders.view': 'Visning av vakt',
    'contractor.pageName.activeOrders': 'Ledige vakter',
    'contractor.pageName.onGoingOrders': 'Mine vakter',
    'contractor.pageName.requested': 'Forespurte vakter',
    'contractor.pageName.completedOrders': 'Fullførte vakter',
    'contractor.pageName.timesheet': 'Timelister',
    'contractor.pageName.vacancy': 'Ledige stillinger',
    'contractor.pageName.view.profile': 'Vikar profil',

    /**
     * Contractor Main Sidenav
     */
    'contractor.link.dashboard': 'Min oversikt',
    'contractor.link.myCompanies': 'Mine avdelinger',
    'contractor.link.index': 'Vakter',
    'contractor.link.activeOrders': 'Ledige vakter',
    'contractor.link.onGoingOrders': 'Mine vakter',
    'contractor.link.onRequestedOrders': 'Vakter du har søkt på',
    'contractor.link.completedOrders': 'Fullførte vakter',
    'contractor.link.timesheet': 'Timelister',
    'contractor.link.handbook': 'Personalhåndboken',
    'contractor.link.vacancy': 'Ledige stillinger',
    'contractor.link.published': 'Alle ledige stillinger',
    'contractor.link.applied': 'Stillinger du har søkt på',
    'contractor.link.hired': 'Hired',

    /**
     * Contractor Settings Sidenav
     */

    'contractor.settings.link': 'Innstillinger',
    'contractor.settings.link.index': 'Velkommen',
    'contractor.settings.link.account': 'Hvem er du?',
    'contractor.settings.link.agreement': 'Din avtale',
    'contractor.settings.link.avatar': 'Profilbilde og video',
    'contractor.settings.link.biography': 'Beskriv deg selv',
    'contractor.settings.link.category': 'Yrker',
    'contractor.settings.link.confirm': 'Fullfør registrering',
    'contractor.settings.link.complete': 'Fullfør registrering',
    'contractor.settings.link.education': 'Utdanning',
    'contractor.settings.link.experience': 'Arbeidserfaring',
    'contractor.settings.link.gcc': 'Politiattester',
    'contractor.settings.link.attachment': 'Andre vedlegg',
    'contractor.settings.link.general': 'Bruker informasjon',
    'contractor.settings.link.languages': 'Språk',
    'contractor.settings.link.others': 'Vakttyper og reisevei',
    'contractor.settings.link.notifications': 'Varslinger',
    'contractor.settings.link.user_data': 'Administrer dine data',
    'contractor.settings.link.payment': 'Betaling og lønn',
    'contractor.settings.link.skills': 'Kurs og ferdigheter',
    'contractor.settings.link.video': 'Profilvideo',

    /**
     * Contractor Settings PageNames
     */

    'contractor.settings.pageName': 'Innstillinger',
    'contractor.settings.pageName.account': 'Hvem er du?',
    'contractor.settings.pageName.agreement': 'Din avtale',
    'contractor.settings.pageName.agreement.confirmation': 'Bekreft ny avtale',
    'contractor.settings.pageName.avatar': 'Profilbilde og video',
    'contractor.settings.pageName.biography': 'Beskriv deg selv',
    'contractor.settings.pageName.category': 'Yrker',
    'contractor.settings.pageName.notifications': 'Varslinger',
    'contractor.settings.pageName.user_data': 'Administrer dine data',
    'contractor.settings.pageName.confirm': 'Fullfør registrering',
    'contractor.settings.pageName.education': 'Utdanning',
    'contractor.settings.pageName.experience': 'Arbeidserfaring',
    'contractor.settings.pageName.gcc': 'Politiattester',
    'contractor.settings.pageName.attachment': 'Andre vedlegg',
    'contractor.settings.pageName.general': 'Bruker',
    'contractor.settings.pageName.index': 'Velkommen',
    'contractor.settings.pageName.languages': 'Språk',
    'contractor.settings.pageName.others': 'Vakttyper og reisevei',
    'contractor.settings.pageName.payment': 'Betaling og lønn',
    'contractor.settings.pageName.skills': 'Kurs og ferdigheter',
    'contractor.settings.pageName.video': 'Profilvideo',

    /**
     * Admin Main Sidenav
     */

    'admin.nav.dashboard': 'Dashboard',
    'admin.nav.messages': 'Email\'s',
    'admin.nav.users': 'Vikarer og bedrifter',
    'admin.nav.list_users': 'Brukere',
    'admin.nav.contractor': 'Vikarer',
    'admin.nav.contractorRequests': 'Requests',
    'admin.nav.contractor.map': 'Kart over vikarer',
    'admin.nav.contractee': 'Bedrifter',

    'admin.nav.vacancy': 'Ledige stillinger',
    'admin.nav.vacancy.active': 'Aktive',
    'admin.nav.vacancy.expired': 'Expired',
    'admin.nav.vacancy.hired': 'Hired/Closed',
    'admin.nav.vacancy.archived': 'Archived',

    'admin.nav.assignment': 'Vakter',
    'admin.nav.assignment.active': 'Utlyste vakter',
    'admin.nav.assignment.completed': 'Avsluttede vakter',
    'admin.nav.assignment.applied': 'Forespurte vakter',
    'admin.nav.assignment.ongoing': 'Bestilte vakter',
    'admin.nav.assignment.single': 'Visning av oppdrag',
    'admin.nav.assignment.lost-declined': 'Avvist jobbforespørsler',
    'admin.nav.assignment.requested': 'Forespurte vakter',
    'admin.nav.timesheet': 'Timelister',

    'admin.nav.vacancy.page': 'Vacancy',
    'admin.nav.vacancy.plan': 'Plans',
    'admin.nav.vacancy.feature': 'Features',
    'admin.nav.vacancy.occupation': 'Stillingsfunksjon',

    'admin.nav.manage': 'Administrasjon',
    'admin.nav.manage.sub.agreements': 'Kontrakter',
    'admin.nav.manage.sub.approvals': 'Godkjennelser',
    'admin.nav.manage.sub.badges': 'Utmerkelser',
    'admin.nav.manage.sub.role': 'Role',
    'admin.nav.manage.sub.courses': 'Kurser',
    'admin.nav.manage.sub.category': 'Yrker',
    'admin.nav.manage.sub.faq': 'Ofte stilte spørsmål',
    'admin.nav.manage.sub.shifts': 'Vakttyper',
    'admin.nav.manage.sub.skills': 'Ferdigheter',
    'admin.nav.manage.sub.salary': 'Salary',
    'admin.nav.manage.sub.faqs': 'Ofte stilte spørmål',
    'admin.nav.manage.sub.desc-template': 'Vakt maler',
    'admin.nav.manage.sub.my-workers': 'Mine Vikarer',
    'admin.nav.manage.sub.export': 'Export CVS for User',
    'admin.nav.manage.sub.titles': 'Yrkes titler',
    'admin.nav.manage.sub.tags': 'Tags',

    'admin.nav.metrics': 'Metrics',
    'admin.nav.horizon': 'Horizon',

    'admin.horizon.PageName': 'Horizon',
    'admin.metrics.PageName': 'Metrics',
    /**
     *  Contractor View Profile
     *
     *  contractor/profile/displayfeedback
     *
     * */

    'app.header.no-messages': 'Ingen meldinger',

    //Messages
    'messages.nav': 'Meldinger',

    // Auth

    'user.account.edit.first-name': 'Fornavn',
    'user.account.edit.last-name': 'Etternavn',
    'user.account.edit.email': 'E-postadresse',
    'user.account.edit.password': 'Passord',
    'user.account.edit.confirm-password': 'Bekreft passord',
    'user.account.edit.save': 'Lagre',
    'contractor.account.edit.phone': 'Mobile',
    'contractor.account.edit.city': 'Sted',

    'auth.signup.link': 'Register deg',

    'auth.login.title': 'Velkommen til uWork',
    'auth.login.description': '',

    'auth.login.sub-title': 'Logg Inn',
    'auth.login.or': 'eller',

    'auth.login.btn': 'Logg inn',
    'auth.login.link': 'Tilbake til innlogging',

    'auth.confirm.title': 'Bekreft din konto',
    'auth.confirm.description': 'For å kunne ta i bruk systemet må du først bekrefte din e-postadresse. Trykk på eller kopier lenken du har fått tilsendt på e-post.',
    'auth.confirm.btn': 'Bekreft konto',
    'auth.confirm.link': 'Bekreft konto.',

    'auth.confirm-resend.title': 'Send ny aktiveringskode',
    'auth.confirm-resend.description': 'Fyll ut din e-postadresse for å få tilsend et nytt aktiveringskode for å bekrefte din konto.',
    'auth.confirm-resend.btn': 'Send',
    'auth.confirm-resend.link': 'Send aktiveringskode på nytt',

    'auth.forgot.title': 'Glemt passord!',
    'auth.forgot.description': 'Vennligst skriv din e-postadresse under for å få tilsendt en e-postmelding med mulighet for å endre ditt passord.',
    'auth.forgot.success': 'Vi har sendt en e-post til deg. Vennligst følg linken i e-posten for å lage et nytt passord.',
    'auth.forgot.btn': 'Send passord',
    'auth.forgot.link': 'Glemt passord?',

    'auth.reset.title': 'Endre passord.',
    'auth.reset.description': '',
    'auth.reset.success.title': 'Suksess!',
    'auth.reset.success.description': 'Ditt passord er nå endret. Trykk på "tilbake til innlogging" for å logge inn med ditt nye passord. ',
    'auth.reset.btn': 'Endre passord',
    'auth.reset.link': '',

    'auth.lable.account': 'Brukernavn',
    'auth.label.email': 'E-post',
    'auth.label.password': 'Passord',
    'auth.label.forget-password': 'Skriv inn nytt passord',
    'auth.label.password-confirmation': 'Bekreft passord',
    'auth.label.token': 'Aktiveringskode',

    //Notifications

    'contractor.notification.order_created.header': 'Vakter (Gjelder kun «verifiserte uWorkere»)',
    'contractor.notification.order_created': 'Velg hvordan du ønsker å bli varslet når det legges ut nye vakter på uWork. Velg én eller flere',

    'contractor.notification.shift_types.header': 'Vakttyper (Gjelder kun «verifiserte uWorkere»)',
    'contractor.notification.shift_types': 'Velg hvilke vakttyper du ønsker å bli varslet på sms og/eller e-post om',

    'contractor.notification.newsletter.header': 'Nyhetsbrev',
    'contractor.notification.newsletter': 'Ønsker du å motta nyhetsbrev fra uWork-teamet?',
    'contractor.notification.newsletter.option': 'Ja, jeg ønsker å motta nyhetsbrev med relevant info om stillinger og oppdateringer på uWork.',

    'all_day_shift': 'Døgnvakt',
    'day_shift': 'Dagvakt',
    'night_shift': 'Kveldsvakt',
    'guard': 'Nattvakt',

    'user.notification.message_received.header': 'Meldinger',
    'user.notification.message_received': 'Vil du bli varslet på e-post når noen sender deg en melding på uWork?',
    'contractee.notification.message_received': 'Vil du bli varslet på e-post når noen sender deg en melding på uWork?',
    'user.notification.message_received.option': 'Ja, jeg ønsker å bli varslet på e-post når jeg mottar en melding inne på uWork.',

    'user.notification.email': 'E-post',
    'user.notification.sms': 'SMS',

    'contractee.notification.order_user_applied.header': 'En uWorker har søkt på et vikaroppdrag',
    'contractee.notification.order_user_applied': 'Vil du blir varslet på e-post når du har fått søkere på vikaroppdrag? Det sendes kun ut én e.post for å varsle deg om at du har fått søkere.',
    'contractee.notification.order_user_applied.option': 'Ja, jeg ønsker å bli varslet på e-post.',

    'contractee.notification.vacancy_user_applied.header': 'En uWorker har søkt på en stillingsutlysning',
    'contractee.notification.vacancy_user_applied': 'Vil du bli varslet på e-post når du får nye søkere på utlyste stillinger? Du mottar kun én e-post om dagen.',
    'contractee.notification.vacancy_user_applied.option': 'Ja, jeg ønsker å bli varslet på e-post.',

    'contractor.notification.order_alert.header': 'Vaktoppdateringer (Gjelder kun "verifiserte uWorkere")',
    'contractor.notification.order_alert': 'Vil du bli varslet om oppdateringer på vakter du har meldt interesse på? Varslinger innebærer: om du blir valgt til vakt, en annen blir valgt til vakten eller om vakten blir slettet.',
    'contractor.notification.order_alert.option': 'Ja jeg ønsker å bli varslet på e-post når det skjer oppdateringer på vakter jeg har meldt interesse på.',

    'contractor.notification.vacancy_created.header': 'Stillinger publisert på uWork ',
    'contractor.notification.vacancy_created': 'Vil du bli varslet om nye stillinger publisert på uWork? Vi sender ut kun én automatisk e-post hver 14. dag. ',
    'contractor.notification.vacancy_created.option': 'Ja, jeg ønsker å bil varslet på e-post om nye stillinger på uWork. ',

    'contractee.notification.newsletter.header': 'Nyhetsbrev',
    'contractee.notification.newsletter': 'Ønsker du å motta nyhetsbrev fra uWork-teamet?',
    'contractee.notification.newsletter.option': 'Ja, jeg ønsker å motta nyhetsbrev med relevant info om nyheter og oppdateringer på uWork.',

    //User single-request
    'user.single-request.download': 'Nedlasting',
    'user.single-request.nda-item.title': 'Tittel:',

    // Validations

    'The institution field is required.': 'Feltet er påkrevet.',
    'The degree field is required.': 'Feltet er påkrevet.',
    'The degree must be at least 2 characters.': 'Feltet skal være minst 2 tegn.',
    'The started field is required.': 'Feltet er påkrevet. Du må velge både dag, mnd. og år.',
    'The ended field is required when current is .': 'Feltet er påkrevet. Du må velge både dag, mnd. og år.',
    'The ended must be a date after started.': 'Til dato må være etter fra dato.',

    'The name field is required.': 'Feltet er påkrevet.',
    'The name must be at least 2 characters.': 'Feltet skal være minst 2 tegn.',

    'The title field is required.': 'Feltet er påkrevet.',
    'The title must be at least 2 characters.': 'Feltet skal være minst 2 tegn.',

    // Vacancy

    'vacancy.pageName.create': 'Ny stilling',
    'vacancy.pageName.edit': 'Edit Job',
    'vacancy.pageName.index': 'Jobs Board',
    'vacancy.pageName.view': 'Visning av stilling',
    'vacancy.pageName.select': 'Opprett dialog med kandidater',

    'vacancy.content.title': 'Du er nesten i mål!',
    'vacancy.content.description': 'Om du allerede har en stillingsbeskrivelse kan du enkelt copy/paste den i feltet "beskrivelse av stillingen".',

    'vacancy.form.title': 'Stillingstittel',
    'vacancy.form.deadLineAt': 'Søknadsfrist',
    'vacancy.form.expireAt': 'Expire At',
    'vacancy.form.type': 'Stillingstype',
    'vacancy.form.numberOfPosition': 'Antall stillinger',
    'vacancy.form.status': 'Status',
    'vacancy.form.description': 'Beskrivelse av stillingen',
    'vacancy.form.submit': 'Legg ut stilling',
    'vacancy.form.close': 'Close',
    'vacancy.form.save': 'Lagre',
    'vacancy.form.clear': 'Fjern',
    'vacancy.form.draft': 'Lagre som kladd',
    'vacancy.form.cancel': 'Lukk',
    'vacancy.form.delete': 'Slette',
    'vacancy.form.archive': 'Arkiver',
    'vacancy.form.unpublish': 'Fjern publisering',
    'vacancy.form.publish': 'Publiser stilling',
    'vacancy.form.plans': 'Fortsett',
    'vacancy.form.plan_extra_features': 'Show Extra Features',
    'vacancy.form.vacancy': 'Show Vacancy',

    'vacancy.view': 'Ledig stilling',
    'vacancy.view.tags': 'Nøkkerlord',
    'vacancy.view.location': 'Sted',
    'vacancy.view.occupations': 'Stillingsfunksjon',
    'vacancy.view.deadLineAt': 'Søknadsfrist',
    'vacancy.view.expireAt': 'Expire At',
    'vacancy.view.type': 'Stillingstype',
    'vacancy.view.status': 'Status',
    'vacancy.view.plan': 'Plan',
    'vacancy.view.price': 'Price',
    'vacancy.view.features': 'Tilleggsprodukter',
    'vacancy.view.share': 'Del:',
    'vacancy.view.hired': 'Hired',
    'vacancy.view.hire': 'Hire Me',
    'vacancy.view.phone': 'Ring',
    'vacancy.view.message': 'Send melding',
    'vacancy.view.rating': 'Rating:',
    'vacancy.view.orderCount': 'Gjennomført:',
    'vacancy.view.orderCountDescription': '{{length}} vakter',
    'vacancy.view.place': 'Sted:',
    'vacancy.view.numberOfPosition': 'Antall stillinger',
    'vacancy.view.zipCode': 'Postnummer:',
    'vacancy.view.address': 'Adresse:',
    'vacancy.view.edit': 'Rediger',
    'vacancy.view.hasApplicant': 'Se søkere',
    'vacancy.view.selectApplicant': ' Velg flere søkere',
    'vacancy.view.description': 'Beskrivelse av stillingen',
    'vacancy.view.submit': 'Legg ut stilling',
    'vacancy.view.delete': 'Slette',
    'vacancy.view.apply': 'Meld interesse',
    'vacancy.view.views': {
      'zero': 'Ingen visninger',
      'one': '1 visning',
      'other': '{{count}} visninger'
    },
    'vacancy.view.applicants': {
      'zero': 'Ingen søkere',
      'one': '1 søker',
      'other': '{{count}} søkere'
    },

    'vacancy.select.title': 'Velg søkere for følgende stilling:',
    'vacancy.select.search-header': 'Velg en eller flere søkere du ønsker å opprette dialog med',
    'vacancy.select.expireAt': 'Expire At',

    'vacancy.user.applied.title': 'Du har meldt interesse!',
    'vacancy.user.applied.content': 'Arbeidsgiveren har blitt informert om at du har søkt på stillingen. Arbeidsgiveren vil gjøre en vurdering av deg basert på din uWork profil. Om du er en interessant kandidat vil arbeidsgiver kontakte deg via uWork chatten. Du vil bli varslet på e-post om du blir kontaktet. Lykke til!',

    'vacancy.user.selected.title': 'Selected!',
    'vacancy.user.selected.content': 'You are the best match for company, Congrats! You will be informed for further steps',

    'vacancy.user.hired.title': 'Hired!',
    'vacancy.user.hired.content': 'We wish you good look in new you journey',

    'vacancy.contractee.hired.title': 'Gratulerer med nyansettelse!',
    'vacancy.contractee.hired.content': 'Vi setter pris på at dere brukte uWork for å utlyse stillingen. Ønsker dere lykke til med ny medarbeider. Del gjerne med oss deres tilbakemeldinger med prosessen.',

    'vacancy.contractee.closed.title': 'Archived!',
    'vacancy.contractee.closed.content': 'Please contact the uWork management to learn why the vacancy has been archived',

    'vacancy.contractee.has-expired.title': 'Søknadsfristen har gått ut!',
    'vacancy.contractee.has-expired.content': 'Brukere kan ikke lenger søke på stillingen. Oppdater søknadsfristen hvis du fortsatt ønsker å få inn søkere.',

    'vacancy.contractor.has-expired.title': 'Søknadsfristen har gått ut!',
    'vacancy.contractor.has-expired.content': 'Det er ikke mulig å søke på denne stillingen lenger!',

    'vacancy.contractor.pageName.index': 'Stillinger',
    'vacancy.contractor.pageName.applied': 'Stillinger du har søkt på',
    'vacancy.contractor.pageName.view': 'Visning av stilling',

    'vacancy.noResult': 'Fant ingen ledige stillinger!',
    'vacancy.noVacancyFound': 'Fant ingen ledige stillinger!',

    'vacancy.contractor.view.zero': 'Det er ingen søkere på denne vakten!',
    'vacancy.contractor.view.five': 'Det er få søkere på denne vakten, meld interesse for å bli valgt!',
    'vacancy.contractor.dashboard.newJobs.title': 'Publiserte stillinger',
    'vacancy.contractor.dashboard.newJobs.viewAll': 'Se alle aktive stillinger',

    'vacancy.contractor.dashboard.appliedJobs.title': 'Stillinger du har søkt på',
    'vacancy.contractor.dashboard.appliedJobs.viewAll': 'Se alle ledige stillinger',

    'vacancy.contractor.dashboard.table.title': 'Stilling',
    'vacancy.contractor.dashboard.table.contractee': 'Bedrift',
    'vacancy.contractor.dashboard.table.type': 'Stillingstype',
    'vacancy.contractor.dashboard.table.expireAt': 'Søknadsfrist',
    'vacancy.contractor.dashboard.table.viewAll': 'Se alle ledige stillinger'
    //Wizard
    // 'wizard.next': 'left',
    // 'wizard.right': 'right',


  };
});