define('uwork-app-v2/vacancy-plan-feature/adapter', ['exports', 'uwork-app-v2/application/adapter'], function (exports, _adapter) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _adapter.default.extend({
        session: Ember.inject.service(),
        account: Ember.inject.service('user/session-account'),
        user: Ember.computed.alias('account.currentUser'),
        urlForQuery: function urlForQuery(modelName, snapshot) {
            // console.log('user');
            // console.log(get(this, 'user.isContractee'));
            if (Ember.get(this, 'user.isContractee')) {
                return this.urlPrefix() + '/vacancies/public/plan-features';
            }
            return this._super.apply(this, arguments);
        }
    }
    // urlForQueryRecord(query){
    //     if (query.plan_id && query.feature_id && query.order_id) {
    //
    //         let plan_id =  query.plan_id;
    //         let feature_id =  query.feature_id;
    //         let order_id =  query.order_id;
    //
    //         delete query.plan_id;
    //         delete query.feature_id;
    //         delete query.order_id;
    //
    //         return `${this.urlPrefix()}/vacancy-plan-features/update-order-id/${plan_id}/${feature_id}/${order_id}`;
    //     }
    //
    //     return this._super(...arguments);
    // }

    );
});