define('uwork-app-v2/components/admin/assignment/shift-item/component', ['exports', 'uwork-app-v2/mixins/default-ds-action-mixin', 'uwork-app-v2/components/admin/assignment/shift-item/validation', 'uwork-app-v2/order-shift/model', 'moment', 'ember-changeset-validations', 'ember-changeset'], function (exports, _defaultDsActionMixin, _validation, _model, _moment, _emberChangesetValidations, _emberChangeset) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var keys = Object.keys;
    exports.default = Ember.Component.extend(_defaultDsActionMixin.default, {
        store: Ember.inject.service(),
        validation: _validation.default,
        schema: _model.schema,

        errors: null,

        init: function init() {
            this._super.apply(this, arguments);
            this._changeset();
        },
        _changeset: function _changeset() {
            Ember.set(this, 'changeset', new _emberChangeset.default(Ember.get(this, 'model'), (0, _emberChangesetValidations.default)(_validation.default), _validation.default));
        },


        tagName: '',
        modelType: "order-shift",

        timeFrom: Ember.computed('changeset.fromDate', function () {
            var date = Ember.get(this, 'changeset.fromDate');

            if (!_moment.default.isMoment(date)) {
                date = (0, _moment.default)(date);
            }

            return date.format('HH:mm');
        }),

        timeTo: Ember.computed('changeset.toDate', function () {
            var date = Ember.get(this, 'changeset.toDate');

            if (!_moment.default.isMoment(date)) {
                date = (0, _moment.default)(date);
            }

            return date.format('HH:mm');
        }),

        actions: {
            addTime: function addTime(changeset, item) {
                var date = Ember.get(changeset, item);
                var object = (0, _moment.default)(date).add(15, 'm');

                Ember.set(changeset, item, object);
            },
            close: function close(changeset) {
                Ember.set(this, 'errors', null);
                changeset.rollback();
                this.toggleProperty('isEditing');
            },
            subTime: function subTime(changeset, item) {
                var date = Ember.get(changeset, item);
                var object = (0, _moment.default)(date).subtract(15, 'm');

                Ember.set(changeset, item, object);
            },
            save: function save(changeset) {
                var _this = this;

                var snapshot = changeset.snapshot();

                // Reset errors variable
                Ember.set(this, 'errors', null);

                var fromDate = (0, _moment.default)(Ember.get(changeset, 'fromDate'));
                var toDate = (0, _moment.default)(Ember.get(changeset, 'toDate'));
                if (fromDate.isBefore(toDate)) {

                    return changeset.cast(keys(Ember.get(this, 'schema'))).validate().then(function () {
                        if (Ember.get(changeset, 'isValid')) {

                            Ember.set(changeset, 'fromDate', fromDate.format('YYYY-MM-DD H:m:s'));
                            Ember.set(changeset, 'toDate', toDate.format('YYYY-MM-DD H:m:s'));

                            _this.setLoading(true);
                            changeset.save().then(function () {
                                _this.swal({
                                    title: "Info",
                                    html: "Vakt oppdatert"
                                });

                                Ember.set(_this, 'isEditing', false);
                            }).catch(function () {
                                Ember.get(_this, 'model.errors').forEach(function (_ref) {
                                    var attribute = _ref.attribute,
                                        message = _ref.message;

                                    changeset.pushErrors(attribute, message);
                                });
                                //this.setLoading(false);
                            });
                        } else {
                            _this.swal({
                                title: "Can't Update!",
                                html: "Is not valid",
                                type: 'error'
                            });
                        }
                        _this.setLoading(false);
                    }).catch(function () {
                        changeset.restore(snapshot);
                        _this.setLoading(false);
                    });
                } else {
                    this.swal({
                        title: "Info",
                        html: "Dato til må være etter dato fra!",
                        type: 'info'
                    });
                    this.setLoading(false);
                    Ember.set(this, 'errors', [{ detail: 'Dato til må være etter dato fra!' }]);
                    changeset.restore();
                    return;
                }
            }
        }

    });
});