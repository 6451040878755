define('uwork-app-v2/initializers/inject-swal', ['exports', 'uwork-app-v2/mixins/swal-mixin'], function (exports, _swalMixin) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.initialize = initialize;
    function initialize() {
        Ember.Component.reopen(_swalMixin.default);
        Ember.Controller.reopen(_swalMixin.default);
        Ember.Route.reopen(_swalMixin.default);
    }

    exports.default = {
        name: 'inject-swal',
        initialize: initialize
    };
});