define('uwork-app-v2/contractee/assignments/view/index/controller', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        notFoundError: Ember.computed.empty('model'),
        actions: {
            changeNotification: function changeNotification(model) {
                var _this2 = this;

                this.setLoading(true);

                model.setNotificationAll();
                model.save().then(function () {
                    _this2.setLoading(false);

                    _this2.swal({
                        title: "Alle vikarer har blitt varslet",
                        text: "Du vil nå kunne motta flere søkere på vakten.",
                        showCancelButton: false,
                        confirmButtonColor: "#14CA9C",
                        confirmButtonText: "OK"
                    }).then(function () {}, function () {}).finally(function () {

                        model.reload();
                    });
                });
            },
            deleteOrder: function deleteOrder(model) {
                var _this = this;
                this.swal({
                    title: "Ønsker du å slette denne bestillingen?",
                    text: "Det er ikke mulig å gjenopprette en slettede bestilling i ettertid!",
                    type: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#DD6B55",
                    confirmButtonText: "Ja, slette bestilling!"
                }).then(function () {
                    model.destroyRecord().then(function () {
                        _this.transitionToRoute('contractee.assignments.active-requests');
                    });
                }, function () {});
            }
        }
    });
});