define('uwork-app-v2/contractor/vacancy/index/route', ['exports', 'ember-i18n', 'ember-cli-jsonapi-pagination/mixins/routes/jsonapi-pagination'], function (exports, _emberI18n, _jsonapiPagination) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend(_jsonapiPagination.default, {
        title: (0, _emberI18n.translationMacro)('vacancy.contractor.pageName.index'),
        breadCrumb: {},
        queryParams: {
            query: {
                refreshModel: true,
                replace: true
            },
            recent: {
                refreshModel: true,
                replace: true
            },
            location: {
                refreshModel: true,
                replace: true
            }
        },
        beforeModel: function beforeModel() {
            this.setLoading(true);
        },
        afterModel: function afterModel() {
            var title = this.get('title');

            var bTitle = {
                title: title.string
            };

            this.setLoading(false);
            Ember.set(this, 'breadCrumb', bTitle);
        },
        model: function model(params) {
            return this.store.query('vacancy', {
                number: params.number,
                deadline_in_future: 1,
                status: 20,
                size: params.size
            });
        }
    });
});