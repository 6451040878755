define('uwork-app-v2/authenticators/auth-login', ['exports', 'ember-simple-auth/authenticators/base'], function (exports, _base) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _base.default.extend({
        ajax: Ember.inject.service(),
        session: Ember.inject.service(),

        authenticate: function authenticate(token) {

            var _token = {
                token: token
            };

            return Ember.RSVP.resolve(_token);
        }
    });
});