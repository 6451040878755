define('uwork-app-v2/contractee/settings/controller', ['exports', 'uwork-app-v2/substitute/model', 'uwork-app-v2/vacancy/model', 'uwork-app-v2/mixins/has-role-mixin'], function (exports, _model, _model2, _hasRoleMixin) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend(_hasRoleMixin.default, {
        ROLE_NAME_VACANCY: _model2.ROLE_NAME,
        ROLE_NAME_SUBSTITUTE: _model.ROLE_NAME,
        media: Ember.inject.service(),
        routing: Ember.inject.service('-routing'),
        account: Ember.inject.service('user/session-account'),
        menu: Ember.inject.service('extend-menu'),
        user: Ember.computed.alias('account.currentUser'),
        isWizard: Ember.computed.equal('user.isSignUpCompleted', false),
        wizardService: Ember.inject.service('contractee/wizard'),
        wizardNavLinks: Ember.computed('wizardService.visitedRoutes.[]', function () {
            return Ember.get(this, 'wizardService.getNavLinks');
        }),
        links: Ember.computed('user', function () {

            if (this.hasRole(_model.ROLE_NAME, Ember.get(this, 'user'))) {
                return [{ id: 1, route: 'contractee.settings.account', name: 'contractee.settings.link.account' }, { id: 2, route: 'contractee.settings.avatar', name: 'contractee.settings.link.avatar' }, { id: 3, route: 'contractee.settings.category', name: 'contractee.settings.link.category' }, { id: 4, route: 'contractee.settings.company', name: 'contractee.settings.link.company' }, { id: 5, route: 'contractee.settings.nda', name: 'contractee.settings.link.nda' }, { id: 6, route: 'contractee.settings.rates', name: 'contractee.settings.link.rates' }, { id: 7, route: 'contractee.settings.contracts', name: 'contractee.settings.link.contracts' }, { id: 8, route: 'contractee.settings.additional-payments', name: 'contractee.settings.link.additional_payments' }, { id: 9, route: 'contractee.settings.notifications', name: 'contractee.settings.link.notifications' }, { id: 10, route: 'contractee.settings.data', name: 'contractee.settings.link.user_data' }, { id: 11, route: 'contractee.settings.agreement', name: 'contractee.settings.link.agreement' }];
            }

            if (this.hasRole(_model2.ROLE_NAME, Ember.get(this, 'user'))) {
                return [{ id: 1, route: 'contractee.settings.account', name: 'contractee.settings.link.account' }, { id: 2, route: 'contractee.settings.avatar', name: 'contractee.settings.link.avatar' }, { id: 3, route: 'contractee.settings.company', name: 'contractee.settings.link.company' }, { id: 4, route: 'contractee.settings.notifications', name: 'contractee.settings.link.notifications' }, { id: 5, route: 'contractee.settings.data', name: 'contractee.settings.link.user_data' }, { id: 6, route: 'contractee.settings.agreement', name: 'contractee.settings.link.agreement' }];
            }

            return [];
        }),
        closeMenu: function closeMenu() {
            // let menu = get(this, 'menu.menu');
            // menu.publicActions.closeSubNavigation();
            if (Ember.$('#navbarToggleExternalContent').hasClass('show')) {
                Ember.$('#navbarToggleExternalContent').removeClass('show');
            }
        },

        actions: {
            closeMenu: function closeMenu() {
                this.closeMenu();
            }
        }
    });
});