define('uwork-app-v2/components/admin/assignments/new/order-shift-turn-items/component', ['exports', 'moment'], function (exports, _moment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        tagName: 'table',
        classNames: ['table', 'table-sm', 'responsive', 'app-order-create'],

        actions: {
            decrement: function decrement(model, key) {
                var oppositeKey = key === 'fromDate' ? 'toDate' : 'fromDate';
                var dt = Ember.get(model, key);
                var oppositeDt = Ember.get(model, oppositeKey);
                var now = (0, _moment.default)();
                var allowPast = Ember.get(this, 'allowPast');
                var shifts = Ember.get(this, 'model.orderShifts');

                var sub = dt.clone().subtract(30, 'm');
                var min = model.isNightShift() ? dt.clone().subtract(1, 'd').startOf('day') : dt.clone().startOf('day');

                // Before today not allowed
                if (sub.isBefore(now) && allowPast === false) {
                    return;
                }

                // To date cant be before from date
                if (sub.isSame(oppositeDt)) {
                    return;
                }

                // Validate min date
                if (key === 'fromDate' && sub.isSameOrBefore(min)) {
                    return;
                }

                var index = shifts.indexOf(model);
                var prev = shifts.objectAt(index - 1);

                if (key === 'fromDate' && prev) {
                    var closest = Ember.get(prev, oppositeKey);

                    // To date cant be before from date on prev model.
                    if (closest.isSameOrBefore(Ember.get(prev, key))) {
                        return;
                    }

                    // Change closest date
                    if (closest.isSameOrAfter(sub)) {
                        Ember.set(prev, oppositeKey, sub);
                    }
                }

                Ember.set(model, key, sub);
            },
            increment: function increment(model, key) {
                var oppositeKey = key === 'fromDate' ? 'toDate' : 'fromDate';
                var dt = Ember.get(model, key);
                var oppositeDt = Ember.get(model, oppositeKey);
                //let allowPast = get(this, 'allowPast');
                var shifts = Ember.get(this, 'model.orderShifts');

                var add = dt.clone().add(30, 'm');
                var max = model.isNightShift() ? oppositeDt.clone().add(1, 'd').endOf('day') : oppositeDt.clone().endOf('day');

                // To date cant be before from date
                if (add.isSame(oppositeDt)) {
                    return;
                }

                // Validate max date (end of today/add one day on All day shifts and Night Shifts)
                if (key === 'toDate' && add.isSameOrAfter(max)) {
                    return;
                }

                var index = shifts.indexOf(model);
                var next = shifts.objectAt(index + 1);

                if (key === 'toDate' && next) {
                    var closest = Ember.get(next, oppositeKey);

                    // Change closest date
                    if (add.isSameOrAfter(closest)) {
                        Ember.set(next, oppositeKey, add);
                    }
                }

                Ember.set(model, key, add);
            },
            delete: function _delete(model) {
                model.destroyRecord();
            }
        }
    });
});