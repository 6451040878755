define('uwork-app-v2/validations/found-zipcode', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = foundZipcode;
    function foundZipcode() {
        return function (key, newValue, oldValue, changes, content) {

            if (!key) {
                return true;
            }
            if (!newValue) {
                return 'Feil postnummer';
            }
            var found = content.filterModel(newValue.toString());

            if (found) {
                return true;
            }
            return 'Feil postnummer';
        };
    }
});