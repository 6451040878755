define('uwork-app-v2/order/model', ['exports', 'ember-data/model', 'ember-data/attr', 'ember-data/relationships', 'moment'], function (exports, _model, _attr, _relationships, _moment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.schema = undefined;


    var STATUS_DEFAULT = 1;
    var STATUS_ACCEPTED = 2;
    var STATUS_PENDING = 3;

    var TYPE_DEFAULT = 1;
    var TYPE_MYWORKERS = 10;
    var TYPE_TURNUS = 20;

    var TYPE_ADMIN_DEFAULT = 50;
    var TYPE_ADMIN_DIRECT = 60;
    var TYPE_ADMIN_DIRECT_PAST = 70;
    var TYPE_ADMIN_TURNUS = 80;
    var TYPE_ADMIN_GEOGRAPHIC = 90;

    var NOTIFY_MYWORKERS = 1;
    var NOTIFY_DIRECT = 5;
    var NOTIFY_ALL = 10;
    var NOTIFY_GEO = 20;

    var schema = exports.schema = {
        fromDate: (0, _attr.default)('mdate'),
        toDate: (0, _attr.default)('mdate'),
        duration: (0, _attr.default)(),
        type: (0, _attr.default)('number'),
        notify: (0, _attr.default)('number', { defaultValue: 1 }),
        description: (0, _attr.default)('string', { defaultValue: '' }),
        period: (0, _attr.default)('number'),
        status: (0, _attr.default)('number'),
        views: (0, _attr.default)('number', { defaultValue: 0 }),
        createdAt: (0, _attr.default)('mdate'),
        updatedAt: (0, _attr.default)('mdate'),
        places: (0, _attr.default)(),
        radius: (0, _attr.default)('number', { defaultValue: 50 }),

        // @todo Clean up later, are being used on create order (admin/contractee)
        startDate: (0, _attr.default)('string', { defaultValue: '' }),
        endDate: (0, _attr.default)('string', { defaultValue: '' }),
        startTime: (0, _attr.default)('string', { defaultValue: '' }),
        endTime: (0, _attr.default)('string', { defaultValue: '' }),

        contractors: (0, _relationships.hasMany)('contractor'),
        category: (0, _relationships.belongsTo)('category'),
        shift: (0, _relationships.belongsTo)('shift', { async: false }),
        contractee: (0, _relationships.belongsTo)('contractee'),
        contractor: (0, _relationships.belongsTo)('contractor'),
        gcc: (0, _relationships.belongsTo)('gcc'),
        nda: (0, _relationships.belongsTo)('nda'),
        orderShifts: (0, _relationships.hasMany)('order-shift'),
        orderRequests: (0, _relationships.hasMany)('order-request', { async: false }),
        rating: (0, _relationships.belongsTo)('rating', { async: false }),
        conversation: (0, _relationships.belongsTo)('conversation', { async: false }),

        //Computed Operations
        showDescription: Ember.computed('description', function () {
            var description = Ember.get(this, 'description');
            return description ? Ember.String.htmlSafe(description) : '';
        }),
        hasRating: Ember.computed.notEmpty('rating'),
        hasNoRating: Ember.computed.empty('rating'),
        hasOrderShifts: Ember.computed.notEmpty('orderShifts'),
        turnus: Ember.computed.equal('duration', 0),

        canComment: Ember.computed('rating.hasNotComment', 'rating.createdAt', 'hasNoRating', function () {
            var now = parseInt((0, _moment.default)().diff(Ember.get(this, 'rating.createdAt'), 'minutes'));
            return now <= 30;
        }),

        canRate: Ember.computed('hasNoRating', 'rating.hasNotStar', 'rating.stars', 'rating.createdAt', function () {
            var now = parseInt((0, _moment.default)().diff(Ember.get(this, 'rating.createdAt'), 'minutes'));
            return now <= 30;
        }),

        contractorCanRepaly: Ember.computed('rating.hasReplay', 'rating.hasNotReplay', 'rating.replyAt', function () {
            var replyAt = Ember.get(this, 'rating.replyAt');

            if (!replyAt) {
                return true;
            }

            var _m = (0, _moment.default)().diff(replyAt, 'minutes');

            var now = parseInt(_m);

            return !Ember.get(this, 'rating.replyAt') || now <= 30;
        }),

        hasRequests: Ember.computed.notEmpty('orderRequests'),
        hasRequestsNotAccepted: Ember.computed('hasRequests', 'isAccepted', function () {
            return Ember.get(this, 'hasRequests') && !Ember.get(this, 'isAccepted');
        }),

        appliers: Ember.computed('orderRequests', function () {

            var interested = Ember.get(this, 'orderRequests').filterBy('status', 1);
            var pending = Ember.get(this, 'orderRequests').filterBy('status', 7);

            return pending.get('length') > 0 || interested.get('length') > 0;
        }),
        hasAppliers: Ember.computed.notEmpty('appliers'),
        hasAppliersNotAccepted: Ember.computed('appliers', 'isAccepted', function () {
            return Ember.get(this, 'appliers') && !Ember.get(this, 'isAccepted');
        }),
        requested: Ember.computed('orderRequests', function () {
            var orderRequests = Ember.get(this, 'orderRequests');
            return orderRequests.filterBy('status', 1);
        }),

        pendings: Ember.computed('orderRequests', function () {
            var orderRequests = Ember.get(this, 'orderRequests');
            return orderRequests.filterBy('status', 7);
        }),

        pending: Ember.computed('pendings', function () {
            return Ember.get(this, 'pendings.firstObject');
        }),

        isPending: Ember.computed('status', 'fromDate', 'pendings.length', function () {
            var fromDate = Ember.get(this, 'fromDate');
            var now = (0, _moment.default)().format('YYYY-MM-DD HH:mm:ss');

            return Ember.get(this, 'status') == STATUS_DEFAULT && (0, _moment.default)(fromDate).isSameOrAfter(now, 'hours') && Ember.get(this, 'pendings.length') > 0;
        }),
        isActive: Ember.computed('status', 'fromDate', 'requested.length', function () {
            var fromDate = Ember.get(this, 'fromDate');
            var now = (0, _moment.default)().format('YYYY-MM-DD HH:mm:ss');

            return Ember.get(this, 'status') == STATUS_DEFAULT && (0, _moment.default)(fromDate).isSameOrAfter(now, 'hours') && Ember.get(this, 'requested.length') == 0;
        }),
        isRequested: Ember.computed('status', 'fromDate', 'requested.length', function () {
            var fromDate = Ember.get(this, 'fromDate');
            var now = (0, _moment.default)().format('YYYY-MM-DD HH:mm:ss');

            return Ember.get(this, 'status') == STATUS_DEFAULT && (0, _moment.default)(fromDate).isSameOrAfter(now, 'hours') && Ember.get(this, 'requested.length') > 0;
        }),
        isSelecting: Ember.computed('isActive', 'isRequested', function () {
            return Ember.get(this, 'isActive') || Ember.get(this, 'isRequested');
        }),
        isAccepted: Ember.computed.equal('status', STATUS_ACCEPTED),
        isOngoing: Ember.computed('isAccepted', 'toDate', function () {
            var toDate = Ember.get(this, 'toDate');
            var now = (0, _moment.default)();

            return Ember.get(this, 'isAccepted') && now.isBefore(toDate);
        }),
        isLost: Ember.computed('status', 'fromDate', function () {
            var fromDate = Ember.get(this, 'fromDate');
            var now = (0, _moment.default)();

            return Ember.get(this, 'status') == STATUS_DEFAULT && (0, _moment.default)(fromDate).isSameOrBefore(now, 'hours');
        }),
        isCompleted: Ember.computed('isAccepted', 'toDate', function () {
            var toDate = Ember.get(this, 'toDate');
            var now = (0, _moment.default)();

            return Ember.get(this, 'isAccepted') && now.isAfter(toDate);
        }),

        isOngoingOrIsCompleted: Ember.computed.or('isCompleted', 'isOngoing'),

        setTypeDefault: function setTypeDefault() {
            this.set('type', TYPE_DEFAULT);
        },
        setTypeTurnus: function setTypeTurnus() {
            this.set('type', TYPE_TURNUS);
        },
        setTypeMyWorker: function setTypeMyWorker() {
            this.set('type', TYPE_MYWORKERS);
        },


        isNotifyMyWorker: Ember.computed.equal('notify', NOTIFY_MYWORKERS),
        isNotifyDirect: Ember.computed.equal('notify', NOTIFY_DIRECT),
        isNotifyAll: Ember.computed.equal('notify', NOTIFY_ALL),
        isNotifyGeo: Ember.computed.equal('notify', NOTIFY_GEO),

        setNotificationAll: function setNotificationAll() {
            this.set('notify', NOTIFY_ALL);
        },


        isDirectAdmin: Ember.computed.equal('type', TYPE_ADMIN_DIRECT),
        isDirectPastAdmin: Ember.computed.equal('type', TYPE_ADMIN_DIRECT_PAST),
        isGeographicAdmin: Ember.computed.equal('type', TYPE_ADMIN_GEOGRAPHIC),
        isTurnusAdmin: Ember.computed.equal('type', TYPE_ADMIN_TURNUS),

        isAdminDirect: Ember.computed('isDirectAdmin', 'isDirectPastAdmin', 'isTurnusAdmin', function () {
            return Ember.get(this, 'isDirectAdmin') === true || Ember.get(this, 'isDirectPastAdmin') === true || Ember.get(this, 'isTurnusAdmin') === true;
        }),
        isStatusPending: Ember.computed.equal('status', STATUS_PENDING),
        setStatusPending: function setStatusPending() {
            this.set('status', STATUS_PENDING);
        },
        setDirectAdmin: function setDirectAdmin() {
            this.set('type', TYPE_ADMIN_DIRECT);
        },
        setDirectPastAdmin: function setDirectPastAdmin() {
            this.set('type', TYPE_ADMIN_DIRECT_PAST);
        },
        setTurnusAdmin: function setTurnusAdmin() {
            this.set('type', TYPE_ADMIN_TURNUS);
        },
        setDefaultAdmin: function setDefaultAdmin() {
            this.set('type', TYPE_ADMIN_DEFAULT);
        },
        setGeographicAdmin: function setGeographicAdmin() {
            this.set('type', TYPE_ADMIN_GEOGRAPHIC);
        }
    };

    exports.default = _model.default.extend(schema);
});