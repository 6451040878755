define('uwork-app-v2/components/contractee/verifying/category-skills/component', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        session: Ember.inject.service(),
        account: Ember.inject.service('user/session-account'),
        user: Ember.computed.alias('account.currentUser'),
        getUserVerifiedRequests: Ember.computed.filterBy('user.userRequests', 'type', 'verification'),
        hasUnhandeledVerifiedRequests: Ember.computed('getUserVerifiedRequests', function () {
            return Ember.get(this, 'getUserVerifiedRequests').findBy('status', null);
        }),

        userHasBeenVerified: Ember.computed('getUserVerifiedRequests', function () {
            return Ember.get(this, 'getUserVerifiedRequests').findBy('status', 10);
        }),

        store: Ember.inject.service(),
        intercom: Ember.inject.service(),
        _body: {
            categories: []
        },

        save: function save() {
            var _this = this;

            var body = Ember.get(this, '_body');

            var userRequest = Ember.get(this, 'store').createRecord('user-request');

            userRequest.setVerificationType();
            userRequest.set('body', body);

            userRequest.save().then(function () {
                _this.setLoading(false);
                _this.swal({
                    customClass: 'verify_container',
                    title: "Sendt inn!",
                    html: "<h5 class='verify-title'>Neste steg</h5> <div class='verify-list'>" + "<div class='list'><div class='count'>1</div>En uWork representant vil gjøre en vurdering av din profil.</div>" + "<div class='list'><div class='count'>2</div>Innen 48 timer får du tilbakemelding på om du kvalifiserer til å gå videre i prosessen.</div>" + "<div class='list'><div class='count'>3</div>Om du kvalifiserer vil HR ansvarlig i uWork kontakte deg for å avtale en samtale på våre kontorer eller via skype facetime</div>" + "</div> ",
                    icon: "success",
                    type: "success",
                    confirmButtonColor: "#14CA9C",
                    confirmButtonText: "OK"
                });
            });
        },

        actions: {
            setCategory: function setCategory(data) {

                var body = Ember.get(this, '_body');

                body.categories = [];
                body.categories.addObject(data);
            },
            save: function save() {
                var body = Ember.get(this, '_body');

                if (!Ember.isEmpty(body.categories)) {
                    this.setLoading(true);
                    this.save();
                } else {
                    this.swal({
                        title: "Required Fields",
                        text: "Select one Category ",
                        type: "error",
                        confirmButtonColor: "#DD6B55",
                        confirmButtonText: "Back to Panel"
                    }).then(function () {}, function () {});
                }
            }
        }
    });
});