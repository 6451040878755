define('uwork-app-v2/contractor/settings/avatar/controller', ['exports', 'ember-i18n'], function (exports, _emberI18n) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        title: (0, _emberI18n.translationMacro)('contractor.settings.pageName.avatar'),
        wizardService: Ember.inject.service('contractor/wizard'),
        wizardRouteName: 'contractor.settings.avatar',

        account: Ember.inject.service('user/session-account'),
        wizardCurrentStep: Ember.computed.alias('wizardStep.step'),
        user: Ember.computed.alias('account.currentUser'),
        isWizard: Ember.computed.equal('user.isSignUpCompleted', false)
    });
});