define('uwork-app-v2/admin/timesheet/route', ['exports', 'ember-i18n'], function (exports, _emberI18n) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        title: (0, _emberI18n.translationMacro)('timesheet.PageName'),
        queryParams: {
            // week: {replace: true, refreshModel: true},
            month: { replace: true, refreshModel: true },
            year: { replace: true, refreshModel: true },
            shift: { replace: true, refreshModel: true },
            contractor: { replace: true, refreshModel: true },
            contractee: { replace: true, refreshModel: true }
        },
        breadCrumb: {},

        beforeModel: function beforeModel() {
            this.setLoading(true);
        },
        afterModel: function afterModel() {
            var title = this.get('title');

            var bTitle = {
                title: title.string
            };
            this.setLoading(false);
            Ember.set(this, 'breadCrumb', bTitle);
        },
        model: function model(params) {

            var month = params.month;
            var year = params.year;

            return Ember.RSVP.hash({
                contractors: this.store.query('contractor', { timesheet: true, year: year, month: month }),
                contractees: this.store.query('contractee', { timesheet: true, year: year, month: month }),
                shifts: this.store.findAll('shift')
            });
        },
        setupController: function setupController(controller, model) {
            // Call _super for default behavior
            this._super(controller, model);
            // Implement your custom setup after

            Ember.get(controller, 'search').perform();
        },


        actions: {
            goTo: function goTo(orderId) {
                this.transitionTo('admin.assignments.view', orderId);
            },
            willTransition: function willTransition() {
                this.controller.set('_filter', {});
            }
        }
    });
});