define('uwork-app-v2/contractor/assignments/view/route', ['exports', 'ember-i18n', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _emberI18n, _authenticatedRouteMixin) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, {
        title: (0, _emberI18n.translationMacro)('contractor.assignment.view.PageName'),
        breadCrumb: {},
        beforeModel: function beforeModel() {
            this.setLoading(true);
        },
        afterModel: function afterModel() {
            var title = this.get('title');

            var bTitle = {
                title: title.string
            };

            this.setLoading(false);

            Ember.set(this, 'breadCrumb', bTitle);
        },
        model: function model(params) {
            return this.store.findRecord('order', params.order_id).catch(function () {
                //_this.transitionTo('contractor.orders.active');
            });
        }
    });
});