define('uwork-app-v2/components/contractee/vacancy/view/display-vacancy-toolbar/component', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        menu: Ember.inject.service('extend-menu'),
        click: function click() {
            this.sendAction('toRoute');
        }
    });
});