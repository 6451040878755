define('uwork-app-v2/skill/model', ['exports', 'ember-data/model', 'ember-data/attr', 'ember-data/relationships'], function (exports, _model, _attr, _relationships) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.schema = undefined;
    var schema = exports.schema = {
        name: (0, _attr.default)(),
        description: (0, _attr.default)(),
        users: (0, _relationships.hasMany)('user')
    };

    exports.default = _model.default.extend(schema);
});