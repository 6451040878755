define('uwork-app-v2/components/contractor/profile/display-avatar/component', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        tagName: "",
        editing: false,
        store: Ember.inject.service(),
        titles: [],
        actions: {
            editForm: function editForm() {
                this.toggleProperty('editing');
            }
        },
        init: function init() {
            this._super.apply(this, arguments);
            var titles = Ember.get(this, 'store').findAll('contractor-title');

            Ember.set(this, 'titles', titles);
        }
    });
});