define('uwork-app-v2/components/g-map-marker', ['exports', 'ember-g-map/components/g-map-marker'], function (exports, _gMapMarker) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _gMapMarker.default;
    }
  });
});