define('uwork-app-v2/components/admin/order/user-assigned/component', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        media: Ember.inject.service(),
        account: Ember.inject.service('user/session-account'),
        user: Ember.computed.alias('account.currentUser'),
        // members: alias('user.users'),
        classNames: ['col-12', 'col-md-12', 'col-lg-2', 'col-xl-3', 'd-flex', 'mb-3', 'align-items-center', 'justify-content-between', 'justify-content-lg-start'],
        orderRequests: Ember.computed.alias('model.orderRequests'),
        orderRequestsUsers: Ember.computed.filter('orderRequests', function (user, index) {
            var media = Ember.get(this, 'media');

            if (media.get('isXs') || media.get('isLg')) {
                return index < 3;
            } else if (media.get('isSm') || media.get('isMd')) {
                return index < 1;
            }
            return index < 2;
        }),
        orderRequestsMore: Ember.computed.filter('orderRequests', function (user, index) {
            var media = Ember.get(this, 'media');

            if (media.get('isXs') || media.get('isLg')) {
                return index >= 3;
            } else if (media.get('isSm') || media.get('isMd')) {
                return index >= 1;
            }
            return index >= 2;
        })

    });
});