define('uwork-app-v2/components/app/video-player/component', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        selectedUser: false,
        src: "/assets/video/small.mp4",
        showPlayer: false,
        modalMode: false,
        inlineMode: false,
        setup: {
            play: true,
            playing: true,
            progress: true,
            controls: true,
            preload: "none"
        },
        player: false,
        component: false,
        user: false,
        actions: {
            ready: function ready(player, component) {
                Ember.set(this, 'player', player);
                Ember.set(this, 'component', component);
            },
            closeModal: function closeModal() {
                Ember.set(this, 'showPlayer', false);
                var player = Ember.get(this, 'player');
                player.pause();
                //player.setCurrentTime(0);
            },
            playVideo: function playVideo() {
                var player = Ember.get(this, 'player');
                //let component = get(this, 'component');

                Ember.set(this, 'showPlayer', true);
                player.play();
            }
        }
    });
});