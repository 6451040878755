define('uwork-app-v2/order-request/model', ['exports', 'ember-data/model', 'ember-data/attr', 'moment', 'ember-data/relationships'], function (exports, _model, _attr, _moment, _relationships) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });


    var STATUS_ACTIVE = 1;
    var STATUS_ACCEPTED = 2;
    var STATUS_REJECTED = 3;
    // const STATUS_LOST = 4;
    var STATUS_APPLY = 5;
    var STATUS_BUSY = 6;
    var STATUS_PENDING = 7;

    exports.default = _model.default.extend({
        // account: service('user/session-account'),
        // _user: computed.alias('account.currentUser'),
        expireAt: (0, _attr.default)(),
        status: (0, _attr.default)("number"),
        createdAt: (0, _attr.default)(),
        updatedAt: (0, _attr.default)(),
        checked: (0, _attr.default)('boolean', { defaultValue: false }),
        order: (0, _relationships.belongsTo)('order'),
        user: (0, _relationships.belongsTo)('user'),
        contractor: (0, _relationships.belongsTo)('contractor'),
        isApply: Ember.computed('status', 'order', function () {
            var fromDate = Ember.get(this, 'fromDate');
            var now = (0, _moment.default)();

            return Ember.get(this, 'status') == STATUS_APPLY && (0, _moment.default)(now).isSameOrBefore(fromDate, 'hours');
        }),
        isCanApply: function isCanApply() {
            Ember.set(this, 'status', STATUS_APPLY);
        },

        isActive: Ember.computed('status', 'order', function () {
            var orderRealDate = Ember.get(this, 'order.orderRealDate');
            var now = (0, _moment.default)().format('YYYY-MM-DDTHH:mm:ss');

            return Ember.get(this, 'status') == STATUS_ACTIVE && (0, _moment.default)(now).isSameOrBefore(orderRealDate, 'hours');
        }),
        // isActiveAdmin: computed('status','order', function(){
        //     return get(this, 'status') == STATUS_ACTIVE;
        // }),
        // isMine: computed('order','user', '_user',function () {
        //     let _user = get(this,'_user');
        //     let user = get(this,'user');
        //
        //
        //     // console.log(user);
        //     // console.log(_user);
        //     return null;
        // }),
        isAccepted: Ember.computed.equal('status', STATUS_ACTIVE),
        isOngoing: Ember.computed.equal('order.isOngoing', true),
        isCompleted: Ember.computed.equal('order.isCompleted', true),
        isRejected: Ember.computed.equal('status', STATUS_REJECTED),
        isPending: Ember.computed.equal('status', STATUS_PENDING),
        isLost: Ember.computed('status', 'order', function () {
            return Ember.get(this, 'isRejected') || Ember.get(this, 'order.isLost');
        }),
        isBusy: Ember.computed.equal('status', STATUS_BUSY),

        setActive: function setActive() {
            Ember.set(this, 'status', STATUS_ACTIVE);
        },
        setAccept: function setAccept() {
            Ember.set(this, 'status', STATUS_ACCEPTED);
        },
        setPending: function setPending() {
            Ember.set(this, 'status', STATUS_PENDING);
        },
        setDecline: function setDecline() {
            Ember.set(this, 'status', STATUS_REJECTED);
        }
    });
});