define('uwork-app-v2/components/contractee/timesheet/shift-item/component', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        tagName: 'tr',
        click: function click() {
            this.get('clicked')(Ember.get(this, 'model.orderId'));
        }
    });
});