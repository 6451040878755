define('uwork-app-v2/components/bs/time-picker/component', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        tagName: 'input',
        classNames: ['timepicker'],
        attributeBindings: ['value', 'minTime', 'maxTime', 'scrollDefault', 'disableTextInput', 'readonly'],
        timePicker: null,
        timeStart: false,
        disableTimeRanges: false,
        disableTextInput: false,
        disableTimeRangesChanged: Ember.observer('disableTimeRanges', function () {
            var element = this.$();
            var minTime = Ember.get(this, 'disableTimeRanges');
            element.timepicker('option', 'minTime', minTime);
        }),
        didInsertElement: function didInsertElement() {
            this._super.apply(this, arguments);
            this._setup();
        },
        didRender: function didRender() {
            var element = this.$();
            var disableTextInput = Ember.get(this, 'disableTextInput') ? Ember.get(this, 'disableTextInput') : false;

            element.attr("disabled", disableTextInput);
            element.attr("readyonly", disableTextInput);
        },
        didUpdate: function didUpdate() {
            this._super.apply(this, arguments);
            var _this = this;
            var val = Ember.get(_this, 'value');
            if (val !== '') {
                this.$().siblings('label').addClass('active');
            }
        },
        _setup: function _setup() {
            var _this = this;
            var element = this.$();
            var val = Ember.get(_this, 'value');

            var placeholder = Ember.get(_this, 'placeholder');
            var scrollDefault = Ember.get(_this, 'scrollDefault');
            var _disableTimeRanges = Ember.get(_this, 'disableTimeRanges') ? Ember.get(_this, 'disableTimeRanges') : '00:00';
            if (val || element.autofocus || placeholder !== undefined) {
                element.siblings('label').addClass('active');
            }

            element.timepicker({
                'scrollDefault': scrollDefault,
                'minTime': _disableTimeRanges,
                'show2400': true,
                'timeFormat': 'H:i',
                'maxTime': '24:00'
            });

            this.$().on('changeTime', function () {
                if (Ember.$(this).val() || element.autofocus || placeholder !== undefined) {
                    element.siblings('label').addClass('active');
                }
                Ember.set(_this, 'value', Ember.$(this).val());
            });

            //on close if no value is set remove active class or revers
            this.$().on('hideTimepicker', function () {
                if (Ember.$(this).val() || element.autofocus || placeholder !== undefined) {
                    element.siblings('label').addClass('active');
                } else {
                    element.siblings('label').removeClass('active');
                }
                Ember.set(_this, 'value', Ember.$(this).val());
            });
        }
    });
});