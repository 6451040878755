define('uwork-app-v2/components/cal/order-shift-item/component', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        tagName: 'a',
        showElement: true,
        classNameBindings: ['showElement:show', 'typeClassName', 'shift.isSecondDay:is-second-day'],
        click: function click() {
            this.toggleProperty('showElement');
        },


        typeClassName: Ember.computed('shift', function () {
            return "shift-type-" + Ember.get(this, 'shift.data.shift.id');
        })
    });
});