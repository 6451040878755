define('uwork-app-v2/components/contractee/settings/add-new-payment/component', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });


    var TYPE_PERCENTAGE = 10;
    var TYPE_RATES = 20;

    exports.default = Ember.Component.extend({
        store: Ember.inject.service(),
        model: null,
        didValidate: false,
        types: [{ id: 10, type: '%' }, { id: 20, type: 'NOK' }],

        init: function init() {
            this._super.apply(this, arguments);
            var store = Ember.get(this, 'store');
            var contractee = Ember.get(this, 'contractee');

            var model = store.createRecord('contractee-payment', { contractee: contractee });

            Ember.set(this, 'model', model);
        },
        didInsertElement: function didInsertElement() {
            this._super.apply(this, arguments);
        },
        clear: function clear() {
            var store = Ember.get(this, 'store');

            var contractee = Ember.get(this, 'contractee');

            var model = store.createRecord('contractee-payment', { contractee: contractee });

            Ember.set(this, 'model', model);
        },

        actions: {
            clear: function clear() {
                this.clear();
            },
            save: function save() {
                var _this = this;

                var model = Ember.get(this, 'model');
                Ember.set(this, 'didValidate', true);

                model.validate().then(function (_ref) {
                    var validations = _ref.validations;


                    if (validations.get('isValid')) {
                        model.save().then(function () {

                            Ember.set(_this, 'didValidate', false);
                            _this.swal({
                                title: "Dine opplysninger er lagret!",
                                timer: 1000,
                                showConfirmButton: false,
                                showCloseButton: false,
                                type: 'success'
                            });

                            _this.clear();
                        });
                    } else {
                        //console.log('isInvalid');
                    }
                });
            }
        }
    });
});