define('uwork-app-v2/file-store/adapter', ['exports', 'uwork-app-v2/application/adapter'], function (exports, _adapter) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _adapter.default.extend({
        urlForUpdateRecord: function urlForUpdateRecord(id, modelName, snapshot) {
            if (snapshot.attr('type') === 7) {
                return this.urlPrefix() + '/upload/attachment/' + id;
            }
            return this._buildURL(modelName, id);
        }
    });
});