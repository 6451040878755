define('uwork-app-v2/contractor/vacancy/view/adapter', ['exports', 'uwork-app-v2/application/adapter'], function (exports, _adapter) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _adapter.default.extend({
        urlForQueryRecord: function urlForQueryRecord(query) {
            // console.log(query);
            var id = query.id;
            if (id) {
                delete query.id;
                //delete query.message;
                return this.urlPrefix() + '/vacancies/' + id + '/apply';
            }
        }
    });
});