define('uwork-app-v2/validators/has-role', ['exports', 'ember-cp-validations/validators/base'], function (exports, _base) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var HasRole = _base.default.extend({
    validate: function validate(value, options, model, attribute) {

      if (model.hasRole('substitute') && value.get('length') > 0) {
        return true;
      } else if (model.hasRole('substitute') && value.get('length') === 0) {
        return options.get('message');
      }
      return false;
    }
  });

  HasRole.reopenClass({
    getDependentsFor: function getDependentsFor() /* attribute, options */{
      return [];
    }
  });

  exports.default = HasRole;
});