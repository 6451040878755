define('uwork-app-v2/contractee/vacancy/select-applicants/route', ['exports', 'ember-i18n', 'ember-infinity/mixins/route'], function (exports, _emberI18n, _route) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend(_route.default, {
        perPageParam: 'size',
        pageParam: 'number',

        totalPagesParam: 'meta.pagination.total_pages',

        title: (0, _emberI18n.translationMacro)('vacancy.pageName.select'),
        breadCrumb: {},
        queryParams: {
            query: { replace: true, refreshModel: true },
            size: { replace: true, refreshModel: true },
            status: { replace: true, refreshModel: true },
            sorting: { replace: true, refreshModel: true },
            titles: { replace: true, refreshModel: true },
            skills: { replace: true, refreshModel: true },
            number: { replace: true, refreshModel: true },
            driver_license: { replace: true, refreshModel: true },
            have_car: { replace: true, refreshModel: true },
            page: { replace: true, refreshModel: true }
        },

        beforeModel: function beforeModel() {
            this.setLoading(true);
        },
        afterModel: function afterModel() {
            var title = this.get('title');

            var bTitle = {
                title: title.string
            };

            this.setLoading(false);

            Ember.set(this, 'breadCrumb', bTitle);
        },
        model: function model(params) {
            var _this = this;

            var vacancy = this.store.findRecord('vacancy', params.vacancy_id).catch(function () {
                _this.transitionTo('contractee.vacancy.index');
            });

            var isSearch = params.query || params.have_car || params.driver_license || params.skills || params.titles;
            Ember.set(this, 'isSearch', isSearch);
            var contractors = this.infinityModel('contractor', {
                startingPage: 1,
                page: 1,
                modelPath: 'controller.model.contractors',
                perPage: 6,
                vacancy_id: params.vacancy_id,
                vacancy_applicant_status: 1,
                search: true,
                query: params.query,
                driver_license: params.driver_license,
                remaining_applicants: 1,
                have_car: params.have_car,
                sorting: params.sorting,
                skills: params.skills,
                titles: params.titles
            });

            return Ember.RSVP.hash({
                vacancy: vacancy,
                contractors: contractors,
                categories: this.store.findAll('category'),
                skills: this.store.findAll('skill'),
                titles: this.store.findAll('contractor-title')
            });
        },


        // infinityModelUpdated() {
        //     Ember.Logger.debug('updated with more items');
        // },
        // infinityModelLoaded(lastPageLoaded, infinityModel) {
        //     Ember.Logger.info('no more items to load');
        // },

        setupController: function setupController(controller, model) {
            // Call _super for default behavior
            this._super(controller, model);
            // Implement your custom setup after

            // get(controller, 'search').perform();
            Ember.set(controller, 'isSearch', Ember.get(this, 'isSearch'));
            Ember.get(controller, 'latestApplicants').perform();
            Ember.get(controller, 'selectedApplicants').perform();
        }
    });
});