define('uwork-app-v2/components/contractor/assignment/view/main-contratee/component', ['exports', 'uwork-app-v2/mixins/contractor/is-my-order'], function (exports, _isMyOrder) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_isMyOrder.default, {
        store: Ember.inject.service(),
        model: Ember.computed.alias('vacancy.model'),
        conversation: Ember.computed.alias('model.conversation'),
        userModel: Ember.computed.alias('vacancy.user'),
        vacancy: Ember.inject.service(),
        menu: Ember.inject.service('extend-menu'),
        account: Ember.inject.service('user/session-account'),
        user: Ember.computed.alias('account.currentUser'),
        showRateModal: false,
        stars: Ember.computed('order.rating.stars', function () {
            var stars = Ember.get(this, 'order.rating.stars');
            return stars > 0 ? stars : 0;
        }),
        classNames: ['contractor-assignment-view-contractee', 'mb-4'],
        isApply: Ember.computed('order.status', 'request.status', function () {
            var orderIsActive = Ember.get(this, 'order.isSelecting');

            var requestIsActive = Ember.get(this, 'request.isApply');
            return orderIsActive && requestIsActive;
        }),
        actions: {
            newMessage: function newMessage(contractee, model) {

                var sideNav = Ember.get(this, 'menu.menu');
                var vacancy = Ember.get(this, 'vacancy');

                contractee.get('user').then(function (user) {
                    vacancy.set('user', user);
                });

                vacancy.set('order', model);
                vacancy.set('type', 'order');
                vacancy.set('conversation', model.get('conversation'));

                sideNav.publicActions.showSubNavigation('messageNew');
            },
            changeRateModal: function changeRateModal() {
                this.toggleProperty('showRateModal');
            },
            replyToContractee: function replyToContractee() {
                var rating = Ember.get(this, 'order.rating');

                if (rating.get('userReply.length') > 0) {
                    rating.save();
                    this.toggleProperty('showRateModal');
                } else {
                    //sett melding
                    this.swal({
                        title: "Våre uWorkere er avhenig av deres tilbakemelding! Fyll det ut.",
                        timer: 1500,
                        showConfirmButton: false,
                        showCloseButton: false,
                        type: 'error'
                    });
                }
            },
            acceptJob: function acceptJob(request) {
                var _this = this;

                this.setLoading(true);
                var order = Ember.get(this, 'order');
                request.setActive();

                request.save().then(function () {

                    order.reload();
                    _this.swal('Din interesse er nå meldt.').then(function () {
                        // request.reload();

                    });
                }).catch(function () {}).finally(function () {
                    _this.setLoading(false);
                });
            },
            deleteOrder: function deleteOrder(request) {
                var _this2 = this;

                this.setLoading(true);
                request.isCanApply();
                var order = Ember.get(this, 'order');

                request.save().then(function () {
                    order.reload();
                    _this2.swal('Du har blitt fjernet som en søker på vakten.').then(function () {
                        // request.reload();

                    }, function () {});
                }).catch(function () {}).finally(function () {
                    _this2.setLoading(false);
                });
            }
        }
    });
});