define('uwork-app-v2/utils/is-promise', ['exports', 'uwork-app-v2/utils/is-object'], function (exports, _isObject) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = isPromise;


    function isPromiseLike() {
        var obj = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

        return Ember.typeOf(obj.then) === 'function' && Ember.typeOf(obj.catch) === 'function' && Ember.typeOf(obj.finally) === 'function';
    }

    function isPromise(obj) {
        return (0, _isObject.default)(obj) && isPromiseLike(obj);
    }
});