define('uwork-app-v2/vacancy-plan/adapter', ['exports', 'uwork-app-v2/application/adapter'], function (exports, _adapter) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _adapter.default.extend({
        session: Ember.inject.service(),
        account: Ember.inject.service('user/session-account'),
        user: Ember.computed.alias('account.currentUser'),
        urlForFindAll: function urlForFindAll(modelName, snapshot) {
            // console.log('user');
            // console.log(get(this, 'user.isContractee'));
            if (Ember.get(this, 'user.isContractee')) {
                return this.urlPrefix() + '/vacancies/public/plans';
            }
            return this._super.apply(this, arguments);
        },
        urlForQuery: function urlForQuery(query) {
            if (query.public) {
                delete query.public;
                return this.urlPrefix() + '/vacancies/public/plans';
            }
            return this._super.apply(this, arguments);
        }
    });
});