define('uwork-app-v2/components/admin/order-desc-template-item/validation', ['exports', 'ember-changeset-validations/validators'], function (exports, _validators) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = {
        name: (0, _validators.validatePresence)(true),
        body: (0, _validators.validatePresence)(true),
        type: (0, _validators.validatePresence)(true)
    };
});