define('uwork-app-v2/utils/bugsnag', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.getUser = getUser;
    function getUser(owner) {
        var currentUser = owner.lookup('service:user/session-account').get('currentUser');
        if (currentUser) {
            var _Ember$getProperties = Ember.getProperties(currentUser, 'email', 'id', 'fullName'),
                email = _Ember$getProperties.email,
                id = _Ember$getProperties.id,
                name = _Ember$getProperties.fullName;

            return { email: email, id: id, name: name };
        }
    }
});