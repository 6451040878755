define('uwork-app-v2/vacancy-user-pivot/adapter', ['exports', 'uwork-app-v2/application/adapter'], function (exports, _adapter) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _adapter.default.extend({
        urlForQueryRecord: function urlForQueryRecord(query) {
            if (query.applicant) {
                delete query.applicant;
                var vacancy_id = query.vacancy_id;
                var user_id = query.user_id;
                delete query.applicant;
                delete query.vacancy_id;
                delete query.user_id;
                return this.urlPrefix() + '/vacancy-user-pivots/applicant/' + vacancy_id + '/' + user_id;
            }

            if (query.status && query.status === 30) {
                return this.urlPrefix() + '/vacancy-user-pivots/select-applicant';
            }

            return this._super.apply(this, arguments);
        }
    });
});