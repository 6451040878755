define('uwork-app-v2/components/admin/calendar/day-shift/component', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        tagName: 'a',
        showElement: false,
        classNameBindings: ['showElement:show'],
        click: function click() {
            this.toggleProperty('showElement');
        }
    });
});