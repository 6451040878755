define('uwork-app-v2/components/contractor/settings/contractor-intro/component', ['exports', 'uwork-app-v2/mixins/cities-mixin'], function (exports, _citiesMixin) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_citiesMixin.default, {
        //validationContractor,
        //schema,
        session: Ember.inject.service(),
        store: Ember.inject.service(),
        account: Ember.inject.service('user/session-account'),
        user: Ember.computed.alias('account.currentUser'),
        contractor: Ember.computed.alias('user.contractor'),

        isWizard: Ember.computed.equal('user.isSignUpCompleted', false),
        wizardService: Ember.inject.service('contractor/wizard'),

        //didValidate: alias('contractor.validations.didValidate'),

        actions: {
            save: function save(model) {
                var _this = this;

                // Resolve the promise
                model.then(function (m) {
                    Ember.set(_this, 'didValidate', true);

                    // Validate
                    //{ on: ['sex', 'address', 'zipCode', 'city', 'place', 'contractorTitle.name'] }


                    m.validate().then(function (_ref) {
                        var validations = _ref.validations;


                        if (validations.get('isValid')) {
                            m.save().then(function () {
                                _this.swal({
                                    title: "Dine opplysninger er lagret!",
                                    timer: 1000,
                                    showConfirmButton: false,
                                    showCloseButton: false,
                                    type: 'success'
                                });
                            });
                        } else {
                            //console.log('isInvalid');
                        }
                    });
                });
            },
            saveNext: function saveNext(model) {
                var _this2 = this;

                // Get next route
                var next = Ember.get(this, 'wizardService').getNextRoute();

                // Resolve the promise
                model.then(function (m) {
                    Ember.set(_this2, 'didValidate', true);

                    // Validate
                    m.validate({ on: ['sex', 'address', 'zipCode', 'city', 'place', 'contractorTitle.name'] }).then(function (_ref2) {
                        var validations = _ref2.validations;

                        if (validations.get('isValid')) {
                            m.save().then(function () {
                                _this2.swal({
                                    title: "Dine opplysninger er lagret!",
                                    timer: 2500,
                                    showConfirmButton: false,
                                    showCloseButton: false,
                                    type: 'success'
                                }).then(function () {
                                    _this2.sendAction('toRoute', Ember.get(next, 'route'));
                                }, function () {
                                    _this2.sendAction('toRoute', Ember.get(next, 'route'));
                                });
                            });
                        } else {
                            //console.log('isInvalid');
                        }
                    });
                });
            }
        }
    });
});