define('uwork-app-v2/helpers/shift-icons', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.shiftIcons = shiftIcons;
    function shiftIcons(params) {

        var name = params[0];

        var icons = [{ id: 1, name: 'Døgnvakt', icon: 'av-timer' }, { id: 2, name: 'Dagvakt', icon: 'white-balance-sunny' }, { id: 3, name: 'Kveldsvakt', icon: 'weather-night' }, { id: 4, name: 'Nattvakt', icon: 'human-male' }, { id: 5, name: 'Turnus', icon: 'brightness-7' }];
        var level = icons.findBy('name', name);

        if (level) {
            return Ember.get(level, 'icon');
        }
        return '';
    }

    exports.default = Ember.Helper.helper(shiftIcons);
});