define('uwork-app-v2/components/contractor/settings/education/display-form/component', ['exports', 'uwork-app-v2/education/model', 'uwork-app-v2/validations/education'], function (exports, _model, _education) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var keys = Object.keys;
    exports.default = Ember.Component.extend({
        EducationValidations: _education.default,
        schema: _model.schema,
        session: Ember.inject.service(),
        account: Ember.inject.service('user/session-account'),
        store: Ember.inject.service(),
        isCurrent: Ember.computed.equal('model.isCurrent', true),
        actions: {
            skip: function skip() {
                this.sendAction('toRoute', 'contractor.settings.experience');
            },
            save: function save(changeset) {
                var _this = this;

                var snapshot = changeset.snapshot();

                return changeset.cast(keys(_model.schema)).validate().then(function () {
                    if (Ember.get(changeset, 'isValid')) {
                        _this.swal({
                            title: 'Oppdatert!',
                            timer: 1000,
                            showConfirmButton: false,
                            showCloseButton: false,
                            type: 'success'
                        });

                        return changeset.save();
                    }
                }).catch(function () {
                    changeset.restore(snapshot);
                });
            },
            delete: function _delete(model) {
                this.swal({
                    title: 'Slettet!',
                    type: "question",
                    confirmButtonColor: "#DD6B55",
                    confirmButtonText: 'Slettet',
                    showCloseButton: true
                }).then(function () {
                    model.destroyRecord();
                }, function () {});
            },
            setCurrent: function setCurrent(changeset) {
                this.set('isCurrent', changeset.get('current'));
            }
        }
    });
});