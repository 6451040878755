define('uwork-app-v2/contractee/dashboard/controller', ['exports', 'uwork-app-v2/substitute/model', 'uwork-app-v2/vacancy/model'], function (exports, _model, _model2) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });


    var USER_REQUEST_TYPE = "verification_company";

    exports.default = Ember.Controller.extend({
        ROLE_NAME_SUBSTITUTE: _model.ROLE_NAME,
        ROLE_NAME_VACANCY: _model2.ROLE_NAME,

        media: Ember.inject.service(),
        account: Ember.inject.service('user/session-account'),
        user: Ember.computed.alias('account.currentUser'),

        store: Ember.inject.service(),

        userNS: Ember.computed.alias('model.userNotificationSettings'),
        vacancies: Ember.computed.alias('model.vacancies'),

        showModal: false,

        size: 4,
        number: 1,

        preserveScrollPosition: true,
        queryParams: ['preserveScrollPosition'], //'from', 'to',

        hasUserRequest: Ember.computed.filterBy('user.userRequests', 'type', USER_REQUEST_TYPE),

        getUserVerifiedRequests: Ember.computed.filterBy('user.userRequests', 'type', 'verification'),
        hasUnhandeledVerifiedRequests: Ember.computed('getUserVerifiedRequests', function () {
            return Ember.get(this, 'getUserVerifiedRequests').findBy('status', null);
        }),

        userHasBeenVerified: Ember.computed('getUserVerifiedRequests', function () {
            return Ember.get(this, 'getUserVerifiedRequests').findBy('status', 10);
        }),

        actions: {
            goToNewPortal: function goToNewPortal() {
                window.localStorage.removeItem('useOldPortal');
                window.location.href = 'https://app.uwork.no/company';
            },
            toRoute: function toRoute(path, data) {
                if (data) {
                    this.transitionToRoute(path, data);
                }
                this.transitionToRoute(path);
            },
            userRequest: function userRequest() {
                var _this = this;

                this.setLoading(true);
                var userRequest = Ember.get(this, 'store').createRecord('user-request');

                userRequest.setVerificationCompnayType();

                userRequest.save().then(function () {
                    _this.setLoading(false);
                    _this.swal({
                        customClass: 'verify_container',
                        title: "Sendt inn!",
                        html: "<br /><h5 class='verify-title'>Neste steg:</h5>" + "<hr />" + "<div class='verify-list'>" + "<div class='list'><div class='count'>1</div>En uWork-representant vil kontakte deg for å kartlegge dine behov og ferdigstille din profil</div><br />" + "<div class='list'><div class='count'>2</div>Etter samtalen vil vi oppgradere din profil slik at du har mulighet til å bestille vikarer gjennom plattformen.</div><br />" + "<div class='list'><div class='count'>3</div>Vi avtaler en dag hvor vi kan gjennomgå en 15 min demo for å sette deg inn i systemet. </div>" + "</div>" + "<hr />",
                        type: "success",
                        confirmButtonColor: "#B3BBCF",
                        confirmButtonText: "Lukk"
                    });

                    Ember.get(_this, 'user').reload();
                });
            }
        }
    });
});