define('uwork-app-v2/applicant/model', ['exports', 'uwork-app-v2/user/model'], function (exports, _model) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _model.default.extend({
        //conversations: hasMany('conversation', {async: false, defaultValue: false}),
        //conversation: alias('conversations.firstObject'),
        // conversation: computed.alias('conversations.firstObject'),
    });
});