define('uwork-app-v2/admin/management/myworkers/validation', ['exports', 'ember-changeset-validations/validators'], function (exports, _validators) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = {
        contractee: (0, _validators.validatePresence)(true),
        contractor: (0, _validators.validatePresence)(true)
    };
});