define('uwork-app-v2/components/contractee/card/main-admin/component', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        user: Ember.computed.alias('model.user'),
        classNames: ['user-card', 'd-flex', 'bg-white', 'align-items-stretch', 'mt-4'],
        percentageClass: Ember.computed('model.profilePercentage', function () {
            var percentage = Ember.get(this, 'model.profilePercentage');

            if (percentage > 50 && percentage < 70) {
                return 'bg-warning';
            } else if (percentage < 50) {
                return 'bg-danger';
            }

            return 'bg-success';
        })
    });
});