define('uwork-app-v2/calendar/service', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Service.extend({
        date: null,
        getDate: function getDate() {
            return this.get('date') || new Date();
        }
    });
});