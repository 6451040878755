define('uwork-app-v2/components/contractor/settings/completed/display-mian/component', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        account: Ember.inject.service('user/session-account'),
        user: Ember.computed.alias('account.currentUser'),
        isWizard: Ember.computed.equal('user.isSignUpCompleted', false),
        wizardService: Ember.inject.service('contractor/wizard'),
        menu: Ember.inject.service('extend-menu'),
        session: Ember.inject.service(),
        actions: {
            acceptAgreement: function acceptAgreement() {
                var _this = this;

                var accepted = Ember.get(this, 'agreementAccepted');
                var sideNav = Ember.get(this, 'menu.menu');

                if (accepted) {
                    var user = Ember.get(this, 'user');

                    user.setSignupCompleted();
                    user.save().then(function () {

                        Ember.get(_this, 'wizardService').resetVisitedRoutes();
                        sideNav.set('extendMenu', false);
                        sideNav.publicActions.showSubNavigation('navigation');

                        _this.swal({
                            title: "Registering fullført!",
                            timer: 1500,
                            showConfirmButton: false,
                            showCloseButton: false,
                            type: 'success'
                        }).then(function () {}, function () {

                            _this.sendAction('toRoute', 'contractor.dashboard');
                        });
                    });
                }
            }
        }
    });
});