define('uwork-app-v2/components/contractee/card/change-company-status/component', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        classNames: ['user-select'],
        actions: {
            allowEdit: function allowEdit(model) {
                var _this = this;

                model.allowEdit();
                model.save().then(function () {
                    _this.swal({
                        title: "Bruker unaktivert!",
                        type: 'info'
                    });
                });
            },
            disableEdit: function disableEdit(model) {
                var _this2 = this;

                model.disableEdit();
                model.save().then(function () {
                    _this2.swal({
                        title: "Bruker unaktivert!",
                        type: 'info'
                    });
                });
            },
            approve: function approve(model) {
                var _this3 = this;

                model.then(function (m) {
                    m.setApproved();
                    m.save().then(function () {
                        _this3.swal({
                            title: "Bruker status satt til: godkjent.",
                            type: 'info'
                        });
                    });
                });
            },
            default: function _default(model) {
                var _this4 = this;

                model.then(function (m) {
                    m.setDefault();
                    m.save().then(function () {
                        _this4.swal("Bruker status satt til: default.", 'info');
                    });
                });
            },
            verify: function verify(model) {
                var _this5 = this;

                model.then(function (m) {
                    m.setVerified();
                    m.save().then(function () {
                        _this5.swal("Bruker status satt til: verifisert.", 'info');
                    });
                });
            },
            resetAgreement: function resetAgreement(model) {
                var _this6 = this;

                model.then(function (m) {
                    m.setAgreementUpdated();
                    m.save().then(function () {
                        _this6.swalNotify("Avtalen er resatt!");
                    });
                });
            },
            setPublic: function setPublic(model) {
                var _this7 = this;

                model.setPublic();
                model.save().then(function () {
                    _this7.swal("Brukerprofil publisert tilgjengelig for alle!", 'info');
                });
            },
            unsetPublic: function unsetPublic(model) {
                var _this8 = this;

                model.unsetPublic();
                model.save().then(function () {
                    _this8.swal("Fjernet publisering av brukerprofil!", 'info');
                });
            },
            delete: function _delete(model) {
                var _this9 = this;

                this.swal({
                    title: "Slett",
                    html: "Er du sikker du vil slette denne brukeren?",
                    type: "warning",
                    showCancelButton: false,
                    confirmButtonColor: "#14CA9C",
                    confirmButtonText: "Slett!",
                    showCloseButton: true
                }).then(function () {
                    model.destroyRecord().then(function () {
                        _this9.swal("Deleted", 'info');
                    });
                }, function () {});
            }
        }
    });
});