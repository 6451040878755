define('uwork-app-v2/helpers/custom-format-number', ['exports', 'npm:numeral', 'npm:numeral/locales'], function (exports, _npmNumeral, _locales) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.customFormatNumber = customFormatNumber;
    function customFormatNumber(params /*, hash*/) {
        var number = params[0];
        var format = '0,0.00';

        if (params[1]) {
            format = params[1];
        }

        _npmNumeral.default.locale('no');

        return (0, _npmNumeral.default)(number).format(format);
    }

    exports.default = Ember.Helper.helper(customFormatNumber);
});