define('uwork-app-v2/order-shift/model', ['exports', 'ember-data/model', 'ember-data/attr', 'ember-data/relationships', 'moment', 'ember-cp-validations'], function (exports, _model, _attr, _relationships, _moment, _emberCpValidations) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.schema = undefined;


    var STATUS_DEFAULT = 1;
    var STATUS_ABSENCE = 10;

    var ABSENCE_TYPE_SELF_DECLARATION = 10;
    var ABSENCE_TYPE_SICK_LEAVE = 20;
    var ABSENCE_TYPE_OTHER = 30;

    var Validations = (0, _emberCpValidations.buildValidations)({
        description: {
            description: 'Kommentar',
            validators: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('length', {
                min: 5
            }), (0, _emberCpValidations.validator)('ds-error')]
        },
        restTimeComment: {
            description: 'Kommentar',

            validators: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('length', {
                min: 5
            }), (0, _emberCpValidations.validator)('ds-error')]
        },
        absenceType: {
            description: 'Hviletype',
            validators: [(0, _emberCpValidations.validator)('number', {
                allowString: false,
                integer: true,
                gt: 0,
                message: 'Please select one'
            }), (0, _emberCpValidations.validator)('ds-error')]
        }
    }, { debounce: 500 });

    var schema = exports.schema = {

        fromDate: (0, _attr.default)('mdate'),
        toDate: (0, _attr.default)('mdate'),
        restTime: (0, _attr.default)('number'),
        restTimeComment: (0, _attr.default)('string', { defaultValue: '' }),

        description: (0, _attr.default)('string'),
        //
        // hourlyRate: attr('number'),
        // holidayHours: attr('number'),
        // holidayAmount: attr('number'),
        // holidayFixedAmount: attr('number'),
        // sumAdditionalHours: attr('number'),
        // sumAdditionalAmount: attr('number'),
        sumHours: (0, _attr.default)('number'),
        // sumAmount: attr('number'),
        // fixedAmount: attr('number'),

        status: (0, _attr.default)('number'),
        type: (0, _attr.default)('number'),

        absenceType: (0, _attr.default)('number'),

        absenceComment: (0, _attr.default)('string'),
        absenceReportedAt: (0, _attr.default)('mdate'),

        orderShiftRules: (0, _relationships.hasMany)('order-shift-rule'),

        createdAt: (0, _attr.default)(),
        updatedAt: (0, _attr.default)(),
        edit: (0, _attr.default)('boolean', { defaultValue: false }),
        approvedAt: (0, _attr.default)('mdate'),
        weeklyApprovedAt: (0, _attr.default)('mdate'),
        monthlyApprovedAt: (0, _attr.default)('mdate'),
        paidAt: (0, _attr.default)('mdate'),
        order: (0, _relationships.belongsTo)('order', { async: false }),
        shift: (0, _relationships.belongsTo)('shift', { async: false })

    };

    exports.default = _model.default.extend(schema, Validations, {

        session: Ember.inject.service(),
        account: Ember.inject.service('user/session-account'),
        user: Ember.computed.alias('account.currentUser'),

        leaveTypes: [{ id: 0, name: 'Velg type' }, { id: ABSENCE_TYPE_SELF_DECLARATION, name: 'Egenmelding' }, { id: ABSENCE_TYPE_SICK_LEAVE, name: 'Sykemelding' }, { id: ABSENCE_TYPE_OTHER, name: 'Annet' }],

        isActive: Ember.computed.equal('status', STATUS_DEFAULT),
        isAbsence: Ember.computed.equal('status', STATUS_ABSENCE),
        setAbsence: function setAbsence() {
            Ember.set(this, 'status', STATUS_ABSENCE);
        },


        absenceTypeFormat: Ember.computed('absenceType', function () {
            var leaveTypes = Ember.get(this, 'leaveTypes');
            var absenceType = Ember.get(this, 'absenceType');

            if (absenceType) {
                return leaveTypes.findBy('id', absenceType).name || '';
            }
            return '';
        }),

        absenceByName: Ember.computed('absenceType', function () {
            return Ember.get(this, 'leaveTypes').findBy('id', Ember.get(this, 'absenceType')).name;
        }),

        isSelfDeclaration: Ember.computed.equal('absenceType', ABSENCE_TYPE_SELF_DECLARATION),
        isSickLeave: Ember.computed.equal('absenceType', ABSENCE_TYPE_SICK_LEAVE),
        isOther: Ember.computed.equal('absenceType', ABSENCE_TYPE_OTHER),

        isNightShift: function isNightShift() {
            var shift_id = this.get('shift.id');

            if (shift_id == 1 || shift_id == 4) {
                return true;
            }
            return false;
        },

        content: Ember.computed('user', 'order', function () {
            var user = Ember.get(this, 'user');

            if (Ember.get(user, 'isContractor')) {
                return Ember.get(this, 'order.contractee.name');
            } else {
                return Ember.get(this, 'order.contractor.user.fullName');
            }
        }),

        img: Ember.computed('user', 'order', function () {
            var user = Ember.get(this, 'user');

            if (Ember.get(user, 'isContractor')) {
                return Ember.get(this, 'order.contractee.user.avatarUrl');
            } else {
                return Ember.get(this, 'order.contractor.user.avatarUrl');
            }
        }),

        fromDateUppercase: Ember.computed('fromDate', function () {
            return (0, _moment.default)(Ember.get(this, 'fromDate')).format('dddd');
        }),
        start: Ember.computed('fromDate', function () {
            return Ember.get(this, 'fromDate').format('YYYY-MM-DDTHH:mm:ss');
        }),
        end: Ember.computed('toDate', function () {
            return Ember.get(this, 'toDate').format('YYYY-MM-DDTHH:mm:ss');
        }),

        isApproved: Ember.computed('approvedAt', function () {
            var approvedAt = Ember.get(this, 'approvedAt');

            if (approvedAt) {
                return approvedAt.isValid();
            }

            return false;
        }),

        isPaid: Ember.computed('paidAt', function () {
            var paidAt = Ember.get(this, 'paidAt');

            if (paidAt) {
                return paidAt.isValid();
            }

            return false;
        }),

        isApprovedAndPaid: Ember.computed('isApproved', 'isPaid', function () {
            return Ember.get(this, 'isApproved') && Ember.get(this, 'isPaid');
        }),

        showFromDate: Ember.computed('fromDate', function () {
            var fromDate = Ember.get(this, 'fromDate');
            return (0, _moment.default)(fromDate).format('DD.MM.YYYY');
        }),
        showToDate: Ember.computed('toDate', function () {
            var toDate = Ember.get(this, 'toDate');
            return (0, _moment.default)(toDate).format('DD.MM.YYYY');
        }),
        showFromTime: Ember.computed('fromDate', function () {
            var fromDate = Ember.get(this, 'fromDate');
            return (0, _moment.default)(fromDate).format('HH:mm');
        }),
        showToTime: Ember.computed('toDate', function () {
            var toDate = Ember.get(this, 'toDate');
            return (0, _moment.default)(toDate).format('HH:mm');
        }),

        workingTotal: Ember.computed('restTime', 'fromDate', 'toDate', function () {
            var from = Ember.get(this, 'fromDate');
            var to = Ember.get(this, 'toDate');

            var wH = to.diff(from, 'minutes');

            // return wH;
            return _moment.default.duration(wH, 'minutes').asHours();
        }),

        // workingTotalFormatted: computed('workingTotal', function () {
        //     let workingTotal = get(this, 'workingTotal');
        //
        //     return this.time_convert(workingTotal);
        // }),

        workingHours: Ember.computed('restTime', 'fromDate', 'toDate', function () {
            var workingTotal = Ember.get(this, 'workingTotal');
            var restTime = Ember.get(this, 'restTime');

            return workingTotal - restTime;
        }),

        // workingHoursFormatted: computed('workingHours', function () {
        //     let workingHours = get(this, 'workingHours');
        //
        //     return this.time_convert(workingHours);
        // }),


        restTimeFormatted: Ember.computed('restTime', 'fromDate', 'toDate', function () {
            var restTime = Ember.get(this, 'restTime');

            return this.time_convert(restTime);
        }),

        time_convert: function time_convert(num) {

            var decimalTimeString = num;
            var decimalTime = parseFloat(decimalTimeString);
            decimalTime = decimalTime * 60 * 60;
            var hours = Math.floor(decimalTime / (60 * 60));
            decimalTime = decimalTime - hours * 60 * 60;
            var minutes = Math.floor(decimalTime / 60);

            if (hours < 10) {
                hours = "0" + hours;
            }
            if (minutes < 10) {
                minutes = "0" + minutes;
            }

            return hours + ":" + minutes;
        },


        duration: Ember.computed('fromDate', 'toDate', 'shift', function () {
            var from = Ember.get(this, 'fromDate');
            var to = Ember.get(this, 'toDate');
            var restTimeAllDayShifts = Ember.get(this, 'order.contractee.restTimeAllDayShifts');

            var shift = parseInt(Ember.get(this, 'shift.id'));
            var diff = to.diff(from, 'hours', true);

            // Remove 6 hours from all day shifts
            if (shift === 1) {
                diff = diff - restTimeAllDayShifts;
            }

            return diff;
        }),
        durationFormatted: Ember.computed('duration', function () {
            var duration = Ember.get(this, 'duration');
            //console.log(duration);
            return this.time_convert(duration);
        })
    });
});