define('uwork-app-v2/components/contractor/settings/experience/display-form-new/component', ['exports', 'uwork-app-v2/validations/experience', 'uwork-app-v2/experience/model'], function (exports, _experience, _model) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var keys = Object.keys;
    exports.default = Ember.Component.extend({
        ExperienceValidations: _experience.default,
        schema: _model.schema,
        isEditing: false,
        classNames: ['experience-item'],
        store: Ember.inject.service(),

        model: false,
        didInsertElement: function didInsertElement() {
            this._super.apply(this, arguments);
            Ember.set(this, 'model', Ember.get(this, 'store').createRecord('experience'));
        },
        saved: function saved() {
            Ember.set(this, 'model', Ember.get(this, 'store').createRecord('experience'));
        },

        actions: {
            save: function save(changeset) {
                var _this = this;

                var snapshot = changeset.snapshot();
                return changeset.cast(keys(_model.schema)).validate().then(function () {
                    if (Ember.get(changeset, 'isValid')) {
                        return changeset.save().then(function () {
                            _this.swal({
                                title: 'Arbeidserfaring har blitt lagt til!',
                                timer: 1000,
                                showConfirmButton: false,
                                showCloseButton: false,
                                type: 'success'
                            });

                            // ToDo - close the form after save ?
                            //this.toggleProperty('isEditing');
                            _this.saved();
                            _this.set('isCurrent', false);
                        });
                    }
                }).catch(function () {
                    changeset.restore(snapshot);
                });
            },
            clear: function clear(changeset) {
                return changeset.rollback();
            },
            setCurrent: function setCurrent(changeset) {
                this.set('isCurrent', changeset.get('current'));
            }
        }
    });
});