define('uwork-app-v2/components/bs/popover-button/component', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        tagName: 'span',
        attributeBindings: ['data-container', 'data-toggle', 'data-placement', 'data-content'],
        didInsertElement: function didInsertElement() {
            this._super.apply(this, arguments);
            this.$().popover({
                trigger: 'focus',
                container: 'body'
            });
        }
    });
});