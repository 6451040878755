define('uwork-app-v2/contractee/adapter', ['exports', 'uwork-app-v2/application/adapter'], function (exports, _adapter) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _adapter.default.extend({
        urlForQueryRecord: function urlForQueryRecord(query) {
            if (query.contractee_id && query.view) {
                return this.urlPrefix() + '/contractees/' + query.contractee_id + '/view';
            }
            return this._super.apply(this, arguments);
        },
        urlForQuery: function urlForQuery(query) {

            if (query.cc === true) {
                delete query.cc;
                return this.urlPrefix() + '/contractee-contractors/contractees';
            }

            if (query.timesheet === true) {
                delete query.timesheet;
                return this.urlPrefix() + '/contractees/timesheet';
            }

            if (query.order === true) {
                delete query.order;
                return this.urlPrefix() + '/contractees/orders/ongoing';
            }

            return this._super.apply(this, arguments);
        }
    });
});