define('uwork-app-v2/components/contractor/settings/gcc/display-item/component', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        tagName: 'tr',
        actions: {
            download: function download(file) {
                var link = document.createElement('a');
                link.href = file;
                link.id = 'down';

                // link.click();
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            },
            delete: function _delete(model) {
                var _this2 = this;

                var _this = this;
                _this.setLoading(true);
                model.destroyRecord().then(function () {
                    _this.setLoading(false);
                    _this2.swal('Attest slettet!');
                    // new Notification('Attest slettet!');
                }, function () {
                    _this.setLoading(false);
                    _this2.swal('Kan ikke slettes!', 'error');
                }).catch(function () {
                    _this.setLoading(false);
                    _this2.swal('Kan ikke slettes!', 'error');
                }).finally(function () {
                    _this.setLoading(false);
                });
            }
        }
    });
});