define('uwork-app-v2/components/contractor/profile/display-shift-types/component', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        tagName: "div",
        shifts: false,
        store: Ember.inject.service(),
        editing: false,
        init: function init() {
            this._super.apply(this, arguments);
            var shifts = Ember.get(this, 'store').findAll('shift');

            Ember.set(this, 'shifts', shifts);
        },

        shiftsUser: Ember.computed('shifts.[]', 'user.shifts.[]', function () {
            //let user = get(this, 'user');
            var model = Ember.get(this, 'shifts');
            var _userShifts = Ember.get(this, 'user.shifts');
            if (_userShifts !== undefined) {
                if (model) {
                    model.forEach(function (shift) {
                        var id = Ember.get(shift, 'id');

                        var _shift = _userShifts.findBy('id', id);
                        if (_shift) {
                            Ember.set(shift, 'checked', true);
                        }
                    });
                }
                return model;
            }
            return null;
        }),
        addShiftsToUser: function addShiftsToUser(user) {

            var shifts = Ember.get(this, 'shifts');

            var shiftsChecked = shifts.filterBy('checked', true);
            var shiftsUnChecked = shifts.filterBy('checked', false);

            user.get('shifts').pushObjects(shiftsChecked);
            user.get('shifts').removeObjects(shiftsUnChecked);
        },

        actions: {
            editForm: function editForm() {
                this.toggleProperty('editing');
            },
            save: function save(user) {
                var _this = this;

                user.then(function (data) {

                    _this.addShiftsToUser(data);
                    _this.setLoading(true);
                    data.save().then(function () {
                        _this.swal('Dine opplysninger er lagret!');
                        _this.setLoading(false);
                    });
                });
                // console.log();
            }
        }
    });
});