define('uwork-app-v2/components/bs/city-by-zipcode/component', ['exports', 'uwork-app-v2/mixins/city-by-zipcode-mixin'], function (exports, _cityByZipcodeMixin) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_cityByZipcodeMixin.default, {
        filterModel: function filterModel(text) {
            var zipCodes = Ember.get(this, 'zipCodes');
            var selected = zipCodes.findBy('zipCode', text) || false;

            this.setModel(selected);
        },
        setModel: function setModel(selected) {
            var model = Ember.get(this, 'changeset');
            if (selected) {
                Ember.set(model, 'place', selected.city);
                Ember.set(model, 'zipCode', selected.zipCode);
            }

            model.notifyPropertyChange('zipCode');
        },

        actions: {
            selectCity: function selectCity(text) {

                //basic input
                if (text.length === 4) {
                    this.filterModel(text);
                } else if (text.length < 4) {
                    Ember.set(this, 'changeset.zipCode', text);
                }

                Ember.get(this, 'changeset').notifyPropertyChange('zipCode');
            }
        }
    });
});