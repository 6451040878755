define('uwork-app-v2/admin/management/badges/controller', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({

        // Display only saved items
        saved: Ember.computed.filterBy('model', 'isNew', false)
    });
});