define('uwork-app-v2/admin/dashboard/controller', ['exports', 'moment', 'ember-cli-jsonapi-pagination/mixins/controllers/jsonapi-pagination'], function (exports, _moment, _jsonapiPagination) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend(_jsonapiPagination.default, {
        store: Ember.inject.service(),
        account: Ember.inject.service('user/session-account'),
        user: Ember.computed.alias('account.currentUser'),
        recentContractees: Ember.computed.alias('model.contractees'),
        recentContractors: Ember.computed.alias('model.contractors'),
        size: 14,
        number: 1,
        preserveScrollPosition: true,
        totalPages: Ember.computed('size', 'number', 'model', function () {
            return Ember.get(this, 'model.meta.pagination.total_pages');
        }),
        week: (0, _moment.default)().locale('en').format('ww'),
        year: (0, _moment.default)().locale('en').format('YYYY'),
        // from: moment().locale('en').format('YYYY-MM-DD'),
        // to: moment().add(6, 'days').locale('en').format('YYYY-MM-DD'),
        queryParams: ['week', 'year', 'preserveScrollPosition'], //'from', 'to',
        orderShifts: Ember.computed.alias('model.orderShifts'),
        events: Ember.computed('model.orderShifts', function () {
            var orderShifts = Ember.get(this, 'model.orderShifts');
            var events = [];
            if (orderShifts && orderShifts.get('length') > 0) {
                orderShifts.forEach(function (item) {

                    var _item = {
                        id: item.get('id'),
                        title: "#" + item.get('order.id'),
                        routeID: item.get('order.id'),
                        description: item.get('content'),
                        img: item.get('img'),
                        start: item.get('start'),
                        end: item.get('end'),
                        shiftType: item.get('shift.id')
                    };

                    events.pushObject(_item);
                });
            }
            return events;
        }),
        actions: {
            toRoute: function toRoute(path, data) {
                if (data) {
                    this.transitionToRoute(path, data);
                }
                this.transitionToRoute(path);
            },
            setPeriod: function setPeriod(direction) {
                // set(this, 'from', direction.format('YYYY-MM-DD'));
                // set(this, 'to', direction.add(6, 'days').format('YYYY-MM-DD'));
                Ember.set(this, 'week', direction.format('ww'));
                Ember.set(this, 'year', direction.format('YYYY'));
            }
        }

    });
});