define('uwork-app-v2/components/contractor/assignment/display-shifts/component', ['exports', 'uwork-app-v2/mixins/contractor/is-my-order'], function (exports, _isMyOrder) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_isMyOrder.default, {
        actions: {
            restTime: function restTime(shift, key, time, format) {

                var element = Ember.get(shift, key);
                var value = 0;

                if (element >= 0 && element <= 24 && format === 'plus') {
                    value = element + parseFloat(time / 60);
                    Ember.set(shift, key, value);
                } else if (element > 0 && format === 'minus') {
                    value = element - parseFloat(time / 60);
                    Ember.set(shift, key, value);
                }
            },
            edit: function edit(model) {
                var status = !model.get('edit');

                model.set('edit', status);
            }
        }
    });
});