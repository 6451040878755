define('uwork-app-v2/components/contractor/settings/gcc/validation', ['exports', 'ember-changeset-validations/validators'], function (exports, _validators) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = {
        category: (0, _validators.validatePresence)(true),
        file: (0, _validators.validatePresence)(true),
        issuedAt: [(0, _validators.validatePresence)(true), (0, _validators.validateFormat)({ type: 'date', regex: /^\d{4}-\d{2}-\d{2}$/, message: "Utstedt dato må være i gyldig format." })]
    };
});