define('uwork-app-v2/notification/model', ['exports', 'ember-data/model', 'ember-data/attr', 'ember-data/relationships', 'moment'], function (exports, _model, _attr, _relationships, _moment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _model.default.extend({
        text: (0, _attr.default)(),
        url: (0, _attr.default)(),
        read: (0, _attr.default)('number'),
        extra: (0, _attr.default)(),
        readAt: (0, _attr.default)('mdate'),
        createdAt: (0, _attr.default)('mdate'),
        updatedAt: (0, _attr.default)('mdate'),

        // Relations
        user: (0, _relationships.belongsTo)('user'),
        from: (0, _relationships.belongsTo)('user'),
        contractee: (0, _relationships.belongsTo)('contractee'),
        contractor: (0, _relationships.belongsTo)('contractor'),

        // Computed properties
        isUnread: Ember.computed('read', function () {
            var read = Ember.get(this, 'read');

            if (parseInt(read) === 0) {
                return true;
            }
            return false;
        }),
        setRead: function setRead() {
            this.set('readAt', (0, _moment.default)().format());
        },

        extraId: Ember.computed.alias('extra.id'),
        extraOrderId: Ember.computed.alias('extra._id'),
        extraFromId: Ember.computed.alias('extra.from_id'),
        extraToId: Ember.computed.alias('extra.to_id'),
        // Backward compatible with old notifications
        urlId: Ember.computed('extra', function () {

            var _id = Ember.get(this, 'extra._id') ? Ember.get(this, 'extra._id') : Ember.get(this, 'extra.id');
            if (_id) {
                return _id;
            }
            return null;
        }),

        // selectCandidate: computed('url', 'urlId', function(){
        //     let urls = 'contractee.assignments.select-candidates';
        //     let urlId = get(this, 'urlId');
        //     let url = get(this, 'url');
        //
        //
        //     if(!url){
        //         return false;
        //     }
        //
        //     if(!urlId){
        //         return false;
        //     }
        //
        //     return url == urls ? true : false;
        //
        // }),
        linkable: Ember.computed('url', 'urlId', function () {
            var urls = ['admin.assignments.view', 'admin.management.user-requests.view', 'contractee.assignments.view', 'contractee.view', 'contractor.view', 'contractor.assignments.view', 'contractee.assignments.select-worker', 'contractee.vacancy.select-applicants', 'contractee.vacancy.view', 'contractor.vacancy.view', 'contractor.user-requests.view'];

            var urlId = Ember.get(this, 'urlId');
            var url = Ember.get(this, 'url');

            if (!url) {
                return false;
            }

            if (!urlId) {
                return false;
            }

            var inUrl = Ember.$.inArray(url, urls);

            if (inUrl >= 0) {
                return true;
            }
            return false;
        }),

        fromName: Ember.computed('contractee', 'from', function () {
            if (Ember.get(this, 'contractee.id')) {
                return Ember.get(this, 'contractee.name');
            } else {
                return Ember.get(this, 'from.fullName');
            }
        })
    });
});