define('uwork-app-v2/helpers/custom-format-currency', ['exports', 'npm:numeral', 'npm:numeral/locales'], function (exports, _npmNumeral, _locales) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.concurrencyFormater = concurrencyFormater;
    exports.customFormatCurrency = customFormatCurrency;
    function concurrencyFormater(number) {
        _npmNumeral.default.locale('no');
        return (0, _npmNumeral.default)(number).format('$ 0,0.00');
    }

    function customFormatCurrency(params /*, hash*/) {
        var number = params[0];

        return concurrencyFormater(number);
    }

    exports.default = Ember.Helper.helper(customFormatCurrency);
});