define('uwork-app-v2/user-request/model', ['exports', 'ember-data', 'ember-data/attr', 'ember-data/relationships'], function (exports, _emberData, _attr, _relationships) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.schema = undefined;


    var TYPE_VERIFICATION = "verification";
    var TYPE_VERIFICATION_COMPANY = "verification_company";

    var STATUS_DEFAULT = null;
    var STATUS_ACCEPTED = 10;
    var STATUS_DECLINED = 20;

    var schema = exports.schema = {
        /**
         * Attributes
         */
        type: (0, _attr.default)(),
        body: (0, _attr.default)(),
        decision: (0, _attr.default)(),
        status: (0, _attr.default)('number'),
        createdAt: (0, _attr.default)('mdate'),
        remindAt: (0, _attr.default)('mdate'),
        handledAt: (0, _attr.default)('mdate')
    };

    exports.default = _emberData.default.Model.extend(schema, {

        /**
         * Relations
         */
        user: (0, _relationships.belongsTo)('user'),
        handler: (0, _relationships.belongsTo)('user', { async: false }),

        /**
         * Computed properties
         */
        title: Ember.computed('type', function () {
            var type = Ember.get(this, 'type');
            var user = Ember.get(this, 'user');

            if (type === this.getVerificationType()) {
                return Ember.get(user, 'fullName') + " ønsker å bli verifisert som vikar.";
            }

            return '';
        }),

        reason: Ember.computed('type', function () {
            var type = Ember.get(this, 'type');

            if (type === this.getVerificationType()) {
                return "Ønsker å bli verifisert som vikar.";
            }

            return '';
        }),

        statusDescription: Ember.computed('status', function () {
            var status = Ember.get(this, 'status');

            if (status === STATUS_ACCEPTED) {
                return "Akseptert";
            }

            if (status === STATUS_DECLINED) {
                return "Avslått";
            }

            return "Ubehandlet";
        }),

        isNotHandled: Ember.computed.equal('status', STATUS_DEFAULT),
        isVerificationType: Ember.computed.equal('type', TYPE_VERIFICATION),
        isVerificationCompanyType: Ember.computed.equal('type', TYPE_VERIFICATION_COMPANY),
        isHandled: Ember.computed.not('isNotHandled'),

        /**
         * Setters & Getters
         */
        template: Ember.computed('type', function () {
            return 'admin/user-request/template-' + this.getType();
        }),
        setType: function setType(value) {
            Ember.set(this, 'type', value);
        },
        getType: function getType() {
            return Ember.get(this, 'type');
        },
        getVerificationCompnayType: function getVerificationCompnayType() {
            return TYPE_VERIFICATION_COMPANY;
        },
        setVerificationCompnayType: function setVerificationCompnayType() {
            Ember.set(this, 'type', TYPE_VERIFICATION_COMPANY);
        },
        getVerificationType: function getVerificationType() {
            return TYPE_VERIFICATION;
        },
        setVerificationType: function setVerificationType() {
            Ember.set(this, 'type', TYPE_VERIFICATION);
        },
        getStatus: function getStatus() {
            return Ember.get(this, 'status');
        },
        setStatusAccepted: function setStatusAccepted() {
            Ember.set(this, 'status', STATUS_ACCEPTED);
        },
        setStatusDeclined: function setStatusDeclined() {
            Ember.set(this, 'status', STATUS_DECLINED);
        }
    });
});