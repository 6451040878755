define('uwork-app-v2/components/admin/card/main-vacancy-select/component', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        media: Ember.inject.service(),
        session: Ember.inject.service(),
        store: Ember.inject.service(),
        menu: Ember.inject.service('extend-menu'),
        vacancyService: Ember.inject.service('vacancy'),
        skills: Ember.computed.alias('contractor.user.skills'),
        classNames: ['user-card', 'd-flex', 'bg-white', 'align-items-stretch', 'mt-4']
    });
});