define('uwork-app-v2/components/contractor/settings/skills/display-main/component', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        session: Ember.inject.service(),
        store: Ember.inject.service(),
        intercom: Ember.inject.service(),
        account: Ember.inject.service('user/session-account'),
        user: Ember.computed.alias('account.currentUser'),
        contractor: Ember.computed.alias('user.contractor'),
        isWizard: Ember.computed.equal('user.isSignUpCompleted', false),
        isEditable: Ember.computed.oneWay('user.editable'),
        wizardService: Ember.inject.service('contractor/wizard'),

        // Sort contractee by name/title
        sortByName: ['name:asc'],
        sortedSkills: Ember.computed.sort('skills', 'sortByName'),
        sortByTitle: ['title:asc'],
        sortedCourses: Ember.computed.sort('courses', 'sortByTitle'),

        courses: Ember.computed('model.courses.@each', 'account.currentUser.courses.@each', function () {
            var selected = Ember.get(this, 'account.currentUser.courses');
            var courses = Ember.get(this, 'model.courses');

            courses.forEach(function (course) {
                var id = Ember.get(course, 'id');
                var _selected = selected.findBy('id', id);
                if (_selected) {
                    Ember.set(course, 'checked', true);
                }
            });

            return courses;
        }),

        skills: Ember.computed('model.skills.@each', 'account.currentUser.skills.@each', function () {
            var selected = Ember.get(this, 'account.currentUser.skills');
            var skills = Ember.get(this, 'model.skills');

            skills.forEach(function (skill) {
                var id = Ember.get(skill, 'id');
                var _selected = selected.findBy('id', id);
                if (_selected) {
                    Ember.set(skill, 'checked', true);
                }
            });

            return skills;
        }),

        addSkillsToUser: function addSkillsToUser(user) {
            var model = Ember.get(this, 'model.skills');
            if (model) {
                model.forEach(function (skill) {
                    if (skill.get('checked')) {
                        user.get('skills').pushObject(skill);
                    } else {
                        user.get('skills').removeObject(skill);
                    }
                });
            }
        },
        addCoursesToUser: function addCoursesToUser(user) {
            var model = Ember.get(this, 'model.courses');
            if (model) {
                model.forEach(function (course) {
                    if (course.get('checked')) {
                        user.get('courses').pushObject(course);
                    } else {
                        user.get('courses').removeObject(course);
                    }
                });
            }
        },


        actions: {
            save: function save() {
                var _this = this;

                var user = Ember.get(this, 'user');
                this.addSkillsToUser(user);
                this.addCoursesToUser(user);

                user.save().then(function () {

                    _this.swal({
                        title: "Dine opplysninger har blitt laget!",
                        timer: 1000,
                        showConfirmButton: false,
                        showCloseButton: false,
                        type: 'success'
                    });
                });
            },
            saveNext: function saveNext() {
                var _this2 = this;

                var user = Ember.get(this, 'user');
                var next = Ember.get(this, 'wizardService').getNextRoute();

                this.addSkillsToUser(user);
                this.addCoursesToUser(user);

                user.save().then(function () {
                    _this2.swal({
                        title: "Dine opplysninger har blitt laget!",
                        timer: 1000,
                        showConfirmButton: false,
                        showCloseButton: false,
                        type: 'success'
                    }).then(function () {
                        _this2.sendAction('toRoute', Ember.get(next, 'route'));
                    }, function () {
                        _this2.sendAction('toRoute', Ember.get(next, 'route'));
                    });
                });
            },
            skip: function skip() {
                var next = Ember.get(this, 'wizardService').getNextRoute();

                if (next) {
                    this.sendAction('toRoute', Ember.get(next, 'route'));
                }
            },
            startIntercom: function startIntercom() {
                this.get('intercom.api')('show');
            }
        }
    });
});