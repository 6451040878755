define('uwork-app-v2/contractee/wizard/service', ['exports', 'ember-local-storage', 'uwork-app-v2/substitute/model', 'uwork-app-v2/vacancy/model', 'uwork-app-v2/mixins/has-role-mixin'], function (exports, _emberLocalStorage, _model, _model2, _hasRoleMixin) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Service.extend(_hasRoleMixin.default, {
        storage: (0, _emberLocalStorage.storageFor)('contractee/wizard'),
        ROLE_NAME_VACANCY: _model2.ROLE_NAME,
        ROLE_NAME_SUBSTITUTE: _model.ROLE_NAME,

        links: Ember.computed('user', function () {

            if (this.hasRole(_model.ROLE_NAME)) {
                return [{ id: 1, route: 'contractee.settings.index', name: 'contractee.settings.link.index' },
                // {id: 1, route: 'contractee.settings.account', name: 'contractee.settings.link.account'},
                { id: 2, route: 'contractee.settings.avatar', name: 'contractee.settings.link.avatar' }, { id: 3, route: 'contractee.settings.category', name: 'contractee.settings.link.category' }, { id: 4, route: 'contractee.settings.company', name: 'contractee.settings.link.company' }, { id: 5, route: 'contractee.settings.nda', name: 'contractee.settings.link.nda' }, { id: 6, route: 'contractee.settings.rates', name: 'contractee.settings.link.rates' }, { id: 7, route: 'contractee.settings.confirm', name: 'contractee.settings.link.confirm'
                    //{id: 7, route: 'contractee.settings.agreement', name: 'contractee.settings.link.agreement'}
                }];
            }
            if (this.hasRole(_model2.ROLE_NAME)) {
                return [{ id: 1, route: 'contractee.settings.index', name: 'contractee.settings.link.index' },
                // {id: 1, route: 'contractee.settings.account', name: 'contractee.settings.link.account'},
                { id: 2, route: 'contractee.settings.avatar', name: 'contractee.settings.link.avatar' }, { id: 3, route: 'contractee.settings.category', name: 'contractee.settings.link.category' }, { id: 4, route: 'contractee.settings.company', name: 'contractee.settings.link.company' }, { id: 5, route: 'contractee.settings.confirm', name: 'contractee.settings.link.confirm'
                    //{id: 5, route: 'contractee.settings.agreement', name: 'contractee.settings.link.agreement'}
                }];
            }
        }),

        visitedRoutes: Ember.computed.alias('storage.visitedRoutes'),
        current: null,

        getCurrent: function getCurrent() {
            return Ember.get(this, 'current');
        },
        getFirst: function getFirst() {
            return Ember.get(this, 'links').findBy('id', 1);
        },


        getNavLinks: Ember.computed('visitedRoutes.[]', 'links', function () {
            var visited = Ember.get(this, 'visitedRoutes').sortBy('id');
            var last = visited.get('lastObject');

            if (last) {
                return Ember.get(this, 'links').slice(0, Ember.get(last, 'id'));
            }

            return [this.getFirst()];
        }),

        setCurrentRoute: function setCurrentRoute(route) {
            var links = Ember.get(this, 'links');
            var nav = links.findBy('route', route);
            var visited = Ember.get(this, 'visitedRoutes').sortBy('id');

            if (nav) {
                Ember.set(this, 'current', nav);

                // Add only if it does not exists
                if (!visited.findBy('route', route)) {
                    Ember.set(this, 'visitedRoutes', visited.addObject(nav));
                }
            }
        },
        getNextRoute: function getNextRoute() {
            var current = this.getCurrent();
            var links = Ember.get(this, 'links');
            var next = links.findBy('id', parseInt(Ember.get(current, 'id') + 1));
            if (next) {
                return next;
            }

            return false;
        },
        getPrev: function getPrev() {
            var current = this.getCurrent();
            var links = Ember.get(this, 'links');
            var next = links.findBy('id', parseInt(Ember.get(current, 'id') - 1));
            if (next) {
                return next;
            }

            return false;
        },
        resetVisitedRoutes: function resetVisitedRoutes() {
            Ember.set(this, 'visitedRoutes', []);
        }
    });
});