define('uwork-app-v2/components/admin/timesheet/shift-item/component', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        tagName: 'tr',
        // click(){
        //     this.get('clicked')(get(this, 'model.orderId'));
        // },

        classNameBindings: ['model.isAbsence:is-absence'],
        actions: {
            leave: function leave(shift) {
                this.sendAction('leave', shift);
            },
            showRestComment: function showRestComment(shift) {
                this.sendAction('showRestComment', shift);
            }
        }
    });
});