define('uwork-app-v2/mixins/vacancy-mixin', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Mixin.create({
        vacancyTypes: [{ id: 1, name: 'Fast', value: 1 }, { id: 2, name: 'Deltid', value: 10 }, { id: 3, name: 'Prosjekt', value: 20 }, { id: 4, name: 'Vikariat', value: 30 }, { id: 5, name: 'Engasjement', value: 40 }, { id: 6, name: 'Trainee', value: 50 }, { id: 7, name: 'Sommer/Sesong', value: 60 }, { id: 8, name: 'Lærling', value: 70 }],
        vacancyStatuses: [{ id: 1, name: 'Kladd', value: 1 }, { id: 2, name: 'Ikke publisert', value: 10 }, { id: 3, name: 'Publisert', value: 20 }, { id: 4, name: 'Søkere valgt', value: 30 }, { id: 5, name: 'Ferdig', value: 40 }, { id: 5, name: 'Arkivert', value: 50 }],
        getStatus: function getStatus(value) {
            var statuses = Ember.get(this, 'vacancyStatuses');
            var type = statuses.findBy('value', value);

            if (type) {
                return Ember.get(type, 'name');
            }
            return '';
        },
        getType: function getType(value) {
            var types = Ember.get(this, 'vacancyTypes');
            var type = types.findBy('value', value);

            if (type) {
                return Ember.get(type, 'name');
            }
            return '';
        }
    });
});