define('uwork-app-v2/validations/vacancy', ['exports', 'ember-changeset-validations/validators', 'uwork-app-v2/validations/found-zipcode'], function (exports, _validators, _foundZipcode) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = {
        title: [(0, _validators.validatePresence)({ presence: true, description: 'Stillingstittel' }), (0, _validators.validateLength)({ min: 4, description: 'Stillingstittel' })],
        numberOfPositions: [(0, _validators.validatePresence)({ presence: true, description: 'Søknadsfrist' }), (0, _validators.validateNumber)({ number: true, description: 'Søknadsfrist' })],
        description: [(0, _validators.validatePresence)({ presence: true, description: 'Beskrivelse' }), (0, _validators.validateLength)({ min: 4, description: 'Beskrivelse' })],
        //address: validatePresence({ presence: true, description: 'Adresse' }),
        place: (0, _validators.validatePresence)({ presence: true, description: 'Poststed' }),

        vacancyOccupations: [(0, _validators.validatePresence)({ presence: true, description: 'Stillingsfunksjon' }), (0, _validators.validateLength)({ min: 1 })],
        zipCode: [(0, _validators.validatePresence)({ presence: true, description: 'Postnummer' }), (0, _validators.validateLength)({ min: 4 }), (0, _foundZipcode.default)()],
        type: (0, _validators.validatePresence)({ presence: true, description: 'Stillingstype' }),
        deadlineAt: (0, _validators.validatePresence)({ presence: true, description: 'Søknadsfrist' })
        // status: validatePresence({ presence: true, description: 'Status' }),
    };
});