define('uwork-app-v2/components/bread-crumbs', ['exports', 'ember-crumbly/components/bread-crumbs'], function (exports, _breadCrumbs) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _breadCrumbs.default;
    }
  });
});