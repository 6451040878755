define('uwork-app-v2/mixins/order/new-order', ['exports', 'moment', 'uwork-app-v2/mixins/order/validation', 'uwork-app-v2/order/model', 'ember-changeset-validations', 'ember-changeset'], function (exports, _moment2, _validation, _model, _emberChangesetValidations, _emberChangeset) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var keys = Object.keys;
    exports.default = Ember.Mixin.create({
        store: Ember.inject.service(),
        account: Ember.inject.service('user/session-account'),
        user: Ember.computed.alias('account.currentUser'),
        templates: Ember.computed.filterBy('model.templates', 'isNew', false),
        order: Ember.computed.alias('model.order'),
        newOrder: Ember.observer('order', function () {
            this._changeset();
        }),
        // Changeset
        validation: _validation.default,
        _changeset: function _changeset() {
            Ember.set(this, 'schema', Ember.get(this, 'order'));
            Ember.set(this, 'changeset', new _emberChangeset.default(Ember.get(this, 'schema'), (0, _emberChangesetValidations.default)(_validation.default), _validation.default));
            Ember.set(this, 'changeset.notify', Ember.get(this, 'notify'));
        },


        notify: 1,
        btnText: 'Fortsett',
        minDate: (0, _moment2.default)().format('YYYY-MM-DD'),
        durations: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10'],
        times: ['00:00', '00:30', '01:00', '01:30', '02:00', '02:30', '03:00', '03:30', '04:00', '04:30', '05:00', '05:30', '06:00', '06:30', '07:00', '07:30', '08:00', '08:30', '09:00', '09:30', '10:00', '10:30', '11:00', '11:30', '12:00', '12:30', '13:00', '13:30', '14:00', '14:30', '15:00', '15:30', '16:00', '16:30', '17:00', '17:30', '18:00', '18:30', '19:00', '19:30', '20:00', '20:30', '21:00', '21:30', '22:00', '22:30', '23:00', '23:30', '24:00'],

        shiftsTimeRange: [{ id: 1, 'time_start': '09:00', 'time_end': '09:00', 'name': 'Døgnvakt' }, { id: 2, 'time_start': '08:00', 'time_end': '16:00', 'name': 'Dagvakt' }, { id: 3, 'time_start': '16:00', 'time_end': '22:00', 'name': 'Kveldsvakt' }, { id: 4, 'time_start': '23:00', 'time_end': '08:00', 'name': 'Nattvakt' }],

        //set Start time depending on time and start date
        startTimes: Ember.computed('changeset.startDate', 'changeset.startTime', function () {
            var times = Ember.get(this, 'times');

            var date_today = (0, _moment2.default)().format('YYYY-MM-DD HH:mm');

            if (Ember.get(this, 'changeset.startDate')) {

                var startDate = '';
                var startTime = Ember.get(this, 'changeset.startTime');

                //DayShift will set Moment Object
                if (Ember.get(this, 'changeset.startDate') instanceof _moment2.default) {
                    startDate = Ember.get(this, 'changeset.startDate').format("YYYY-MM-DD");
                } else {
                    //DatePicker will set String Object
                    startDate = (0, _moment2.default)(Ember.get(this, 'changeset.startDate')).format("YYYY-MM-DD");
                }

                //concat string to check if now is early then date range
                startDate = startDate + ' ' + startTime;

                //Set Next Hour if date range is in Past && Slice the rest of range of past
                if ((0, _moment2.default)(date_today).isSameOrAfter(startDate)) {
                    var nextHour = (0, _moment2.default)().startOf('hour').add(1, 'hours').format('HH:mm');

                    var index = times.indexOf(nextHour);

                    Ember.set(this, 'changeset.startTime', nextHour);

                    if (index >= times.get('length')) {
                        return times.slice(index - 1);
                    }

                    return times.slice(index);
                }
            }
            return times;
        }),

        // endTime - check if is today timeEnd should not kill startTime ( mos e le me hi minus )
        endTimes: Ember.computed('changeset.startTime', function () {
            var startTime = Ember.get(this, 'changeset.startTime');
            var times = Ember.get(this, 'times');
            var shiftID = Ember.get(this, 'changeset.shift.id');

            //Return full range if is night shift
            if (shiftID == 4 || shiftID == 1) {
                return times;
            } else if (startTime) {
                //send only HH after dateStart
                var index = times.indexOf(startTime) + 1;
                if (index >= times.get('length')) {
                    return times.slice(index - 1);
                }

                return times.slice(index);
            }

            return times;
        }),

        generateOrderShift: function generateOrderShift(changeset, duration) {
            var store = Ember.get(this, 'store');
            var item = store.createRecord('order-shift');

            var shift = Ember.get(changeset, 'shift');
            var startDate = (0, _moment2.default)(Ember.get(changeset, 'startDate')).format('YYYY-MM-DD');
            var startTime = Ember.get(changeset, 'startTime');
            var endTime = Ember.get(changeset, 'endTime');
            var fromDate = (0, _moment2.default)(startDate + ' ' + startTime).add(duration, 'day').format("YYYY-MM-DD HH:mm:ss");
            var toDate = (0, _moment2.default)(startDate + ' ' + endTime).add(duration, 'day').format("YYYY-MM-DD HH:mm:ss");

            Ember.set(item, 'shift', shift);
            Ember.set(item, 'fromDate', fromDate);

            //Check if is Døgnvakt or Nattvakt
            if (item.isNightShift()) {
                toDate = (0, _moment2.default)(startDate + ' ' + endTime).add(duration + 1, 'day').format("YYYY-MM-DD HH:mm:ss");
            }

            Ember.set(item, 'toDate', toDate);

            return item;
        },

        actions: {
            save: function save(changeset) {
                var _this = this;

                var snapshot = changeset.snapshot();
                var shifts = Ember.get(changeset, 'orderShifts');

                // Reset errors & success variable
                Ember.set(this, 'errors', null);
                Ember.set(this, 'success', null);

                return changeset.cast(keys(_model.schema)).validate().then(function () {
                    if (Ember.get(changeset, 'isValid')) {

                        _this.swal({
                            title: "Bekreft vakt",
                            html: "<p class='p-3 text-gray-dark'>Når vakt blitt lagt ut vil vikarene kunne melde sin interesse. Du vil finne vakten i. <a href=''>min oversikt</a>.</p>",
                            type: "info",
                            showCancelButton: false,
                            confirmButtonColor: "#14CA9C",
                            confirmButtonText: "Legg ut vakt!",
                            showCloseButton: true
                        }).then(function () {
                            // Save order
                            var first = shifts.get('firstObject');
                            var last = shifts.get('lastObject');

                            Ember.set(changeset, 'startDate', (0, _moment2.default)(Ember.get(first, 'fromDate')).format('YYYY-MM-DD'));
                            Ember.set(changeset, 'endDate', (0, _moment2.default)(Ember.get(last, 'fromDate')).format('YYYY-MM-DD'));
                            Ember.set(changeset, 'fromDate', Ember.get(first, 'fromDate'));
                            Ember.set(changeset, 'toDate', Ember.get(last, 'toDate'));
                            changeset.save().then(function (order) {

                                //console.log('order', order);
                                // Save order shifts
                                shifts.forEach(function (shift) {
                                    //console.log('shift', order);
                                    shift.set('order', order);
                                    shift.save();
                                });

                                // Transist to route
                                _this.transitionToRoute('contractee.assignments.view', order.get('id'));
                            });
                        }, function () {});
                    } else {
                        //console.log('Form is not valid');
                    }
                }).catch(function () {
                    changeset.restore(snapshot);
                });
            },
            buildOrderShifts: function buildOrderShifts(changeset) {
                var _this2 = this;

                var snapshot = changeset.snapshot();

                Ember.set(this, 'btnText', 'Oppdater');

                // Reset errors & success variable
                Ember.set(this, 'errors', null);
                Ember.set(this, 'success', null);

                return changeset.cast(keys(_model.schema)).validate().then(function () {
                    if (Ember.get(changeset, 'isValid')) {

                        // Build order shifts
                        var shifts = Ember.get(changeset, 'orderShifts');
                        var duration = Ember.get(changeset, 'duration');

                        // Reset previus shifts
                        shifts.setObjects([]);

                        // Loop through duration and generate order shifts
                        var i = 0;
                        for (i; i < duration; i++) {
                            var item = _this2.generateOrderShift(changeset, i);
                            shifts.pushObject(item);
                        }
                    } else {
                        //console.log('isNotValid');
                    }
                }).catch(function () {
                    changeset.restore(snapshot);
                });
            },


            //Create Duration if not on list (press Enter to create)
            setDurationOnEnter: function setDurationOnEnter(select, e) {
                if (e.keyCode === 13 && select.isOpen && !select.highlighted && !Ember.isBlank(select.searchText)) {
                    this.get('durations').addObject(select.searchText);
                }
            },


            //Set description from template
            setDescription: function setDescription(value) {
                var description = Ember.get(value, 'body');
                Ember.set(this, 'order.description', description);
            },
            setNotify: function setNotify(changeset) {
                Ember.set(changeset, 'notify', Ember.get(this, 'notify'));
            },
            shiftTypeChanged: function shiftTypeChanged(changeset) {
                //Get Shift Type
                var shift = Ember.get(changeset, 'shift.id');

                //let shiftID = shift.get('id');
                //Get Today Date
                var _moment = (0, _moment2.default)(); //.add(1, 'day');

                //Get Shifts Range PreDefined
                var shiftsTimeRange = Ember.get(this, 'shiftsTimeRange');

                //Find TypeShift Range
                var shiftType = shiftsTimeRange.findBy('id', parseInt(shift));

                //Don't change date if is set
                if (!Ember.get(changeset, 'startDate')) {
                    Ember.set(changeset, 'startDate', _moment);
                }

                //Set predefined range - filter is set at observer up
                Ember.set(changeset, 'startTime', shiftType.time_start);
                Ember.set(changeset, 'endTime', shiftType.time_end);

                //Don't Change duration if is set
                if (!Ember.get(changeset, 'duration')) {
                    Ember.set(changeset, 'duration', 1);
                }
            }
        }
    });
});