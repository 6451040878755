define('uwork-app-v2/components/contractee/sign-up-form/validation', ['exports', 'ember-changeset-validations/validators'], function (exports, _validators) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = {
        firstName: (0, _validators.validatePresence)(true),
        lastName: (0, _validators.validatePresence)(true),
        email: (0, _validators.validateFormat)({ type: 'email' }),
        contracteeName: (0, _validators.validatePresence)(true),
        password: [(0, _validators.validatePresence)(true), (0, _validators.validateLength)({ min: 8 })],
        phone: [(0, _validators.validatePresence)(true), (0, _validators.validateLength)({ min: 8 })]
    };
});