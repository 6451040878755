define('uwork-app-v2/order-shifts/add-absence/model', ['exports', 'ember-data/model', 'ember-data/attr', 'ember-data/relationships', 'ember-cp-validations'], function (exports, _model, _attr, _relationships, _emberCpValidations) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.schema = undefined;


    var ABSENCE_TYPE_SELF_DECLARATION = 10;
    var ABSENCE_TYPE_SICK_LEAVE = 20;
    var ABSENCE_TYPE_OTHER = 30;

    var Validations = (0, _emberCpValidations.buildValidations)({
        absenceType: {
            description: 'Hviletype',
            validators: [(0, _emberCpValidations.validator)('number', {
                allowString: false,
                integer: true,
                gt: 0,
                message: 'Please select one'
            }), (0, _emberCpValidations.validator)('ds-error')]
        }
    }, { debounce: 500 });

    var schema = exports.schema = {
        absenceType: (0, _attr.default)('number', { defaultValue: 0 }),
        absenceComment: (0, _attr.default)('string')
    };

    exports.default = _model.default.extend(schema, Validations, {
        isSelfDeclaration: Ember.computed.equal('absenceType', ABSENCE_TYPE_SELF_DECLARATION),
        isSickLeave: Ember.computed.equal('absenceType', ABSENCE_TYPE_SICK_LEAVE),
        isOther: Ember.computed.equal('absenceType', ABSENCE_TYPE_OTHER),
        orderShift: (0, _relationships.belongsTo)('order-shift')
    });
});