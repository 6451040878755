define('uwork-app-v2/admin/contractee/edit/route', ['exports', 'ember-i18n'], function (exports, _emberI18n) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        title: (0, _emberI18n.translationMacro)('admin.contractee.profile.PageName'),
        breadCrumb: {},
        beforeModel: function beforeModel() {
            this.setLoading(true);
        },
        model: function model(params) {
            return Ember.RSVP.hash({
                contractee: this.store.queryRecord('contractee', {
                    contractee_id: params.contractee_id,
                    view: true
                }).catch(function () {}),
                categories: this.store.findAll('category'),
                roles: this.store.findAll('role'),
                payments: this.store.query('contractee-payment', { contractee_id: params.contractee_id })
            });
        },
        afterModel: function afterModel() {
            var title = this.get('title');

            var bTitle = {
                title: title.string
            };
            this.setLoading(false);
            Ember.set(this, 'breadCrumb', bTitle);
        }
    });
});