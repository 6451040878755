define('uwork-app-v2/mdate/transform', ['exports', 'ember-data', 'moment'], function (exports, _emberData, _moment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
        return typeof obj;
    } : function (obj) {
        return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
    };

    exports.default = _emberData.default.Transform.extend({
        deserialize: function deserialize(serialized) {
            var type = typeof serialized === 'undefined' ? 'undefined' : _typeof(serialized);

            if ((type === "string" || type === "number" || type === "object") && isNaN(serialized)) {
                return (0, _moment.default)(serialized);
            } else if (serialized instanceof Date && !isNaN(serialized)) {
                return (0, _moment.default)(serialized).format('YYYY-MM-DD HH:mm:ss');
            } else if (serialized === null || serialized === undefined) {
                // if the value is null return null
                // if the value is not present in the data return undefined
                return serialized;
            } else {
                return null;
            }
        },
        serialize: function serialize(deserialized) {
            var type = typeof deserialized === 'undefined' ? 'undefined' : _typeof(deserialized);

            if (deserialized instanceof Date && !isNaN(deserialized)) {
                return (0, _moment.default)(deserialized).format('YYYY-MM-DD HH:mm:ss');
            } else if (deserialized !== null && (type === "string" || type === "number" || type === "object")) {
                return (0, _moment.default)(deserialized).format('YYYY-MM-DD HH:mm:ss');
            } else if (deserialized === null || deserialized === undefined) {
                // if the value is null return null
                // if the value is not present in the data return undefined
                return deserialized;
            } else {
                return null;
            }
        }
    });
});