define('uwork-app-v2/contractor/wizard/service', ['exports', 'ember-local-storage', 'uwork-app-v2/mixins/has-role-mixin', 'uwork-app-v2/substitute/model', 'uwork-app-v2/vacancy/model'], function (exports, _emberLocalStorage, _hasRoleMixin, _model, _model2) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Service.extend(_hasRoleMixin.default, {
        ROLE_NAME_VACANCY: _model2.ROLE_NAME,
        ROLE_NAME_SUBSTITUTE: _model.ROLE_NAME,
        storage: (0, _emberLocalStorage.storageFor)('contractor/wizard'),
        links: Ember.computed('auth', function () {

            if (this.hasRole(_model.ROLE_NAME)) {
                return [{ id: 1, route: 'contractor.settings.index', name: 'contractor.settings.link.index' }, { id: 2, route: 'contractor.settings.account', name: 'contractor.settings.link.account' }, { id: 3, route: 'contractor.settings.education', name: 'contractor.settings.link.education' }, { id: 4, route: 'contractor.settings.experience', name: 'contractor.settings.link.experience' }, { id: 5, route: 'contractor.settings.skills', name: 'contractor.settings.link.skills' },
                // {id: 6, route: 'contractor.settings.others', name: 'contractor.settings.link.others'},
                { id: 6, route: 'contractor.settings.avatar', name: 'contractor.settings.link.avatar' }, { id: 7, route: 'contractor.settings.confirm', name: 'contractor.settings.link.confirm' }];
            }

            if (this.hasRole(_model2.ROLE_NAME)) {
                return [{ id: 1, route: 'contractor.settings.index', name: 'contractor.settings.link.index' }, { id: 2, route: 'contractor.settings.account', name: 'contractor.settings.link.account' }, { id: 3, route: 'contractor.settings.education', name: 'contractor.settings.link.education' }, { id: 4, route: 'contractor.settings.experience', name: 'contractor.settings.link.experience' }, { id: 5, route: 'contractor.settings.skills', name: 'contractor.settings.link.skills' }, { id: 6, route: 'contractor.settings.avatar', name: 'contractor.settings.link.avatar' }, { id: 7, route: 'contractor.settings.confirm', name: 'contractor.settings.link.confirm' }];
            }

            return [];
        }),

        visitedRoutes: Ember.computed.alias('storage.visitedRoutes'),
        current: null,

        getCurrent: function getCurrent() {
            return Ember.get(this, 'current');
        },
        getFirst: function getFirst() {
            return Ember.get(this, 'links').findBy('id', 1);
        },


        getNavLinks: Ember.computed('visitedRoutes.[]', 'links', function () {
            var visited = Ember.get(this, 'visitedRoutes').sortBy('id');
            var last = visited.get('lastObject');

            if (last) {
                return Ember.get(this, 'links').slice(0, Ember.get(last, 'id'));
            }

            return [this.getFirst()];
        }),

        setCurrentRoute: function setCurrentRoute(route) {
            var links = Ember.get(this, 'links');
            var nav = links.findBy('route', route);
            var visited = Ember.get(this, 'visitedRoutes').sortBy('id');

            if (nav) {
                Ember.set(this, 'current', nav);

                // Add only if it does not exists
                if (!visited.findBy('route', route)) {
                    Ember.set(this, 'visitedRoutes', visited.addObject(nav));
                }
            }
        },
        getNextRoute: function getNextRoute() {
            var current = this.getCurrent();
            var links = Ember.get(this, 'links');
            var next = links.findBy('id', parseInt(Ember.get(current, 'id') + 1));
            if (next) {
                return next;
            }

            return false;
        },
        getPrev: function getPrev() {
            var current = this.getCurrent();
            var links = Ember.get(this, 'links');
            var next = links.findBy('id', parseInt(Ember.get(current, 'id') - 1));
            if (next) {
                return next;
            }

            return false;
        },
        resetVisitedRoutes: function resetVisitedRoutes() {
            Ember.set(this, 'visitedRoutes', []);
        }
    });
});