define('uwork-app-v2/contractee/assignments/active-requests/controller', ['exports', 'moment', 'ember-cli-jsonapi-pagination/mixins/controllers/jsonapi-pagination'], function (exports, _moment, _jsonapiPagination) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend(_jsonapiPagination.default, {
        media: Ember.inject.service(),
        queryParams: ['status', 'to_date', 'from_date'],
        status: '1',
        from_date: Ember.computed('size', 'number', 'model', function () {
            return '>' + (0, _moment.default)().format();
        }),
        // to_date: computed(function(){
        //     return '<' + moment().add(1,'month').format();
        // }),
        totalPages: Ember.computed('size', 'number', 'model', function () {
            return Ember.get(this, 'model.meta.pagination.total_pages');
        }),
        outerWindow: Ember.computed('media.isXs', 'media.isSm', 'media.isMd', 'media.isLg', 'media.isXl', function () {
            var media = Ember.get(this, 'media');

            if (media.get('isXs') || media.get('isSm')) {
                return 1;
            } else {
                return 3;
            }
        })
    });
});